import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {defineMessages, useIntl} from 'react-intl';
import {type DragElementWrapper, type DragSourceOptions} from 'react-dnd';
import classNames from 'classnames';

import Icon from 'components/Icon';
import {
  changePositionMediaSource,
  createMediaSource,
  deleteMediaSource,
  getMediaSources,
  getMediaSourcesErrors,
  MediaSourceField,
  updateMediaSource
} from 'store/exercise/editor/actions/mediaSources';
import {resetMediaSourcesErrors} from 'store/exercise/editor/actions/xeditor';
import genKey from 'components/Slate/utils/genKey';
import DragHandle from 'components/DragHandle/DragHandle';

import {preparedKeyValueData} from './utils';
import {KeyValueBlock} from '../KeyValueBlock/KeyValueBlock';
import ValidationError from '../ValidationError';

import './MediaSources.scss';

const mediaSourcesMessages = defineMessages({
  EmptyPlaceholder: {
    id: 'XPlayer.MediaSources.EmptyPlaceholder'
  },
  AddSource: {
    id: 'XPlayer.MediaSources.AddSource'
  },
  SourcePlaceholder: {
    id: 'XPlayer.MediaSources.SourcePlaceholder'
  },
  UrlPlaceholder: {
    id: 'XPlayer.MediaSources.UrlPlaceholder'
  }
});

function MediaSourcesComponent() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const mediaResources = useSelector(getMediaSources);
  const mediaResourcesErrors = useSelector(getMediaSourcesErrors);
  const rows = useMemo(() => preparedKeyValueData(mediaResources), [mediaResources]);
  const errorMessage = useMemo(
    () => mediaResourcesErrors?.first()?.message,
    [mediaResourcesErrors]
  );

  const onAddSource = useCallback(() => dispatch(createMediaSource(genKey())), [dispatch]);
  const onDeleteSource = useCallback(
    (id: string) => {
      dispatch(deleteMediaSource(id));
      dispatch(resetMediaSourcesErrors(id));
    },
    [dispatch]
  );

  const onChangeKey = useCallback(
    (id: string, value: string) =>
      dispatch(updateMediaSource({id, value, field: MediaSourceField.Title})),
    [dispatch]
  );
  const onChangeValue = useCallback(
    (id, value) => dispatch(updateMediaSource({id, value, field: MediaSourceField.Url})),
    [dispatch]
  );
  const onDrop = useCallback(
    (dragIndex: number, hoverIndex: number) =>
      dispatch(changePositionMediaSource({startIndex: dragIndex, endIndex: hoverIndex})),
    [dispatch]
  );

  const onFocus = useCallback((id: string) => dispatch(resetMediaSourcesErrors(id)), [dispatch]);

  const renderEmptyBlock = useCallback(
    () => (
      <div className="media-sources__empty">
        {intl.formatMessage(mediaSourcesMessages.EmptyPlaceholder)}
      </div>
    ),
    [intl]
  );

  const renderFooter = useCallback(
    () => (
      <div className="media-sources__footer">
        <div className="control" onClick={onAddSource}>
          <Icon name="plus-circle" />
          {intl.formatMessage(mediaSourcesMessages.AddSource)}
        </div>
      </div>
    ),
    [intl, onAddSource]
  );

  const renderRowActions = useCallback(
    (id: string, ref: DragElementWrapper<DragSourceOptions>) => (
      <>
        <Icon name="trash" className="control-icon" onClick={() => onDeleteSource(id)} />
        <div ref={ref} className="control-drag-handle">
          <DragHandle />
        </div>
      </>
    ),
    [onDeleteSource]
  );

  return (
    <div className="xeditor-widget media-sources">
      <div
        className={classNames('widget-body', {
          'widget-has-error': Boolean(errorMessage)
        })}
      >
        <KeyValueBlock
          rows={rows}
          onChangeKey={onChangeKey}
          onChangeValue={onChangeValue}
          onFocus={onFocus}
          onDrop={onDrop}
          renderEmptyBlock={renderEmptyBlock}
          renderFooter={renderFooter}
          renderRowActions={renderRowActions}
          keyPlaceholder={intl.formatMessage(mediaSourcesMessages.SourcePlaceholder)}
          valuePlaceHolder={intl.formatMessage(mediaSourcesMessages.UrlPlaceholder)}
          errors={mediaResourcesErrors}
        />
      </div>
      <ValidationError message={errorMessage} />
    </div>
  );
}

export const MediaSources: React.FC = React.memo(MediaSourcesComponent);
