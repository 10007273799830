import {buttonLabel, buttonTitle} from '../../button/i18n';
import {ButtonType} from '../../interface';
import EditGapDND from './forms/EditGapDND';
import {GapFillType} from '../../../../interface';

import GapFill from './index';

class GapFillDND extends GapFill {
  public gap = GapFillType.DND;
  public title = buttonTitle.GapFillDND;
  public type = ButtonType.GAP_FILL_DND;
  public label = buttonLabel.GapFillDND;

  public editForm = EditGapDND;
}

export default GapFillDND;
