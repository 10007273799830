import {type Editor, type Value} from '@englex/slate';
import React, {memo, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';

import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import {
  changeCardSizeType,
  changeImage,
  changeText,
  removeCard
} from 'store/exercise/editor/widgets/XWordPictureSet/actions';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';
import TextAlignment from 'components/Slate/SlateEditor/plugins/button/TextAlignment';
import TextAlignLeft from 'components/Slate/SlateEditor/plugins/button/TextAlignment/TextAlignLeft';
import TextAlignCenter from 'components/Slate/SlateEditor/plugins/button/TextAlignment/TextAlignCenter';
import TextAlignRight from 'components/Slate/SlateEditor/plugins/button/TextAlignment/TextAlignRight';
import {type DndSortingWrapperProps} from 'components/DndSortingWrapper/DndSortingWrapper';

import {messages} from '../../messages';
import ImageCard from '../../../components/ImageCard/ImageCard';

import './WordPictureSetCard.scss';

export interface XWordPictureSetCardProps extends DndSortingWrapperProps {
  widgetId: string;
  cardId: string;
  text: Value;
  image: number;
  cardSizeType?: CardSizeType;
  resetErrors: () => void;
  setError: (e: string | React.ReactElement) => string | React.ReactElement;
  updateRecoveryPoint: () => void;
}

const slatePlugins = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting(),
  new TextAlignment({alignClasses: [TextAlignLeft, TextAlignCenter, TextAlignRight]})
];

const toolbarProp = {portalId: 'xeditor-toolbar-portal'};

export const XWordPictureSetCard: React.FC<XWordPictureSetCardProps> = memo(
  ({
    cardId,
    image,
    text,
    widgetId,
    cardSizeType,
    style,
    handle,
    isDragLayer,
    isDragging,
    resetErrors,
    setError,
    updateRecoveryPoint
  }) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();

    const onImageChange = useCallback(
      (image: number) => {
        dispatch(changeImage(widgetId, cardId, image));
        resetErrors();
      },
      [dispatch, widgetId, cardId, resetErrors]
    );

    const onEditorChange = useCallback(
      (change: Editor) => {
        dispatch(changeText(widgetId, cardId, change));
        resetErrors();
      },
      [dispatch, widgetId, cardId, resetErrors]
    );

    const onDelete = useCallback(() => {
      dispatch(removeCard(widgetId, cardId));
      resetErrors();
      updateRecoveryPoint();
    }, [cardId, dispatch, resetErrors, updateRecoveryPoint, widgetId]);

    return (
      <div
        className={classNames('word-picture-set__card', {
          'is-rectangle': cardSizeType === CardSizeType.RECTANGLE,
          'is-dragging': isDragging && !isDragLayer,
          'is-drg-layer': isDragLayer
        })}
        style={style}
      >
        <ImageCard
          id={cardId}
          xwidgetId={widgetId}
          imageId={image}
          handle={handle}
          isDragLayer={isDragLayer}
          reportError={setError}
          resetErrors={resetErrors}
          deletePair={onDelete}
          setImage={onImageChange}
          changeCardSizeTypeAction={changeCardSizeType}
        />

        <SlateEditor
          value={text}
          placeholder={!isDragLayer ? formatMessage(messages.Placeholder) : undefined}
          className="text-field"
          toolbar={toolbarProp}
          onChange={onEditorChange}
          plugins={slatePlugins}
        />
      </div>
    );
  }
);
