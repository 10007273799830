import React, {type FC, memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {List} from 'immutable';
import classNames from 'classnames';
import {useIntl} from 'react-intl';
import {type Value, type Editor} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';
import Button from 'react-bootstrap/lib/Button';

import Icon from 'components/Icon';
import {type PictureChoiceCardRecord} from 'store/exercise/player/widgets/PictureChoice/PictureChoiceCardRecord';
import {
  addCard,
  changeQuestionContent,
  deleteQuestion,
  moveQuestion,
  pictureChoiceMoveCard,
  pictureChoiceRollBackCards
} from 'store/exercise/editor/widgets/XPictureChoice/actions';
import {
  type PictureChoiceCardProperties,
  type PictureChoiceQuestionProperties
} from 'store/exercise/player/widgets/PictureChoice/interface';
import {
  type PictureChoiceMode,
  type XPictureChoiceProperties
} from 'store/exercise/editor/widgets/XPictureChoice/interface';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Highlight from 'components/Slate/SlateEditor/plugins/button/Highlight';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import {DndTypes} from 'components/dnd/interface';
import {DndSortingWrapper} from 'components/DndSortingWrapper/DndSortingWrapper';
import {Provider} from 'components/XPlayer/contexts/dndContext';

import {Card} from '../Card/Card';
import {messages} from './messages';

import './QuestionBlock.scss';

const TitlePlugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new Highlight(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting()
];

interface Props {
  widgetId: string;
  id: string;
  questionIndex: number;
  content?: Value;
  cards: List<PictureChoiceCardProperties>;
  widget: XPictureChoiceProperties;
  errors: Record<string, {blockId: string}>;
  mode: PictureChoiceMode;
  canDelete: boolean;
  canMoveUp: boolean;
  canMoveDown: boolean;
  recoveryPoint: React.MutableRefObject<List<PictureChoiceQuestionProperties>>;
  resetErrors: () => void;
  setError: (e: string | JSX.Element) => string | JSX.Element;
  updateRecoveryPoint: () => void;
}

export const QuestionBlock: FC<Props> = memo(
  ({
    widgetId,
    id,
    questionIndex,
    content,
    cards,
    widget,
    errors,
    mode,
    canDelete,
    canMoveUp,
    canMoveDown,
    recoveryPoint,
    resetErrors,
    setError,
    updateRecoveryPoint
  }) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();

    const withError = Boolean(errors.meta?.[id]);

    const onDelete = () => {
      dispatch(deleteQuestion(widgetId, id));
      updateRecoveryPoint();
    };

    const onMoveUp = () => {
      dispatch(moveQuestion(widgetId, questionIndex, questionIndex - 1));
      updateRecoveryPoint();
    };
    const onMoveDown = () => {
      dispatch(moveQuestion(widgetId, questionIndex, questionIndex + 1));
      updateRecoveryPoint();
    };

    const onChangeTitle = useCallback(
      (change: Editor) => {
        resetErrors();
        dispatch(changeQuestionContent(widgetId, questionIndex, change));
        updateRecoveryPoint();
      },
      [dispatch, questionIndex, resetErrors, updateRecoveryPoint, widgetId]
    );

    const onAddCard = () => {
      dispatch(addCard(widgetId, questionIndex));
      updateRecoveryPoint();
    };

    const moveItem = useCallback(
      (moveItemIndex: number, targetIndex: number) => {
        dispatch(pictureChoiceMoveCard(widgetId, id, moveItemIndex, targetIndex));
        updateRecoveryPoint();
      },
      [dispatch, id, updateRecoveryPoint, widgetId]
    );

    const rollBackChanges = useCallback(() => {
      dispatch(pictureChoiceRollBackCards(widgetId, recoveryPoint.current));
      updateRecoveryPoint();
    }, [dispatch, recoveryPoint, updateRecoveryPoint, widgetId]);

    const getItemIndex = useCallback(
      (cardId: string) => {
        return cards.findIndex((card: PictureChoiceCardProperties) => card.id === cardId);
      },
      [cards]
    );

    return (
      <Provider>
        <div className="pc-block">
          <div className="pc-block__header">
            <div className="title">
              {formatMessage(messages.Question)} {questionIndex + 1}
            </div>
            <div className="actions">
              <button
                className="btn btn-ico"
                disabled={!canDelete}
                onClick={onDelete}
                title={formatMessage(messages.DeleteCard)}
              >
                <Icon name="trash" />
              </button>

              <button
                className="btn btn-ico"
                onClick={onMoveUp}
                disabled={!canMoveUp}
                title={formatMessage(messages.MoveCardUp)}
              >
                <Icon name="pc-left-arrow" className="i-rotate-90" />
              </button>

              <button
                className="btn btn-ico"
                onClick={onMoveDown}
                disabled={!canMoveDown}
                title={formatMessage(messages.MoveCardDown)}
              >
                <Icon name="pc-right-arrow" className="i-rotate-90" />
              </button>
            </div>
          </div>

          {content && (
            <div className={classNames('pc-block__question', {'with-error': withError})}>
              <SlateEditor
                className="title"
                placeholder={formatMessage(messages.QuestionPlaceholder)}
                toolbar={{portalId: 'xeditor-toolbar-portal'}}
                value={content}
                onChange={onChangeTitle}
                plugins={TitlePlugins}
              />
            </div>
          )}

          <div className="pc-block__cards">
            {cards.map((card: PictureChoiceCardRecord, index: number) => {
              const checked = !!widget
                .getIn(['answers', id], List())
                .find((cardId: string) => cardId === card.id);

              const withError = Boolean(errors.meta?.[card.id]);

              return (
                <DndSortingWrapper
                  key={card?.id}
                  itemId={card?.id}
                  dndType={DndTypes.PICTURE_CHOICE}
                  isNotUseDragLayer={false}
                  ChildComponent={Card}
                  childOptions={{
                    widgetId: widgetId,
                    questionId: id,
                    id: card?.id,
                    imageId: card.imageId,
                    questionIndex: questionIndex,
                    cardIndex: index,
                    checked: checked,
                    withError: withError,
                    mode: mode,
                    cardSizeType: card.cardSizeType,
                    resetErrors: resetErrors,
                    setError: setError,
                    updateRecoveryPoint
                  }}
                  updateRecoveryPoint={updateRecoveryPoint}
                  moveItem={moveItem}
                  rollBackChanges={rollBackChanges}
                  getItemIndex={getItemIndex}
                />
              );
            })}
          </div>

          <div className="pc-block__add-picture">
            <Button onClick={onAddCard} className="add-card">
              <Icon name="plus-circle" />
              {formatMessage({id: 'XEditorWidget.ImageMatching.AddPairButton'})}
            </Button>
          </div>
        </div>
      </Provider>
    );
  }
);
