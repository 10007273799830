import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import genKey from 'components/Slate/utils/genKey';
import {valueJSONFromText} from 'components/Slate/utils';
import {
  type MultipleChoiceJSON,
  type XMultipleChoiceWizardOptions
} from 'store/exercise/player/widgets/List/interface';
import XMultipleChoiceRecord from 'store/exercise/editor/widgets/XMultipleChoice/XMultipleChoiceRecord';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';

import {type XWizardWidgetData} from '../interface';
import MultipleChoiceWizard from './MultipleChoiceWizard';
import Tile from '../Tile';
import {MultipleChoiceSVG} from '../assets';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.MultipleChoice}>
    <MultipleChoiceSVG />
  </Tile>
);

export const multipleChoiceWizardData: XWizardWidgetData<MultipleChoiceJSON, XWidgetProperties> = {
  type: WidgetType.MULTIPLE_CHOICE,
  steps: [
    {
      canGoToNextStep: (widget: XMultipleChoiceWizardOptions) => {
        return !!(widget.element && widget.defaultAnswersType);
      },
      component: MultipleChoiceWizard
    }
  ],
  tile,
  createWidget: (widget: XMultipleChoiceWizardOptions) =>
    new XMultipleChoiceRecord({
      id: genKey(),
      type: widget.type,
      task: valueJSONFromText(''),
      content: valueJSONFromText(''),
      element: widget.element,
      options: {
        defaultAnswersType: widget.defaultAnswersType,
        defaultAnswersNumber: widget.defaultAnswersNumber,
        horizontal: widget.horizontal,
        initialQuestionsNumber: widget.initialQuestionsNumber,
        withoutQuestions: widget.withoutQuestions
      }
    })
};
