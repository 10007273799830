import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import ModalBody from 'react-bootstrap/lib/ModalBody';
import ModalFooter from 'react-bootstrap/lib/ModalFooter';
import ModalHeader from 'react-bootstrap/lib/ModalHeader';
import {connect} from 'react-redux';
import {type Action, type Dispatch} from 'redux';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';
import {type WidgetJSON, type WidgetType} from 'store/exercise/player/interface';
import {type AppState} from 'store/interface';
import {
  addWidgetField,
  nextStep,
  prevStep,
  toggleWizard
} from 'store/exercise/editor/actions/xwizard';
import {addWidget} from 'store/exercise/editor/actions/xwidgets';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type ToggleElementAction} from 'common/interface';
import {type XWizardAddWidgetFieldCreator} from 'store/exercise/editor/actions/interface';

import {wizardWidgetsData} from './wizardWidgetsData';
import {type XWizardWidgetData} from './interface';
import {WizardTabs} from './WizardTabs';

import './XWizardModal.scss';

interface XWizardModalStateProps {
  showModal: boolean;
  step: number;
  widgetFields?: Partial<WidgetJSON>;
}

interface XWizardModalDispatchProps {
  closeWizard: () => ToggleElementAction;
  addWidget: (widget: XWidgetProperties) => void;
  nextStep: () => void;
  prevStep: () => void;
  addWidgetField: XWizardAddWidgetFieldCreator;
}

interface State {
  activeTab: number;
}

interface Props extends XWizardModalStateProps, XWizardModalDispatchProps {}

class XWizardModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {activeTab: 1};
  }

  public componentDidUpdate(prevProps: Props) {
    const prevPropsType = prevProps.widgetFields && prevProps.widgetFields.type;
    const thisPropsType = this.props.widgetFields && this.props.widgetFields.type;
    if (thisPropsType && prevPropsType !== thisPropsType) {
      this.goToNextStepOrAddWidget(0);
    }
  }

  public render() {
    const {showModal, step} = this.props;
    return (
      <Modal
        backdrop="static"
        onHide={step === 0 ? this.closeWizard : this.onBackBtnClick}
        show={showModal}
        className="x-wizard-modal"
      >
        <ModalHeader>
          <Modal.Title>
            {this.renderModalTitle()}
            <a onClick={this.closeWizard}>
              <Icon name="pc-close" tag="i" />
            </a>
          </Modal.Title>
        </ModalHeader>
        <ModalBody className="x-wizard-modal-body">{this.renderModalBody()}</ModalBody>
        <ModalFooter>
          <div className="modal-footer-text">
            <Button
              bsStyle="default"
              className="btn-transparent"
              bsSize="sm"
              onClick={this.closeWizard}
            >
              <FormattedMessage id="Common.Cancel" />
            </Button>
          </div>
          <div className="buttons">
            {this.renderBackButton()}
            {!!step && (
              <Button
                bsStyle="primary"
                bsSize="sm"
                onClick={() => this.goToNextStepOrAddWidget(step)}
                disabled={!showModal || this.disableNextButton()}
              >
                <FormattedMessage id="Common.Next" />
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  private closeWizard = () => {
    this.setState({activeTab: 1});
    this.props.closeWizard();
  };

  private renderBackButton = () => {
    const {step} = this.props;
    if (step === 0) {
      return null;
    }
    return (
      <Button
        bsStyle="default"
        className="btn-transparent"
        bsSize="sm"
        onClick={this.onBackBtnClick}
      >
        <FormattedMessage id="Common.Back" />
      </Button>
    );
  };

  private onBackBtnClick = () => {
    const {step} = this.props;
    this.props.prevStep();
    if (step === 1) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.props.addWidgetField('type', undefined as any);
    }
  };

  private renderModalTitle = () => {
    const {step} = this.props;
    if (step === 0) {
      return <FormattedMessage id="XEditor.Wizard.Header1" />;
    }
    return <FormattedMessage id="XEditor.Wizard.Header2" />;
  };

  private changeTab = (tab: number) => {
    this.setState({activeTab: tab});
  };

  private renderModalBody = () => {
    const {step, widgetFields, addWidgetField: addField} = this.props;
    if (step === 0) {
      return (
        <WizardTabs
          activeTab={this.state.activeTab}
          changeTab={this.changeTab}
          handleSelected={this.handleWidgetTypeSelected}
        />
      );
    }
    const WizardStep = this.getCurrentStepForSelectedWidget().component;
    return <WizardStep fields={widgetFields!} addField={addField} />;
  };

  private disableNextButton = () => {
    const {step, widgetFields} = this.props;
    if (step === 0) {
      return widgetFields && !widgetFields.type;
    }
    return !this.getCurrentStepForSelectedWidget().canGoToNextStep(widgetFields!);
  };

  private goToNextStepOrAddWidget = (step: number) => {
    const {addWidget: insertWidget, widgetFields, nextStep: goToNextStep} = this.props;
    const widgetData = this.selectedWidgetData();
    if (step === widgetData.steps.length) {
      insertWidget(widgetData.createWidget(widgetFields!));
      this.closeWizard();
    } else {
      goToNextStep();
      const nextWizardStep = this.selectedWidgetData().steps[step];
      if (nextWizardStep.skipStep && nextWizardStep.skipStep(widgetFields!)) {
        this.goToNextStepOrAddWidget(step + 1);
      }
    }
  };

  private getCurrentStepForSelectedWidget = () => {
    const {step} = this.props;
    return this.selectedWidgetData().steps[step - 1];
  };

  private selectedWidgetData = () => {
    const {type} = this.props.widgetFields!;
    return wizardWidgetsData.find(widget => widget.type === type) as XWizardWidgetData<
      WidgetJSON,
      XWidgetProperties
    >;
  };

  private handleWidgetTypeSelected = (type: WidgetType) => {
    // prevent user selecting widget while modal closing animation is in progress;
    // can be achieved by furiously pressing 'next' button multiple times
    if (!this.props.showModal) {
      return;
    }
    this.props.addWidgetField('type', type);
  };
}

const mapStateToProps = (state: AppState): XWizardModalStateProps => {
  const {xwizard} = state.xeditor!;
  return {
    showModal: !!xwizard,
    step: xwizard ? xwizard.step : 0,
    widgetFields: xwizard && xwizard.widgetFields
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): XWizardModalDispatchProps => ({
  closeWizard: () => dispatch(toggleWizard(false)),
  addWidget: (widget: XWidgetProperties) => dispatch(addWidget(widget)),
  nextStep: () => dispatch(nextStep()),
  prevStep: () => dispatch(prevStep()),
  addWidgetField: <P extends keyof WidgetJSON>(key: P, value: WidgetJSON[P]) =>
    dispatch(addWidgetField(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(XWizardModal);
