import React from 'react';

import XPreview from 'components/XPreview';
import {Resources, ViewerProvider} from 'contexts/Viewer';
import {useNode} from 'contexts/Viewer/utils';

export const XPreviewWithCarousel = () => (
  <ViewerProvider>
    <Resources id="x-preview" node={useNode('.xeditor-content')}>
      <XPreview />
    </Resources>
  </ViewerProvider>
);
