import React from 'react';
import Radio from 'react-bootstrap/lib/Radio';

import Icon from 'components/Icon';

import {type VideoItem} from './interface';

interface Props {
  node: VideoItem;
  select: (node: VideoItem) => void;
  selected: (node: VideoItem) => boolean;
}

const File: React.FC<Props> = ({node, select, selected}) => (
  <div className="file" key={node.id}>
    <div>
      <Radio checked={selected(node)} onChange={() => select(node)}>
        <span title={node.title} className="file-info">
          <Icon name="file-video-o" className="file-extension-icon" size="lg" />
          <span className="file-title">{node.title.replace(/\.[^/.]+$/, '')}</span>
        </span>
      </Radio>
    </div>
  </div>
);

export default File;
