import {TOGGLE_MULTIPLE_CHOICE_ALIGNMENT} from './actionTypes';
import {type XWidgetAction} from '../../actions/interface';

export interface ToggleMultipleChoiceAlignmentAction extends XWidgetAction {}

export const toggleMultipleChoiceAlignment = (
  xwidgetId: string
): ToggleMultipleChoiceAlignmentAction => ({
  type: TOGGLE_MULTIPLE_CHOICE_ALIGNMENT,
  xwidgetId
});
