import React from 'react';
import {FormattedMessage} from 'react-intl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';

import {VideoSourceType} from 'components/media/interface';
import {type XVideoWidgetOptions} from 'store/exercise/player/widgets/Video/interface';
import Icon from 'components/Icon';

import {type XWizardStepProps} from '../interface';
import './VideoOptions.scss';

type Props = XWizardStepProps<XVideoWidgetOptions>;

class VideoOptions extends React.Component<Props> {
  public componentDidMount() {
    this.props.addField('video', {source: VideoSourceType.SELECTEL});
  }

  public render() {
    const radioGroupName = 'video';
    const {addField} = this.props;
    return (
      <form className="video-wizard-options-form">
        <p className="video-wizard-options-title">
          <b>
            <FormattedMessage id="XEditor.Wizard.Video.Header" />
          </b>
        </p>
        <FormGroup>
          <div className="group">
            <Radio
              name={radioGroupName}
              inline={true}
              defaultChecked={true}
              onChange={() => addField('video', {source: VideoSourceType.SELECTEL})}
            >
              <FormattedMessage
                id="XEditor.Wizard.Essay.LibraryOption"
                values={{icon: <Icon name="enlx-logo" />}}
              />
            </Radio>
          </div>
          <div className="group">
            <Radio
              name={radioGroupName}
              inline={true}
              onChange={() => addField('video', {source: VideoSourceType.YOUTUBE})}
            >
              <span>
                <Icon name="youtube-play" /> Youtube
              </span>
            </Radio>
          </div>
        </FormGroup>
      </form>
    );
  }
}

export default VideoOptions;
