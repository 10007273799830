import React, {type FC, useCallback} from 'react';
import {useIntl} from 'react-intl';
import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';

import {type WidgetJSON, type WidgetType} from 'store/exercise/player/interface';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';

import {
  wizardWidgetsAutoCheckingData,
  wizardWidgetsInformationData,
  wizardWidgetsNoAutoCheckingData
} from './wizardWidgetsData';
import {type XWizardWidgetData} from './interface';

interface Props {
  activeTab: number;
  changeTab: (key: number) => void;
  handleSelected: (type: WidgetType) => void;
}

export const WizardTabs: FC<Props> = ({activeTab, changeTab, handleSelected}) => {
  const {formatMessage} = useIntl();

  const wizardTabs = [
    {
      id: 0,
      title: `${formatMessage({id: 'XEditor.Wizard.AutoChecking'})} (${
        wizardWidgetsAutoCheckingData.length
      })`,
      widgets: wizardWidgetsAutoCheckingData
    },
    {
      id: 1,
      title: `${formatMessage({id: 'XEditor.Wizard.NoAutoChecking'})} (${
        wizardWidgetsNoAutoCheckingData.length
      })`,
      widgets: wizardWidgetsNoAutoCheckingData
    },
    {
      id: 2,
      title: `${formatMessage({id: 'XEditor.Wizard.Information'})} (${
        wizardWidgetsInformationData.length
      })`,
      widgets: wizardWidgetsInformationData
    }
  ];

  const onChangeTab = useCallback(
    tab => {
      changeTab(tab);
    },
    [changeTab]
  );

  return (
    <div className="widget-type-selector">
      <Tabs activeKey={activeTab} onSelect={onChangeTab} id="wizard-tabs">
        {wizardTabs.map((tab, index) => (
          <Tab eventKey={index + 1} title={tab.title} key={tab.id}>
            {tab.widgets.map(
              (widgetData: XWizardWidgetData<WidgetJSON, XWidgetProperties>, key: number) => {
                const Tile = widgetData.tile;

                const toSelect = () => {
                  handleSelected(widgetData.type);
                };

                return (
                  <div className="widget-tile" onClick={toSelect} key={key}>
                    <Tile />
                  </div>
                );
              }
            )}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};
