import React, {useCallback, useEffect} from 'react';
import {FormattedMessage, injectIntl, type IntlShape} from 'react-intl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';

import {WidgetType} from 'store/exercise/player/interface';
import {type WordPictureSetOptions} from 'store/exercise/player/widgets/WordPictureSet/interface';

import {type XWizardStepProps} from '../interface';

import './ChooseTypeModal.scss';

type Props = XWizardStepProps<WordPictureSetOptions> & {intl: IntlShape};

const ChooseTypeModal: React.FC<Props> = ({addField}) => {
  useEffect(() => {
    addField('sort', WidgetType.WORD_PICTURE_SET);
  }, [addField]);

  const radioGroupName = 'word-picture-set-choose';

  const onSetWordSet = useCallback(() => addField('sort', WidgetType.WORD_SET), [addField]);
  const onSetPictureSet = useCallback(() => addField('sort', WidgetType.PICTURE_SET), [addField]);
  const onSetWordPictureSet = useCallback(
    () => addField('sort', WidgetType.WORD_PICTURE_SET),
    [addField]
  );

  return (
    <form className="word-picture-set-wizard-form">
      <p className="word-picture-set-wizard-title">
        <b>
          <FormattedMessage id="XEditor.Wizard.WordPictureSet.Header" />
        </b>
      </p>
      <FormGroup>
        <div className="group">
          <Radio
            name={radioGroupName}
            inline={true}
            onChange={onSetWordPictureSet}
            defaultChecked={true}
          >
            <FormattedMessage id="XEditor.Wizard.WordPictureSet.WordPictureSetTitle" />
          </Radio>
        </div>
        <div className="group">
          <Radio name={radioGroupName} inline={true} onChange={onSetPictureSet}>
            <FormattedMessage id="XEditor.Wizard.WordPictureSet.PictureSetTitle" />
          </Radio>
        </div>
        <div className="group">
          <Radio name={radioGroupName} inline={true} onChange={onSetWordSet}>
            <FormattedMessage id="XEditor.Wizard.WordPictureSet.WordSetTitle" />
          </Radio>
        </div>
      </FormGroup>
    </form>
  );
};

export default injectIntl(ChooseTypeModal);
