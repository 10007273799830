import React, {Component} from 'react';
import {connect} from 'react-redux';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Value} from '@englex/slate';

import {type AppState} from 'store/interface';
import {type PronunciationOption} from 'store/exercise/player/interface';
import {PronunciationModal} from 'components/Dictionary/shared/PronunciationModal/PronunciationModal';
import {type PartOfSpeech} from 'components/Dictionary/shared/interface';
import {
  loadPronunciationsRequest,
  type LoadPronunciationsResponseAction
} from 'store/dictionary/requests';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {
  type XVocabularyProperties,
  type XPronunciationModalProperties
} from 'store/exercise/editor/widgets/XVocabulary/interface';
import {
  changePronunciationsForWord,
  modalChangePhoneticSpelling,
  modalPronunciationsLoaded,
  selectPronunciationOption,
  xVocabularyClosePronunciationModal
} from 'store/exercise/editor/widgets/XVocabulary/actions';

interface OwnProps {
  xwidgetId: string;
}

interface StateProps {
  modalRecord: XPronunciationModalProperties | null;
}

interface DispatchProps {
  closeModal: () => void;
  loadPronunciations: (
    word: string,
    partOfSpeech?: PartOfSpeech
  ) => Promise<LoadPronunciationsResponseAction>;
  handlePronunciationsLoaded: (options: PronunciationOption[]) => void;
  selectPronunciationOption: (index: number) => void;
  changePhoneticSpelling: (value: Value) => void;
  submit: (wordIndex: number, soundId: number | null, phoneticSpelling?: string) => void;
}

interface Props extends StateProps, OwnProps, DispatchProps {}

class XEditorPronunciationModal extends Component<Props> {
  public render() {
    const {
      changePhoneticSpelling,
      selectPronunciationOption,
      modalRecord,
      handlePronunciationsLoaded
    } = this.props;
    return (
      !!modalRecord && (
        <PronunciationModal
          loadPronunciations={this.loadPronunciations}
          changePhoneticSpelling={changePhoneticSpelling}
          selectPronunciationOption={selectPronunciationOption}
          pronunciationsLoaded={handlePronunciationsLoaded}
          validationStatus={modalRecord.validationStatus}
          phoneticSpelling={modalRecord.phoneticSpelling}
          selectedOptionIndex={modalRecord.selectedOptionIndex}
          pronunciationOptions={modalRecord.pronunciationOptions?.toJS()}
          close={this.closeModal}
          submit={this.handleSubmit}
        />
      )
    );
  }

  private handleSubmit = () => {
    const {modalRecord, submit} = this.props;
    if (!modalRecord) {
      return;
    }
    const selectedOption = modalRecord.pronunciationOptions!.get(modalRecord.selectedOptionIndex);
    const {text: spellingString} = modalRecord.phoneticSpelling!.document;
    const textIsEmpty = !spellingString.length || spellingString.match(/^\s*$/);
    submit(
      modalRecord.wordIndex,
      selectedOption ? selectedOption.id : null,
      textIsEmpty ? undefined : spellingString
    );
  };

  private loadPronunciations = () => {
    const {modalRecord} = this.props;
    return this.props.loadPronunciations(modalRecord!.word, modalRecord!.partOfSpeech);
  };

  private closeModal = () => {
    this.props.closeModal();
  };
}

const mapStateToProps = (state: AppState, {xwidgetId}: OwnProps): StateProps => {
  const xwidget = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === xwidgetId
  ) as XVocabularyProperties;

  return {
    modalRecord: xwidget.pronunciationModal
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action, AppState>,
  {xwidgetId}: OwnProps
): DispatchProps => ({
  closeModal: () => dispatch(xVocabularyClosePronunciationModal(xwidgetId)),
  loadPronunciations: (word: string, partOfSpeech?: PartOfSpeech) =>
    dispatch(loadPronunciationsRequest(word, partOfSpeech)),
  handlePronunciationsLoaded: (options: PronunciationOption[]) =>
    dispatch(modalPronunciationsLoaded(xwidgetId, options)),
  selectPronunciationOption: (index: number) =>
    dispatch(selectPronunciationOption(xwidgetId, index)),
  changePhoneticSpelling: (value: Value) => dispatch(modalChangePhoneticSpelling(xwidgetId, value)),
  submit: (wordIndex: number, soundId: number | null, phoneticSpelling?: string) => {
    const phoneticSpellingWithoutBraces =
      phoneticSpelling && phoneticSpelling.replace(/[[\]]/g, '');
    dispatch(
      changePronunciationsForWord(xwidgetId, wordIndex, soundId, phoneticSpellingWithoutBraces)
    );
    dispatch(xVocabularyClosePronunciationModal(xwidgetId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(XEditorPronunciationModal);
