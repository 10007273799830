import React, {type FC} from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {valueJSONFromText} from 'components/Slate/utils/value';
import genKey from 'components/Slate/utils/genKey';
import {GapFillType} from 'components/Slate/interface';
import {type GapFillJSON} from 'store/exercise/player/widgets/GapFill/interface';
import XGapFillRecord from 'store/exercise/editor/widgets/XGapFill/XGapFillRecord';

import {type XWizardWidgetData} from '../interface';
import GapTypeSelector from './GapTypeSelector';
import Tile from '../Tile';
import GapSizeSelector from './GapSizeSelector';
import {GapFillSVG} from '../assets';

const tileAdditionalText = 'fill gaps, choose, match';

const tile: FC = () => (
  <Tile title={WidgetTitle.GapFill} additionalText={tileAdditionalText}>
    <GapFillSVG />
  </Tile>
);

export const gapFillWizardData: XWizardWidgetData<GapFillJSON, XGapFillRecord> = {
  type: WidgetType.GAP_FILL,
  steps: [
    {
      canGoToNextStep: (widget: Partial<GapFillJSON>) => !!widget.gap,
      component: GapTypeSelector
    },
    {
      component: GapSizeSelector,
      canGoToNextStep: () => true,
      skipStep: ({gap}: Partial<GapFillJSON>) =>
        !!gap && [GapFillType.DND, GapFillType.DND_INPUT].includes(gap)
    }
  ],
  tile,
  createWidget: (widget: Pick<GapFillJSON, 'type' | 'gap' | 'gapSize'>) =>
    new XGapFillRecord({
      id: genKey(),
      type: widget.type,
      task: valueJSONFromText(''),
      content: valueJSONFromText(''),
      gap: widget.gap,
      gapSize: widget.gapSize
    })
};
