import React from 'react';

import {type XMultipleChoiceWizardOptions} from 'store/exercise/player/widgets/List/interface';

import MultipleChoiceWizard from '../MultipleChoice/MultipleChoiceWizard';
import {type XWizardStepProps} from '../interface';

type Props = XWizardStepProps<XMultipleChoiceWizardOptions>;

function FreeChoiceWizard(props: Props) {
  return <MultipleChoiceWizard {...props} isFreeChoice={true} />;
}

export default FreeChoiceWizard;
