import {SlateDecoration} from '../../interface';
import {wordSplitter} from '../../../../config/static';
import {isSurroundedByChar, wordChecker} from './util';
import {DecorateText} from './DecorateText';

export const DecorateWord = () =>
  DecorateText({
    type: SlateDecoration.WORD,
    splitter: wordSplitter,
    checker: wordChecker,
    surround: {
      predicate: (t, c) => isSurroundedByChar(t, c)
    }
  });
