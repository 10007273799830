import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  CardTitle: {
    id: 'XEditorXWidget.Cards.Title'
  },
  CardText: {
    id: 'XEditorXWidget.Cards.Text'
  },
  AddCard: {
    id: 'XEditorXWidget.Cards.AddCard'
  },
  DeleteCard: {
    id: 'XEditorXWidget.Cards.DeleteCard'
  },
  MoveCardUp: {
    id: 'XEditorXWidget.Cards.MoveCardUp'
  },
  MoveCardDown: {
    id: 'XEditorXWidget.Cards.MoveCardDown'
  }
});
