import React from 'react';
import {FormattedMessage} from 'react-intl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';

import {type XEssayWizardOptions} from 'store/exercise/player/widgets/Essay/interface';

import {type XWizardStepProps} from '../interface';

type Props = XWizardStepProps<XEssayWizardOptions>;

class EssayOptions extends React.Component<Props> {
  public render() {
    const radioGroupName = 'shouldCountWords';
    const {addField, fields} = this.props;
    return (
      <form className="gap-type-selector">
        <p>
          <b>
            <FormattedMessage id="XEditor.Wizard.Essay.Header" />
          </b>
        </p>
        <FormattedMessage id="XEditor.Wizard.Essay.Text" />
        <FormGroup>
          <div className="group">
            <Radio
              name={radioGroupName}
              inline={true}
              onChange={() => addField('shouldCountWords', true)}
              checked={fields.shouldCountWords === true}
            >
              <FormattedMessage id="XEditor.Wizard.Essay.Option1" />
            </Radio>
          </div>
          <div className="group">
            <Radio
              name={radioGroupName}
              inline={true}
              onChange={() => addField('shouldCountWords', false)}
              checked={!fields.shouldCountWords}
            >
              <FormattedMessage id="XEditor.Wizard.Essay.Option2" />
            </Radio>
          </div>
        </FormGroup>
      </form>
    );
  }
}

export default EssayOptions;
