import {maxSoundSize} from 'config/static';

import {UploadingValidationError} from './interface';

const {EMPTY_FILE, FILE_TOO_BIG, INVALID_EXTENSION} = UploadingValidationError;

export const validate = (file: File, maxFileSize = maxSoundSize): Error | undefined => {
  const {name, size} = file;
  if (!name.match(/\.mp3$/i)) {
    throw new Error(INVALID_EXTENSION);
  }
  if (size > maxFileSize) {
    throw new Error(FILE_TOO_BIG);
  }
  if (size === 0) {
    throw new Error(EMPTY_FILE);
  }
  return;
};
