import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';

import {type LibraryCollapsedState} from 'routes/ClassRoom/components/FilesWorkSpace/Library/interface';

import {type CollapseState, type VideoItem, type VideoLibraryNode} from './interface';
import {TreeNode} from './TreeNode';

interface Props {
  library: VideoLibraryNode;
  selectFile: (node: VideoItem) => void;
  selectedId?: string;
}

interface State {
  collapseState: CollapseState;
}

class FileTree extends React.Component<Props, State> {
  public state: State = {collapseState: {}};

  private get rootKey(): string {
    const {library} = this.props;
    return Object.keys(library).length ? Object.keys(library)[0] : '';
  }

  public componentDidMount() {
    const [{library}, {collapseState}] = [this.props, this.state];
    mutateCollapseState(library, collapseState);
    this.setState({collapseState});
  }

  public render() {
    const {library, selectFile} = this.props;
    return (
      <div className="library-tree">
        <div className="library-actions">
          <Button className="btn-transparent" bsSize="xs" onClick={this.collapseAll}>
            <FormattedMessage id="Common.Collapse.All" />
          </Button>
        </div>
        <div>
          <Scrollbars
            autoHide={true}
            autoHeight={true}
            autoHeightMin={294}
            autoHeightMax={'calc(100vh - 277px)'}
          >
            <TreeNode
              collapse={this.collapse}
              defaultCollapsed={false}
              isFileSelected={this.isFileSelected}
              isFolderCollapsed={this.isFolderCollapsed}
              libraryNode={library[this.rootKey]}
              nodeKey={this.rootKey}
              prefix={'0'}
              selectFile={selectFile}
            />
          </Scrollbars>
        </div>
      </div>
    );
  }

  private collapse = (nodeKey: string) => {
    const {collapseState} = this.state;
    if (collapseState && collapseState[nodeKey] !== undefined) {
      collapseState[nodeKey] = !collapseState[nodeKey];
    }
    this.setState({collapseState});
  };

  private collapseAll = () => {
    const {collapseState} = this.state;

    for (const i in collapseState) {
      collapseState[i] = true;
    }
    this.setState({collapseState});
  };

  private isFileSelected = (node: VideoItem) => {
    return this.props.selectedId === node.id;
  };

  private isFolderCollapsed = (nodeKey: string) =>
    this.state.collapseState[nodeKey] ? this.state.collapseState[nodeKey] : false;
}

function mutateCollapseState(
  response: VideoLibraryNode,
  collapseState: LibraryCollapsedState,
  prevKey: string = ''
): VideoLibraryNode {
  const defaultCollapsed = true;

  return Object.keys(response).map((dir: string, index) => {
    const node = response[dir];
    const prefix = prevKey !== '' ? `${prevKey}-${index}` : String(index);
    if (node.hasOwnProperty('id') && node.hasOwnProperty('title')) {
      collapseState[`${prefix}-f-${node.id}`] = defaultCollapsed;
      return node;
    }
    collapseState[`${prefix}-d-${dir.toLowerCase()}`] = defaultCollapsed;
    return mutateCollapseState(response[dir], collapseState, prefix);
  });
}

export default FileTree;
