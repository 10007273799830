import React, {type FC} from 'react';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import {useIntl} from 'react-intl';
import {useMediaQuery} from '@englex/react-hooks/lib/useMediaQuery';
import classNames from 'classnames';

import {AspectRatio} from 'components/Crop/static';

import {CropDropdown} from '../CropDropdown/CropDropdown';

import './CropControls.scss';

const CropControls: FC<{
  aspectRatio: AspectRatio;
  lockedAspectRatios: AspectRatio[];
  setAspectRatio: (aspectRatio: AspectRatio) => void;
  breakpoint?: number;
  rectangleDisabled: boolean;
}> = ({aspectRatio, lockedAspectRatios, setAspectRatio, breakpoint = 485, rectangleDisabled}) => {
  const matches = useMediaQuery(breakpoint);

  const {SQUARE, RECTANGLE} = AspectRatio;
  const {formatMessage} = useIntl();

  if (matches) {
    return (
      <div className="crop-dropdown dropdown-container ">
        <CropDropdown
          aspectRatio={aspectRatio}
          lockedAspectRatios={lockedAspectRatios}
          setAspectRatio={setAspectRatio}
        />
      </div>
    );
  }

  return (
    <ToggleButtonGroup
      className="crop-controls btn-group-sm"
      name="image-upload-btn-group"
      onChange={setAspectRatio}
      type="radio"
      value={aspectRatio}
    >
      <ToggleButton value={SQUARE} title={formatMessage({id: 'CropModal.Square'})}>
        {formatMessage({id: 'CropModal.Square'})}
      </ToggleButton>
      <ToggleButton
        className={classNames('toggle-button', 'rectangle', {disabled: rectangleDisabled})}
        value={RECTANGLE}
        title={
          rectangleDisabled
            ? formatMessage({id: 'CropModal.Rectangle.MinimalAllowedSize'})
            : formatMessage({id: 'CropModal.Rectangle'})
        }
        disabled={rectangleDisabled}
      >
        {formatMessage({id: 'CropModal.Rectangle'})}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CropControls;
