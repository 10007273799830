import React, {type FC} from 'react';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';

import BreadcrumbLine from '../../../../components/BreadcrumbLine';
import {useXEditorPageMeta} from '../useXEditorPageMeta';

export const Breadcrumbs: FC = () => {
  const {
    pageNumber,
    exerciseId,
    exerciseOrdinal,
    parentExerciseId,
    coursebookTitle,
    unitTitle,
    supplementaryOrdinal
  } = useXEditorPageMeta();

  // RFC: A similar check was in the old component, decided to keep it just in case
  if (!pageNumber && !exerciseId && !unitTitle && !coursebookTitle) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="breadcrumb coursebook">
        <Icon name="pc-book" />
        <span className="title" title={coursebookTitle}>
          {' '}
          {coursebookTitle}
        </span>
        <BreadcrumbLine />
      </div>
      <div className="breadcrumb unit">
        <span className="title" title={unitTitle}>
          {unitTitle}
        </span>
        <BreadcrumbLine />
      </div>
      {!!pageNumber && (
        <div className="breadcrumb page">
          <span className="title" title={`${pageNumber}`}>
            <FormattedMessage id="Library.Breadcrumbs.Page" values={{pageNumber}} />
          </span>
          <BreadcrumbLine />
        </div>
      )}

      <div className="breadcrumb exercise">
        <span className="title">
          {exerciseOrdinal !== -1 ? (
            <FormattedMessage
              id="Library.Breadcrumbs.Exercise"
              values={{ordinal: exerciseOrdinal + 1}}
            />
          ) : (
            <FormattedMessage id="Library.Breadcrumbs.NewExercise" />
          )}
        </span>
        <BreadcrumbLine />
      </div>

      {parentExerciseId && exerciseOrdinal !== -1 && (
        <div className="breadcrumb additional-exercise">
          <span className="title">
            {!exerciseId ? (
              <FormattedMessage id="Library.Breadcrumbs.AdditionalExercise.New" />
            ) : (
              <FormattedMessage
                id="Library.Breadcrumbs.AdditionalExercise"
                values={{ordinal: supplementaryOrdinal + 1}}
              />
            )}
          </span>
          <BreadcrumbLine />
        </div>
      )}
    </React.Fragment>
  );
};
