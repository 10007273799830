import React, {type PropsWithChildren} from 'react';
import {type WrappedComponentProps} from 'react-intl';
import {type Action, type Dispatch} from 'redux';
import {connect} from 'react-redux';
import {getEventTransfer} from '@englex/slate-react';

import {type VocabularyRow} from 'store/exercise/player/widgets/Vocabulary/interface';
import {
  resetPronunciation,
  xVocabularyAddRows,
  xVocabularySetOriginal,
  xVocabularySetTranslation
} from 'store/exercise/editor/widgets/XVocabulary/actions';
import {type VocabularyWordProperties} from 'store/exercise/editor/widgets/XVocabulary/interface';

import XEditorVocabularyWordControls from './XEditorVocabularyWordControls';
import {vocabularyMessages} from './vocabularyMessages';

interface OwnProps extends WrappedComponentProps {
  onFocus: () => void;
  onBlur: () => void;
  word: VocabularyWordProperties;
  widgetId: string;
  title: string;
}

interface DispatchProps {
  addRows: (rows: VocabularyRow[]) => void;
  changeOriginal: (value: string) => void;
  changeTranslation: (value: string) => void;
}

type Props = PropsWithChildren<OwnProps> & DispatchProps;

class XVocabularyWordInput extends React.Component<Props> {
  public render() {
    const {onFocus, onBlur, word, intl, title, widgetId, children} = this.props;
    return (
      <React.Fragment>
        <div className="vocabulary-word-input" key="1">
          <div className="title">{title}</div>
          <input
            className="original-input"
            placeholder={intl.formatMessage(vocabularyMessages.OriginalPlaceholder)}
            onFocus={onFocus}
            onBlur={onBlur}
            value={word.original}
            onChange={this.onOriginalChange}
            onPaste={this.handlePaste}
          />
        </div>
        <div className="vocabulary-word-input" key="2">
          <input
            className="translation-input"
            placeholder={intl.formatMessage(vocabularyMessages.TranslationPlaceholder)}
            onFocus={onFocus}
            onBlur={onBlur}
            value={word.translation}
            onChange={this.onTranslationChange}
          />
          <div className="controls">
            <XEditorVocabularyWordControls xwidgetId={widgetId} word={word} />
            {children}
          </div>
        </div>
      </React.Fragment>
    );
  }

  private handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    const {html} = getEventTransfer(e.nativeEvent);
    if (!html) {
      return;
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    if (import.meta.env.MODE === 'development') {
      /* eslint-disable no-console */
      console.groupCollapsed('Paste HTML:');
      console.log('HTML:', html);
      console.log('DOM:', doc);
      console.groupEnd();
      /* eslint-enable no-console */
    }

    const table = doc.getElementsByTagName('table').item(0);
    if (table) {
      const rows: VocabularyRow[] = [];
      // table has only rows with exact two rows, so try to convert html table to VocabularyRow
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      for (const r of table.rows as any) {
        if (r.cells.length === 2) {
          rows.push({
            original: r.cells
              .item(0)
              .textContent.replace(/\/.+\//g, '') // removes '/some transcript/'
              .trim(),
            translation: r.cells.item(1).textContent.trim()
          });
        }
      }

      if (rows.length) {
        this.props.addRows(rows);
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };
  private onOriginalChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.props.changeOriginal(e.currentTarget.value);

  private onTranslationChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.props.changeTranslation(e.currentTarget.value);
}

const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): DispatchProps => ({
  changeOriginal: (value: string) => {
    dispatch(xVocabularySetOriginal(ownProps.widgetId, ownProps.word.wordId, value));
    dispatch(resetPronunciation(ownProps.widgetId, ownProps.word.wordId));
  },
  addRows: (values: VocabularyRow[]) =>
    dispatch(xVocabularyAddRows(ownProps.widgetId, ownProps.word.wordId, values)),
  changeTranslation: (value: string) =>
    dispatch(xVocabularySetTranslation(ownProps.widgetId, ownProps.word.wordId, value))
});

export default connect(null, mapDispatchToProps)(XVocabularyWordInput);
