import React, {type FC, useCallback, useState} from 'react';
import {type Dispatch} from 'redux';
import {connect, type MapStateToProps, useDispatch} from 'react-redux';
import {type List} from 'immutable';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import classNames from 'classnames';

import Icon from 'components/Icon';
import {type AppState} from 'store/interface';
import {
  addPair,
  togglePreFillValues,
  moveCard,
  rollBackCards
} from 'store/exercise/editor/widgets/XImage/actions';
import type XImageMatchingCardRecord from 'store/exercise/editor/widgets/XImage/XImageMatching/XImageMatchingCardRecord';
import {resetWidgetErrors} from 'store/exercise/editor/actions/xeditor';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {DndSortingWrapper} from 'components/DndSortingWrapper/DndSortingWrapper';
import {useRecoveryPoint} from 'components/DndSortingWrapper/hooks/useRecoveryPoint';
import type XImageMatchingRecord from 'store/exercise/editor/widgets/XImage/XImageMatching/XImageMatchingRecord';
import {DndTypes} from 'components/dnd/interface';
import {Provider} from 'components/XPlayer/contexts/dndContext';
import {type XWidgetAction} from 'store/exercise/editor/actions/interface';

import ImageMatchingCard from './ImageMatchingCard';

import '../XEditorImage.scss';

interface CardsProps {
  updateRecoveryPoint: () => void;
  moveItem: (moveItemIndex: number, targetIndex: number) => void;
  rollBackChanges: () => void;
  getItemIndex: (cardId: string) => number;
}

interface OwnProps {
  id: string;
  setError: (e: string | JSX.Element) => string | JSX.Element;
}

interface StateProps {
  cards: List<XImageMatchingCardRecord>;
  hasPreFillValues: boolean;
}

interface DispatchProps {
  addPair: () => void;
  resetErrors: () => void;
  togglePreFillValues: () => void;
}

type Props = OwnProps & StateProps & DispatchProps;

class XEditorImageMatching extends React.Component<Props> {
  private onPreFillCheckbox = () => {
    this.props.togglePreFillValues();
  };

  public render() {
    const {Content} = this;
    return <Content />;
  }

  public Content: FC = () => {
    const {Cards} = this;
    const {id: widgetId, cards, hasPreFillValues, addPair} = this.props;
    const dispatch = useDispatch();

    const {recoveryPoint, updateRecoveryPoint} = useRecoveryPoint({list: cards});

    const moveItem = useCallback(
      (moveItemIndex: number, targetIndex: number) => {
        dispatch(moveCard(widgetId, moveItemIndex, targetIndex));
      },
      [dispatch, widgetId]
    );

    const rollBackChanges = useCallback(() => {
      dispatch(rollBackCards(widgetId, recoveryPoint.current));
    }, [dispatch, recoveryPoint, widgetId]);

    const getItemIndex = useCallback(
      (cardId: string) => {
        return cards.findIndex(card => card?.id === cardId);
      },
      [cards]
    );
    const addCard = useCallback(() => {
      addPair();
      requestAnimationFrame(updateRecoveryPoint);
    }, [addPair, updateRecoveryPoint]);

    return (
      <Provider>
        <div className="image-matching">
          <Cards
            updateRecoveryPoint={updateRecoveryPoint}
            moveItem={moveItem}
            rollBackChanges={rollBackChanges}
            getItemIndex={getItemIndex}
          />

          <div className="image-matching__actions">
            <FormGroup>
              <Checkbox
                className="alignment"
                name={'alignmentGroup'}
                checked={hasPreFillValues}
                onChange={this.onPreFillCheckbox}
              >
                <FormattedMessage id="XEditor.Wizard.ImageMatching.MakeTwoThirdAnswersIncorrect" />
              </Checkbox>
            </FormGroup>

            <Button onClick={addCard} className="add-card">
              <Icon name="plus-circle" />
              <FormattedMessage id="XEditorWidget.ImageMatching.AddPairButton" />
            </Button>
          </div>
        </div>
      </Provider>
    );
  };

  public Cards: FC<CardsProps> = ({
    updateRecoveryPoint,
    moveItem,
    rollBackChanges,
    getItemIndex
  }) => {
    const {id: widgetId, cards, setError, resetErrors} = this.props;
    const [isDndDragUsed, setIsDndDragUsed] = useState(false);

    return (
      <div className={classNames('im-cards', {'is-dnd-drag-used': isDndDragUsed})}>
        {cards.map((card: XImageMatchingCardRecord, index: number) => (
          <DndSortingWrapper
            key={card.id}
            itemId={card.id}
            dndType={DndTypes.IMAGE_MATCHING}
            isNotUseDragLayer={false}
            ChildComponent={ImageMatchingCard}
            childOptions={{
              id: card.id,
              imageId: card.imageId,
              index: index,
              choiceId: card.choiceId,
              xwidgetId: widgetId,
              value: card.phrase,
              cardSizeType: card.cardSizeType,
              reportError: setError,
              resetErrors: resetErrors,
              updateRecoveryPoint: updateRecoveryPoint
            }}
            updateRecoveryPoint={updateRecoveryPoint}
            moveItem={moveItem}
            rollBackChanges={rollBackChanges}
            getItemIndex={getItemIndex}
            setIsDndDragUsed={setIsDndDragUsed}
          />
        ))}
      </div>
    );
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  {id}: OwnProps
): StateProps => {
  const {cards, hasPreFillValues} = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === id
  ) as XImageMatchingRecord;

  return {cards, hasPreFillValues: !!hasPreFillValues};
};

const mapDispatchToProps = (dispatch: Dispatch<XWidgetAction>, {id}: OwnProps) => ({
  addPair: () => dispatch(addPair(id)),
  resetErrors: () => dispatch(resetWidgetErrors(id)),
  togglePreFillValues: () => dispatch(togglePreFillValues(id))
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(XEditorImageMatching);
