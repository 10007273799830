import React from 'react';
import {List} from 'immutable';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import XFlipCardsRecord from 'store/exercise/editor/widgets/XFlipCards/XFlipCardsRecord';
import XFlipCardsCardRecord from 'store/exercise/editor/widgets/XFlipCards/XFlipCardsCardRecord';
import {type FlipCardsJSON} from 'store/exercise/player/widgets/FlipCards/interface';

import Tile from '../Tile';
import {FlipCardsSVG} from '../assets';
import {type XWizardWidgetData} from '../interface';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.FlipCards}>
    <FlipCardsSVG />
  </Tile>
);

export const flipCardsWizardData: XWizardWidgetData<FlipCardsJSON, XFlipCardsRecord> = {
  type: WidgetType.FLIP_CARDS,
  steps: [],
  tile,
  createWidget: () => {
    return XFlipCardsRecord.createWidget(
      List([new XFlipCardsCardRecord(), new XFlipCardsCardRecord(), new XFlipCardsCardRecord()])
    );
  }
};
