import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';
import {VideoSourceType} from 'components/media/interface';

import './ReplaceVideoTrigger.scss';

interface Props {
  source: VideoSourceType;
  onClick: () => void;
  show: boolean;
}

class ReplaceVideoTrigger extends React.Component<Props> {
  public render() {
    const {source, onClick, show} = this.props;
    return show ? (
      <div className="replace-video-trigger">
        <Button bsSize="small" className="replace-video-trigger-btn" onClick={onClick}>
          {source === VideoSourceType.SELECTEL ? (
            <>
              <Icon name="virc-replace" />
              <FormattedMessage id="XEditorWidget.Video.ReplaceVideo" />
            </>
          ) : (
            <>
              <Icon name="gear" />
              <FormattedMessage id="XEditorWidget.Video.Settings" />
            </>
          )}
        </Button>
      </div>
    ) : null;
  }
}

export default ReplaceVideoTrigger;
