import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {type CommentJSON, CommentType} from 'store/exercise/player/widgets/Comment/interface';
import {valueJSONFromText} from 'components/Slate/utils/value';
import genKey from 'components/Slate/utils/genKey';
import XCommentRecord from 'store/exercise/editor/widgets/XComment/XCommentRecord';

import {type XWizardWidgetData} from '../interface';
import Tile from '../Tile';
import CommentTypeSelector from './CommentTypeSelector';
import {CommentSVG} from '../assets';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.Comment}>
    <CommentSVG />
  </Tile>
);

export const commentWizardData: XWizardWidgetData<CommentJSON, XCommentRecord> = {
  type: WidgetType.COMMENT,
  steps: [
    {
      canGoToNextStep: () => true,
      component: CommentTypeSelector
    }
  ],
  tile,
  createWidget: (widget: Partial<CommentJSON>) => {
    return new XCommentRecord({
      id: genKey(),
      type: widget.type!,
      task: valueJSONFromText(''),
      content: valueJSONFromText(''),
      commentType: widget.commentType,
      displayButton:
        widget.commentType === CommentType.TEACHING_TIPS ? valueJSONFromText('') : undefined
    });
  }
};
