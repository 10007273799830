import {genKey} from '../../../utils';
import {SlateDecoration} from '../../../interface';
import {type Options, TextDecorator} from './TextDecorator';
import {DecorateLetter} from '../../../plugins/DecorateText';

export class LetterDecorator extends TextDecorator {
  constructor({keyGen = genKey, checker}: Options) {
    super({
      decoration: SlateDecoration.LETTER,
      wrapperClassName: 'letter',
      isCapitalizable: false,
      keyGen,
      checker
    });

    this.plugins = [DecorateLetter({checker})];
  }
}
