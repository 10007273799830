import {type Editor as ReactEditor, type RenderInlineProps} from '@englex/slate-react';
import React from 'react';
import {type Editor, type Inline, type Next, type Query, type SlateError} from '@englex/slate';

import isShortcut from 'helpers/shortcut';

import {
  type EditGapFillOptions,
  type GapFillData,
  GapFillType,
  getBeforeInsertFragmentPlugins,
  getToolBox,
  SlateInline,
  type GapFillInline
} from '../../../../interface';
import ToolbarButton from '../../ToolbarButton';
import XWidgetGap from '../XWidgetGap';
import {logNormalizeError} from '../../utils';
import genKey from '../../../../utils/genKey';
import {
  type InsertFragmentPlugin,
  type SchemaInlinePlugin,
  type SchemaInlineRules,
  type ToolBoxChildrenProps
} from '../../interface';
import {isGapFillInline} from '../../../../utils';
import {getMarkClassNamesForNode} from '../../../../plugins/utils';
import type ToolBox from '../../ToolBox';
import {createInlineGap, updateInlineGap, deleteInlineGap, selectInlineGapText} from './commands';

interface GapFillEditFormOptions {
  bannedEmpty?: boolean;
}

export interface GapFillEditFormProps {
  defaultAnswer?: string;
  save: (answers: string[], options: EditGapFillOptions) => void;
  del?: () => void;
  data?: GapFillData;
  options?: GapFillEditFormOptions;
}

abstract class GapFill extends ToolbarButton implements SchemaInlinePlugin, InsertFragmentPlugin {
  public abstract gap: GapFillType;
  public abstract editForm: React.ElementType<GapFillEditFormProps & ToolBoxChildrenProps>;

  public icon = 'plus-circle';
  public shortcut = 'mod+shift+g';

  protected markTypes = [];
  protected inline = SlateInline.GAP;
  protected toggleChange = undefined;

  public inlineRules = (): SchemaInlineRules => ({
    type: this.inline,
    rules: {
      isVoid: true,
      data: {
        id: (v: unknown) => typeof v === 'string' && v.length > 0,
        type: (v: string) => this.gap === v,
        answer: (v: string[]) =>
          !!v && !!v.length && (this.gap === GapFillType.DND_INPUT ? v.length >= 2 : v.length > 0),
        example: (v?: unknown) => v === undefined || v === true,
        caseSensitive: (v?: true) =>
          [GapFillType.DND, GapFillType.DND_INPUT].includes(this.gap)
            ? v === true || v === undefined
            : v === undefined,
        startOfSentence: (v?: true) =>
          [GapFillType.DND, GapFillType.DND_INPUT].includes(this.gap)
            ? v === true || v === undefined
            : v === undefined,
        indefiniteForm: (v?: string) =>
          [GapFillType.INPUT].includes(this.gap)
            ? typeof v === 'string' || v === undefined
            : v === undefined,
        editable: (v?: true) =>
          [GapFillType.INPUT].includes(this.gap) ? v === true || v === undefined : v === undefined,
        choiceId: (v: unknown) =>
          [GapFillType.DND, GapFillType.DND_INPUT].includes(this.gap)
            ? typeof v === 'string'
            : v === undefined,
        choices: (v: string[]) =>
          this.gap === GapFillType.DROPDOWN ? Array.isArray(v) && v.length > 0 : v === undefined
      },
      normalize: (change: Editor, error: SlateError) => {
        if (error.code !== 'node_data_invalid') {
          return;
        }
        logNormalizeError(error, 'GapFill.inlineRules.normalize', true, '0');
        const {node} = error;
        change
          .moveToRangeOfNode(node)
          .moveToStartOfNextText()
          .insertText(((node as Inline).data.get('answer') as string[])[0])
          .removeNodeByKey(node.key);
      }
    }
  });

  public onQuery = (query: Query, editor: Editor, next: Next) => {
    if (query.type === getBeforeInsertFragmentPlugins) {
      const plugins = next() || [];
      plugins.unshift(this);
      return plugins;
    }
    return this.onToolbarButtonQuery(query, editor, next);
  };

  /**
   * Clones inline gaps with unique IDs on before insert Slate fragment
   *
   * @param {Event} event
   * @param {Editor} fragmentChange
   */
  public onBeforeInsertFragment = (event: React.ClipboardEvent, fragmentChange: Editor) => {
    const inlines = fragmentChange.value.document.getInlines();

    inlines
      .filter((i: Inline) => i.type === this.inline)
      .forEach((i: Inline) => {
        let inline = i.setIn(['data', 'id'], genKey()) as Inline;
        if (i.data.get('choiceId')) {
          inline = inline.setIn(['data', 'choiceId'], genKey()) as Inline;
        }
        fragmentChange.setNodeByKey(inline.key, {
          data: inline.data
        });
      });
  };

  public handleClick = (e: React.MouseEvent<HTMLButtonElement>, editor: Editor) => {
    this.openToolBox(editor);
  };

  public isDisabled(editor: Editor): boolean {
    if (!editor.value.selection) return false;

    const {readOnly} = editor;
    const {isBlurred, isExpanded, start, end} = editor.value.selection;
    return readOnly || isBlurred || (isExpanded && start.key !== end.key);
  }

  public onKeyDown = (event: React.KeyboardEvent, editor: ReactEditor & Editor, next: Next) => {
    // edit gap on Enter key if it is the only node selected
    if (
      isShortcut(event, 'enter') &&
      editor.value.selection?.isCollapsed &&
      editor.value.inlines.count() === 1
    ) {
      const inline = editor.value.inlines.first();
      if (isGapFillInline(inline)) {
        this.editGap(editor, inline);
        return;
      }
    }

    if (this.isDisabled(editor) || !isShortcut(event, this.shortcut)) {
      return next();
    }

    event.preventDefault();
    this.openToolBox(editor);

    return;
  };

  public renderInline = (props: RenderInlineProps, editor: ReactEditor & Editor, next: Next) => {
    const {node} = props;
    if (!isGapFillInline(node)) {
      return next();
    }

    const {isSelected} = props;
    const data = node.data;

    const gapClasses = getMarkClassNamesForNode(editor, node.getMarks(), this.markTypes).join(' ');
    const id = data.get('id');
    const type = data.get('type');
    const answers = data.get('answer');
    const choices = data.get('choices');
    const example = data.get('example');
    const answer = type === GapFillType.DND_INPUT ? answers[1] : answers[0];
    const count = choices
      ? choices.length
      : type === GapFillType.DND_INPUT
        ? answers.length - 1
        : answers.length;
    return (
      <span className="xwidget-gap-inline" {...props.attributes} contentEditable={false}>
        <XWidgetGap
          id={id}
          type={type}
          example={example}
          count={count}
          answer={answer}
          isSelected={isSelected}
          select={() => requestAnimationFrame(() => selectInlineGapText(editor, node))}
          editGap={() => this.editGap(props.editor, node)}
          className={gapClasses}
        />
      </span>
    );
  };

  protected getClassNames = () => 'success';

  private editGap = (editor: Editor, inline: GapFillInline) => {
    const data: GapFillData = inline.data;
    const toolbox = editor.query<ToolBox>(getToolBox);
    toolbox.open(this.editForm, {
      save: (answers: string[], options: EditGapFillOptions = {}) =>
        requestAnimationFrame(() => updateInlineGap(editor, answers, options)),
      del: () => deleteInlineGap(editor),
      data
    });
  };

  private openToolBox(editor: Editor) {
    const toolbox = editor.query<ToolBox>(getToolBox);
    toolbox.open(this.editForm, {
      defaultAnswer: editor.value.fragment.text,
      save: (answers: string[], options: EditGapFillOptions = {}) =>
        requestAnimationFrame(() => createInlineGap(editor, this.gap, answers, options))
    });
  }
}

export default GapFill;
