import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import genKey from 'components/Slate/utils/genKey';
import {valueJSONFromText} from 'components/Slate/utils';
import {
  type MultipleChoiceJSON,
  type XMultipleChoiceWizardOptions
} from 'store/exercise/player/widgets/List/interface';
import XFreeChoiceRecord from 'store/exercise/editor/widgets/XFreeChoice/XFreeChoiceRecord';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';

import {type XWizardWidgetData} from '../interface';
import FreeChoiceWizard from './FreeChoiceWizard';
import Tile from '../Tile';
import {FreeChoiceSVG} from '../assets';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.FreeChoice}>
    <FreeChoiceSVG />
  </Tile>
);

export const freeChoiceWizardData: XWizardWidgetData<MultipleChoiceJSON, XWidgetProperties> = {
  type: WidgetType.FREE_CHOICE,
  steps: [
    {
      canGoToNextStep: (widget: XMultipleChoiceWizardOptions) => {
        return !!(widget.element && widget.defaultAnswersType);
      },
      component: FreeChoiceWizard
    }
  ],
  tile,
  createWidget: (widget: XMultipleChoiceWizardOptions) =>
    new XFreeChoiceRecord({
      id: genKey(),
      type: widget.type,
      task: valueJSONFromText(''),
      content: valueJSONFromText(''),
      element: widget.element,
      options: {
        defaultAnswersType: widget.defaultAnswersType,
        defaultAnswersNumber: widget.defaultAnswersNumber,
        horizontal: widget.horizontal,
        initialQuestionsNumber: widget.initialQuestionsNumber,
        withoutQuestions: widget.withoutQuestions
      }
    })
};
