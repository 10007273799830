import React, {useCallback} from 'react';
import {FormattedMessage, type WrappedComponentProps, injectIntl} from 'react-intl';
import Radio from 'react-bootstrap/lib/Radio';

import Icon from 'components/Icon';
import {
  commentDataByType,
  getCommentType
} from 'store/exercise/player/widgets/Comment/commentDataByType';
import {type CommentJSON, CommentType} from 'store/exercise/player/widgets/Comment/interface';

import {type XWizardStepProps} from '../interface';
import './CommentTypeSelector.scss';

type Props = XWizardStepProps<CommentJSON>;

const CommentTypeSelector: React.FC<Props & WrappedComponentProps> = (
  props: Props & WrappedComponentProps
) => {
  const radioGroupName = 'comment-type-selector';
  const teachingNotesData = commentDataByType[CommentType.TEACHING_NOTES];
  const suggestedAnswerData = commentDataByType[CommentType.SUGGESTED_ANSWERS];
  const teachingTipsData = commentDataByType[CommentType.TEACHING_TIPS];
  const selectedType = getCommentType(props.fields);

  const onClickTeachingNotes = useCallback(() => {
    props.addField('commentType', CommentType.TEACHING_NOTES);
  }, [props]);
  const onClickSuggestedAnswer = useCallback(() => {
    props.addField('commentType', CommentType.SUGGESTED_ANSWERS);
  }, [props]);
  const onClickTeachingTips = useCallback(() => {
    props.addField('commentType', CommentType.TEACHING_TIPS);
  }, [props]);

  return (
    <form className="note-type-selector comment-type-selector">
      <p>
        <b>
          <FormattedMessage id="XEditor.Wizard.Note.Header" />
        </b>
      </p>
      <Radio
        name={radioGroupName}
        onChange={onClickTeachingNotes}
        checked={selectedType === CommentType.TEACHING_NOTES}
        key={teachingNotesData.title}
      >
        <span>
          <Icon name={teachingNotesData.iconName} />
          <span>{teachingNotesData.title}</span>
        </span>
      </Radio>
      <Radio
        name={radioGroupName}
        onChange={onClickSuggestedAnswer}
        checked={selectedType === CommentType.SUGGESTED_ANSWERS}
      >
        <span>
          <Icon name={suggestedAnswerData.iconName} />
          <span>{suggestedAnswerData.title}</span>
        </span>
      </Radio>
      <Radio
        name={radioGroupName}
        onChange={onClickTeachingTips}
        checked={selectedType === CommentType.TEACHING_TIPS}
      >
        <span>
          <Icon name={teachingTipsData.iconName} />
          <span>{teachingTipsData.title}</span>
        </span>
      </Radio>
    </form>
  );
};

export default injectIntl(CommentTypeSelector);
