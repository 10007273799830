import React from 'react';
import {type DragElementWrapper, type DragSourceOptions, useDrop} from 'react-dnd';
import {type List} from 'immutable';

import {type XMediaSourcesErrors} from 'store/exercise/editor/interface';
import {DndTypes} from 'components/dnd/interface';

import {type KeyValueItem} from './types';
import {KeyValueRow} from './KeyValueRow';

interface Props {
  readonly rows: List<KeyValueItem>;
  readonly onChangeKey: (id: string, value: string) => void;
  readonly onChangeValue: (id: string, value: string) => void;
  readonly onFocus: (id: string) => void;
  readonly onDrop: (dragIndex: number, hoverIndex: number) => void;
  readonly renderEmptyBlock: () => React.ReactElement;
  readonly renderFooter: () => React.ReactElement;
  readonly renderRowActions: (
    id: string,
    ref: DragElementWrapper<DragSourceOptions>
  ) => React.ReactElement;
  readonly keyPlaceholder: string;
  readonly valuePlaceHolder: string;
  readonly errors?: XMediaSourcesErrors;
}

export function KeyValueBlock({
  rows,
  onDrop,
  onChangeKey,
  onChangeValue,
  onFocus,
  renderEmptyBlock,
  keyPlaceholder,
  valuePlaceHolder,
  renderFooter,
  renderRowActions,
  errors
}: Props) {
  const [, drop] = useDrop({
    accept: DndTypes.EXERCISE_MEDIA_SOURCE,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <div className="key-value-container">
      <div ref={drop} className="key-value-content">
        {rows.size
          ? rows.map((row: KeyValueItem, rowNumber: number) => (
              <KeyValueRow
                key={row.id}
                row={row}
                rowNumber={rowNumber}
                onChangeKey={onChangeKey}
                onChangeValue={onChangeValue}
                onFocus={onFocus}
                onDrop={onDrop}
                renderRowActions={renderRowActions}
                keyPlaceholder={keyPlaceholder}
                valuePlaceHolder={valuePlaceHolder}
                showError={Boolean(errors?.get(row.id))}
              />
            ))
          : renderEmptyBlock()}
      </div>
      {renderFooter && renderFooter()}
    </div>
  );
}
