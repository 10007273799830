import {Editor, Value, type Query, type Next, type Block} from '@englex/slate';

import {
  getBeforeInsertFragmentPlugins,
  getToolbarButtonDisablers
} from 'components/Slate/interface';

import {
  type ButtonType,
  type InsertFragmentPlugin,
  type PluginDisablerPredicate,
  type RenderToolbarButtonProps
} from '../../../interface';
import {getDefaultLeafBlocksFragment} from '../../../utils';
import {isListBlock} from '../../../../../utils';
import Decimal from '../Decimal';

class SimplyExistingOrderedList extends Decimal implements InsertFragmentPlugin {
  protected shouldUnwrapEmptyLiOnEnter = false;

  public renderToolbarButton(props: RenderToolbarButtonProps) {
    return null;
  }

  public onQuery = (query: Query, editor: Editor, next: Next) => {
    if (query.type === getToolbarButtonDisablers) {
      const predicates: PluginDisablerPredicate[] = next() || [];
      const buttonType: ButtonType = query.args[0];
      if (buttonType && Object.keys(this.disableButtons).includes(buttonType)) {
        predicates.unshift(this.disableButtons[buttonType]);
      }
      return predicates;
    }
    if (query.type === getBeforeInsertFragmentPlugins) {
      const plugins = next() || [];
      plugins.unshift(this);
      return plugins;
    }
    return this.onToolbarButtonQuery(query, editor, next);
  };

  protected onBackspace = (
    change: Editor,
    startListItem: Block,
    startBlock: Block,
    endBlock: Block,
    next: Next
  ) => next();

  protected onDelete = (
    change: Editor,
    startBlock: Block,
    endBlock: Block,
    blockHasIcons: boolean,
    next: Next
  ) => {
    return next();
  };

  public toggleChange = (change: Editor) => change;

  public onBeforeInsertFragment = (event: React.ClipboardEvent, tmpChange: Editor) => {
    // if copied fragment is list, paste each block in it as new listItem, else merge all blocks into
    // one and paste it

    if (isListBlock(tmpChange.value.document.nodes.get(0))) {
      return getDefaultLeafBlocksFragment(tmpChange, false);
    }

    const newChange = new Editor({
      value: Value.create({
        document: getDefaultLeafBlocksFragment(tmpChange, false)
      })
    });

    newChange.value.document
      .getBlocks()
      .rest()
      .forEach(block => {
        newChange.mergeNodeByKey(block!.key);
      });

    return newChange.value.document;
  };
}

export default SimplyExistingOrderedList;
