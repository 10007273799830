import React, {type ChangeEvent, type FC, type FocusEvent, useCallback, useState} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import {type ChoicesMap, type DNDChoice} from 'store/exercise/player/interface';
import Icon from 'components/Icon';

interface Props {
  extraChoices: ChoicesMap;
  xwidgetId: string;
  deleteExtraChoice(xwidgetId: string, extraChoiceId: string): void;
  setExtraChoiceValue(xwidgetId: string, extraChoiceId: string, value: string): void;
}

export const ExtraChoices: FC<Props> = ({
  deleteExtraChoice,
  extraChoices,
  setExtraChoiceValue,
  xwidgetId
}) => {
  const intl = useIntl();
  const [focusId, setFocusId] = useState<string | undefined>();

  const onExtraChoiceInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setExtraChoiceValue(xwidgetId, e.target.id, e.target.value);
    },
    [setExtraChoiceValue, xwidgetId]
  );

  const onFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    setFocusId(e.target.id);
  }, []);

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (focusId === e.target.id) setFocusId(undefined);
    },
    [focusId]
  );

  const onExtraChoiceDelete = useCallback(
    (e: React.MouseEvent<Button, MouseEvent>) => {
      deleteExtraChoice(xwidgetId, e.currentTarget['getAttribute']('dataid'));
    },
    [deleteExtraChoice, xwidgetId]
  );

  return (
    <ul className="extra-choices">
      {extraChoices.entrySeq().map(([key, ec]: [string, DNDChoice]) => (
        <li key={key} className={classNames('extra-choice', {focused: focusId === key})}>
          <span className="title">{intl.formatMessage({id: 'XEditor.ExtraAnswer'})}</span>
          <input
            id={key}
            type="text"
            value={ec.value}
            onBlur={onBlur}
            onChange={onExtraChoiceInput}
            onFocus={onFocus}
            placeholder={intl.formatMessage({id: 'XEditor.EnterExtraAnswer'})}
          />
          <Button className="delete-btn" {...{dataid: key}} onClick={onExtraChoiceDelete}>
            <Icon name="trash" />
          </Button>
        </li>
      ))}
    </ul>
  );
};
