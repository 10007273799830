import {buttonLabel, buttonTitle} from '../../button/i18n';
import {ButtonType} from '../../interface';
import EditGapInput from './forms/EditGapInput';
import {GapFillType} from '../../../../interface';

import GapFill from './index';

class GapFillInput extends GapFill {
  public gap = GapFillType.INPUT;
  public title = buttonTitle.GapFillInput;
  public type = ButtonType.GAP_FILL_INPUT;
  public label = buttonLabel.GapFillInput;

  public editForm = EditGapInput;
}

export default GapFillInput;
