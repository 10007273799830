import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import {type CheckboxProps, default as Checkbox} from 'react-bootstrap/lib/Checkbox';
import {type FormikProps, withFormik} from 'formik';
import {type FormikBag} from 'formik/dist/withFormik';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import FormControl from 'react-bootstrap/lib/FormControl';
import classNames from 'classnames';
import * as Yup from 'yup';
import {collapseSpaces, convertTicks, pipe, removeHidden, trim} from '@englex/utils';

import Icon from 'components/Icon';

import {type ToolBoxChildrenProps} from '../../../interface';
import {type GapFillEditFormProps} from '../';
import FormControlTooltip from './FormControlTooltip';
import {FormFooter} from './FormFooter';
import './EditGapForm.scss';

interface Values {
  answer: string[];
  indefiniteForm?: string;
  example: boolean;
  editable: boolean;
}

type Props = GapFillEditFormProps & ToolBoxChildrenProps & WrappedComponentProps;

type FormProps = Props & FormikProps<Values>;

class EditGapInput extends React.Component<FormProps> {
  public render() {
    const {values, handleChange, handleSubmit, del} = this.props;
    return (
      <form className="edit-gap-form" onSubmit={handleSubmit}>
        <Modal.Body className="no-header">
          <div className="label-answer">
            <FormattedMessage id="XEditor.Form.EditGap.CorrectAnswers" />
          </div>
          {values.answer.map(this.renderInput)}

          <Button bsSize="sm" bsStyle="success" className="add-gap" onClick={this.addAnswer}>
            <FormattedMessage
              id="XEditor.Form.EditGap.AddAnswer"
              values={{
                icon: <Icon name="plus-circle" />
              }}
            />
          </Button>
          <Checkbox
            name="example"
            onChange={this.handleExampleCheckbox}
            defaultChecked={values.example}
          >
            <FormattedMessage id="XEditor.Example" />
          </Checkbox>

          <Button bsStyle={null} className="btn-ico toolbox-close" onClick={this.close}>
            <Icon name="pc-close" />
          </Button>
          <div className="label-indefinite-form">
            <FormattedMessage id="XEditor.Form.EditGap.IndefiniteForm" />
          </div>
          <FormControl
            className="indefinite-form"
            autoComplete="off"
            bsSize="sm"
            name="indefiniteForm"
            onChange={handleChange}
            defaultValue={values.indefiniteForm}
          />
          <Checkbox
            name="editable"
            onChange={this.handleEditableCheckbox}
            defaultChecked={values.editable}
            disabled={!values.indefiniteForm}
          >
            <FormattedMessage id="XEditor.Form.EditGap.Editable" />
          </Checkbox>
        </Modal.Body>
        <FormFooter
          showDeleteButton={!!del}
          submitDisabled={this.isDisabled}
          close={this.close}
          deleteGap={this.deleteGap}
        />
      </form>
    );
  }

  private handleExampleCheckbox = (e: React.SyntheticEvent<CheckboxProps>) =>
    this.props.setFieldValue('example', e.currentTarget.checked);

  private handleEditableCheckbox = (e: React.SyntheticEvent<CheckboxProps>) =>
    this.props.setFieldValue('editable', e.currentTarget.checked);

  private renderInput = (answer: string, key: number) => {
    const {values, handleChange, intl, errors} = this.props;
    const error = errors.answer && errors.answer[key] ? errors.answer[key] : null;

    const answerClasses = classNames('answer-row', {'has-error': error});

    const lastKey = values.answer.length - 1;
    const isOnlyOneChoice = values.answer.length === 1;

    return (
      <div className={answerClasses} key={key}>
        <FormControlTooltip state={error ? 'error' : null} content={error ? error : null}>
          <FormControl
            className="correct"
            autoFocus={key === lastKey}
            autoComplete="off"
            bsSize="sm"
            value={answer}
            name={`answer[${key}]`}
            onChange={handleChange}
          />
        </FormControlTooltip>

        {isOnlyOneChoice ? (
          <div className="answer-delete empty" />
        ) : (
          <Button
            bsStyle={null}
            className="btn-ico answer-delete"
            onClick={() => this.deleteAnswer(key)}
            title={intl.formatMessage({id: 'Common.Delete'})}
          >
            <Icon name="trash" />
          </Button>
        )}
      </div>
    );
  };

  private close = () => {
    this.props.close();
  };

  private addAnswer = () => {
    const {values} = this.props;
    values.answer.push('');
    this.props.setValues(values);
  };

  private deleteAnswer = (key: number) => {
    const {answer} = this.props.values;
    this.props.setFieldValue(
      'answer',
      answer.filter((ans, index) => index !== key)
    );
  };

  private deleteGap = () => {
    const {del, close} = this.props;
    if (del) {
      del();
      close(false);
    }
  };

  private get isDisabled() {
    return this.props.isSubmitting;
  }
}

const mapPropsToValues = (props: Props): Values => {
  const answer: string[] = props.data
    ? [...props.data.get('answer')]
    : [props.defaultAnswer ? props.defaultAnswer : ''];
  const indefiniteForm = props.data?.get('indefiniteForm') || '';
  const editable = !!props.data?.get('editable');
  const example = !!props.data?.get('example');
  return {
    answer,
    editable,
    indefiniteForm,
    example
  };
};

const handleFormSubmit: (values: Values, formik: FormikBag<Props, Values>) => void = (
  values,
  formik
) => {
  const {answer, example, indefiniteForm: indefiniteFormRaw = '', editable} = values;
  const mutate = pipe(trim, removeHidden, collapseSpaces, convertTicks);
  const indefiniteForm = mutate(indefiniteFormRaw);
  const answers = answer.map(mutate);

  formik.props.save(answers, {
    indefiniteForm: indefiniteForm || undefined,
    example: example || undefined,
    editable: indefiniteForm.length && editable ? true : undefined
  });

  formik.props.close(false);
};

const validationSchema = (props: Props) => {
  const clean = pipe(trim, removeHidden, collapseSpaces, convertTicks);

  return Yup.object().shape({
    answer: Yup.array()
      .min(1)
      .of(
        Yup.string()
          .default('')
          .transform(clean)
          .test(
            'notEmptyAnswers',
            props.intl.formatMessage({id: 'XEditor.Validation.BlankAnswers'}),
            answer => !props.options?.bannedEmpty || Boolean(answer)
          )
          .test(
            'uniqueAnswer',
            props.intl.formatMessage({id: 'XEditor.Form.EditGap.Validation.Answer.Unique'}),
            function (value: string) {
              const answers = this.parent as string[];
              const equalAnswers = answers.filter(
                ans => clean(ans).toLowerCase() === value.toLowerCase()
              );

              return equalAnswers.length <= 1;
            }
          )
      )
  });
};

const FormikEditGapInput = withFormik<Props, Values>({
  mapPropsToValues,
  validationSchema,
  handleSubmit: handleFormSubmit
})(EditGapInput);

export default injectIntl(FormikEditGapInput);
