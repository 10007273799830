import React from 'react';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import ReactYoutube from 'react-youtube';
import Button from 'react-bootstrap/lib/Button';
import {captureMessage, withScope} from '@sentry/react';
import type Checkbox from 'react-bootstrap/lib/Checkbox';

import * as toastr from 'components/toastr';
import {type AxiosResponseAction} from 'services/axios/interface';
import {type VideoData, type VideoOptions, VideoSourceType} from 'components/media/interface';
import {YoutubeReadyState, type YT} from 'components/media/VideoPlayer/Youtube/YoutubePlayer';
import Icon from 'components/Icon';

import i18n from '../../i18n';
import YoutubeInputModal from './YoutubeInputModal';
import LibraryInputModal from './LibraryInputModal';
import ReplaceVideoTrigger from './ReplaceVideoTrigger';
import NewVideoTrigger from './NewVideoTrigger';
import PosterInput from './PosterInput';

import './styles.scss';

interface Props extends WrappedComponentProps {
  attachPoster: (id?: number) => void;
  attachVideo: (video: VideoData, options?: VideoOptions) => void;
  posterId?: number;
  postVideo: (sourceId: string, source: VideoSourceType) => Promise<AxiosResponseAction<VideoData>>;
  source: VideoSourceType;
  videoId?: number;
  youtubeSourceId?: string;
  start?: number;
  end?: number;
}

interface State {
  timeoutError?: true;
  link: string;
  showModal: boolean;
  submitting: boolean;
  youtubeSourceId?: string;
  youtubeReadyState?: YoutubeReadyState;
  start?: number;
  end?: number;
}

class Input extends React.Component<Props, State> {
  private static readonly responseAwaitTime = 5000;

  public state: State = {
    link: '',
    showModal: false,
    submitting: false
  };

  private awaitTimer: NodeJS.Timeout | undefined;

  private get videoExists() {
    return !!this.props.videoId;
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    const [{source}, {youtubeReadyState}] = [this.props, this.state];
    if (
      source === VideoSourceType.YOUTUBE &&
      prevState.youtubeReadyState === YoutubeReadyState.LOADING
    ) {
      if (youtubeReadyState === YoutubeReadyState.READY) {
        this.postYoutubeVideo();
      }
      if (youtubeReadyState === YoutubeReadyState.ERROR) {
        this.setState({submitting: false, youtubeSourceId: undefined});
        return;
      }
    }
  }

  public componentWillUnmount() {
    clearTimeout(this.awaitTimer!);
  }

  public render() {
    const {attachPoster, intl, posterId, source, videoId} = this.props;
    const {youtubeSourceId} = this.state;
    const {InputModal} = this;
    return (
      <div className={`input-block${this.videoExists ? ' with-video' : ''}`}>
        <InputModal />
        {youtubeSourceId && (
          <div className="youtube-checker">
            <ReactYoutube onReady={this.onReady} onError={this.onError} videoId={youtubeSourceId} />
          </div>
        )}
        <NewVideoTrigger onClick={this.openModal} show={!this.videoExists} source={source} />
        {source === VideoSourceType.SELECTEL && (
          <PosterInput
            intl={intl}
            attachPoster={attachPoster}
            videoId={videoId}
            modalTitle={
              posterId
                ? intl.formatMessage({
                    id: 'XEditorWidget.Video.ReplacePosterButton'
                  })
                : intl.formatMessage({
                    id: 'XEditorWidget.Video.AddPosterButton'
                  })
            }
          >
            {(showModal, showConfirmModal) => (
              <>
                {posterId && (
                  <Button className="poster-remove-btn" onClick={showConfirmModal}>
                    <Icon name="trash" />
                    <FormattedMessage id="XEditorWidget.Video.RemovePosterButton" />
                  </Button>
                )}
                <Button bsSize="small" className="poster-input-btn" onClick={showModal}>
                  <Icon name="image" />
                  {posterId ? (
                    <FormattedMessage id="XEditorWidget.Video.ReplacePosterButton" />
                  ) : (
                    <FormattedMessage id="XEditorWidget.Video.AddPosterButton" />
                  )}
                </Button>
              </>
            )}
          </PosterInput>
        )}
        <ReplaceVideoTrigger source={source} show={this.videoExists} onClick={this.openModal} />
      </div>
    );
  }

  private onReady = (e: {target: YT}) => {
    clearTimeout(this.awaitTimer!);
    this.setState({
      youtubeReadyState: e.target.getDuration() ? YoutubeReadyState.READY : YoutubeReadyState.ERROR
    });
  };

  private onError = () => this.setState({youtubeReadyState: YoutubeReadyState.ERROR});

  private clearReadyStateError = () =>
    this.setState({timeoutError: undefined, youtubeReadyState: undefined});

  private closeModal = () => {
    clearTimeout(this.awaitTimer!);
    this.setState({
      showModal: false,
      timeoutError: undefined,
      youtubeReadyState: undefined,
      submitting: false
    });
  };

  private handleLibrarySubmit = async (sourceId: string) => {
    const {attachVideo} = this.props;
    const response = await this.postVideo(sourceId, VideoSourceType.SELECTEL);
    this.setState({showModal: false, submitting: false});
    if (response) {
      attachVideo(response.payload.data);
    }
  };

  private handleYoutubeSubmit = async ({
    link,
    start,
    end
  }: {
    link: string;
    start?: number;
    end?: number;
  }) => {
    const {
      intl: {formatMessage}
    } = this.props;
    const sourceId = this.parseLinkForYoutubeSourceId(link);
    if (!sourceId) {
      toastr.error('', formatMessage(i18n.invalidInput));
      return;
    }
    this.setState({
      submitting: true,
      youtubeSourceId: sourceId,
      youtubeReadyState: YoutubeReadyState.LOADING,
      start,
      end
    });
    this.awaitTimer = setTimeout(() => {
      withScope(scope => {
        scope.setExtras({
          link,
          sourceId
        });
        captureMessage(
          'XEditor: Obtaining youtube video by link failed - timeout exceeded.',
          'warning'
        );
      });
      this.setState({
        timeoutError: true,
        youtubeReadyState: YoutubeReadyState.ERROR,
        youtubeSourceId: undefined
      });
      clearTimeout(this.awaitTimer!);
    }, Input.responseAwaitTime);
  };

  private openModal = () => this.setState({showModal: true});

  private parseLinkForYoutubeSourceId = (link: string) => {
    const match = link.match(
      '(?:youtube(?:-nocookie)?.com/(?:[^/]+/.+/|(?:v|e(?:mbed)?)/|.*[?&]v=)|youtu.be/)([^"&?/ ]{11})'
    );
    if (!match || !match[1]) {
      return null;
    }
    return match[1];
  };

  private postVideo = async (sourceId: string, source: VideoSourceType) => {
    this.setState({submitting: true});
    const {
      intl: {formatMessage},
      postVideo
    } = this.props;
    let response: AxiosResponseAction<VideoData> | null = null;
    try {
      response = await postVideo(sourceId, source);
    } catch {
      toastr.error('', formatMessage(i18n.postVideoRequestError));
    }
    return response;
  };

  private postYoutubeVideo = async () => {
    const {attachVideo} = this.props;
    const {youtubeSourceId, start, end} = this.state;
    if (!youtubeSourceId) {
      return;
    }
    const response = await this.postVideo(youtubeSourceId, VideoSourceType.YOUTUBE);
    this.setState({showModal: false, submitting: false, youtubeSourceId: undefined});
    if (response) {
      attachVideo(response.payload.data, {youtubeSourceId, start, end});
      this.setState({youtubeReadyState: undefined, start: undefined, end: undefined});
    }
  };

  private onChangeTime = (
    event: React.FormEvent<Checkbox> | React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    this.setState(prevState => ({
      ...prevState,
      [target.name]: target.type === 'checkbox' ? target.checked : target.value
    }));
  };

  private InputModal = () => {
    const {intl, source, youtubeSourceId, start, end} = this.props;
    const {showModal, submitting, youtubeReadyState} = this.state;

    if (source === VideoSourceType.YOUTUBE) {
      return (
        <YoutubeInputModal
          clearReadyStateError={this.clearReadyStateError}
          close={this.closeModal}
          onChangeTime={this.onChangeTime}
          handleSubmit={this.handleYoutubeSubmit}
          intl={intl}
          start={start}
          end={end}
          youtubeSourceId={youtubeSourceId}
          timeoutError={this.state.timeoutError}
          readyStateError={youtubeReadyState === YoutubeReadyState.ERROR}
          show={showModal}
          submitting={submitting}
        />
      );
    }
    if (source === VideoSourceType.SELECTEL) {
      return (
        <LibraryInputModal
          close={this.closeModal}
          handleSubmit={this.handleLibrarySubmit}
          intl={intl}
          show={showModal}
          submitting={submitting}
        />
      );
    }
    return null;
  };
}

export default injectIntl(Input);
