import React from 'react';
import {type Action} from 'redux';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';

import {type VideoData, type VideoOptions, type VideoSourceType} from 'components/media/interface';
import {type AxiosResponseAction} from 'services/axios/interface';
import {type AppState} from 'store/interface';
import {
  attachPosterToRecord as attachPosterActionCreator,
  attachVideoToRecord as attachVideoActionCreator,
  requestPostVideo
} from 'store/exercise/editor/widgets/XVideo/actions';

import Input from './Input';
import Player from './Player';

interface OwnProps {
  posterId?: number;
  sourceId?: string;
  video: VideoData;
  videoOptions?: VideoOptions;
  xwidgetId: string;
}

interface DispatchProps {
  attachPoster: (id?: number) => void;
  attachVideo: (video: VideoData, options: VideoOptions) => void;
  postVideo: (sourceId: string, source: VideoSourceType) => Promise<AxiosResponseAction<VideoData>>;
}

interface Props extends OwnProps, DispatchProps {}

class VideoBlock extends React.Component<Props> {
  public render() {
    const {
      attachPoster,
      attachVideo,
      posterId,
      postVideo,
      video: {id, source},
      videoOptions,
      xwidgetId
    } = this.props;

    const {youtubeSourceId, start, end} = videoOptions || {};

    return (
      <div className="video-block">
        {id && (
          <Player
            id={id}
            posterId={posterId}
            source={source}
            start={start}
            end={end}
            widgetId={xwidgetId}
          />
        )}
        <Input
          videoId={id}
          source={source}
          attachPoster={attachPoster}
          attachVideo={attachVideo}
          posterId={posterId}
          postVideo={postVideo}
          youtubeSourceId={youtubeSourceId}
          start={start}
          end={end}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<Action, AppState>,
  {xwidgetId}: OwnProps
): DispatchProps => ({
  attachVideo: (video: VideoData, options?: VideoOptions) =>
    dispatch(attachVideoActionCreator(video, xwidgetId, options)),
  attachPoster: (id?: number) => dispatch(attachPosterActionCreator(xwidgetId, id)),
  postVideo: (sourceId: string, source: VideoSourceType) =>
    dispatch(requestPostVideo(sourceId, source))
});

export default connect(null, mapDispatchToProps)(VideoBlock);
