import {type Editor as ReactEditor, type Plugin, type RenderBlockProps} from '@englex/slate-react';
import React from 'react';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import Radio from 'react-bootstrap/lib/Radio';
import {type Block, type Editor, type Next} from '@englex/slate';
import {type List} from 'immutable';

import {MultipleChoiceElement} from 'store/exercise/player/widgets/List/interface';

import {isBlockOfType} from '../../../../../utils';
import {SlateBlock, type SlateMark} from '../../../../../interface';
import {type MCAnswerBlock} from './interface';
import QuestionAnswer from '../QuestionAnswer';
import {addDataToBlock} from '../../../utils';

import './MCAnswer.scss';

interface MCAnswerPluginOptions {
  element: MultipleChoiceElement;
  genKey: () => string;
  preventCreate?: boolean;
  preventDelete?: boolean;
  allowedMarks?: SlateMark[];
}

class MCAnswer {
  public element: MultipleChoiceElement;
  public plugins: Plugin[];

  constructor({
    element,
    genKey,
    preventCreate,
    preventDelete,
    allowedMarks
  }: MCAnswerPluginOptions) {
    this.plugins = [new QuestionAnswer({genKey, preventCreate, preventDelete, allowedMarks})];
    this.element = element;
  }

  static stopPropagation(event: React.SyntheticEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  private getElement() {
    switch (this.element) {
      case MultipleChoiceElement.CHECKBOX:
        return Checkbox;
      case MultipleChoiceElement.CIRCLE:
        return () => <div className="circle">&#9679;</div>;
      case MultipleChoiceElement.UpperAlpha:
        return () => <div className="upper-alpha" />;
      default:
        return Radio;
    }
  }

  public renderBlock = (
    {node, attributes, children}: RenderBlockProps,
    editor: ReactEditor & Editor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.QUESTION_ANSWER)) {
      return next();
    }
    const Element = this.getElement();

    return (
      <div {...attributes} className="mc-answer">
        <div
          className="input-wrapper"
          contentEditable={false}
          onMouseDown={MCAnswer.stopPropagation}
        >
          <Element
            checked={!!node.data.get('checked')}
            onChange={(ev: React.FormEvent<Checkbox>) => this.handleSelected(ev, node, editor)}
            tabIndex={-1}
          >
            <span />
          </Element>
        </div>
        <div className="content-wrapper">{children}</div>
      </div>
    );
  };

  public handleSelected = (
    ev: React.FormEvent<Checkbox>,
    answerBlock: MCAnswerBlock,
    editor: Editor
  ) => {
    editor.command((ch: Editor) => {
      ch.withoutSaving(() => {
        if (this.element === MultipleChoiceElement.RADIO) {
          const questionNode = ch.value.document.getParent(answerBlock.key) as Block;
          const previousSelectedAnswers = questionNode.nodes.filter((node: Block) =>
            node.data.get('checked')
          ) as List<MCAnswerBlock>;
          previousSelectedAnswers.forEach(selectedAnswer =>
            addDataToBlock(ch, selectedAnswer!, 'checked', undefined)
          );
          addDataToBlock(ch, answerBlock, 'checked', true);
        }

        if (this.element === MultipleChoiceElement.CHECKBOX) {
          addDataToBlock(
            ch,
            answerBlock,
            'checked',
            answerBlock.data.get('checked') ? undefined : true
          );
        }
      });
    });
  };
}

export default MCAnswer;
