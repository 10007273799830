import React, {type FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

import {addQuestion} from 'store/exercise/editor/widgets/XPictureChoice/actions';
import {type AppState} from 'store/interface';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type PictureChoiceQuestionProperties} from 'store/exercise/player/widgets/PictureChoice/interface';
import {type XPictureChoiceRecord} from 'store/exercise/editor/widgets/XPictureChoice/XPictureChoiceRecord';
import {pictureChoiceQuestionsNumberMin} from 'store/exercise/editor/widgets/constants';
import {resetWidgetErrors} from 'store/exercise/editor/actions/xeditor';
import Icon from 'components/Icon';
import {useRecoveryPoint} from 'components/DndSortingWrapper/hooks/useRecoveryPoint';

import {QuestionBlock} from './QuestionBlock/QuestionBlock';

import './XEditorPictureChoice.scss';

interface OwnProps {
  id: string;
  setError: (e: string | JSX.Element) => string | JSX.Element;
}

type Props = OwnProps;

const XEditorPictureChoice: FC<Props> = ({id, setError}) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();

  const widget = useSelector((state: AppState) =>
    state.xeditor!.xexercise.widgets.find((x: XWidgetProperties) => x.id === id)
  ) as XPictureChoiceRecord;

  const errors = useSelector((state: AppState) => {
    return state.xeditor!.errors.getIn(['widgets', id], {});
  });

  const {questions, mode} = widget;

  const {recoveryPoint, updateRecoveryPoint} = useRecoveryPoint<PictureChoiceQuestionProperties>({
    list: questions
  });

  const resetErrors = useCallback(() => dispatch(resetWidgetErrors(id)), [dispatch, id]);

  const onAddQuestion = useCallback(() => {
    dispatch(addQuestion(id));
    updateRecoveryPoint();
  }, [dispatch, id, updateRecoveryPoint]);

  return (
    <div className="picture-choice">
      {questions.map((question: PictureChoiceQuestionProperties, index: number) => {
        const questionsSize = questions.size;
        const canDelete = questionsSize !== pictureChoiceQuestionsNumberMin;
        const canMoveUp = questionsSize !== pictureChoiceQuestionsNumberMin && index !== 0;
        const canMoveDown =
          questionsSize !== pictureChoiceQuestionsNumberMin && index !== questionsSize - 1;

        return (
          <QuestionBlock
            widgetId={id}
            id={question.id}
            questionIndex={index}
            content={question.content}
            cards={question.cards}
            widget={widget}
            errors={errors}
            mode={mode}
            canDelete={canDelete}
            canMoveUp={canMoveUp}
            canMoveDown={canMoveDown}
            resetErrors={resetErrors}
            setError={setError}
            key={question.id}
            recoveryPoint={recoveryPoint}
            updateRecoveryPoint={updateRecoveryPoint}
          />
        );
      })}

      <div className="add-question">
        <Button onClick={onAddQuestion} className="add-card">
          <Icon name="plus-circle" />
          {formatMessage({id: 'Slate.Toolbar.Button.AddMCQuestion'})}
        </Button>
      </div>
    </div>
  );
};

export default XEditorPictureChoice;
