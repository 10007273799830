export default [
  'COMICS: listening and reading',
  'COMICS: post-reading',
  'COMICS: pre-reading',
  'COMMUNICATION',
  'GRAMMAR',
  'HOMEWORK',
  'HOMEWORK: comics',
  'HOMEWORK: listening and reading',
  'HOMEWORK: post-reading',
  'HOMEWORK: song',
  'HOMEWORK: words',
  'HOMEWORK: writing',
  'LANGUAGE AT WORK',
  'LEAD-IN',
  'LISTENING',
  'LISTENING: pre-listening',
  'MINI GRAMMAR',
  'PRONUNCIATION',
  'READING',
  'READING: listening and reading',
  'READING: post-reading',
  'READING: pre-reading',
  'SONG',
  'SONG: listening and reading',
  'SONG: post-reading',
  'SONG: pre-reading',
  'SPEAKING',
  'TRANSLATION',
  'VIDEO',
  'VOCABULARY',
  'WORD LIST',
  'WORDS',
  'WRITING'
].sort();
