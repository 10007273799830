import {
  Block,
  type Editor,
  type Next,
  type SchemaProperties,
  type SlateError,
  Document,
  type Node
} from '@englex/slate';
import {type Editor as ReactEditor, type Plugin} from '@englex/slate-react';
import {type List} from 'immutable';
import type React from 'react';

import {type SchemaBlockPlugin, type SchemaBlockRules} from '../../interface';
import {SlateBlock, SlateObject} from '../../../../interface';
import {isBlockOfType} from '../../../../utils';
import {copyFragment, logNormalizeError} from '../../utils';
import QuestionsListRenderer from '../../../../plugins/renderers/QuestionsList/QuestionsList';
import {blockIsQuestionAnswer} from './utils';

interface QuestionsListPluginOptions {
  defaultQuestionsNumber?: number;
}

export default class QuestionsList implements SchemaBlockPlugin, Plugin {
  public block = SlateBlock.QUESTION_LIST;
  public defaultQuestionsNumber: number;
  public plugins = [new QuestionsListRenderer()];

  public schema: SchemaProperties = {
    rules: [
      {
        match: {object: SlateObject.DOCUMENT},
        nodes: [
          {
            match: [{type: this.block}],
            min: 1,
            max: 1
          }
        ],
        normalize: (change: Editor, error: SlateError) => {
          const {child, code} = error;
          if (code === 'child_type_invalid') {
            // this code runs only one time when editor is initialised and has no block of type QUESTION_LIST
            change.withoutSaving(() => {
              change.wrapBlockByKey(child!.key, {type: SlateBlock.QUESTION_LIST});

              const createdList = change.value.document.nodes.get(0);
              for (let i = 1; i < this.defaultQuestionsNumber; i++) {
                // these blocks later will be wrapped in QUESTION_ITEM and added answers in normalizer
                change.insertNodeByKey(
                  createdList.key,
                  i,
                  Block.create({type: SlateBlock.DEFAULT})
                );
              }
            });
            logNormalizeError(error, 'QuestionsList.schema.rules', true, '0');
            return;
          }
          logNormalizeError(error, 'QuestionsList.schema.rules', false, '0');
        }
      }
    ]
  };

  constructor({defaultQuestionsNumber}: QuestionsListPluginOptions) {
    this.defaultQuestionsNumber = defaultQuestionsNumber || 1;
  }

  public blockRules = (): SchemaBlockRules => ({
    type: this.block,
    rules: {
      nodes: [
        {
          match: [{type: SlateBlock.QUESTION_ITEM}],
          min: 1
        }
      ]
    },
    normalizer: {
      predicate: ({node}: SlateError) => isBlockOfType(node, SlateBlock.QUESTION_LIST),
      reasons: {
        child_type_invalid: (change: Editor, {child}: SlateError) => {
          if (isBlockOfType(child!, SlateBlock.DEFAULT)) {
            change.wrapBlockByKey(child.key, {type: SlateBlock.QUESTION_ITEM});
            return true;
          }
          return;
        }
      }
    }
  });

  public onCopy = (event: React.ClipboardEvent, change: ReactEditor & Editor, next: Next) => {
    const handled = this.handleCopy(event, change);
    return !handled ? next() : undefined;
  };

  public handleCopy = (event: React.ClipboardEvent, change: ReactEditor): boolean | undefined => {
    const {value} = change;
    const {blocks, selection, document} = value;
    const allCopiedBlocksAreAnswers = !blocks.find(block => !blockIsQuestionAnswer(value, block!));
    if (allCopiedBlocksAreAnswers) {
      // if all copied blocks are ANSWERS blocks, copy just them, without QUESTIONS or QUESTION_LIST blocks
      const range = document.createRange({anchor: selection?.anchor, focus: selection?.focus});
      const fragmentWithAllLevels = document.getFragmentAtRange(range);
      const answerBlocks = fragmentWithAllLevels.getBlocks().map<Node>(block => {
        return fragmentWithAllLevels.getParent(block!.key) as Node;
      }) as List<Node>;
      const minIndentLevelFragment = Document.create(answerBlocks);
      copyFragment(event, minIndentLevelFragment);
      return true;
    }
    return;
  };

  public onCut = (event: React.ClipboardEvent, change: ReactEditor & Editor, next: Next) => {
    if (this.handleCopy(event, change)) {
      window.requestAnimationFrame(() => {
        change.command(newChange => newChange.delete());
      });
      return;
    }
    return next();
  };
}
