import React, {useCallback, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {Map} from 'immutable';
import classNames from 'classnames';

import {resetWidgetErrors} from 'store/exercise/editor/actions/xeditor';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type AppState} from 'store/interface';
import {type XImageLabelingProperties} from 'store/exercise/editor/widgets/XImage/XImageLabeling/interface';
import Icon from 'components/Icon';
import SlateToolBox from 'components/Slate/SlateEditor/plugins/components/SlateToolBox';
import EditModal from 'components/Slate/SlateEditor/plugins/widget/GapFill/forms/EditGapInput';
import type XImageLabelingCardRecord from 'store/exercise/editor/widgets/XImage/XImageLabeling/XImageLabelingCardRecord';
import {
  addPair,
  moveCard,
  rollBackCards,
  updatePair
} from 'store/exercise/editor/widgets/XImage/actions';
import {DndSortingWrapper} from 'components/DndSortingWrapper/DndSortingWrapper';
import {useRecoveryPoint} from 'components/DndSortingWrapper/hooks/useRecoveryPoint';
import {DndTypes} from 'components/dnd/interface';
import {Provider} from 'components/XPlayer/contexts/dndContext';

import ImageLabelingCard from './ImageLabelingCard';

import '../XEditorImage.scss';

interface Options {
  example?: boolean;
  editable?: boolean;
  indefiniteForm: string;
}

interface Props {
  id: string;
  setError: (e: string | JSX.Element) => string | JSX.Element;
}

const XEditorImageLabeling = React.memo(({id, setError}: Props) => {
  const toolboxRef = useRef<SlateToolBox>(null);

  const dispatch = useDispatch();

  const widget = useSelector(
    (state: AppState) =>
      state.xeditor!.xexercise.widgets.find(
        (x: XWidgetProperties) => x.id === id
      ) as XImageLabelingProperties
  );

  const {recoveryPoint, updateRecoveryPoint} = useRecoveryPoint({list: widget.cards});
  const [isDndDragUsed, setIsDndDragUsed] = useState(false);

  const resetErrors = useCallback(() => dispatch(resetWidgetErrors(id)), [dispatch, id]);

  const updateCard = useCallback(
    (cardId: string, answers: string[], options: Options) => {
      dispatch(
        updatePair(id, cardId, {
          answers,
          example: options.example,
          editable: options.editable,
          placeholder: options.indefiniteForm
        })
      );
      requestAnimationFrame(updateRecoveryPoint);
    },
    [dispatch, id, updateRecoveryPoint]
  );

  const onOpenModal = useCallback(
    (cardId: string) => {
      const card = widget.cards.find((card: XImageLabelingCardRecord) => card.id === cardId);
      const answers = card.example
        ? widget.exampleAnswers.get(cardId, [])
        : widget.answers.get(cardId, []);

      toolboxRef.current?.open(EditModal, {
        data: Map({
          answers,
          answer: answers.length ? answers : [''],
          indefiniteForm: card.placeholder,
          editable: card.editable,
          example: card.example
        }),
        options: {
          bannedEmpty: true
        },
        save: (answers: string[], options: Options) => {
          requestAnimationFrame(() => updateCard(cardId, answers, options));
        }
      });
    },
    [updateCard, widget]
  );

  const moveItem = useCallback(
    (moveItemIndex: number, targetIndex: number) => {
      dispatch(moveCard(id, moveItemIndex, targetIndex));
    },
    [dispatch, id]
  );

  const rollBackChanges = useCallback(() => {
    dispatch(rollBackCards(id, recoveryPoint.current));
  }, [dispatch, recoveryPoint, id]);

  const getItemIndex = useCallback(
    (cardId: string) => {
      return widget.cards.findIndex(card => card?.id === cardId);
    },
    [widget.cards]
  );
  const addCard = useCallback(() => {
    dispatch(addPair(id));
    requestAnimationFrame(updateRecoveryPoint);
  }, [dispatch, updateRecoveryPoint, id]);

  return (
    <Provider>
      <div className="image-labeling">
        <div className={classNames('im-cards', {'is-dnd-drag-used': isDndDragUsed})}>
          {widget.cards.map((card: XImageLabelingCardRecord, index: number) => {
            const answers = card.example
              ? widget.exampleAnswers.get(card.id, [])
              : widget.answers.get(card.id, []);

            return (
              <DndSortingWrapper
                key={card.id}
                itemId={card.id}
                dndType={DndTypes.IMAGE_LABELING}
                isNotUseDragLayer={false}
                ChildComponent={ImageLabelingCard}
                childOptions={{
                  index: index,
                  xwidgetId: id,
                  id: card.id,
                  imageId: card.imageId,
                  example: card.example,
                  editable: card.editable,
                  placeholder: card.placeholder,
                  answers: answers,
                  cardSizeType: card.cardSizeType,
                  reportError: setError,
                  resetErrors: resetErrors,
                  updateRecoveryPoint: updateRecoveryPoint,
                  onOpenModal: onOpenModal
                }}
                updateRecoveryPoint={updateRecoveryPoint}
                moveItem={moveItem}
                rollBackChanges={rollBackChanges}
                getItemIndex={getItemIndex}
                setIsDndDragUsed={setIsDndDragUsed}
              />
            );
          })}
        </div>

        <Button onClick={addCard} className="add-card">
          <Icon name="plus-circle" />
          <FormattedMessage id="XEditorWidget.ImageMatching.AddPairButton" />
        </Button>

        <SlateToolBox ref={toolboxRef} />
      </div>
    </Provider>
  );
});

export default XEditorImageLabeling;
