import React from 'react';

import {type VideoData} from 'components/media/interface';
import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {type VideoWidgetJSON} from 'store/exercise/player/widgets/Video/interface';
import {valueJSONFromText} from 'components/Slate/utils/value';
import genKey from 'components/Slate/utils/genKey';
import XVideoRecord from 'store/exercise/editor/widgets/XVideo/XVideoRecord';

import {type XWizardWidgetData} from '../interface';
import Tile from '../Tile';
import VideoOptions from './VideoOptions';
import {VideoSVG} from '../assets';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.Video}>
    <VideoSVG />
  </Tile>
);

export const videoWizardData: XWizardWidgetData<VideoWidgetJSON, XVideoRecord> = {
  type: WidgetType.VIDEO,
  steps: [
    {
      canGoToNextStep: (widget: Partial<VideoWidgetJSON>) =>
        !!(widget.video && widget.video.source),
      component: VideoOptions
    }
  ],
  tile,
  createWidget: (widget: {type: WidgetType; video: VideoData}) =>
    new XVideoRecord({
      id: genKey(),
      task: valueJSONFromText(''),
      type: widget.type,
      content: valueJSONFromText(''),
      video: widget.video
    })
};
