import React, {memo} from 'react';
import classNames from 'classnames';
import {type CompleteCrop} from 'react-image-crop';

import {type ValidatedImageFile} from 'common/ImageUpload/functions';
import {
  type UploadingPictureStatus,
  type UploadingPictureValidationError
} from 'common/ImageUpload/interface';
import {AspectRatio} from 'components/Crop/static';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';
import {type EnglexImage} from 'store/interface';

import CropModal from '../../../../../components/CropModal';
import {CropFooter} from '../CropFooter/CropFooter';
import {AvatarImage, AvatarTypes} from '../../../../assets/AvatarImage';

import './Avatar.scss';

interface Props {
  avatarMinWidth: number;
  isErrorStatus: boolean;
  retry: boolean;
  freezeModal: boolean;
  isOver: boolean;
  avatar: JSX.Element;
  imageDataUrl?: string;
  uploadingStarted?: boolean;
  errorMessage?: string;
  validatedFile?: ValidatedImageFile;
  cropResult?: CompleteCrop;
  status?: UploadingPictureStatus | UploadingPictureValidationError;
  handleFileInput: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  closeCrop: () => void;
  storeCrop: (cropResult: CompleteCrop) => void;
  uploadCrop: () => void;
  turnOffRetry: () => void;
  setStatus: (status?: UploadingPictureStatus | undefined) => void;
  handleError: ({error}: AxiosRequestError) => void;
  handleResponse: ({payload}: AxiosResponseAction<EnglexImage>) => void;
}

export const AvatarView: React.FC<Props> = memo(
  ({
    avatarMinWidth,
    imageDataUrl,
    uploadingStarted,
    errorMessage,
    validatedFile,
    isErrorStatus,
    cropResult,
    retry,
    status,
    freezeModal,
    isOver,
    avatar,
    handleFileInput,
    closeCrop,
    storeCrop,
    uploadCrop,
    turnOffRetry,
    setStatus,
    handleError,
    handleResponse
  }) => (
    <>
      {imageDataUrl ? (
        <CropModal
          aspect={AspectRatio.SQUARE}
          minWidth={avatarMinWidth}
          imageDataUrl={imageDataUrl}
          storeCrop={storeCrop}
          hide={closeCrop}
          renderFooter={() => (
            <CropFooter
              uploadingStarted={uploadingStarted}
              errorMessage={errorMessage}
              validatedFile={validatedFile}
              isErrorStatus={isErrorStatus}
              cropResult={cropResult}
              retry={retry}
              status={status}
              freezeModal={freezeModal}
              closeCrop={closeCrop}
              storeCrop={storeCrop}
              handleError={handleError}
              handleResponse={handleResponse}
              setStatus={setStatus}
              turnOffRetry={turnOffRetry}
              uploadCrop={uploadCrop}
            />
          )}
        />
      ) : (
        <label className={classNames('avatar', {isOver: isOver})}>
          <AvatarImage avatarType={AvatarTypes.HOVER} />
          {avatar}
          <input
            className="file-input"
            accept={'.jpg,.jpeg,.png,.bmp'}
            multiple={false}
            type="file"
            onChange={handleFileInput}
          />
        </label>
      )}
    </>
  )
);
