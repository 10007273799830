import {type FC} from 'react';

import {type FooterProps, Phase} from './interface';

const ImageModalFooterBody: FC<FooterProps> = ({phase, renderErrorStatus, renderUploader}) => {
  switch (phase) {
    case Phase.UPLOADING:
      return renderUploader();
    case Phase.DEFAULT:
      return renderErrorStatus();
    default:
      return null;
  }
};

export default ImageModalFooterBody;
