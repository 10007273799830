import {type AxiosRequestAction} from 'services/axios/interface';
import {type VideoData, type VideoOptions, type VideoSourceType} from 'components/media/interface';

import {
  ATTACH_POSTER,
  ATTACH_VIDEO,
  REQUEST_ATTACH_POSTER_TO_VIDEO,
  REQUEST_POST_VIDEO,
  REQUEST_VIDEO_LIBRARY_TREE
} from './actionTypes';
import {type XWidgetAction} from '../../actions/interface';

export interface AttachPosterAction extends XWidgetAction {
  id?: number;
}

export interface AttachVideoAction extends XWidgetAction {
  video: VideoData;
  options?: VideoOptions;
}

export const attachVideoToRecord = (
  video: VideoData,
  xwidgetId: string,
  options?: VideoOptions
): AttachVideoAction => ({
  type: ATTACH_VIDEO,
  video,
  xwidgetId,
  options
});

export const attachPosterToRecord = (xwidgetId: string, id?: number): AttachPosterAction => ({
  type: ATTACH_POSTER,
  id,
  xwidgetId
});

export const requestLibraryTree: () => AxiosRequestAction = () => ({
  type: REQUEST_VIDEO_LIBRARY_TREE,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `/v2/video/library`
    }
  }
});

export const requestPostVideo: (sourceId: string, source: VideoSourceType) => AxiosRequestAction = (
  sourceId,
  source
) => ({
  type: REQUEST_POST_VIDEO,
  payload: {
    client: 'v2',
    request: {
      data: {source, sourceId},
      method: 'POST',
      url: '/v2/video'
    }
  }
});

export const requestAttachPosterToVideo: (
  videoId: number,
  posterId: number
) => AxiosRequestAction = (videoId, posterId) => ({
  type: REQUEST_ATTACH_POSTER_TO_VIDEO,
  payload: {
    client: 'v2',
    request: {
      data: {posterId},
      method: 'POST',
      url: `/v2/video/${videoId}/poster`
    }
  }
});
