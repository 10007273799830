import React from 'react';
import {type Editor, type Value} from '@englex/slate';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {type Action, type Dispatch} from 'redux';
import {type Plugin} from '@englex/slate-react';
import {type List} from 'immutable';

import {type QuizOptions} from 'store/exercise/player/widgets/Quiz/interface';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import AddQuestion from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/AddQuestion';
import RemoveQuestion from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/RemoveQuestion';
import MCAnswer from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/MultipleChoice/MCAnswer';
import QuestionsList from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/QuestionsList';
import Question from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/Question';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import {
  MultipleChoiceDefaultAnswers,
  MultipleChoiceElement
} from 'store/exercise/player/widgets/List/interface';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import genKey from 'components/Slate/utils/genKey';
import {resetWidgetErrors} from 'store/exercise/editor/actions/xeditor';
import {changeBlockText, changeBlockTitle} from 'store/exercise/editor/widgets/XQuiz/actions';
import {formattedTextContentChange} from 'store/exercise/editor/xwidgetActions';
import {deleteWidget} from 'store/exercise/editor/actions/xwidgets';
import {type AppState} from 'store/interface';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type XQuizProperties} from 'store/exercise/editor/widgets/XQuiz/XQuizRecord';
import type XQuizBlockRecord from 'store/exercise/editor/widgets/XQuiz/XQuizBlockRecord';

import {XQuizBlock} from './XQuizBlock';

import './XEditorQuiz.scss';

interface StateProps {
  content: Value;
  options: QuizOptions;
  resultBlocks: List<XQuizBlockRecord>;
  errors: Record<string, {blockId: string}>;
}

interface DispatchProps {
  onContentChange(widgetId: string, change: Editor): void;
  onTitleChange(widgetId: string, block: string, change: Editor): void;
  onTextChange(widgetId: string, block: string, change: Editor): void;
  resetErrors(widgetId: string): void;
}

interface OwnProps {
  id: string;
}

type Props = OwnProps & StateProps & DispatchProps;

class XEditorQuiz extends React.Component<Props> {
  private readonly plugins: Plugin[];

  constructor(props: Props) {
    super(props);
    this.plugins = [
      new AddQuestion(),
      new RemoveQuestion(),
      new Undo(),
      new Redo(),
      new Bold(),
      new Italic(),
      new Underline(),
      new StrikeThrough(),
      new HighlightToolbar(),
      new CharSelector(),
      new MCAnswer({
        element: MultipleChoiceElement.UpperAlpha,
        preventCreate: true,
        preventDelete: true,
        genKey
      }),
      new QuestionsList({defaultQuestionsNumber: props.options.questionsNumber}),
      new Question({
        minAnswers: props.options.answersNumber,
        maxAnswers: props.options.answersNumber,
        defaultAnswersNumber: props.options.answersNumber,
        defaultAnswersType: MultipleChoiceDefaultAnswers.EMPTY,
        genKey
      }),
      new FormatPainter(),
      new ClearFormatting()
    ];
  }

  public render() {
    return (
      <div className="x-quiz">
        <SlateEditor
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          value={this.props.content}
          onChange={this.onContentChange}
          plugins={this.plugins}
        />

        <div className="quiz-blocks">
          {this.props.resultBlocks.map((block: XQuizBlockRecord) => {
            const withError = Boolean(this.props.errors.meta?.[block.id]);

            return (
              <XQuizBlock
                key={block.id}
                id={block.id}
                title={block.title}
                text={block.text}
                onChangeTitle={this.onTitleChange}
                onChangeText={this.onTextChange}
                withError={withError}
              />
            );
          })}
        </div>
      </div>
    );
  }

  private onContentChange = (change: Editor) => {
    this.props.resetErrors(this.props.id);
    this.props.onContentChange(this.props.id, change);
  };

  private onTitleChange = (blockId: string, change: Editor) => {
    this.props.resetErrors(this.props.id);
    this.props.onTitleChange(this.props.id, blockId, change);
  };

  private onTextChange = (blockId: string, change: Editor) => {
    this.props.resetErrors(this.props.id);
    this.props.onTextChange(this.props.id, blockId, change);
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps
): StateProps => {
  const xwidget = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === ownProps.id
  ) as XQuizProperties;

  const {content, options, resultBlocks} = xwidget;

  const errors = state.xeditor!.errors.getIn(['widgets', ownProps.id], {});

  return {
    content,
    options,
    resultBlocks,
    errors
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch<Action>
) => {
  return {
    onContentChange: (widgetId: string, change: Editor) =>
      dispatch(formattedTextContentChange(widgetId, change)),
    onTitleChange: (widgetId: string, blockId: string, change: Editor) =>
      dispatch(changeBlockTitle(widgetId, blockId, change)),
    onTextChange: (widgetId: string, blockId: string, change: Editor) =>
      dispatch(changeBlockText(widgetId, blockId, change)),
    deleteWidget: (widgetId: string) => dispatch(deleteWidget(widgetId)),
    resetErrors: (widgetId: string) => dispatch(resetWidgetErrors(widgetId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(XEditorQuiz);
