import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import {type Editor} from '@englex/slate';
import classNames from 'classnames';

import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {WidgetType} from 'store/exercise/player/interface';
import {type AppState} from 'store/interface';
import {displayButtonTitleChange, toggleWidgetAsButton} from 'store/exercise/editor/xwidgetActions';
import {resetWidgetErrors} from 'store/exercise/editor/actions/xeditor';

import {XDisplayAsButtonIconSelector} from './XDisplayAsButtonIconSelector';

import './XDisplayAsButton.scss';

const plugins = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FormatPainter(),
  new ClearFormatting()
];

const messages = defineMessages({
  buttonTitlePlaceholder: {
    id: 'XEditor.XWidget.EnterButtonTitle'
  },
  buttonTitleOptionalPlaceholder: {
    id: 'XEditor.XWidget.EnterButtonTitleOptional'
  }
});

interface Props {
  xwidgetId: string;
  disabled?: boolean;
  tip?: string;
  hasOptionalPlaceholder?: boolean;
  className?: string;
}

export const XDisplayAsButton: React.FC<Props> = ({
  xwidgetId,
  disabled,
  tip,
  hasOptionalPlaceholder,
  className,
  children
}) => {
  const {formatMessage} = useIntl();

  const dispatch = useDispatch();

  const widget = useSelector((state: AppState) => {
    return state.xeditor!.xexercise.widgets.find((x: XWidgetProperties) => x.id === xwidgetId);
  });

  const toggleAsButton = useCallback(() => {
    dispatch(toggleWidgetAsButton(xwidgetId));
    dispatch(resetWidgetErrors(xwidgetId));
  }, [dispatch, xwidgetId]);

  const onButtonTitleChange = useCallback(
    (change: Editor) => dispatch(displayButtonTitleChange(xwidgetId, change)),
    [dispatch, xwidgetId]
  );

  const checked = Boolean(widget.displayButton);

  const classnames = classNames('display-as-button__editor', className);

  if (!widget.hasDisplayButton) {
    return null;
  }

  const slateEditor = widget.displayButton && (
    <SlateEditor
      toolbar={{portalId: 'xeditor-toolbar-portal'}}
      value={widget.displayButton.title}
      onChange={onButtonTitleChange}
      plugins={plugins}
      placeholder={
        hasOptionalPlaceholder
          ? formatMessage(messages.buttonTitleOptionalPlaceholder)
          : formatMessage(messages.buttonTitlePlaceholder)
      }
    />
  );

  return (
    <div className="display-as-button">
      <Checkbox onChange={toggleAsButton} checked={checked} title={tip} disabled={disabled}>
        <FormattedMessage id="XEditor.XWidget.DisplayAsButton" />
      </Checkbox>

      {children}

      {checked && widget.displayButton && (
        <div className={classnames}>
          {widget.type === WidgetType.COMMENT ? (
            slateEditor
          ) : (
            <XDisplayAsButtonIconSelector
              displayButton={widget.displayButton}
              xwidgetId={xwidgetId}
            >
              {slateEditor}
            </XDisplayAsButtonIconSelector>
          )}
        </div>
      )}
    </div>
  );
};
