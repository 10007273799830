import React from 'react';
import {List} from 'immutable';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {type DialogueJSON} from 'store/exercise/player/widgets/Dialogue/interface';
import XDialogueRecord from 'store/exercise/editor/widgets/XDialogue/XDialogueRecord';
import XDialogueSpeakerRecord from 'store/exercise/editor/widgets/XDialogue/XDialogueSpeakerRecord';

import Tile from '../Tile';
import {DialogueSVG} from '../assets';
import {type XWizardWidgetData} from '../interface';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.Dialogue}>
    <DialogueSVG />
  </Tile>
);

export const dialogueWizardData: XWizardWidgetData<DialogueJSON, XDialogueRecord> = {
  type: WidgetType.DIALOGUE,
  steps: [],
  tile,
  createWidget: () => XDialogueRecord.createDialogue(List([new XDialogueSpeakerRecord()]), List([]))
};
