import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {type CompleteCrop} from 'react-image-crop';

import ImageCropUploader from 'common/ImageUpload/ImageCropUploader';
import {type ValidatedImageFile} from 'common/ImageUpload/functions';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';
import {type EnglexImage} from 'store/interface';
import {
  type UploadingPictureStatus,
  type UploadingPictureValidationError
} from 'common/ImageUpload/interface';

interface Props {
  isErrorStatus: boolean;
  retry: boolean;
  freezeModal: boolean;
  uploadingStarted?: boolean;
  errorMessage?: string;
  validatedFile?: ValidatedImageFile;
  status?: UploadingPictureStatus | UploadingPictureValidationError;
  cropResult?: CompleteCrop;
  closeCrop: () => void;
  storeCrop: (cropResult: CompleteCrop) => void;
  uploadCrop: () => void;
  turnOffRetry: () => void;
  setStatus: (status?: UploadingPictureStatus) => void;
  handleError: ({error}: AxiosRequestError) => void;
  handleResponse: ({payload}: AxiosResponseAction<EnglexImage>) => void;
}

export const CropFooter: React.FC<Props> = memo(
  ({
    uploadingStarted,
    errorMessage,
    validatedFile,
    isErrorStatus,
    cropResult,
    retry,
    status,
    freezeModal,
    closeCrop,
    storeCrop,
    uploadCrop,
    turnOffRetry,
    setStatus,
    handleError,
    handleResponse
  }) => (
    <div className="image-upload-controls">
      <Button bsSize="small" onClick={closeCrop}>
        <FormattedMessage id="Slate.Modal.Image.CancelButton" />
      </Button>

      {uploadingStarted && (
        <ImageCropUploader
          errorMessage={errorMessage}
          file={validatedFile!}
          isErrorStatus={isErrorStatus}
          crop={cropResult!}
          shouldRetry={retry}
          status={status}
          thumbnail={[96]}
          handleError={handleError}
          handleResponse={handleResponse}
          turnOffRetry={turnOffRetry}
          setStatus={setStatus}
        />
      )}

      <Button bsSize="small" bsStyle="primary" onClick={uploadCrop} disabled={freezeModal}>
        <FormattedMessage id="Common.Upload" />
      </Button>
    </div>
  )
);
