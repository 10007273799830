import React from 'react';

import {type DialogueJSON} from 'store/exercise/player/widgets/Dialogue/interface';
import {WidgetType} from 'store/exercise/player/interface';
import XSpellingRecord from 'store/exercise/editor/widgets/XSpelling/XSpellingRecord';
import {type SpellingOptions} from 'store/exercise/player/widgets/Selling/interface';
import XSpellingWordsRecord from 'store/exercise/editor/widgets/XSpelling/XSpellingWordsRecord';

import Tile from '../Tile';
import {SpellingSVG} from '../assets';
import {type XWizardWidgetData} from '../interface';
import SpellingWizard from './SpellingWizard';
import {SpellingType} from '../../../../../../../store/exercise/editor/widgets/XSpelling/interface';

const tile: React.FC = () => (
  <Tile title="Spelling">
    <SpellingSVG />
  </Tile>
);

export const spellingWizardData: XWizardWidgetData<DialogueJSON, XSpellingRecord> = {
  type: WidgetType.SPELLING,
  steps: [
    {
      canGoToNextStep: (options: SpellingOptions) => !!options.spellingType,
      component: SpellingWizard
    }
  ],
  tile,
  createWidget: (options: SpellingOptions) =>
    options.spellingType === SpellingType.Phrases
      ? XSpellingRecord.create()
      : XSpellingWordsRecord.create()
};
