import React, {type FC, memo} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {type Iterable} from 'immutable';

import Icon from 'components/Icon';
import type XDialogueSpeakerRecord from 'store/exercise/editor/widgets/XDialogue/XDialogueSpeakerRecord';
import {type XDialogueSpeakerProperties} from 'store/exercise/editor/widgets/XDialogue/interface';

import {SpeakerView} from '../SpeakerView/SpeakerView';

import './SpeakersModal.scss';

interface Props {
  opened: boolean;
  speakers: Iterable<number, XDialogueSpeakerProperties>;
  selectedSpeaker: string | null;
  setSelectedSpeaker: React.Dispatch<React.SetStateAction<string | null>>;
  onClose: () => void;
  onSelected: () => void;
}

export const SpeakersModal: FC<Props> = memo(
  ({opened, speakers, selectedSpeaker, setSelectedSpeaker, onClose, onSelected}) => (
    <Modal backdrop="static" className="speakers-modal" show={opened} onHide={onClose}>
      <Modal.Header className="header">
        <FormattedMessage id="XEditorXWidget.Dialogue.SelectSpeaker" />
        <Button className="btn-xs" onClick={onClose}>
          <Icon name="pc-close i-lg" />
        </Button>
      </Modal.Header>
      <Modal.Body className="body">
        <div className="speaker-views">
          {speakers.map((speaker: XDialogueSpeakerRecord) => {
            const onClick = () => setSelectedSpeaker(speaker.id);
            const selected = selectedSpeaker === speaker.id;
            return (
              <SpeakerView
                key={speaker.id}
                avatar={speaker.avatar}
                name={speaker.name}
                selected={selected}
                onClick={onClick}
              />
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="footer">
        <div className="modal-controls">
          <Button bsSize="small" onClick={onClose}>
            <FormattedMessage id="MediaDevicesWizard.ButtonClose" />
          </Button>

          <Button bsSize="small" bsStyle="primary" disabled={!selectedSpeaker} onClick={onSelected}>
            <FormattedMessage id="MediaDevicesWizard.ButtonSave" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
);
