import React, {useMemo} from 'react';
import {type IntlShape, useIntl} from 'react-intl';

import {AspectRatio} from 'components/Crop/static';
import Icon from 'components/Icon';
import DropDown from 'components/DropDown';

import './CropDropdown.scss';

const trigger = ['click'];

const placement = 'top';

function getAspectRatioText(aspectRatio: keyof AspectRatio, intl: IntlShape) {
  switch (AspectRatio[aspectRatio]) {
    case AspectRatio.SQUARE:
      return intl.formatMessage({id: 'CropModal.Square'});
    case AspectRatio.RECTANGLE:
      return intl.formatMessage({id: 'CropModal.Rectangle'});
    default:
      return undefined;
  }
}

export const CropDropdown: React.FC<{
  aspectRatio: AspectRatio;
  lockedAspectRatios: AspectRatio[];
  setAspectRatio: (aspectRatio: AspectRatio) => void;
}> = ({aspectRatio, lockedAspectRatios, setAspectRatio}) => {
  const intl = useIntl();

  const items = useMemo(
    () =>
      [AspectRatio.SQUARE, AspectRatio.RECTANGLE]
        .filter(value => !lockedAspectRatios.includes(value))
        .reduce((res, value) => {
          const key = Object.keys(AspectRatio).find(key => AspectRatio[key] === value);

          return key ? {...res, [key]: value} : res;
        }, {}),
    [lockedAspectRatios]
  );

  const selectedText = getAspectRatioText(aspectRatio as unknown as keyof AspectRatio, intl);

  return (
    <DropDown
      activeKey={aspectRatio}
      items={items}
      value={
        <span>
          <span className="text">{selectedText}</span>
          <Icon name="angle-down" />
        </span>
      }
      trigger={trigger}
      placement={placement}
      onChange={setAspectRatio}
      noCaret={true}
    />
  );
};
