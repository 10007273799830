import {type IntlShape, type WrappedComponentProps} from 'react-intl';
import {type CompleteCrop} from 'react-image-crop';

import {
  type UploadingPictureStatus,
  type UploadingPictureValidationError
} from 'common/ImageUpload/interface';
import {type ValidatedImageFile} from 'common/ImageUpload/functions';
import {type AxiosRequestError} from 'services/axios/interface';

export enum Phase {
  DEFAULT = 'default',
  CROP = 'crop',
  UPLOADING = 'uploading'
}

interface OwnProps {
  attachPoster: (id?: number, errorCallback?: (e: AxiosRequestError) => void) => Promise<boolean>;
  hide: () => void;
  title: string;
  show: boolean;
}

export interface Props extends OwnProps, WrappedComponentProps {}

export interface State {
  crop?: CompleteCrop;
  errorMessage?: string;
  file: File | null;
  freeze: boolean;
  imageDataUrl?: string;
  shouldRetry: boolean;
  status?: UploadingPictureStatus | UploadingPictureValidationError;
  phase: Phase;
  validatedFile?: ValidatedImageFile;
}

export interface BodyProps {
  handleFileSelected: (imageDataUrl: string) => void;
  imageDataUrl?: string;
  intl: IntlShape;
  minHeight: number;
  minWidth: number;
  phase: Phase;
  storeCrop: (crop: CompleteCrop) => void;
  storeFile: (file: File) => void;
}

export interface FooterProps {
  phase: Phase;
  renderErrorStatus: () => JSX.Element | null;
  renderUploader: () => JSX.Element | null;
}

export const initialState: State = {
  crop: undefined,
  errorMessage: undefined,
  file: null,
  freeze: false,
  imageDataUrl: undefined,
  phase: Phase.DEFAULT,
  shouldRetry: false,
  status: undefined,
  validatedFile: undefined
};
