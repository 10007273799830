import React from 'react';
import {connect} from 'react-redux';
import {type Action, type Dispatch} from 'redux';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';

import Icon from 'components/Icon';
import {type VocabularyWordJSON} from 'store/exercise/player/widgets/Vocabulary/interface';
import PronunciationSoundPlayer from 'components/XPlayer/widgets/Vocabulary/PronunciationSoundPlayer';
import Spinner from 'components/Spinner';
import {xVocabularyOpenPronunciationModal} from 'store/exercise/editor/widgets/XVocabulary/actions';
import {isOriginalNotEmpty} from 'store/exercise/editor/widgets/XVocabulary/utils';
import {isVocabularyWordJSON} from 'store/exercise/player/widgets/Vocabulary/utils';
import {type AppState} from 'store/interface';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {
  type XVocabularyProperties,
  type VocabularyWordProperties
} from 'store/exercise/editor/widgets/XVocabulary/interface';

interface OwnProps {
  word: VocabularyWordJSON;
  xwidgetId: string;
}

interface StateProps {
  loadingPronunciationsForThisWord?: boolean;
}

interface DispatchProps {
  customizePronunciation: () => void;
}

interface Props extends OwnProps, DispatchProps, StateProps {}

class XEditorVocabularyWordControls extends React.Component<Props> {
  public render() {
    return (
      <React.Fragment>
        {this.renderTooltip()}
        {this.renderCustomizePronunciationIcon()}
      </React.Fragment>
    );
  }

  private renderCustomizePronunciationIcon = () => {
    const {customizePronunciation, word, loadingPronunciationsForThisWord} = this.props;
    if (loadingPronunciationsForThisWord) {
      return (
        <span>
          <Spinner size={16} />
        </span>
      );
    }
    if (isOriginalNotEmpty(word)) {
      return <Icon name="gear" onClick={customizePronunciation} />;
    }
    return null;
  };

  private renderTooltip = () => {
    const {
      word: {phoneticSpelling, soundId}
    } = this.props;
    if (!phoneticSpelling && !soundId) {
      return null;
    }
    if (phoneticSpelling) {
      return (
        <Tooltip
          destroyTooltipOnHide={true}
          overlay={`[${phoneticSpelling}]`}
          trigger={['hover']}
          placement="top"
        >
          <span>{this.renderSoundPlayer()}</span>
        </Tooltip>
      );
    }
    return <span>{this.renderSoundPlayer()}</span>;
  };

  private renderSoundPlayer = () => {
    const {
      word: {soundId}
    } = this.props;
    if (soundId) {
      return (
        <PronunciationSoundPlayer
          soundId={soundId}
          renderContent={({playSound, status}) =>
            status === 'loading' ? (
              <Spinner size={16} />
            ) : (
              this.renderPronunciationIcon(playSound, classNames({playing: status === 'playing'}))
            )
          }
        />
      );
    }
    return this.renderPronunciationIcon();
  };

  private renderPronunciationIcon = (onClick?: () => void, className?: string) => {
    const {
      word: {soundId, phoneticSpelling}
    } = this.props;
    if (soundId) {
      return <Icon name="volume-up" onClick={onClick} className={className} />;
    }
    if (phoneticSpelling) {
      return <Icon name="commenting" />;
    }
    return null;
  };
}

const mapStateToProps = (state: AppState, {xwidgetId, word: {wordId}}: OwnProps): StateProps => {
  const xwidget = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === xwidgetId
  ) as XVocabularyProperties;
  const wordRecord = xwidget.vocabulary.find(
    entry => !!entry && isVocabularyWordJSON(entry) && entry.wordId === wordId
  ) as VocabularyWordProperties;
  return {
    loadingPronunciationsForThisWord: wordRecord.loadingPronunciations
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  {word: {original, wordId}, xwidgetId}: OwnProps
) => ({
  customizePronunciation: () => {
    dispatch(xVocabularyOpenPronunciationModal(xwidgetId, wordId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(XEditorVocabularyWordControls);
