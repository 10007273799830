import {type List} from 'immutable';

import {type ExerciseMediaSource, type ExerciseMediaSources} from 'store/exercise/player/interface';

import {type KeyValueItem} from '../KeyValueBlock/types';

export function preparedKeyValueData(mediaResources: ExerciseMediaSources): List<KeyValueItem> {
  return mediaResources
    .map((media: ExerciseMediaSource) => ({
      id: media.get('id'),
      key: media.get('title'),
      value: media.get('url')
    }))
    .toList();
}
