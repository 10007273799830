import React, {Component, type FC, type MouseEvent, type SyntheticEvent} from 'react';
import classNames from 'classnames';
import Checkbox, {type CheckboxProps} from 'react-bootstrap/lib/Checkbox';

import {type ExerciseCategory} from 'store/interface';
import {dropdownDropupCheckCreator} from 'helpers/dropdownDropupCheck';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';

import Dropdown from './Dropdown';
import Editor from './Editor';

interface Props {
  active: boolean;
  category: ExerciseCategory;
  categories: ExerciseCategory[];
  deleteCategory: (id: number) => void;
  hasChildren: boolean;
  openCategory: (id: number, parentId: number | null) => void;
  parentRef: () => HTMLDivElement;
  processingRequest: boolean;
  renameCategory: (title: string, id: number) => void;
  toggleCategory: (id: number) => void;
  selected: boolean;
}

interface State {
  editorOpened: boolean;
  shouldDropup: boolean;
}

class CategoryComponent extends Component<Props, State> {
  private static modalHeight = 106;
  public state: State = {editorOpened: false, shouldDropup: false};

  private checkbox: HTMLInputElement;
  private checkboxView: HTMLSpanElement;
  private dropDownDropupCheck = dropdownDropupCheckCreator(
    shouldDropup => this.setState({shouldDropup}),
    CategoryComponent.modalHeight,
    this.props.parentRef
  );

  private get classes() {
    return classNames('category', {active: this.props.active});
  }

  public render() {
    const {
      CategoryControls,
      CategoryEditor,
      props: {
        category: {id}
      }
    } = this;
    return (
      <div onClick={this.openCategory} className={this.classes} id={`categories-modal-item-${id}`}>
        <CategoryControls />
        <CategoryEditor />
      </div>
    );
  }

  private checkboxRef = (el: HTMLInputElement) => el && (this.checkbox = el);
  private checkboxViewRef = (el: HTMLSpanElement) => el && (this.checkboxView = el);

  private openCategory = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === this.checkboxView || e.target === this.checkbox) {
      return;
    }
    const {
      category: {id, parentId},
      openCategory
    } = this.props;
    openCategory(id, parentId);
  };

  private closeEditor = () => this.setState({editorOpened: false});

  private openEditor = () => this.setState({editorOpened: true});

  private toggleCategory = (e: SyntheticEvent<CheckboxProps>) => {
    e.stopPropagation();
    this.props.toggleCategory(this.props.category.id);
  };

  private CategoryControls: FC = () => {
    if (this.state.editorOpened) {
      return null;
    }
    const {
      category: {id, title},
      deleteCategory,
      hasChildren,
      processingRequest,
      selected
    } = this.props;
    return (
      <>
        {processingRequest ? (
          <span className="spinner-wrapper">
            <Spinner size={15} />
          </span>
        ) : (
          <Checkbox
            onChange={this.toggleCategory}
            inline={true}
            checked={selected}
            inputRef={this.checkboxRef}
          >
            <span ref={this.checkboxViewRef} />
          </Checkbox>
        )}
        <div className="title" title={title}>
          <span>{title}</span>
        </div>
        <Dropdown
          delete={deleteCategory}
          edit={this.openEditor}
          id={id}
          shouldDropup={this.state.shouldDropup}
          dropdownDropupCheck={this.dropDownDropupCheck}
        />
        <Icon name="angle-right" className={hasChildren ? 'visible' : 'invisible'} />
      </>
    );
  };

  private CategoryEditor: FC = () => {
    if (!this.state.editorOpened) {
      return null;
    }
    const {
      categories,
      category: {id, title},
      processingRequest,
      renameCategory
    } = this.props;
    return (
      <Editor
        id={id}
        categories={categories}
        close={this.closeEditor}
        initialValue={title}
        processingRequest={processingRequest}
        renameCategory={renameCategory}
        parentId={null}
      />
    );
  };
}

export default CategoryComponent;
