import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {type VocabularyJSON} from 'store/exercise/player/widgets/Vocabulary/interface';
import {valueJSONFromText} from 'components/Slate/utils/value';
import genKey from 'components/Slate/utils/genKey';
import XVocabularyRecord from 'store/exercise/editor/widgets/XVocabulary/XVocabularyRecord';

import {type XWizardWidgetData} from '../interface';
import Tile from '../Tile';
import {VocabularySVG} from '../assets';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.Vocabulary}>
    <VocabularySVG />
  </Tile>
);

export const vocabularyWizardData: XWizardWidgetData<VocabularyJSON, XVocabularyRecord> = {
  type: WidgetType.VOCABULARY,
  steps: [],
  tile,
  createWidget: (widget: Pick<VocabularyJSON, 'type'>) =>
    new XVocabularyRecord({
      id: genKey(),
      type: widget.type,
      task: valueJSONFromText(''),
      vocabulary: [{name: ''}, {wordId: genKey(), original: '', translation: ''}]
    })
};
