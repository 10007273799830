import {defineMessages} from 'react-intl';

export const formattedTextMessages = defineMessages({
  placeholder: {
    id: 'XEditor.XWidget.XFormattedText.Placeholder'
  },
  placeholderOptional: {
    id: 'XEditor.XWidget.XFormattedText.PlaceholderOptional'
  },
  titlePlaceholder: {
    id: 'XEditor.XWidget.XGrammarRules.GrammarTitle.Placeholder'
  }
});
