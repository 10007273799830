import React, {type FC, useCallback} from 'react';
import {type Editor, type Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';
import {defineMessages, useIntl} from 'react-intl';
import classNames from 'classnames';

import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import TextAlignment from 'components/Slate/SlateEditor/plugins/button/TextAlignment';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import Link from 'components/Slate/SlateEditor/plugins/button/Link';
import Image from 'components/Slate/SlateEditor/plugins/button/Image';
import ImageButton from 'components/Slate/SlateEditor/plugins/button/Image/ImageButton';
import {NestedLists} from 'components/Slate/SlateEditor/plugins/button/List/NestedLists';
import AddDialogBtn from 'components/Slate/SlateEditor/plugins/button/Table/Dialog/AddDialogBtn';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import FontSizeToolbar from 'components/Slate/SlateEditor/plugins/button/FontSize';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';

const Messages = defineMessages({
  Header: {
    id: 'XEditor.XWidget.XQuiz.MostAnswersAre'
  },
  Title: {
    id: 'XEditor.XWidget.XQuiz.Title'
  },
  Text: {
    id: 'XEditor.XWidget.XQuiz.Text'
  }
});

const TitlePlugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new HighlightToolbar(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting()
];

const TextPlugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new TextAlignment(),
  new FontSizeToolbar(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new NestedLists(),
  new AddDialogBtn(),
  new CharSelector(),
  new Image(),
  new ImageButton(),
  new Link(),
  new FormatPainter(),
  new ClearFormatting()
];

interface Props {
  id: string;
  title: Value;
  text: Value;
  withError: boolean;
  onChangeTitle: (blockId: string, editor: Editor) => void;
  onChangeText: (blockId: string, editor: Editor) => void;
}

export const XQuizBlock: FC<Props> = React.memo(
  ({id, title, text, withError, onChangeTitle, onChangeText}) => {
    const {formatMessage} = useIntl();

    const onChangeTitleHandler = useCallback(
      (editor: Editor) => onChangeTitle(id, editor),
      [id, onChangeTitle]
    );

    const onChangeTextHandler = useCallback(
      (editor: Editor) => onChangeText(id, editor),
      [id, onChangeText]
    );

    return (
      <div className={classNames('quiz-block', {'with-error': withError})}>
        <div className="quiz-block__header">{formatMessage(Messages.Header)}</div>

        <SlateEditor
          className="title"
          placeholder={formatMessage(Messages.Title)}
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          value={title}
          onChange={onChangeTitleHandler}
          plugins={TitlePlugins}
        />

        <div className="quiz-block__divider" />

        <SlateEditor
          placeholder={formatMessage(Messages.Text)}
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          value={text}
          onChange={onChangeTextHandler}
          plugins={TextPlugins}
        />
      </div>
    );
  }
);
