import React, {useCallback} from 'react';
import {type Editor, type Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';

import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import TextAlignment from 'components/Slate/SlateEditor/plugins/button/TextAlignment';
import FontSizeToolbar from 'components/Slate/SlateEditor/plugins/button/FontSize';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import {NestedLists} from 'components/Slate/SlateEditor/plugins/button/List/NestedLists';
import AddDialogBtn from 'components/Slate/SlateEditor/plugins/button/Table/Dialog/AddDialogBtn';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import Image from 'components/Slate/SlateEditor/plugins/button/Image';
import ImageButton from 'components/Slate/SlateEditor/plugins/button/Image/ImageButton';
import Link from 'components/Slate/SlateEditor/plugins/button/Link';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import {ToggleExample} from 'components/Slate/SlateEditor/plugins/button/Example/ToggleExample';
import ImageCropUploader from 'common/ImageUpload/ImageCropUploader';

import {messages} from '../messages';
import CropModal from '../../components/CropModal';
import {ImageInput} from '../../components/ImageInput';
import {useCropModal} from '../../hooks/useCropModal';

const IMAGE_MIN_DIMENSION = 235;

const TextPlugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new TextAlignment(),
  new FontSizeToolbar(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new NestedLists(),
  new AddDialogBtn(),
  new CharSelector(),
  new Image(),
  new ImageButton(),
  new Link(),
  new FormatPainter(),
  new ClearFormatting(),
  new ToggleExample()
];

interface Props {
  id: string;
  widgetId: string;
  index: number;
  image?: number;
  text: Value;
  withError: boolean;
  onChangeText: (change: Editor) => void;
  onChangeImage: (image: number) => void;
  renderFooter: () => React.ReactElement;
  setError: (message: string, meta: Record<string, unknown>) => void;
}

export function XEditorImageCard({
  id,
  widgetId,
  text,
  image,
  withError,
  onChangeText,
  onChangeImage,
  renderFooter,
  setError
}: Props) {
  const {formatMessage} = useIntl();

  const onError = useCallback(errorMessage => setError(errorMessage, {cardId: id}), [id, setError]);

  const {
    imageDataUrl,
    errorMessage,
    cropResult,
    freezeModal,
    isErrorStatus,
    retry,
    status,
    uploadingStarted,
    validatedFile,
    hideModal,
    storeCrop,
    storeFile,
    startUploading,
    handleFileSelected,
    handleError,
    handleResponse,
    turnOffRetry,
    setStatus
  } = useCropModal({onSuccess: onChangeImage, onError});

  return (
    <div className={classNames('card-container', {'with-error': withError})}>
      <div className="card-content">
        <div className="card-content__image">
          <div className={classNames('img-block', {'with-image': !!image})}>
            {imageDataUrl ? (
              <CropModal
                aspect={1}
                minWidth={IMAGE_MIN_DIMENSION}
                hide={hideModal}
                imageDataUrl={imageDataUrl}
                storeCrop={storeCrop}
                renderFooter={() => (
                  <div className="image-upload-controls">
                    <Button bsSize="small" onClick={hideModal}>
                      <FormattedMessage id="Slate.Modal.Image.CancelButton" />
                    </Button>
                    {uploadingStarted && (
                      <ImageCropUploader
                        errorMessage={errorMessage}
                        file={validatedFile!}
                        handleError={handleError}
                        handleResponse={handleResponse}
                        isErrorStatus={isErrorStatus}
                        crop={cropResult!}
                        setStatus={setStatus}
                        shouldRetry={retry}
                        turnOffRetry={turnOffRetry}
                        status={status}
                        thumbnail={[IMAGE_MIN_DIMENSION]}
                      />
                    )}
                    <Button
                      bsSize="small"
                      bsStyle="primary"
                      onClick={startUploading}
                      disabled={freezeModal}
                    >
                      <FormattedMessage id="Common.Upload" />
                    </Button>
                  </div>
                )}
              />
            ) : (
              <ImageInput
                id={id}
                handleFileSelected={handleFileSelected}
                imageId={image}
                storeFile={storeFile}
                widgetId={widgetId}
              />
            )}
          </div>
        </div>

        <div className="card-content__text">
          <SlateEditor
            toolbar={{portalId: 'xeditor-toolbar-portal'}}
            value={text}
            onChange={onChangeText}
            plugins={TextPlugins}
            placeholder={formatMessage(messages.CardText)}
          />
        </div>
      </div>

      {renderFooter()}
    </div>
  );
}
