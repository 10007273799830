import React, {type FC, memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';

import {
  setImageData,
  deleteCard,
  chooseAnswer,
  pictureChoiceChangeCardSizeType
} from 'store/exercise/editor/widgets/XPictureChoice/actions';
import {CornerCheckbox} from 'components/XPlayer/components/CornerCheckbox/CornerCheckbox';
import {PictureChoiceMode} from 'store/exercise/editor/widgets/XPictureChoice/interface';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';
import {type DndSortingWrapperProps} from 'components/DndSortingWrapper/DndSortingWrapper';

import ImageCard from '../../components/ImageCard/ImageCard';

import './Card.scss';

export interface XPictureChoiceCardProps extends DndSortingWrapperProps {
  widgetId: string;
  questionId: string;
  id: string;
  imageId: number;
  questionIndex: number;
  cardIndex: number;
  checked: boolean;
  withError: boolean;
  mode: PictureChoiceMode;
  cardSizeType: CardSizeType;
  resetErrors: () => void;
  setError: (e: string | JSX.Element) => string | JSX.Element;
  updateRecoveryPoint: () => void;
}

export const Card: FC<XPictureChoiceCardProps> = memo(
  ({
    widgetId,
    questionId,
    id,
    imageId,
    questionIndex,
    cardIndex,
    checked,
    withError,
    mode,
    cardSizeType,
    handle,
    isDragLayer,
    isDragging,
    style,
    resetErrors,
    setError,
    updateRecoveryPoint
  }) => {
    const dispatch = useDispatch();

    const setImage = useCallback(
      (imageId: number, url: string) => {
        dispatch(setImageData(widgetId, questionIndex, cardIndex, imageId, url));
      },
      [cardIndex, dispatch, questionIndex, widgetId]
    );

    const onDeleteCard = useCallback(() => {
      dispatch(deleteCard(widgetId, questionId, id));
      updateRecoveryPoint();
    }, [dispatch, id, questionId, updateRecoveryPoint, widgetId]);

    const onClickCheckbox = useCallback(() => {
      dispatch(chooseAnswer(widgetId, questionId, id));
    }, [dispatch, id, questionId, widgetId]);

    const changeCardSizeTypeAction = useCallback(
      (widgetId, cardId, cardSizeType: CardSizeType) => {
        return pictureChoiceChangeCardSizeType(widgetId, questionIndex, cardIndex, cardSizeType);
      },
      [cardIndex, questionIndex]
    );

    const imageCard = (
      <ImageCard
        id={id}
        xwidgetId={widgetId}
        imageId={imageId}
        reportError={setError}
        resetErrors={resetErrors}
        deletePair={onDeleteCard}
        setImage={setImage}
        changeCardSizeTypeAction={changeCardSizeTypeAction}
        handle={handle}
        isDragLayer={isDragLayer}
        isCenterDeleteButton={mode !== PictureChoiceMode.FREE_CHOICE}
      />
    );

    return mode === PictureChoiceMode.MULTIPLE_CHOICE ? (
      <div
        className={classNames('picture-choice-card', {
          'with-error': withError,
          checked: checked,
          'is-rectangle': cardSizeType === CardSizeType.RECTANGLE,
          'is-dragging': isDragging && !isDragLayer,
          'is-drg-layer': isDragLayer
        })}
        style={style}
      >
        <div className={'wrapper'}>
          {imageId ? (
            <CornerCheckbox checked={checked} borderCorrect={true} onChange={onClickCheckbox} />
          ) : null}
          {imageCard}
        </div>
      </div>
    ) : (
      <div
        className={classNames('picture-choice-card', {
          'with-error': withError,
          'is-rectangle': cardSizeType === CardSizeType.RECTANGLE,
          'is-dragging': isDragging && !isDragLayer,
          'is-drg-layer': isDragLayer
        })}
        style={style}
      >
        {imageCard}
      </div>
    );
  }
);
