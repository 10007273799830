import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import genKey from 'components/Slate/utils/genKey';
import {valueJSONFromText} from 'components/Slate/utils';
import {XPictureChoiceRecord} from 'store/exercise/editor/widgets/XPictureChoice/XPictureChoiceRecord';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type PictureChoiceJSON} from 'store/exercise/player/widgets/PictureChoice/interface';
import {type XPictureChoiceWizardProps} from 'store/exercise/editor/widgets/XPictureChoice/interface';

import {type XWizardWidgetData} from '../interface';
import {PictureChoiceSVG} from '../assets';
import PictureChoiceWizard from './PictureChoiceWizard';
import Tile from '../Tile';
const tile: React.FC = () => (
  <Tile title={WidgetTitle.PictureChoice}>
    <PictureChoiceSVG />
  </Tile>
);

export const pictureChoiceWizardData: XWizardWidgetData<PictureChoiceJSON, XWidgetProperties> = {
  type: WidgetType.PICTURE_CHOICE,
  steps: [
    {
      canGoToNextStep: () => true,
      component: PictureChoiceWizard
    }
  ],
  tile,
  createWidget: (widget: XPictureChoiceWizardProps) => {
    return new XPictureChoiceRecord({
      id: genKey(),
      type: widget.type,
      task: valueJSONFromText(),
      mode: widget.mode,
      questionsMode: widget.questionsMode,
      answerType: widget.answerType,
      questions: XPictureChoiceRecord.createQuestions(
        widget.initialQuestionsNumber,
        widget.defaultAnswersNumber,
        widget.questionsMode
      ),
      options: {
        defaultAnswersNumber: widget.defaultAnswersNumber
      },
      answers: {}
    });
  }
};
