import React, {type FC} from 'react';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import Radio from 'react-bootstrap/lib/Radio';
import FormGroup from 'react-bootstrap/lib/FormGroup';

import {type XImageMatchingOptions} from 'store/exercise/player/widgets/ImageMatching/interface';
import {GapFillType} from 'components/Slate/interface';
import GapChecked from 'components/XPlayer/widgets/GapFill/component/GapChecked';
import DragHandle from 'components/DragHandle/DragHandle';

import {type XWizardStepProps} from '../interface';

import '../GapFill/GapTypeSelector.scss';

type Props = XWizardStepProps<XImageMatchingOptions> & WrappedComponentProps;

const ImageMatchingWizard: FC<Props> = ({fields, addField}) => {
  const radioGroupName = 'gap';

  return (
    <form className="gap-type-selector">
      <p>
        <b>
          <FormattedMessage id="XEditor.Wizard.ImageMatching.Header" />
        </b>
      </p>
      <FormattedMessage id="XEditor.Wizard.ImageMatching.Text" />
      <FormGroup>
        <div className="group">
          <Radio
            name={radioGroupName}
            inline={true}
            onChange={() => addField('gap', GapFillType.INPUT)}
            checked={fields.gap === GapFillType.INPUT}
          >
            <span>
              <FormattedMessage id="XEditor.Wizard.Gap.Option1" />
            </span>
          </Radio>
          <GapChecked gap={GapFillType.INPUT} value="Examp..." />
        </div>

        <div className="group">
          <Radio
            name={radioGroupName}
            inline={true}
            onChange={() => addField('gap', GapFillType.DND)}
            checked={fields.gap === GapFillType.DND}
          >
            <span>
              <FormattedMessage id="XEditor.Wizard.Gap.Option3" />
            </span>
          </Radio>
          <GapChecked gap={GapFillType.DND} value="Example">
            <DragHandle />
          </GapChecked>
        </div>
      </FormGroup>
    </form>
  );
};

export default injectIntl(ImageMatchingWizard);
