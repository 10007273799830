import React, {createRef} from 'react';
import {type Value} from '@englex/slate';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {type Action} from 'redux';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import {type Dispatch} from 'redux-axios-middleware';
import {type Plugin} from '@englex/slate-react';
import Tooltip from 'rc-tooltip';

import {type AppState} from 'store/interface';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import {
  loadPronunciationsForAll,
  xVocabularyAddCategory,
  xVocabularyAddWord,
  xVocabularySetListTitle,
  xVocabularySetQuizletUrl
} from 'store/exercise/editor/widgets/XVocabulary/actions';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {
  type VocabularyCategoryProperties,
  type VocabularyWordErrorMeta,
  type VocabularyWordProperties,
  type XVocabularyProperties
} from 'store/exercise/editor/widgets/XVocabulary/interface';
import {type XWidgetError} from 'store/exercise/editor/interface';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import {isVocabularyWordJSON} from 'store/exercise/player/widgets/Vocabulary/utils';

import XEditorPronunciationModal from './XEditorPronunciationModal';
import {XEditorVocabularyEntry} from './XEditorVocabularyEntry';
import {XDisplayAsButton} from '../components/XDisplayAsButton/XDisplayAsButton';
import {vocabularyMessages} from './vocabularyMessages';
import './XEditorVocabulary.scss';

interface StateProps {
  vocabulary: Array<VocabularyWordProperties | VocabularyCategoryProperties>;
  quizletURL: string;
  listTitle?: string;
  isLoadingPronunciationsForSomeWords: boolean;
  errors?: XWidgetError;
}

interface DispatchProps {
  addWord: () => void;
  addCategory: () => void;
  loadPronunciationsForAll: () => void;
  changeQuizletURL: (value: string) => void;
  changeListTitle: (value: string) => void;
}

interface OwnProps extends WrappedComponentProps {
  id: string;
}

type Props = OwnProps & StateProps & DispatchProps;

class XEditorVocabulary extends React.Component<Props> {
  public bottomPanelRef = createRef<HTMLDivElement>();
  public static buttonTitlePlugins: Plugin[] = [
    new Undo(),
    new Redo(),
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new FormatPainter(),
    new ClearFormatting()
  ];

  public render() {
    const {vocabulary, id} = this.props;
    return (
      <div className="content">
        {this.renderListTitleInput()}
        {vocabulary.map(this.renderEntry) || null}
        <XDisplayAsButton xwidgetId={id} />
        {this.renderBottomPanel()}
        <XEditorPronunciationModal xwidgetId={id} />
      </div>
    );
  }

  private renderListTitleInput = () => {
    const {intl, listTitle} = this.props;
    return (
      <div className="xvocabulary-entry">
        <input
          value={listTitle || ''}
          placeholder={intl.formatMessage(vocabularyMessages.ListTitlePlaceholder)}
          onChange={this.onListTitleInputChange}
        />
      </div>
    );
  };

  private onListTitleInputChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.props.changeListTitle(e.currentTarget.value);

  private renderEntry = (
    entry: VocabularyWordProperties | VocabularyCategoryProperties,
    index: number
  ) => {
    const {id, errors} = this.props;
    const key = isVocabularyWordJSON(entry) ? entry.wordId : entry.categoryId;

    const withError = errors ? Boolean((errors.meta as VocabularyWordErrorMeta)?.[key]) : false;

    return (
      <XEditorVocabularyEntry
        key={key}
        wordNumber={this.getWordNumber(index)}
        entry={entry}
        widgetId={id}
        positionInVocabulary={index}
        withError={withError}
      />
    );
  };

  private renderBottomPanel = () => {
    const {isLoadingPronunciationsForSomeWords} = this.props;
    return (
      <div className="xvocabulary-bottom-panel" ref={this.bottomPanelRef}>
        <span className="control" onClick={this.props.addWord}>
          <Icon name="plus-circle" />
          <FormattedMessage id="XEditorXWidget.Vocabulary.AddWord" />
        </span>
        <span className="control" onClick={this.props.addCategory}>
          <Icon name="folder-open" />
          <FormattedMessage id="XEditorXWidget.Vocabulary.AddCategory" />
        </span>
        <Tooltip
          getTooltipContainer={() => this.bottomPanelRef.current!}
          destroyTooltipOnHide={{keepParent: false}}
          overlayClassName="download-pronunciations-temporarily-unavailable"
          overlay={
            <FormattedMessage id="XEditorXWidget.Vocabulary.DownloadPronunciations.TemporarilyUnavailable" />
          }
          placement="top"
        >
          <span className="control float-right unavailable">
            {isLoadingPronunciationsForSomeWords ? (
              <Spinner size={15} />
            ) : (
              <Icon name="volume-up" />
            )}
            <FormattedMessage id="XEditorXWidget.Vocabulary.DownloadPronunciations" />
          </span>
        </Tooltip>
      </div>
    );
  };

  private loadPronunciationsForAllClick = () => {
    if (!this.props.isLoadingPronunciationsForSomeWords) {
      this.props.loadPronunciationsForAll();
    }
  };

  private getWordNumber = (index: number) => {
    const partialArr = this.props.vocabulary.slice(0, index);
    return partialArr.filter(isVocabularyWordJSON).length;
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps
) => {
  const xwidget = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === ownProps.id
  ) as XVocabularyProperties;

  const errors = state.xeditor!.errors?.getIn(['widgets', ownProps.id]);

  const {vocabulary} = xwidget;

  return {
    errors,
    vocabulary: vocabulary.toArray(),
    isLoadingPronunciationsForSomeWords: !!vocabulary.find(
      entry => !!entry && isVocabularyWordJSON(entry) && !!entry.loadingPronunciations
    ),
    quizletURL: xwidget.quizletURL,
    listTitle: xwidget.listTitle
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {task: Value; id: string}> = (
  dispatch: Dispatch<Action, AppState>,
  {id}
) => {
  return {
    addWord: () => dispatch(xVocabularyAddWord(id)),
    addCategory: () => dispatch(xVocabularyAddCategory(id)),
    loadPronunciationsForAll: () => dispatch(loadPronunciationsForAll(id)),
    changeQuizletURL: (value: string) => dispatch(xVocabularySetQuizletUrl(id, value)),
    changeListTitle: (value: string) => dispatch(xVocabularySetListTitle(id, value))
  };
};

export default injectIntl(
  connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(XEditorVocabulary)
);
