import React, {memo, useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import {type List} from 'immutable';

import Icon from 'components/Icon';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type XPictureSetProperties} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';
import {type XWordPictureSetProperties} from 'store/exercise/editor/widgets/XWordPictureSet/XWordPictureSet/interface';
import {type XWordSetProperties} from 'store/exercise/editor/widgets/XWordPictureSet/XWordSet/interface';
import {
  addCard,
  changeDefaultVisibility,
  changeWidgetTheme,
  switchIsNotInteractive
} from 'store/exercise/editor/widgets/XWordPictureSet/actions';
import {type AppState} from 'store/interface';
import {resetWidgetErrors} from 'store/exercise/editor/actions/xeditor';
import {WidgetType} from 'store/exercise/player/interface';
import {useRecoveryPoint} from 'components/DndSortingWrapper/hooks/useRecoveryPoint';
import {type WordPictureSetBaseCardId} from 'store/exercise/player/widgets/WordPictureSet/baseInterface';

import {Cards} from './components/Cards/Cards';
import {messages} from './messages';
import {XDisplayAsButton} from '../components/XDisplayAsButton/XDisplayAsButton';
import {ThemeModal} from './components/ThemeModal/ThemeModal';

import './XWordPictureSet.scss';

interface Props {
  id: string;
  setError: (e: string | React.ReactElement) => string | React.ReactElement;
}

export const XWordPictureSet: React.FC<Props> = memo(({id, setError}) => {
  const {formatMessage} = useIntl();
  const dispatch = useDispatch();

  const {type, cards, defaultValues, isDefaultHidden, isNotInteractive, widgetTheme} = useSelector(
    (state: AppState) =>
      state.xeditor?.xexercise.widgets.find((widget: XWidgetProperties) => widget.id === id)
  ) as XWordSetProperties | XWordPictureSetProperties | XPictureSetProperties;

  const cardIds = defaultValues.get('cardIds') as List<WordPictureSetBaseCardId>;
  const {recoveryPoint, updateRecoveryPoint} = useRecoveryPoint<WordPictureSetBaseCardId>({
    list: cardIds
  });

  const [styleModalShowed, setStyleModalSowed] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(widgetTheme);

  const isWordSet = type === WidgetType.PICTURE_SET;
  const displayBtnTip = !isNotInteractive
    ? formatMessage(messages.AvailableForNotInteractive)
    : undefined;

  const resetErrors = useCallback(() => dispatch(resetWidgetErrors(id)), [dispatch, id]);

  const onSwitchIsDefaultHidden = useCallback(() => {
    dispatch(changeDefaultVisibility(id));
  }, [id, dispatch]);

  const onSwitchIsNotInteractive = useCallback(() => {
    dispatch(switchIsNotInteractive(id));
  }, [id, dispatch]);

  const onAddCard = () => {
    resetErrors();
    dispatch(addCard(id));
    updateRecoveryPoint();
  };

  const openStyleModal = () => {
    setStyleModalSowed(true);
    setCurrentTheme(widgetTheme);
  };

  const onSelect = () => {
    setStyleModalSowed(false);
  };

  const closeStyleModal = () => {
    dispatch(changeWidgetTheme(id, currentTheme));
    setStyleModalSowed(false);
  };

  return (
    <div className="x-word-picture-set">
      <ThemeModal
        opened={styleModalShowed}
        widgetId={id}
        styleName={widgetTheme}
        onClose={closeStyleModal}
        onSelect={onSelect}
      />

      <Cards
        cards={cards}
        type={type}
        defaultValues={defaultValues}
        widgetId={id}
        recoveryPoint={recoveryPoint}
        resetErrors={resetErrors}
        setError={setError}
        updateRecoveryPoint={updateRecoveryPoint}
      />

      <div className="control-panel">
        <div className="first-line">
          <Checkbox
            onChange={onSwitchIsDefaultHidden}
            checked={isDefaultHidden}
            disabled={isNotInteractive}
          >
            <span />
            {formatMessage(messages.ChangeDefaultVisible)}
          </Checkbox>
          <button className="add-btn" onClick={onAddCard}>
            <Icon name="plus-circle" />
            {formatMessage(isWordSet ? messages.AddPicture : messages.AddWord)}
          </button>
        </div>

        <div className="second-line">
          <Checkbox
            onChange={onSwitchIsNotInteractive}
            checked={isNotInteractive}
            disabled={isDefaultHidden}
          >
            <span />
            {formatMessage(messages.NotInteractive)}
          </Checkbox>

          <button className="control" onClick={openStyleModal}>
            <Icon name="cog" />
            {formatMessage({id: 'XEditorXWidget.Dialogue.ChangeTheme'})}
          </button>
        </div>

        <XDisplayAsButton disabled={!isNotInteractive} tip={displayBtnTip} xwidgetId={id} />
      </div>
    </div>
  );
});
