import {genKey} from '../../../utils';
import {SlateDecoration} from '../../../interface';
import {DecorateWord} from '../../../plugins/DecorateText';
import {type Options, TextDecorator} from './TextDecorator';

export class WordDecorator extends TextDecorator {
  public plugins = [DecorateWord()];
  constructor({keyGen = genKey}: Options = {}) {
    super({
      decoration: SlateDecoration.WORD,
      wrapperClassName: 'word',
      keyGen
    });
  }
}
