import {buttonTitle} from '../../button/i18n';
import {type ButtonTitle, ButtonType, type QuestionProps} from '../../interface';
import ToolbarButton from '../../ToolbarButton';
import {addQuestion} from './changes';

class AddQuestion extends ToolbarButton {
  public type: ButtonType;
  public title: ButtonTitle;
  public label: ButtonTitle;
  public shortcut = 'mod+option+i';

  public icon = 'plus-circle';

  static getInitProps(props?: QuestionProps) {
    return {
      type: ButtonType.ADD_MC_QUESTION,
      title: buttonTitle.AddMCQuestion,
      label: buttonTitle.AddMCQuestion,
      ...props
    };
  }

  constructor(props?: QuestionProps) {
    super();

    const {type, title, label} = AddQuestion.getInitProps(props);

    this.type = type;
    this.title = title;
    this.label = label;
  }

  public toggleChange = addQuestion;

  protected getClassNames = (): string => {
    return 'success';
  };
}

export default AddQuestion;
