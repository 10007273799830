import {type Editor, type Text} from '@englex/slate';

import {
  type GapFillInlineData,
  GapFillType,
  type EditGapFillOptions,
  SlateInline,
  type GapFillData,
  type GapFillInline
} from 'components/Slate/interface';
import genKey from 'components/Slate/utils/genKey';

import {isInlineOfType} from '../../../../utils';
import {insertInline} from '../../changes';

const shuffleChoices = (initial: string[]): string[] => {
  const shuffled: string[] = [];
  const haystack = [...initial];
  for (let i = 0; i < initial.length - 1; i++) {
    const needle = haystack.splice(Math.floor(Math.random() * haystack.length), 1)[0];
    shuffled.push(needle);
  }
  shuffled.push(haystack[0]);
  return shuffled;
};

export const createInlineGap = (
  editor: Editor,
  type: GapFillType,
  answer: string[],
  options: EditGapFillOptions = {}
) => {
  const {
    example,
    caseSensitive,
    choices,
    startOfSentence,
    indefiniteForm,
    editable,
    idGen = genKey,
    choiceIdGen = genKey
  } = options;
  const data: GapFillInlineData = {
    id: idGen(),
    type: type,
    answer
  };

  if (example) data.example = example;
  if (caseSensitive) data.caseSensitive = caseSensitive;
  if ([GapFillType.DND, GapFillType.DND_INPUT].includes(type)) data.choiceId = choiceIdGen();
  if (choices) data.choices = choices;
  if (startOfSentence) data.startOfSentence = startOfSentence;
  if (indefiniteForm) data.indefiniteForm = indefiniteForm;
  if (editable) data.editable = editable;

  if (editor.value.startBlock && editor.value.startBlock !== editor.value.endBlock) {
    return;
  }

  editor
    .command(insertInline, {
      type: SlateInline.GAP,
      data
    })
    .moveToStartOfNextText()
    .focus();
};

const resolveInlineGap = (editor: Editor): GapFillInline | undefined => {
  const textInsideGapInline: boolean | Text =
    !!editor.value.anchorText &&
    !!editor.value.focusText &&
    editor.value.anchorText === editor.value.focusText &&
    editor.value.anchorText;
  if (!textInsideGapInline) {
    return;
  }
  const inline = editor.value.document.getParent(textInsideGapInline.key) as GapFillInline | null;
  if (!inline || !isInlineOfType(inline, SlateInline.GAP)) {
    return;
  }

  return inline;
};

export const updateInlineGap = (
  editor: Editor,
  answer: string[],
  options: EditGapFillOptions = {}
) => {
  const {
    choices,
    caseSensitive,
    example,
    startOfSentence,
    indefiniteForm,
    editable,
    choiceIdGen = genKey
  } = options;
  const inline = resolveInlineGap(editor);
  if (!inline) {
    return;
  }
  const type = inline.type as GapFillType;
  const sourceData: GapFillData = inline.data;
  const data = sourceData.withMutations(d => {
    d = d.set('answer', answer);
    d = choices
      ? d.set('choices', type === GapFillType.DROPDOWN ? shuffleChoices(choices) : choices)
      : d;
    d = caseSensitive ? d.set('caseSensitive', caseSensitive) : d.delete('caseSensitive');
    d = example ? d.set('example', example) : d.delete('example');
    d = startOfSentence ? d.set('startOfSentence', startOfSentence) : d.delete('startOfSentence');
    d = indefiniteForm ? d.set('indefiniteForm', indefiniteForm) : d.delete('indefiniteForm');
    d = editable ? d.set('editable', editable) : d.delete('editable');
    if ([GapFillType.DND, GapFillType.DND_INPUT].includes(sourceData.get('type'))) {
      d = d.get('choiceId') ? d : d.set('choiceId', choiceIdGen());
    }
  });
  editor.command((change: Editor) =>
    change
      .setInlines({
        type: SlateInline.GAP,
        data
      })
      .moveToStartOfNextText()
      .focus()
  );
};

export const deleteInlineGap = (editor: Editor) => {
  const inline = resolveInlineGap(editor);
  if (!inline) {
    return;
  }
  const data: GapFillData = inline.data;
  const type = data.get('type');
  const firstAnswerText = data.get('answer')[type === GapFillType.DND_INPUT ? 1 : 0];

  editor.command((change: Editor) =>
    change
      .deleteBackward(1) // just delete the only selected inline block
      .insertText(firstAnswerText) // add text from first answer
      .focus()
  );
};

export const selectInlineGapText = (editor: Editor, inline: GapFillInline) => {
  const inlineVoidTextNode = inline.nodes.first() as Text;
  editor.command((change: Editor) => change.moveToStartOfNode(inlineVoidTextNode).blur());
};
