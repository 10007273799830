import React from 'react';
import {type Editor, type Value} from '@englex/slate';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {type Action, type Dispatch} from 'redux';
import {type Plugin} from '@englex/slate-react';
import {FormattedMessage} from 'react-intl';
import Checkbox from 'react-bootstrap/lib/Checkbox';

import {
  type MultipleChoiceDefaultAnswers,
  MultipleChoiceElement
} from 'store/exercise/player/widgets/List/interface';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import AddQuestion from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/AddQuestion';
import RemoveQuestion from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/RemoveQuestion';
import MCAnswer from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/MultipleChoice/MCAnswer';
import QuestionsList from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/QuestionsList';
import Question from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/Question';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import genKey from 'components/Slate/utils/genKey';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {formattedTextContentChange} from 'store/exercise/editor/xwidgetActions';
import {type AppState} from 'store/interface';
import {deleteWidget} from 'store/exercise/editor/actions/xwidgets';
import {toggleMultipleChoiceAlignment} from 'store/exercise/editor/widgets/XMultipleChoice/actions';
import {type XMultipleChoiceProperties} from 'store/exercise/editor/widgets/XMultipleChoice/interface';
import {OddWordNormalizer} from 'components/Slate/plugins/renderers/QuestionsList/OddWord/OddWordNormalizer';

interface StateProps {
  content: Value;
  defaultAnswersNumber: number;
  defaultAnswersType: MultipleChoiceDefaultAnswers;
  element: MultipleChoiceElement;
  initialQuestionsNumber?: number;
  alignment?: boolean;
  withoutQuestions: boolean;
}

interface DispatchProps {
  toggleAlignment(widgetId: string): void;
  onContentChange(widgetId: string, change: Editor): void;
}

interface OwnProps {
  id: string;
}

type Props = OwnProps & StateProps & DispatchProps;

class XEditorFreeChoice extends React.Component<Props> {
  private readonly plugins: Plugin[];

  constructor(props: Props) {
    super(props);
    this.plugins = [
      new AddQuestion(),
      new RemoveQuestion(),
      new Undo(),
      new Redo(),
      new Bold(),
      new Italic(),
      new Underline(),
      new StrikeThrough(),
      new HighlightToolbar(),
      new CharSelector(),
      new MCAnswer({element: MultipleChoiceElement.CIRCLE, genKey}),
      new QuestionsList({defaultQuestionsNumber: props.initialQuestionsNumber}),
      new Question({
        defaultAnswersNumber: props.defaultAnswersNumber,
        defaultAnswersType: props.defaultAnswersType,
        genKey
      }),
      new FormatPainter(),
      new ClearFormatting()
    ];

    if (props.withoutQuestions) this.plugins.push(new OddWordNormalizer());
  }

  public render() {
    return (
      <>
        <SlateEditor
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          value={this.props.content}
          onChange={this.onContentChange}
          plugins={this.plugins}
        />

        <Checkbox name="alignment" onChange={this.toggleAlignment} checked={this.props.alignment}>
          <FormattedMessage id="XEditor.Wizard.MultipleChoice.DefaultAlignmentHorizontal" />
        </Checkbox>
      </>
    );
  }

  private onContentChange = (change: Editor) => {
    this.props.onContentChange(this.props.id, change);
  };

  private toggleAlignment = () => {
    this.props.toggleAlignment(this.props.id);
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps
): StateProps => {
  const xwidget = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === ownProps.id
  ) as XMultipleChoiceProperties;

  const {content, element, options} = xwidget;

  return {
    content,
    element,
    alignment: options.get('horizontal'),
    defaultAnswersNumber: options.get('defaultAnswersNumber'),
    defaultAnswersType: options.get('defaultAnswersType'),
    initialQuestionsNumber: options.get('initialQuestionsNumber'),
    withoutQuestions: !!options.get('withoutQuestions')
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch<Action>
) => {
  return {
    onContentChange: (widgetId: string, change: Editor) =>
      dispatch(formattedTextContentChange(widgetId, change)),
    deleteWidget: (widgetId: string) => dispatch(deleteWidget(widgetId)),
    toggleAlignment: (widgetId: string) => dispatch(toggleMultipleChoiceAlignment(widgetId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(XEditorFreeChoice);
