import {type List} from 'immutable';

import {
  ADD_CARD,
  DELETE_CARD,
  SET_CARD_IMAGE_DATA,
  IMAGE_MOVE_CARD,
  IMAGE_ROLL_BACK_CARS,
  SET_CARD_PHRASE,
  UPDATE_CARD,
  TOGGLE_PRE_FILL_VALUES,
  CHANGE_CARD_SIZE_TYPE
} from './actionTypes';
import {type XImageLabelingCardProperties} from './XImageLabeling/interface';
import {type XImageMatchingCardProperties} from './XImageMatching/interface';
import {type CardSizeType} from '../XWordPictureSet/XPictureSet/interface';
import {type XWidgetAction} from '../../actions/interface';

export interface XImageSetPhraseAction extends XWidgetAction {
  choiceId: string;
  value: string;
}

export interface XImageSetCardDataAction extends XWidgetAction {
  index: number;
  imageId: number;
  url: string;
}

export interface XImageCardDeleteAction extends XWidgetAction {
  index: number;
}

export interface XImageCardChangeType extends XWidgetAction {
  cardId: string;
  cardSizeType: CardSizeType;
}

interface Fields {
  answers: string[];
  editable?: boolean;
  example?: boolean;
  placeholder: string;
}

export interface XImageCardUpdateAction extends XWidgetAction {
  cardId: string;
  fields: Fields;
}

export interface XImageMoveCardAction extends XWidgetAction {
  moveCardIndex: number;
  targetIndex: number;
}

export interface XImageRollBackCardsAction extends XWidgetAction {
  cards: List<XImageLabelingCardProperties | XImageMatchingCardProperties>;
}

export const setChoiceValue = (
  xwidgetId: string,
  choiceId: string,
  value: string
): XImageSetPhraseAction => ({
  type: SET_CARD_PHRASE,
  xwidgetId,
  choiceId,
  value
});

export const setImageData = (
  xwidgetId: string,
  index: number,
  imageId: number,
  url: string
): XImageSetCardDataAction => ({
  type: SET_CARD_IMAGE_DATA,
  xwidgetId,
  index,
  imageId,
  url
});

export const deletePair = (xwidgetId: string, index: number): XImageCardDeleteAction => ({
  type: DELETE_CARD,
  xwidgetId,
  index
});

export const addPair = (xwidgetId: string): XWidgetAction => ({
  type: ADD_CARD,
  xwidgetId
});

export const updatePair = (
  xwidgetId: string,
  cardId: string,
  fields: Fields
): XImageCardUpdateAction => ({
  type: UPDATE_CARD,
  xwidgetId,
  cardId,
  fields
});

export const moveCard = (
  xwidgetId: string,
  moveCardIndex: number,
  targetIndex: number
): XImageMoveCardAction => ({
  type: IMAGE_MOVE_CARD,
  xwidgetId,
  moveCardIndex,
  targetIndex
});

export const rollBackCards = (
  xwidgetId: string,
  cards: List<XImageLabelingCardProperties | XImageMatchingCardProperties>
): XImageRollBackCardsAction => ({
  type: IMAGE_ROLL_BACK_CARS,
  xwidgetId,
  cards
});

export const togglePreFillValues = (xwidgetId: string): XWidgetAction => ({
  type: TOGGLE_PRE_FILL_VALUES,
  xwidgetId
});

export const imageMatchingChangeCardSizeType = (
  xwidgetId: string,
  cardId: string,
  cardSizeType: CardSizeType
): XImageCardChangeType => ({
  type: CHANGE_CARD_SIZE_TYPE,
  xwidgetId,
  cardId,
  cardSizeType
});
