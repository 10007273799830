import {type List} from 'immutable';
import {useCallback, useEffect, useRef, useState} from 'react';

interface Props<T> {
  list: List<T>;
}

export function useRecoveryPoint<T>({list}: Props<T>) {
  const recoveryPoint = useRef(list);
  const [isUpdatingRecoveryPoint, setIsUpdatingRecoveryPoint] = useState(false);

  const updatePoint = useCallback(() => {
    recoveryPoint.current = list;
    setIsUpdatingRecoveryPoint(false);
  }, [list]);

  const updateRecoveryPoint = useCallback(() => {
    setIsUpdatingRecoveryPoint(true);
  }, []);

  useEffect(() => {
    if (isUpdatingRecoveryPoint) {
      updatePoint();
    }
  }, [isUpdatingRecoveryPoint, updatePoint]);

  return {recoveryPoint: recoveryPoint, updateRecoveryPoint};
}
