import type React from 'react';
import {useCallback, useEffect, useReducer} from 'react';
import {type CompleteCrop} from 'react-image-crop';

import {
  renderStatusText,
  type ValidatedImageFile,
  validateImageFile
} from 'common/ImageUpload/functions';
import {
  UploadingPictureStatus,
  UploadingPictureValidationError
} from 'common/ImageUpload/interface';
import md5Chunk from 'services/common-methods/md5chunk';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';
import {type EnglexImage} from 'store/interface';

enum ActionTypes {
  HideModal = 'hide-modal',
  StoreCrop = 'store-crop',
  StoreFile = 'store-file',
  StartUploading = 'start-uploading',
  ChangeStatus = 'change-status',
  FileSelected = 'file-selected',
  SetError = 'set-error',
  HandleResponse = 'handle-response',
  SetRetry = 'set-Retry'
}

interface HideModalAction {
  type: ActionTypes.HideModal;
}

interface StoreCropAction {
  type: ActionTypes.StoreCrop;
  cropResult: CompleteCrop;
}

interface StoreFileAction {
  type: ActionTypes.StoreFile;
  file: File;
}

interface StartUploadingAction {
  type: ActionTypes.StartUploading;
}

interface ChangeStatusAction {
  type: ActionTypes.ChangeStatus;
  status?: UploadingPictureStatus | UploadingPictureValidationError;
}

interface FileSelectedAction {
  type: ActionTypes.FileSelected;
  imageDataUrl: string;
  validatedFile: ValidatedImageFile;
}

interface SetErrorAction {
  type: ActionTypes.SetError;
  errorMessage: string;
}

interface HandleResponseAction {
  type: ActionTypes.HandleResponse;
}

interface SetRetryAction {
  type: ActionTypes.SetRetry;
  retry: boolean;
}

type Action =
  | HideModalAction
  | StoreCropAction
  | StoreFileAction
  | StartUploadingAction
  | ChangeStatusAction
  | FileSelectedAction
  | SetErrorAction
  | HandleResponseAction
  | SetRetryAction;

type Status = UploadingPictureStatus | UploadingPictureValidationError | undefined;

interface State {
  uploadingStarted: boolean;
  freezeModal: boolean;
  isErrorStatus: boolean;
  retry: boolean;
  status?: Status;
  imageDataUrl?: string;
  errorMessage?: string;
  file?: File;
  validatedFile?: ValidatedImageFile;
  cropResult?: CompleteCrop;
}

const initialState: State = {
  uploadingStarted: false,
  freezeModal: false,
  isErrorStatus: false,
  retry: false
};

function cardReducer(state: State, action: Action) {
  switch (action.type) {
    case ActionTypes.ChangeStatus:
      return {
        ...state,
        status: action.status
      };

    case ActionTypes.HideModal:
      return {
        ...state,
        status: undefined,
        errorMessage: undefined,
        imageDataUrl: undefined,
        uploadingStarted: false
      };

    case ActionTypes.StoreCrop:
      return {
        ...state,
        cropResult: action.cropResult
      };

    case ActionTypes.StoreFile:
      return {
        ...state,
        file: action.file
      };

    case ActionTypes.StartUploading: {
      if (state.status === UploadingPictureStatus.UPLOADING_ERROR) {
        const retry =
          !!state.status &&
          (state.status! in UploadingPictureValidationError ||
            state.status === UploadingPictureStatus.UPLOADING_ERROR);

        return {
          ...state,
          retry,
          freezeModal: true,
          uploadingStarted: true,
          errorMessage: undefined
        };
      }

      return {
        ...state,
        freezeModal: true,
        uploadingStarted: true
      };
    }

    case ActionTypes.FileSelected:
      return {
        ...state,
        imageDataUrl: action.imageDataUrl,
        validatedFile: action.validatedFile,
        status: undefined
      };

    case ActionTypes.SetError:
      return {
        ...state,
        freezeModal: false,
        status: UploadingPictureStatus.UPLOADING_ERROR,
        errorMessage: action.errorMessage
      };

    case ActionTypes.HandleResponse:
      return {
        ...state,
        freezeModal: false,
        imageDataUrl: undefined,
        status: undefined,
        uploadingStarted: false
      };

    case ActionTypes.SetRetry:
      return {
        ...state,
        retry: action.retry
      };

    default:
      return state;
  }
}

type ErrorElement = string | React.ReactElement;

interface Props {
  onSuccess: (id: number, url: string) => void;
  onError: (error: ErrorElement) => void;
  imageMinDimension?: number;
}

export function useCropModal({onSuccess, onError, imageMinDimension = 235}: Props) {
  const [state, dispatch] = useReducer(cardReducer, initialState);

  useEffect(() => {
    const isErrorStatus =
      !!state.status &&
      (state.status in UploadingPictureValidationError ||
        state.status === UploadingPictureStatus.UPLOADING_ERROR);

    if (isErrorStatus && onError) {
      onError(renderStatusText(state.status!, imageMinDimension));
    }
  }, [imageMinDimension, onError, state.status]);

  const hideModal = useCallback(
    () => !state.freezeModal && dispatch({type: ActionTypes.HideModal}),
    [state.freezeModal]
  );

  const storeCrop = useCallback(
    (cropResult: CompleteCrop) => dispatch({type: ActionTypes.StoreCrop, cropResult}),
    []
  );

  const storeFile = useCallback((file: File) => dispatch({type: ActionTypes.StoreFile, file}), []);

  const startUploading = useCallback(() => dispatch({type: ActionTypes.StartUploading}), []);

  const handleFileSelected = useCallback(
    async (imageDataUrl: string) => {
      const {file} = state;

      if (file) {
        const md5 = await md5Chunk(file);
        const status = await validateImageFile(file, md5, undefined, undefined, imageMinDimension);

        if (status in UploadingPictureValidationError) {
          return dispatch({type: ActionTypes.ChangeStatus, status});
        }

        dispatch({type: ActionTypes.FileSelected, imageDataUrl, validatedFile: {md5, file}});
      }
    },
    [imageMinDimension, state]
  );

  const handleError = useCallback(({error}: AxiosRequestError) => {
    const isErrorCode = error.response && [422, 400].includes(error.response.status);

    const errorMessage = isErrorCode
      ? Array.isArray(error.response!.data)
        ? error.response!.data[0].message
        : error.response!.data.message
      : undefined;

    dispatch({type: ActionTypes.SetError, errorMessage});
  }, []);

  const handleResponse = useCallback(
    ({payload}: AxiosResponseAction<EnglexImage>) => {
      const {
        data: {id, urls}
      } = payload;

      onSuccess(id, urls[0]);
      dispatch({type: ActionTypes.HandleResponse});
    },
    [onSuccess]
  );

  const setStatus = useCallback(
    (status?: UploadingPictureStatus) => dispatch({type: ActionTypes.ChangeStatus, status}),
    []
  );

  const turnOffRetry = useCallback(() => dispatch({type: ActionTypes.SetRetry, retry: false}), []);

  return {
    ...state,
    hideModal,
    storeCrop,
    storeFile,
    startUploading,
    handleFileSelected,
    handleError,
    handleResponse,
    setStatus,
    turnOffRetry
  };
}
