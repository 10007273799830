import React from 'react';
import classNames from 'classnames';
import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';
import './XEditorActions.scss';

interface Props {
  openWizard: () => void;
  clearWidgetClipboard: () => void;
  pasteWidgetFromClipboard: () => void;

  wizardIsActive: boolean;
  clientHeight: number;
  scrollHeight: number;
  scrollTop: number;
  clipboardHasWidget: boolean;
}

interface State {
  expanded: boolean;
}

class XEditorActions extends React.Component<Props, State> {
  public state: State = {expanded: false};

  private component: HTMLDivElement | null;

  private get shouldRenderFixedButtons(): boolean {
    const {clientHeight, scrollHeight, scrollTop} = this.props;

    if (clientHeight === scrollHeight) {
      return false;
    }
    return !!this.component && this.component.offsetTop > scrollTop + clientHeight;
  }

  private get fixedAddWidgetBtnClasses(): string {
    return classNames('add-widget-fixed', {
      expanded: this.state.expanded || this.props.wizardIsActive
    });
  }

  public render() {
    const {openWizard, clipboardHasWidget, clearWidgetClipboard, pasteWidgetFromClipboard} =
      this.props;
    return (
      <div className="xeditor-actions" ref={this.componentRef}>
        <Button bsSize="sm" bsStyle="success" onClick={openWizard} className="add-widget-btn">
          <FormattedMessage id="XEditor.AddWidget" values={{icon: <Icon name="plus-circle" />}} />
        </Button>
        {clipboardHasWidget ? (
          <ButtonGroup className="paste-widget-group">
            <Button bsSize="sm" bsStyle="success" onClick={pasteWidgetFromClipboard}>
              <FormattedMessage id="XEditor.PasteWidget" values={{icon: <Icon name="paste" />}} />
            </Button>
            <Button bsSize="sm" bsStyle="success" onClick={clearWidgetClipboard}>
              <Icon name="trash" />
            </Button>
          </ButtonGroup>
        ) : null}
        {this.shouldRenderFixedButtons && this.renderFixedButtons()}
      </div>
    );
  }

  private renderFixedButtons = () => (
    <React.Fragment>
      {this.props.clipboardHasWidget ? (
        <div className="paste-widget-fixed">
          <ButtonGroup>
            <Button bsStyle="success" bsSize="lg" onClick={this.props.pasteWidgetFromClipboard}>
              <span className="button-collapsed-icon">
                <Icon name="paste" />
              </span>
              <span className="button-title">
                <FormattedMessage id="XEditor.PasteWidget" values={{icon: null}} />
              </span>
            </Button>
            <Button bsStyle="success" bsSize="lg" onClick={this.props.clearWidgetClipboard}>
              <Icon name="trash" />
            </Button>
          </ButtonGroup>
        </div>
      ) : null}

      <Button
        className={this.fixedAddWidgetBtnClasses}
        onClick={this.props.openWizard}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <span className="icon-wrapper">
          <Icon name="pc-inc" />
        </span>
        <span className="message">
          <FormattedMessage id="XEditor.AddWidget" values={{icon: null}} />
        </span>
      </Button>
    </React.Fragment>
  );

  private componentRef = (el: HTMLDivElement | null) => (this.component = el);

  private onMouseEnter = () => this.setState(() => ({expanded: true}));

  private onMouseLeave = () => this.setState(() => ({expanded: false}));
}

export default XEditorActions;
