import React, {Component, type FC} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {type IntlShape} from 'react-intl';

import {type ExerciseCategory} from 'store/interface';
import Icon from 'components/Icon';

import CategoryComponent from './Category';
import Editor from './Editor';
import {messages} from '../i18n';

interface Props {
  activeIds: number[];
  allCategories: ExerciseCategory[];
  categories: ExerciseCategory[];
  clearFoundId(): void;
  createCategory(callback: (id: number) => void, title: string, parentId?: number | null): void;
  deleteCategory(id: number): void;
  foundId?: number;
  intl: IntlShape;
  openCategory(id: number, parentId: number | null): void;
  parentId: number | null;
  parentIds: number[];
  renameCategory(title: string, id: number): void;
  pendingCategories: number[];
  processingRequestId?: number | null;
  toggleCategory(id: number): void;
}

interface State {
  showSecondEditor: boolean;
  firstEditorOpened: boolean;
  secondEditorOpened: boolean;
}

class Categories extends Component<Props, State> {
  public state: State = {
    showSecondEditor: false,
    firstEditorOpened: false,
    secondEditorOpened: false
  };
  private component: HTMLDivElement;
  private scrollbars: Scrollbars | null;

  public componentDidMount(): void {
    const {foundId} = this.props;

    if (this.scrollbars) {
      if (this.scrollbars.getClientHeight() < this.scrollbars.getScrollHeight()) {
        this.setState({showSecondEditor: true});
      }
    }
    if (foundId) this.autoScroll();
  }

  public componentDidUpdate(prevProps: Props) {
    const {categories, foundId} = this.props;
    if (this.scrollbars && prevProps.categories.length !== categories.length) {
      const sH = this.scrollbars.getScrollHeight();
      const cH = this.scrollbars.getClientHeight();
      if (cH !== sH) {
        this.setState({showSecondEditor: sH - cH > 0});
      }
    }
    if (!prevProps.foundId && foundId) this.autoScroll();
  }

  public render() {
    const {
      activeIds,
      allCategories,
      categories,
      deleteCategory,
      openCategory,
      parentIds,
      renameCategory,
      pendingCategories,
      processingRequestId,
      toggleCategory
    } = this.props;
    const {showSecondEditor, firstEditorOpened, secondEditorOpened} = this.state;
    const {NewCategory} = this;
    return (
      <div className="categories" ref={this.componentRef}>
        <Scrollbars autoHide={true} ref={this.scrollbarRef}>
          <NewCategory
            opened={firstEditorOpened}
            open={this.openFirstEditor}
            toggle={this.toggleFirstEditor}
          />
          {categories.map(c => (
            <CategoryComponent
              key={c.id}
              active={activeIds.includes(c.id)}
              categories={allCategories}
              category={c}
              deleteCategory={deleteCategory}
              hasChildren={parentIds.includes(c.id)}
              openCategory={openCategory}
              parentRef={() => this.component}
              processingRequest={processingRequestId === c.id}
              renameCategory={renameCategory}
              selected={pendingCategories.includes(c.id)}
              toggleCategory={toggleCategory}
            />
          ))}
          {showSecondEditor ? (
            <NewCategory
              opened={secondEditorOpened}
              open={this.openSecondEditor}
              toggle={this.toggleSecondEditor}
            />
          ) : null}
        </Scrollbars>
      </div>
    );
  }

  private NewCategory: FC<{opened: boolean; open(): void; toggle(): void}> = ({
    opened,
    open,
    toggle
  }) => {
    const {
      allCategories,
      processingRequestId,
      parentId,
      intl: {formatMessage}
    } = this.props;
    return (
      <div className="new-category">
        {opened ? (
          <Editor
            categories={allCategories}
            close={toggle}
            createCategory={this.createCategory}
            parentId={parentId}
            processingRequest={processingRequestId === null}
          />
        ) : (
          <div className="placeholder" onClick={open}>
            <Icon name="pc-inc" />
            {formatMessage(messages.newCategory)}
          </div>
        )}
      </div>
    );
  };

  private autoScroll() {
    const {activeIds, categories, clearFoundId} = this.props;
    const ids = categories.map(c => c.id);
    for (const id of ids) {
      if (activeIds.includes(id)) {
        this.scrollToCategory(id, clearFoundId);
        break;
      }
    }
  }

  private componentRef = (el: HTMLDivElement) => el && (this.component = el);

  private createCategory = (title: string, parentId?: number | null) => {
    this.props.createCategory(this.scrollToCategory, title, parentId);
  };

  private openFirstEditor = () => {
    if (!this.state.firstEditorOpened) {
      this.setState({firstEditorOpened: true, secondEditorOpened: false});
    }
  };

  private openSecondEditor = () => {
    if (!this.state.secondEditorOpened) {
      this.setState({secondEditorOpened: true, firstEditorOpened: false});
    }
  };

  private scrollbarRef = (el: Scrollbars | null) => (this.scrollbars = el);

  private scrollToCategory = (id: number, cb?: () => void) => {
    const categoryNode = document.querySelector(`#categories-modal-item-${id}`) as HTMLDivElement;
    if (categoryNode) this.scrollbars?.scrollTop(categoryNode.offsetTop);
    cb?.();
  };

  private toggleFirstEditor = (state?: true) => {
    this.setState({firstEditorOpened: state || false});
  };

  private toggleSecondEditor = (state?: true) => {
    this.setState({secondEditorOpened: state || false});
  };
}

export default Categories;
