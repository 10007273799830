import {type Decoration, type Next, type Node, type Text} from '@englex/slate';
import {type Editor as ReactEditor, type Plugin} from '@englex/slate-react';

import {SlateBlock, type SlateDecoration} from 'components/Slate/interface';
import {isBlockOfType} from 'components/Slate/utils';

import {getSurroundOffsets, letterChecker} from './util';
import {type CheckerFn} from './interface';

export interface DecorateTextOptions {
  type: SlateDecoration;
  splitter?: RegExp | string;
  checker?: CheckerFn;
  surround?: {
    char?: string;
    predicate: (text: string, char?: string) => boolean;
  };
}

export const DecorateText = (options: DecorateTextOptions): Plugin => {
  const {type, splitter = '', checker = letterChecker, surround} = options;
  return {
    decorateNode: (node: Node, editor: ReactEditor, next: Next) => {
      if (!isBlockOfType(node, SlateBlock.DEFAULT)) return next();

      const {document} = editor.value;
      const children = node.nodes;
      const decorations: Decoration[] = [];
      const text = node.text;
      const parts = text.split(splitter);

      let start = 0;
      let end = 0;

      parts.forEach((p, i) => {
        end = start + p.length;
        if (checker(parts, i, text.length)) {
          const [sQuoteOffset, eQuoteOffset] =
            surround && surround.predicate(p, surround.char)
              ? getSurroundOffsets(p, surround?.char)
              : [0, 0];

          // find start and end text nodes inside target node
          const startText = node.getTextAtOffset(start + sQuoteOffset);
          let endText = node.getTextAtOffset(end - eQuoteOffset);

          if (!startText || !endText) {
            // something went wrong and nodes not found, so skip this word
            start = end;
            return;
          }

          // get the start text node offset related to target node
          const startTextIndex = children.findIndex(n => n === startText);
          const startTextOffset = node.getOffset([startTextIndex]);

          // get end text node offset related to target node
          const endTextIndex = children.findIndex(n => n === endText);
          let endTextOffset = node.getOffset([endTextIndex]);

          // `node.getTextAtOffset` returns the next sibling text node
          // if the word ends exactly at the end of the text node,
          // so we need the end text node to be moved to the previous sibling text node
          if (startText !== endText && end === endTextOffset) {
            endText = children.get(endTextIndex - 1) as Text;
            // endPath = PathUtils.decrement(endPath)
            endTextOffset = node.getOffset([endTextIndex - 1]);
          }

          const startOffset = start + sQuoteOffset - startTextOffset;
          const endOffset = end - eQuoteOffset - endTextOffset;

          // Each parsed word that has at least one letter with different formatting won't be decorated.
          // otherwise each text node (with marks) within the word would be wrapped and
          // it wouldn't be seen as a word visually
          const isWordBelongsToOneTextNode = startText.key === endText.key;

          if (isWordBelongsToOneTextNode) {
            const dec: Decoration = document.createDecoration({
              type,
              anchor: document.createPoint({
                key: startText.key,
                offset: startOffset
              }),
              focus: document.createPoint({
                key: endText.key,
                offset: endOffset
              })
            });
            decorations.push(dec);
          }
        }
        start = end;
      });

      return decorations;
    }
  };
};
