import React from 'react';
import {type Editor, type Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import TextAlignment from 'components/Slate/SlateEditor/plugins/button/TextAlignment';
import FontSizeToolbar from 'components/Slate/SlateEditor/plugins/button/FontSize';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import {NestedLists} from 'components/Slate/SlateEditor/plugins/button/List/NestedLists';
import AddDialogBtn from 'components/Slate/SlateEditor/plugins/button/Table/Dialog/AddDialogBtn';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import Image from 'components/Slate/SlateEditor/plugins/button/Image';
import ImageButton from 'components/Slate/SlateEditor/plugins/button/Image/ImageButton';
import Link from 'components/Slate/SlateEditor/plugins/button/Link';
import {CharCount} from 'components/Slate/plugins/renderers/Counters/CharCount';
import XCardsRecord from 'store/exercise/editor/widgets/XCards/XCardsRecord';
import {InlineInputPlugin} from 'components/Slate/SlateEditor/plugins/InlineInputPlugin';
import {ToggleExample} from 'components/Slate/SlateEditor/plugins/button/Example/ToggleExample';

import {messages} from '../messages';

const TitlePlugins = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FormatPainter(),
  new ClearFormatting(),
  new CharCount({maxCount: XCardsRecord.MaxTitleCardLength}),
  InlineInputPlugin()
] as Plugin[];

const TextPlugins = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new TextAlignment(),
  new FontSizeToolbar(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new NestedLists(),
  new AddDialogBtn(),
  new CharSelector(),
  new Image(),
  new ImageButton(),
  new Link(),
  new FormatPainter(),
  new ClearFormatting(),
  new ToggleExample()
] as Plugin[];

interface Props {
  index: number;
  title: Value;
  text: Value;
  withError: boolean;
  onChangeTitle: (change: Editor) => void;
  onChangeText: (change: Editor) => void;
  renderFooter: () => React.ReactElement;
}

export function XEditorTextCard({
  title,
  text,
  onChangeTitle,
  onChangeText,
  renderFooter,
  withError
}: Props) {
  const {formatMessage} = useIntl();

  return (
    <div className={classNames('card-container', {'with-error': withError})}>
      <SlateEditor
        toolbar={{portalId: 'xeditor-toolbar-portal'}}
        value={title}
        onChange={onChangeTitle}
        plugins={TitlePlugins}
        placeholder={formatMessage(messages.CardTitle)}
      />

      <div className="divider" />

      <SlateEditor
        toolbar={{portalId: 'xeditor-toolbar-portal'}}
        value={text}
        onChange={onChangeText}
        plugins={TextPlugins}
        placeholder={formatMessage(messages.CardText)}
      />

      {renderFooter()}
    </div>
  );
}
