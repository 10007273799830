import React, {type FC, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {type List} from 'immutable';
import {FormattedMessage} from 'react-intl';
import {
  components,
  type OptionProps,
  type MultiValueProps,
  type ValueContainerProps
} from 'react-select';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';

import {type AppState} from 'store/interface';
import {type OptionType, type OptionTypeBase} from 'components/ReactSelect/interface';
import {type GrammarExerciseOptions} from 'store/exercise/player/interface';
import {selectGrammar} from 'store/exercise/editor/actions/xeditor';

import ReactSelect from '../../../../../components/ReactSelect/ReactSelect';

const emptyItemTitle = 'Untitled';

const GrammarSection: React.FC = () => {
  const grammar = useSelector<AppState, GrammarExerciseOptions | undefined>(
    s => s.xeditor?.grammar
  );

  const selectedGrammar = useSelector<AppState, List<number> | undefined>(
    s => s.xeditor?.xexercise.grammar
  );

  const isGrammarExercise = useSelector<AppState, boolean>(
    s => !!s.xeditor?.xexercise.isGrammarExercise
  );
  const dispatch = useDispatch();

  const onGrammarSelect = useCallback(
    (selectedGrammar: Array<OptionType<number>> | null) => {
      const selectedIds = selectedGrammar ? selectedGrammar.map(sl => sl.value) : [];
      dispatch(selectGrammar(selectedIds));
    },
    [dispatch]
  );

  const options = useMemo<Array<OptionType<number>>>(
    () => (grammar ? grammar.map(g => ({label: g.title || emptyItemTitle, value: g.id})) : []),
    [grammar]
  );

  return !isGrammarExercise ? (
    <div className="grammar-section">
      <section>
        <span className="section-title">
          <FormattedMessage id="Exercise.Sidebar.SectionHeading.Grammar" />
        </span>
        <ReactSelect
          value={options.filter(o => !!selectedGrammar?.includes(o.value))}
          onChange={onGrammarSelect}
          options={options}
          components={{Option, MultiValue, ValueContainer}}
        />
      </section>
    </div>
  ) : null;
};

const Option: FC<OptionProps<OptionTypeBase>> = ({children, ...props}) => (
  <components.Option {...props}>
    <span
      title={props.data.label}
      className={classNames('grammar-option', {
        'empty-option': props.data.label === emptyItemTitle
      })}
    >
      {children}
    </span>
  </components.Option>
);

const MultiValue: FC<MultiValueProps<OptionTypeBase>> = ({children, ...props}) => (
  <components.MultiValue {...props} className="selected-grammar-label">
    <span title={props.data.label}>{children}</span>
  </components.MultiValue>
);

const ValueContainer: FC<ValueContainerProps<OptionTypeBase>> = props => {
  return (
    <components.ValueContainer {...props} className="grammar-value-container">
      <Scrollbars autoHeight={true} autoHeightMin={17} autoHeightMax={155} autoHide={true}>
        {props.children}
      </Scrollbars>
    </components.ValueContainer>
  );
};

export default GrammarSection;
