import {defineMessages} from 'react-intl';
export default defineMessages({
  invalidInput: {
    id: 'XEditorWidget.Video.Modal.InvalidInput'
  },
  videoIsUnavailableError: {
    id: 'XEditorWidget.Video.Modal.YoutubeVideoIsUnavailableError'
  },
  errorVerifyingLink: {
    id: 'XEditorWidget.Video.Modal.ErrorVerifyingLink'
  },
  youtubeInputPlaceholder: {
    id: 'XEditorWidget.Video.Modal.YoutubeInputPlaceholder'
  },
  postVideoRequestError: {
    id: 'XEditorWidget.Video.Modal.PostVideoRequestError'
  },
  getVideoLibraryTreeRequestError: {
    id: 'XEditorWidget.Video.Modal.GetVideoLibraryTreeRequestError'
  },
  placeholder: {
    id: 'XEditor.XWidget.XVideo.Placeholder'
  },
  startMustBeLessThanEnd: {
    id: 'XEditorWidget.Video.Modal.Youtube.StartMustBeLessThanEnd'
  },
  startMustBeLessThanDuration: {
    id: 'XEditorWidget.Video.Modal.Youtube.StartMustBeLessThanDuration'
  },
  endMustBeLessThanDuration: {
    id: 'XEditorWidget.Video.Modal.Youtube.EndMustBeLessThanDuration'
  },
  startMustNotBeEmpty: {
    id: 'XEditorWidget.Video.Modal.Youtube.StartMustNotBeEmpty'
  },
  endMustNotBeEmpty: {
    id: 'XEditorWidget.Video.Modal.Youtube.EndMustNotBeEmpty'
  },
  startMustBeValid: {
    id: 'XEditorWidget.Video.Modal.Youtube.StartMustBeValid'
  },
  endMustBeValid: {
    id: 'XEditorWidget.Video.Modal.Youtube.EndMustBeValid'
  },
  endMustNotBeStart: {
    id: 'XEditorWidget.Video.Modal.Youtube.EndMustNotBeStart'
  }
});
