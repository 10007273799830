import {type ElementType} from 'react';

import {UnknownWidget} from 'components/XPlayer/widgets/Unknown/UnknownWidget';
import {WidgetType} from 'store/exercise/player/interface';

import XEditorFormattedText from './XEditorFormattedText';
import XEditorAudio from './XAudio';
import XEditorGapFill from './XEditorGapFill';
import XEditorImageMatching from './XImage/ImageMatching/XEditorImageMatching';
import XEditorImageLabeling from './XImage/ImageLabeling/XEditorImageLabeling';
import XEditorMatching from './XEditorMatching';
import XEditorMultipleChoice from './XMultipleChoice/XEditorMultipleChoice';
import XEditorFreeChoice from './XEditorFreeChoice';
import XEditorOddWord from './XEditorOddWord';
import XEditorQuests from './XEditorQuests';
import XEditorScrambledSentences from './XScrambledSentences';
import XEditorVideo from './XVideo';
import XEditorVocabulary from './XVocabulary/XEditorVocabulary';
import XEditorNote from './XEditorNote';
import XEditorQuiz from './XQuiz/XEditorQuiz';
import XEditorGrammarRules from './XEditorGrammarRules';
import XEditorCards from './XCards/XEditorCards';
import XEditorPictureChoice from './XPictureChoice/XEditorPictureChoice';
import {XDialogue} from './XDialogue/XDialogue';
import XEditorComment from './XEditorComment';
import {XWordPictureSet} from './XWordPictureSet/XWordPictureSet';
import {XFlipCards} from './XFlipCards/XFlipCards';
import {XStyledListHorizontal} from './XStyledList/horizontal/XStyledListHorizontal';
import {XStyledListVertical} from './XStyledList/vertical/XStyledListVertical';
import {XSpelling} from './XSpelling/XSpelling';

const map: Record<WidgetType, ElementType | null> = {
  [WidgetType.FORMATTED_TEXT]: XEditorFormattedText,
  [WidgetType.AUDIO]: XEditorAudio,
  [WidgetType.ESSAY]: null,
  [WidgetType.GAP_FILL]: XEditorGapFill,
  [WidgetType.IMAGE_MATCHING]: XEditorImageMatching,
  [WidgetType.IMAGE_LABELING]: XEditorImageLabeling,
  [WidgetType.MATCHING]: XEditorMatching,
  [WidgetType.MULTIPLE_CHOICE]: XEditorMultipleChoice,
  [WidgetType.FREE_CHOICE]: XEditorFreeChoice,
  [WidgetType.ODD_WORD]: XEditorOddWord,
  [WidgetType.QUESTS]: XEditorQuests,
  [WidgetType.QUESTIONS]: XEditorQuests,
  [WidgetType.SCRAMBLED_SENTENCES]: XEditorScrambledSentences,
  [WidgetType.VIDEO]: XEditorVideo,
  [WidgetType.VOCABULARY]: XEditorVocabulary,
  [WidgetType.COMMENT]: XEditorComment,
  [WidgetType.GRAMMAR_RULES]: XEditorGrammarRules,
  [WidgetType.NOTE]: XEditorNote,
  [WidgetType.SPEAKING]: XEditorFormattedText,
  [WidgetType.CARDS]: XEditorCards,
  [WidgetType.Quiz]: XEditorQuiz,
  [WidgetType.PICTURE_CHOICE]: XEditorPictureChoice,
  [WidgetType.DIALOGUE]: XDialogue,
  [WidgetType.WORD_SET]: XWordPictureSet,
  [WidgetType.PICTURE_SET]: XWordPictureSet,
  [WidgetType.WORD_PICTURE_SET]: XWordPictureSet,
  [WidgetType.FLIP_CARDS]: XFlipCards,
  [WidgetType.HORIZONTAL_STYLED_LIST]: XStyledListHorizontal,
  [WidgetType.VERTICAL_STYLED_LIST]: XStyledListVertical,
  [WidgetType.SPELLING]: XSpelling,
  [WidgetType.UNKNOWN]: UnknownWidget
};

function xeditorComponentFactory(type: WidgetType): ElementType | null {
  return map[type];
}

export default xeditorComponentFactory;
