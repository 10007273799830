import React, {Component} from 'react';
import classNames from 'classnames';
import {type Dispatch} from 'redux';
import {connect} from 'react-redux';
import {defineMessages, type WrappedComponentProps, injectIntl} from 'react-intl';
import {default as FormControl} from 'react-bootstrap/lib/FormControl';

import {
  deletePair,
  imageMatchingChangeCardSizeType,
  setImageData
} from 'store/exercise/editor/widgets/XImage/actions';
import {type DndSortingWrapperProps} from 'components/DndSortingWrapper/DndSortingWrapper';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';
import {type XWidgetAction} from 'store/exercise/editor/actions/interface';

import ImageCard from '../../components/ImageCard/ImageCard';

import './ImageLabelingCard.scss';

const i18n = defineMessages({
  placeholder: {
    id: 'XEditorWidget.ImageLabeling.Input.Placeholder'
  }
});

export interface ImageLabelingCardProps {
  id: string;
  imageId: number;
  index: number;
  xwidgetId: string;
  answers: string[];
  cardSizeType: CardSizeType;
  example?: boolean;
  editable?: boolean;
  placeholder?: string;
  reportError: (e: string | JSX.Element) => string | JSX.Element;
  resetErrors: () => void;
  onOpenModal: (cardId: string) => void;
  updateRecoveryPoint: () => void;
}

interface DispatchProps {
  deletePair: () => void;
  setImage: (imageId: number, url: string) => void;
}

type Props = ImageLabelingCardProps &
  DispatchProps &
  WrappedComponentProps &
  DndSortingWrapperProps;

class ImageLabelingCard extends Component<Props> {
  private get inputClasses() {
    return classNames('value', {example: this.props.example});
  }

  private onInputClick = () => {
    this.props.onOpenModal(this.props.id);
  };

  public render() {
    const {inputClasses} = this;
    const {
      id,
      answers,
      xwidgetId,
      imageId,
      handle,
      isDragging,
      isDragLayer,
      style,
      cardSizeType,
      intl: {formatMessage},
      setImage,
      deletePair,
      reportError,
      resetErrors
    } = this.props;

    const title = answers[0] || '';

    return (
      <div
        className={classNames('image-labeling__card', {
          'is-rectangle': cardSizeType === CardSizeType.RECTANGLE
        })}
        style={style}
      >
        <div
          className={classNames(
            'block-wrapper',
            {'is-dragging': isDragging && !isDragLayer},
            {'is-drag-layer': isDragLayer}
          )}
        >
          <ImageCard
            id={id}
            xwidgetId={xwidgetId}
            imageId={imageId}
            handle={handle}
            isDragLayer={isDragLayer}
            reportError={reportError}
            resetErrors={resetErrors}
            deletePair={deletePair}
            setImage={setImage}
            changeCardSizeTypeAction={imageMatchingChangeCardSizeType}
          />
        </div>

        <div className={classNames('block-wrapper', {'is-dragging': isDragging && !isDragLayer})}>
          <div className="card-input" onClick={this.onInputClick}>
            <FormControl
              bsSize="small"
              className={inputClasses}
              placeholder={!isDragLayer ? formatMessage(i18n.placeholder) : undefined}
              title={title}
              type="text"
              value={title}
              readOnly={true}
            />

            {answers.length > 1 && <div className="answers-count">{answers.length}</div>}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<XWidgetAction>,
  {xwidgetId, index, updateRecoveryPoint}: ImageLabelingCardProps
) => ({
  deletePair: () => {
    dispatch(deletePair(xwidgetId, index));
    requestAnimationFrame(updateRecoveryPoint);
  },
  setImage: (imageId: number, url: string) => {
    dispatch(setImageData(xwidgetId, index, imageId, url));
    requestAnimationFrame(updateRecoveryPoint);
  }
});

export default connect<{}, DispatchProps>(
  undefined,
  mapDispatchToProps
)(injectIntl(ImageLabelingCard));
