import {type ToggleElementAction} from '../../../../common/interface';
import {ADD_WIDGET_FIELD, NEXT_STEP, PREV_STEP, TOGGLE} from '../actionTypes/xwizard';
import {type WidgetJSON} from '../../player/interface';
import {type XWizardAddWidgetFieldCreator} from './interface';

export function toggleWizard(showWizard: boolean = true): ToggleElementAction {
  return {
    type: TOGGLE,
    show: showWizard
  };
}

export const nextStep = () => ({
  type: NEXT_STEP
});

export const prevStep = () => ({
  type: PREV_STEP
});

export const addWidgetField: XWizardAddWidgetFieldCreator = <P extends keyof WidgetJSON>(
  key: P,
  value: WidgetJSON[P]
) => ({
  type: ADD_WIDGET_FIELD,
  key,
  value
});
