import React, {type FC} from 'react';

import ImageFileInput from 'components/Slate/SlateEditor/plugins/button/Image/Modal/ImageFileInput';
import {AspectRatio} from 'components/Crop/static';
import CropComponent from 'components/Crop';

import {type BodyProps, Phase} from './interface';

const ImageModalBody: FC<BodyProps> = ({
  handleFileSelected,
  imageDataUrl,
  intl,
  minHeight,
  minWidth,
  phase,
  storeCrop,
  storeFile
}) => {
  return (
    <>
      {phase !== Phase.DEFAULT && imageDataUrl && (
        <div className="dnd-image">
          <CropComponent
            aspectRatio={AspectRatio.SCREEN}
            getCrop={storeCrop}
            imageDataUrl={imageDataUrl}
            minHeight={minHeight}
            minWidth={minWidth}
          />
        </div>
      )}
      {phase === Phase.DEFAULT && (
        <ImageFileInput
          dispatchFile={storeFile}
          dispatchImageDataUrl={handleFileSelected}
          intl={intl}
          minWidth={minWidth}
          minHeight={minHeight}
        />
      )}
    </>
  );
};

export default ImageModalBody;
