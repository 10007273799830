import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

import {changeAvatar} from 'store/exercise/editor/widgets/XDialogue/actions';
import LoadableImage from 'components/LoadableImage/LoadableImage';

import {useCropModal} from '../../../../../hooks/useCropModal';
import {AvatarView} from './AvatarView';
import {AvatarImage, AvatarTypes} from '../../../../assets/AvatarImage';

interface Props {
  widgetId: string;
  speakerId: string;
  avatar: number;
  droppedFile: File | null;
  isOver: boolean;
  setError: (message: string, meta: Record<string, unknown>) => void;
}

const AVATAR_MIN_WIDTH = 96;

export const Avatar: React.FC<Props> = ({
  widgetId,
  speakerId,
  avatar,
  droppedFile,
  isOver,
  setError
}) => {
  const dispatch = useDispatch();

  const fileReader = useRef(new FileReader());
  const [avatarActivated, setAvatarActivated] = useState(false);

  const onChangeAvatar = useCallback(
    (avatar: number) => {
      dispatch(changeAvatar(widgetId, speakerId, avatar));
    },
    [dispatch, speakerId, widgetId]
  );

  const onError = useCallback(
    errorMessage => setError(errorMessage, {speakerId}),
    [speakerId, setError]
  );

  const {
    imageDataUrl,
    errorMessage,
    cropResult,
    freezeModal,
    isErrorStatus,
    retry,
    status,
    uploadingStarted,
    validatedFile,
    hideModal,
    storeCrop,
    storeFile,
    startUploading,
    handleFileSelected,
    handleError,
    handleResponse,
    turnOffRetry,
    setStatus
  } = useCropModal({onSuccess: onChangeAvatar, onError, imageMinDimension: AVATAR_MIN_WIDTH});

  useEffect(() => {
    if (droppedFile) {
      fileReader.current.readAsDataURL(droppedFile);
      storeFile(droppedFile);
      setAvatarActivated(true);
    }
  }, [droppedFile, storeFile]);

  useEffect(() => {
    fileReader.current.onloadend = () => {
      const res = fileReader.current.result;
      if (res) {
        handleFileSelected(res as string);
      }
    };
  }, [fileReader, handleFileSelected]);

  const handleFileInput = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      const [firstFil] = e.target['files'];

      if (firstFil) {
        fileReader.current.readAsDataURL(firstFil);
        storeFile(firstFil);
      }
    },
    [storeFile]
  );

  const chooseAvatar = () => {
    if (avatarActivated) return <AvatarImage avatarType={AvatarTypes.ACTIVE} />;
    if (avatar)
      return <LoadableImage imageId={avatar} width="100%" height="100%" draggable={false} />;
    return <AvatarImage avatarType={AvatarTypes.DEFAULT} />;
  };

  const closeCrop = useCallback(() => {
    setAvatarActivated(false);
    hideModal();
  }, [hideModal]);

  const uploadCrop = useCallback(() => {
    setAvatarActivated(false);
    startUploading();
  }, [startUploading]);

  return (
    <AvatarView
      avatarMinWidth={AVATAR_MIN_WIDTH}
      imageDataUrl={imageDataUrl}
      uploadingStarted={uploadingStarted}
      errorMessage={errorMessage}
      validatedFile={validatedFile}
      isErrorStatus={isErrorStatus}
      cropResult={cropResult}
      retry={retry}
      status={status}
      freezeModal={freezeModal}
      isOver={isOver}
      avatar={chooseAvatar()}
      handleFileInput={handleFileInput}
      closeCrop={closeCrop}
      storeCrop={storeCrop}
      uploadCrop={uploadCrop}
      turnOffRetry={turnOffRetry}
      setStatus={setStatus}
      handleError={handleError}
      handleResponse={handleResponse}
    />
  );
};
