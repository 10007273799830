import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import {type AppState} from 'store/interface';
import XCardsRecord from 'store/exercise/editor/widgets/XCards/XCardsRecord';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type XCardsProperties} from 'store/exercise/editor/widgets/XCards/interface';
import type XEditorCardRecord from 'store/exercise/editor/widgets/XCards/XEditorCardRecord';
import Icon from 'components/Icon';
import {createCard} from 'store/exercise/editor/widgets/XCards/actions';
import {resetWidgetErrors} from 'store/exercise/editor/actions/xeditor';

import {messages} from './messages';
import {XEditorCard} from './XEditorCard';

import './XEditorCards.scss';

interface Props {
  id: string;
  setError: (e: string | React.ReactElement) => string | React.ReactElement;
}

const XEditorCards = React.memo(({id, setError}: Props) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();

  const widget = useSelector((state: AppState) =>
    state.xeditor!.xexercise.widgets.find((x: XWidgetProperties) => x.id === id)
  ) as XCardsProperties;

  const {cardsType: type, cardsList: cards} = widget;

  const errors = useSelector((state: AppState) => {
    return state.xeditor!.errors.getIn(['widgets', id], {});
  });

  const onCreateCard = useCallback(() => {
    if (cards.size >= XCardsRecord.MaxCardsCount) return;

    dispatch(createCard(id));
  }, [dispatch, id, cards.size]);

  const resetErrors = useCallback(() => dispatch(resetWidgetErrors(id)), [dispatch, id]);

  return (
    <div className="cards-container">
      {cards.map((card: XEditorCardRecord, index: number) => {
        const canMoveUp = cards.size !== XCardsRecord.MinCardsCount && index !== 0;
        const canMoveDown = cards.size !== XCardsRecord.MinCardsCount && index !== cards.size - 1;
        const canDelete = cards.size !== XCardsRecord.MinCardsCount;
        const withError = Boolean(errors.meta?.[card.id]);

        return (
          <XEditorCard
            widgetId={id}
            key={card.id}
            index={index}
            type={type}
            canMoveUp={canMoveUp}
            canMoveDown={canMoveDown}
            canDelete={canDelete}
            withError={withError}
            setError={setError}
            resetErrors={resetErrors}
            data={card}
          />
        );
      })}

      <div className="cards-container__footer">
        <div
          className={classNames('control', {disabled: cards.size >= XCardsRecord.MaxCardsCount})}
          onClick={onCreateCard}
        >
          <Icon name="plus-circle" />
          {formatMessage(messages.AddCard)}
        </div>
      </div>
    </div>
  );
});

export default XEditorCards;
