import {type WidgetJSON} from 'store/exercise/player/interface';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';

import {type XWizardWidgetData} from './interface';
import {gapFillWizardData} from './GapFill';
import {formattedTextWizardData} from './FormattedText';
import {vocabularyWizardData} from './Vocabulary';
import {noteWizardData} from './Note';
import {commentWizardData} from './Comment';
import {essayWizardData} from './Essay';
import {questsWizardData} from './Quests';
import {imageMatchingWizardData} from './ImageMatching';
import {multipleChoiceWizardData} from './MultipleChoice';
import {oddWordWizardData} from './OddWord';
import {freeChoiceWizardData} from './FreeChoice';
import {matchingWizardData} from './Matching';
import {scrambledSentencesWizardData} from './ScrambledSentences';
import {audioWizardData} from './Audio';
import {videoWizardData} from './Video';
import {grammarRulesWizardData} from './GrammarRules';
import {speakingWizardData} from './Speaking';
import {questionsWizardData} from './Questions';
import {cardsWizardData} from './Cards';
import {quizWizardData} from './Quiz';
import {pictureChoiceWizardData} from './PictureChoice';
import {dialogueWizardData} from './Dialogue';
import {wordPictureSetWizardData} from './WordPictureSet';
import {flipCardsWizardData} from './FlipCards';
import {styleListWizardData} from './StyledList';
import {spellingWizardData} from './Spelling';

export const wizardWidgetsAutoCheckingData: Array<
  XWizardWidgetData<WidgetJSON, XWidgetProperties>
> = [
  gapFillWizardData,
  matchingWizardData,
  imageMatchingWizardData,
  quizWizardData,
  multipleChoiceWizardData,
  scrambledSentencesWizardData,
  pictureChoiceWizardData,
  oddWordWizardData,
  spellingWizardData
];

export const wizardWidgetsNoAutoCheckingData: Array<
  XWizardWidgetData<WidgetJSON, XWidgetProperties>
> = [
  formattedTextWizardData,
  audioWizardData,
  videoWizardData,
  speakingWizardData,
  essayWizardData,
  questsWizardData,
  questionsWizardData,
  freeChoiceWizardData,
  dialogueWizardData,
  styleListWizardData,
  cardsWizardData,
  flipCardsWizardData,
  wordPictureSetWizardData
];

export const wizardWidgetsInformationData: Array<XWizardWidgetData<WidgetJSON, XWidgetProperties>> =
  [vocabularyWizardData, grammarRulesWizardData, commentWizardData, noteWizardData];

export const wizardWidgetsData: Array<XWizardWidgetData<WidgetJSON, XWidgetProperties>> = [
  ...wizardWidgetsAutoCheckingData,
  ...wizardWidgetsNoAutoCheckingData,
  ...wizardWidgetsInformationData
];
