import {defineMessages} from 'react-intl';

// todo: mb it should be higher in project tree and contain validation messages
//   for any kind of file and input
export const errorMessages = defineMessages({
  FileCorruptedError: {
    id: 'Uploading.FileCorrupted'
  },
  MultipleFilesError: {
    id: 'Uploading.MultipleFilesError'
  },
  EmptyFile: {
    id: 'Uploading.EmptyFile'
  },
  UnknownUploadingError: {
    id: 'Uploading.UnknownUploadingError'
  },
  UploadingFailed: {
    id: 'Uploading.UploadingFailed'
  },
  UploadingSuccessful: {
    id: 'Uploading.UploadingSuccessful'
  },
  InvalidSoundExtension: {
    id: 'Uploading.InvalidSoundExtension'
  },
  InvalidFileSize: {
    id: 'Uploading.InvalidFileSize'
  },
  AudioFileTooltip: {
    id: 'Uploading.AudioFileTooltip'
  }
});
