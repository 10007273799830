import {ADD_WIDGET, DELETE_WIDGET, MOVE_WIDGET_DOWN, MOVE_WIDGET_UP} from '../actionTypes/xwidgets';
import {type XWidgetProperties} from '../widgets/interface';
import {type AddWidgetAction, type XEditorWidgetsAction} from './interface';

export function addWidget(widget: XWidgetProperties): AddWidgetAction {
  return {
    type: ADD_WIDGET,
    xwidget: widget
  };
}

export function deleteWidget(xwidgetId: string): XEditorWidgetsAction {
  return {
    type: DELETE_WIDGET,
    xwidgetId
  };
}

export function moveWidgetUp(xwidgetId: string): XEditorWidgetsAction {
  return {
    type: MOVE_WIDGET_UP,
    xwidgetId
  };
}

export function moveWidgetDown(xwidgetId: string): XEditorWidgetsAction {
  return {
    type: MOVE_WIDGET_DOWN,
    xwidgetId
  };
}
