import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {type WidgetFormattedTextJSON} from 'store/exercise/player/widgets/FormattedText/interface';
import {valueJSONFromText} from 'components/Slate/utils/value';
import genKey from 'components/Slate/utils/genKey';
import XFormattedTextRecord from 'store/exercise/editor/widgets/XFormattedText/XFormattedTextRecord';

import {type XWizardWidgetData} from '../interface';
import Tile from '../Tile';
import {FormattedTextSVG} from '../assets';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.FormattedText}>
    <FormattedTextSVG />
  </Tile>
);

export const formattedTextWizardData: XWizardWidgetData<
  WidgetFormattedTextJSON,
  XFormattedTextRecord
> = {
  type: WidgetType.FORMATTED_TEXT,
  steps: [],
  tile,
  createWidget: (widget: Pick<WidgetFormattedTextJSON, 'type'>) =>
    new XFormattedTextRecord({
      id: genKey(),
      type: widget.type,
      task: valueJSONFromText(''),
      content: valueJSONFromText('')
    })
};
