import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';
import React, {type FC} from 'react';
import ModalFooter from 'react-bootstrap/lib/ModalFooter';

interface Props {
  showDeleteButton: boolean;
  submitDisabled: boolean;
  close(): void;
  deleteGap(): void;
}

export const FormFooter: FC<Props> = ({showDeleteButton, submitDisabled, close, deleteGap}) => (
  <ModalFooter>
    {showDeleteButton && (
      <Button bsStyle="danger" bsSize="sm" onClick={deleteGap}>
        <FormattedMessage id="Common.Delete" />
      </Button>
    )}
    <div className="actions">
      <Button bsStyle="default" bsSize="sm" onClick={close}>
        <FormattedMessage id="Common.Cancel" />
      </Button>
      <Button bsStyle="primary" bsSize="sm" type="submit" disabled={submitDisabled}>
        <FormattedMessage id="Common.Save" />
      </Button>
    </div>
  </ModalFooter>
);
