import React from 'react';
import Button from 'react-bootstrap/lib/Button';

import Icon from 'components/Icon';
import {type ExerciseCategory} from 'store/interface';

interface Props {
  categories: ExerciseCategory[];
  deselectCategory: (id: number) => void;
}

class SelectedCategories extends React.Component<Props> {
  public render() {
    const {categories} = this.props;
    return (
      <div className="selected-categories">
        {categories.map(c => (
          <div key={c.id} className="selected-category">
            <span title={c.title}>{c.title}</span>
            <Button className="btn-transparent" onClick={this.onClick(c.id)}>
              <Icon name="pc-multiply" />
            </Button>
          </div>
        ))}
      </div>
    );
  }

  private onClick = (id: number) => () => this.props.deselectCategory(id);
}

export default SelectedCategories;
