import {type WidgetJSON} from 'store/exercise/player/interface';
import {genKey} from 'components/Slate/utils';

import xwidgetFactory from './xwidgetFactory';

export function cloneWidgetJSON(widget: WidgetJSON): WidgetJSON {
  return {
    ...xwidgetFactory(widget).toJSON({
      preserveAnswers: true,
      generateIdentifiers: true
    }),
    id: genKey()
  };
}
