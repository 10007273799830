import {type Block, type Document} from '@englex/slate';

export const wordCount = (document: Document): number => {
  return document.getBlocks().reduce(
    (result: number, block: Block) =>
      result +
      block.text
        .trim()
        .split(/\s+/)
        .filter(t => /[a-zA-Z0-9А-Яа-я]+/.test(t)).length,
    0
  );
};

export const charCount = (document: Document): number => {
  return document
    .getBlocks()
    .reduce((result: number, block: Block) => result + block.text.length, 0);
};
