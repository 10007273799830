import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {type NoteJSON} from 'store/exercise/player/widgets/Note/interface';
import {valueJSONFromText} from 'components/Slate/utils/value';
import genKey from 'components/Slate/utils/genKey';
import XNoteRecord from 'store/exercise/editor/widgets/XNote/XNoteRecord';

import {type XWizardWidgetData} from '../interface';
import NoteTypeSelector from './NoteTypeSelector';
import Tile from '../Tile';
import {NoteSVG} from '../assets';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.Note}>
    <NoteSVG />
  </Tile>
);

export const noteWizardData: XWizardWidgetData<NoteJSON, XNoteRecord> = {
  type: WidgetType.NOTE,
  steps: [
    {
      canGoToNextStep: (widget: Partial<NoteJSON>) => !!widget.noteType,
      component: NoteTypeSelector
    }
  ],
  tile,
  createWidget: (widget: Partial<NoteJSON>) =>
    new XNoteRecord({
      id: genKey(),
      type: widget.type!,
      noteType: widget.noteType!,
      task: valueJSONFromText(''),
      content: valueJSONFromText('')
    })
};
