import React, {memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';

import {
  changeCardSizeType,
  changeImage,
  removeCard
} from 'store/exercise/editor/widgets/XWordPictureSet/actions';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';
import {type DndSortingWrapperProps} from 'components/DndSortingWrapper/DndSortingWrapper';

import ImageCard from '../../../components/ImageCard/ImageCard';

import './PictureSetCard.scss';

export interface XPictureSetCardProps extends DndSortingWrapperProps {
  widgetId: string;
  cardId: string;
  image: number;
  cardSizeType?: CardSizeType;
  resetErrors: () => void;
  setError: (e: string | React.ReactElement) => string | React.ReactElement;
  updateRecoveryPoint: () => void;
}

export const XPictureSetCard: React.FC<XPictureSetCardProps> = memo(
  ({
    cardId,
    image,
    widgetId,
    cardSizeType,
    handle,
    isDragLayer,
    isDragging,
    style,
    resetErrors,
    setError,
    updateRecoveryPoint
  }) => {
    const dispatch = useDispatch();

    const onChange = useCallback(
      (image: number) => {
        dispatch(changeImage(widgetId, cardId, image));
        resetErrors();
      },
      [dispatch, widgetId, cardId, resetErrors]
    );

    const onDelete = useCallback(() => {
      dispatch(removeCard(widgetId, cardId));
      resetErrors();
      updateRecoveryPoint();
    }, [cardId, dispatch, resetErrors, updateRecoveryPoint, widgetId]);

    return (
      <div
        className={classNames('picture-set__card', {
          'is-rectangle': cardSizeType === CardSizeType.RECTANGLE,
          'is-dragging': isDragging && !isDragLayer,
          'is-drg-layer': isDragLayer
        })}
        style={style}
      >
        <ImageCard
          id={cardId}
          xwidgetId={widgetId}
          imageId={image}
          handle={handle}
          isDragLayer={isDragLayer}
          reportError={setError}
          resetErrors={resetErrors}
          deletePair={onDelete}
          setImage={onChange}
          changeCardSizeTypeAction={changeCardSizeType}
        />
      </div>
    );
  }
);
