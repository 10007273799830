import React, {type FC, memo} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';

import Icon from 'components/Icon';
import {MessageCard} from 'components/XPlayer/widgets/Dialogue/components/MessageCard/MessageCard';
import {valueFromText} from 'components/Slate/utils';
import {changeColorSchema} from 'store/exercise/editor/widgets/XDialogue/actions';

import {stylesList} from './styles';

import './StyleModal.scss';

interface Props {
  opened: boolean;
  widgetId: string;
  styleName: string;
  onClose: () => void;
  onSelect: () => void;
}

export const StyleModal: FC<Props> = memo(({opened, onClose, onSelect, widgetId, styleName}) => {
  const dispatch = useDispatch();

  return (
    <Modal backdrop="static" className="style-modal" onHide={onClose} show={opened}>
      <Modal.Header className="header">
        <FormattedMessage id="XEditorXWidget.SelectWidgetStyle" />
        <Button className="btn-xs" onClick={onClose}>
          <Icon name="pc-close i-lg" />
        </Button>
      </Modal.Header>
      <Modal.Body className="body">
        <div className="style-cards">
          {Object.keys(stylesList).map((mainColor: string) => {
            return (
              <div className={classNames('style-cards__raw', mainColor)} key={mainColor}>
                {stylesList[mainColor].map((currentColor: string) => {
                  const onChange = () => {
                    dispatch(changeColorSchema(widgetId, currentColor));
                  };
                  return (
                    <div key={currentColor} className={`style-card ${currentColor}`}>
                      <label className="style-card__label">
                        <input
                          className="style-card__radio"
                          type="radio"
                          name="styleName"
                          value={currentColor}
                          defaultChecked={styleName === currentColor}
                          onChange={onChange}
                        />
                        <span className="style-card__radio-border">
                          <span className="styled-card__radio-circle" />
                        </span>
                        <MessageCard
                          cardId={currentColor}
                          styleName={currentColor}
                          name={valueFromText('Albert Flores')}
                          message={valueFromText('Some text')}
                          preview={true}
                        />
                      </label>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="footer">
        <div className="modal-controls">
          <Button bsSize="small" onClick={onClose}>
            <FormattedMessage id="MediaDevicesWizard.ButtonClose" />
          </Button>

          <Button bsSize="small" bsStyle="primary" onClick={onSelect}>
            <FormattedMessage id="Common.Save" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
});
