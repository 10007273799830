import React from 'react';
import {type Editor, type Value} from '@englex/slate';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {type Plugin} from '@englex/slate-react';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import Checkbox from 'react-bootstrap/lib/Checkbox';

import {GapFillType} from 'components/Slate/interface';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import gapPluginFactory from 'components/Slate/SlateEditor/plugins/widget/GapFill/gapPluginFactory';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Image from 'components/Slate/SlateEditor/plugins/button/Image';
import SimpleLists from 'components/Slate/SlateEditor/plugins/button/List/SimpleLists';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import ImageButton from 'components/Slate/SlateEditor/plugins/button/Image/ImageButton';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import AddDialogBtn from 'components/Slate/SlateEditor/plugins/button/Table/Dialog/AddDialogBtn';
import TextAlignment from 'components/Slate/SlateEditor/plugins/button/TextAlignment';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import FontSizeToolbar from 'components/Slate/SlateEditor/plugins/button/FontSize';
import {type AppState} from 'store/interface';
import {type ChoicesMap} from 'store/exercise/player/interface';
import Icon from 'components/Icon';
import {
  addExtraChoice,
  deleteExtraChoice,
  formattedTextContentChange,
  setExtraChoiceValue,
  togglePreFillValues
} from 'store/exercise/editor/xwidgetActions';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type XGapFillProperties} from 'store/exercise/editor/widgets/XGapFill/interface';

import {formattedTextMessages} from './messages';
import './XEditorGapFill.scss';
import {ExtraChoices} from './ExtraChoices';

interface StateProps {
  gapType: GapFillType;
  extraChoices?: ChoicesMap;
  content: Value;
  hasPreFillValues?: boolean;
}

interface DispatchProps {
  addExtraChoice(widgetId: string): void;
  togglePreFillValues(widgetId: string): void;
  deleteExtraChoice(xwidgetId: string, extraChoiceId: string): void;
  onContentChange(widgetId: string, change: Editor): void;
  setExtraChoiceValue(widgetId: string, extraChoiceId: string, value: string): void;
}

interface OwnProps {
  id: string;
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps;

class XEditorGapFill extends React.Component<Props> {
  private readonly plugins: Plugin[];

  constructor(props: Props) {
    super(props);

    this.plugins = [
      gapPluginFactory(props.gapType),
      new Undo(),
      new Redo(),
      new Bold(),
      new Italic(),
      new Underline(),
      new StrikeThrough(),
      new TextAlignment(),
      new FontSizeToolbar(),
      new FontColorToolbar(),
      new HighlightToolbar(),
      new SimpleLists(),
      new AddDialogBtn(),
      new CharSelector(),
      new Image(),
      new ImageButton(),
      new FormatPainter(),
      new ClearFormatting()
    ];
  }

  public render() {
    const {
      content,
      extraChoices,
      deleteExtraChoice,
      gapType,
      setExtraChoiceValue,
      intl: {formatMessage},
      id,
      hasPreFillValues
    } = this.props;
    return (
      <div className="content">
        <SlateEditor
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          value={content}
          onChange={this.onContentChange}
          placeholder={formatMessage(formattedTextMessages.placeholder)}
          plugins={this.plugins}
        />
        {extraChoices && (
          <ExtraChoices
            deleteExtraChoice={deleteExtraChoice}
            extraChoices={extraChoices}
            setExtraChoiceValue={setExtraChoiceValue}
            xwidgetId={id}
          />
        )}
        {gapType === GapFillType.DND && (
          <div className="gapfill-dnd-actions">
            <Checkbox
              className="alignment"
              name={'alignmentGroup'}
              checked={hasPreFillValues}
              onChange={this.onPreFillCheckbox}
            >
              <FormattedMessage id="XEditor.Wizard.ImageMatching.MakeTwoThirdAnswersIncorrect" />
            </Checkbox>

            <Button className="add-extra-answer" bsSize="sm" onClick={this.addExtraChoice}>
              <Icon name="plus-circle" />
              <FormattedMessage id="XEditor.AddExtraAnswer" />
            </Button>
          </div>
        )}
      </div>
    );
  }

  private onPreFillCheckbox = () => {
    this.props.togglePreFillValues(this.props.id);
  };

  private addExtraChoice = () => this.props.addExtraChoice(this.props.id);

  private onContentChange = (change: Editor) => {
    this.props.onContentChange(this.props.id, change);
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps
) => {
  const xwidget = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === ownProps.id
  ) as XGapFillProperties;

  const {content, extraChoices, gap, hasPreFillValues} = xwidget;
  return {
    content,
    extraChoices,
    gapType: gap,
    hasPreFillValues
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {task: Value}> = {
  addExtraChoice,
  deleteExtraChoice,
  onContentChange: formattedTextContentChange,
  setExtraChoiceValue,
  togglePreFillValues
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(XEditorGapFill));
