import React from 'react';
import classNames from 'classnames';
import Panel from 'react-bootstrap/lib/Panel';
import {type Action, type Dispatch} from 'redux';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import {type Editor} from '@englex/slate';

import {type WidgetJSON, WidgetType} from 'store/exercise/player/interface';
import {type AppState} from 'store/interface';
import {deleteWidget, moveWidgetDown, moveWidgetUp} from 'store/exercise/editor/actions/xwidgets';
import {copyWidget, setWidgetError} from 'store/exercise/editor/actions/xeditor';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {xwidgetTaskChange} from 'store/exercise/editor/xwidgetActions';

import XEditorWidgetHeader from '../XEditorWidgetHeader';
import ValidationError from '../../ValidationError';
import XWidgetTask from './XWidgetTask';
import xeditorComponentFactory from './xeditorComponentFactory';

interface OwnProps extends WrappedComponentProps {
  id: string;
  isLastWidget?: boolean;
  isFirstWidget?: boolean;
}

interface StateProps {
  widget: XWidgetProperties;
  validationError?: string;
}

interface DispatchProps {
  onTaskChange: (widgetId: string, change: Editor) => void;
  deleteWidget: (widgetId: string) => void;
  copyWidget: (json: WidgetJSON) => void;
  moveWidgetUp: (widgetId: string) => void;
  moveWidgetDown: (widgetId: string) => void;
  setError: (widgetId: string, message: string, meta?: Record<string, unknown>) => void;
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps;

interface State {
  widgetExpanded: boolean;
  hintExpanded: boolean;
}

class XEditorWidget extends React.PureComponent<Props, State> {
  public state: State = {hintExpanded: false, widgetExpanded: true};

  private get bodyClasses() {
    return classNames('widget-body', this.props.widget.customBodyClass, {
      'widget-has-error': this.props.validationError,
      expanded: this.state.widgetExpanded
    });
  }

  public render() {
    const {id, widget, validationError, isFirstWidget, isLastWidget} = this.props;
    const {hintExpanded, widgetExpanded} = this.state;
    const XWidget = xeditorComponentFactory(widget.type);
    return (
      <div className={classNames('xeditor-widget', widget.type)}>
        <div className={this.bodyClasses}>
          {widget.type !== WidgetType.UNKNOWN && (
            <XEditorWidgetHeader
              widgetExpanded={widgetExpanded}
              hintExpanded={hintExpanded}
              toggleHint={this.toggleHint}
              toggleWidget={this.toggleWidget}
              copyWidget={this.copyWidget}
              deleteWidget={this.deleteWidget}
              disableDownButton={isLastWidget}
              disableUpButton={isFirstWidget}
              moveDown={this.moveWidgetDown}
              moveUp={this.moveWidgetUp}
              title={widget.title}
              titleIconName={widget.titleIconName}
            />
          )}
          <Panel
            expanded={widgetExpanded}
            onToggle={() => ({})}
            defaultExpanded={true}
            className="xwidget-panel"
          >
            <Panel.Collapse>
              <Panel.Body>
                {widget.type !== WidgetType.UNKNOWN && (
                  <XWidgetTask
                    value={widget.task}
                    onChange={this.onTaskChange}
                    placeholder={widget.taskPlaceholder}
                    widgetType={widget.type}
                  />
                )}
                {XWidget && <XWidget id={id} setError={this.setWidgetError} />}
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
        <ValidationError message={validationError} />
      </div>
    );
  }

  private toggleWidget = () =>
    this.state.widgetExpanded
      ? this.setState({widgetExpanded: false, hintExpanded: false})
      : this.setState({widgetExpanded: true});

  private deleteWidget = () => {
    return this.props.deleteWidget(this.props.id);
  };

  private setWidgetError = (message: string, meta?: Record<string, unknown>) => {
    return this.props.setError(this.props.id, message, meta);
  };

  private copyWidget = () => {
    return this.props.copyWidget(this.props.widget.toJSON({preserveAnswers: true}));
  };

  private moveWidgetUp = () => {
    return this.props.moveWidgetUp(this.props.id);
  };

  private moveWidgetDown = () => {
    return this.props.moveWidgetDown(this.props.id);
  };

  private onTaskChange = (change: Editor) => {
    this.props.onTaskChange(this.props.id, change);
  };

  private toggleHint = () =>
    this.state.hintExpanded
      ? this.setState({hintExpanded: false})
      : this.setState({hintExpanded: true, widgetExpanded: true});
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps
): StateProps => {
  const xwidget: XWidgetProperties = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === ownProps.id
  );

  const {errors} = state.xeditor!;
  const error = errors.getIn(['widgets', ownProps.id]);

  return {
    validationError: error?.message,
    widget: xwidget
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch<Action>
) => {
  return {
    onTaskChange: (widgetId: string, change: Editor) =>
      dispatch(xwidgetTaskChange(widgetId, change)),
    deleteWidget: (widgetId: string) => dispatch(deleteWidget(widgetId)),
    copyWidget: (json: WidgetJSON) => dispatch(copyWidget(json)),
    moveWidgetUp: (widgetId: string) => dispatch(moveWidgetUp(widgetId)),
    moveWidgetDown: (widgetId: string) => dispatch(moveWidgetDown(widgetId)),
    setError: (widgetId: string, message: string, meta) =>
      dispatch(setWidgetError(widgetId, message, meta))
  };
};

export default injectIntl(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(XEditorWidget)
);
