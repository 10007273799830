import {type Editor, type Value} from '@englex/slate';

import {
  CONTENT_CHANGE,
  QUESTIONS_CHANGE,
  TASK_CHANGE,
  TOGGLE_FIRST_QUESTION_ITEM_EXAMPLE,
  TOGGLE_CONTAINS_EXAMPLE,
  SCRAMBLED_SENTENCES_CHANGE,
  QUESTIONS_EXAMPLE_ANSWER_CHANGE,
  ADD_EXTRA_CHOICE,
  SET_EXTRA_CHOICE_VALUE,
  DELETE_EXTRA_CHOICE,
  GRAMMAR_TITLE_CHANGE,
  TOGGLE_DISPLAY_AS_BUTTON,
  DISPLAY_BUTTON_TITLE_CHANGE,
  TOGGLE_PRE_FILL_VALUES,
  DISPLAY_BUTTON_ICON_CHANGE
} from './actionTypes';
import {type XWidgetAction} from './actions/interface';

export interface XWidgetChangeAction<T = Editor> extends XWidgetAction {
  change: T;
}

export const xwidgetTaskChange = (xwidgetId: string, change: Editor): XWidgetChangeAction => {
  return {
    type: TASK_CHANGE,
    xwidgetId,
    change
  };
};

export const formattedTextContentChange = (
  xwidgetId: string,
  change: Editor
): XWidgetChangeAction => {
  return {
    type: CONTENT_CHANGE,
    xwidgetId,
    change
  };
};

export const toggleWidgetAsButton = (xwidgetId: string) => {
  return {
    type: TOGGLE_DISPLAY_AS_BUTTON,
    xwidgetId
  };
};

export const displayButtonTitleChange = (
  xwidgetId: string,
  change: Editor
): XWidgetChangeAction => {
  return {
    type: DISPLAY_BUTTON_TITLE_CHANGE,
    xwidgetId,
    change
  };
};

export interface SetDisplayButtonIconAction extends XWidgetAction {
  icon: string;
}

export const displayButtonIconChange = (
  xwidgetId: string,
  icon: string
): SetDisplayButtonIconAction => {
  return {
    type: DISPLAY_BUTTON_ICON_CHANGE,
    xwidgetId,
    icon
  };
};

export const grammarTitleChange = (
  xwidgetId: string,
  change: Value
): XWidgetChangeAction<Value> => {
  return {
    type: GRAMMAR_TITLE_CHANGE,
    xwidgetId,
    change
  };
};

export const questionsChange = (xwidgetId: string, change: Editor): XWidgetChangeAction => ({
  type: QUESTIONS_CHANGE,
  xwidgetId,
  change
});

export const questionsExampleAnswerChange = (
  xwidgetId: string,
  change: Editor
): XWidgetChangeAction => ({
  type: QUESTIONS_EXAMPLE_ANSWER_CHANGE,
  xwidgetId,
  change
});

export const scrambledSentencesChange = (
  xwidgetId: string,
  change: Editor
): XWidgetChangeAction => ({
  type: SCRAMBLED_SENTENCES_CHANGE,
  xwidgetId,
  change
});

export interface ToggleFirstQuestionItemExampleAction extends XWidgetAction {
  example: boolean;
}

export function toggleFirstQuestionItemExample(
  xwidgetId: string,
  example: boolean
): ToggleFirstQuestionItemExampleAction {
  return {
    type: TOGGLE_FIRST_QUESTION_ITEM_EXAMPLE,
    xwidgetId,
    example
  };
}

export const toggleContainsExample = (xwidgetId: string): XWidgetAction => ({
  type: TOGGLE_CONTAINS_EXAMPLE,
  xwidgetId
});

export const addExtraChoice = (xwidgetId: string): XWidgetAction => ({
  type: ADD_EXTRA_CHOICE,
  xwidgetId
});

export const togglePreFillValues = (xwidgetId: string): XWidgetAction => ({
  type: TOGGLE_PRE_FILL_VALUES,
  xwidgetId
});

export interface SetExtraChoiceValueAction extends XWidgetAction {
  extraChoiceId: string;
  value: string;
}
export const setExtraChoiceValue = (
  xwidgetId: string,
  extraChoiceId: string,
  value: string
): SetExtraChoiceValueAction => ({
  type: SET_EXTRA_CHOICE_VALUE,
  xwidgetId,
  extraChoiceId,
  value
});

export interface DeleteExtraChoiceAction extends XWidgetAction {
  extraChoiceId: string;
}
export const deleteExtraChoice = (
  xwidgetId: string,
  extraChoiceId: string
): DeleteExtraChoiceAction => ({
  type: DELETE_EXTRA_CHOICE,
  xwidgetId,
  extraChoiceId
});
