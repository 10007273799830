import React, {type FC} from 'react';
import {FormattedMessage} from 'react-intl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';

import {type XScrambledSentencesWizardOptions} from 'store/exercise/player/widgets/ScrambledSentences/interface';

import {type XWizardStepProps} from '../interface';

export const ManualSplitting: FC<XWizardStepProps<XScrambledSentencesWizardOptions>> = ({
  addField,
  fields
}) => (
  <form className="gap-type-selector">
    <p>
      <b>
        <FormattedMessage id="XEditor.Wizard.ScrambledSentences.Header" />
      </b>
    </p>
    <FormattedMessage id="XEditor.Wizard.ScrambledSentences.Text" />
    <FormGroup>
      <div className="group">
        <Radio
          name="manual-splitting"
          onChange={() => addField('manualSplitting', false)}
          checked={!fields.manualSplitting}
        >
          <FormattedMessage id="XEditor.Wizard.ScrambledSentences.Auto" />
        </Radio>
      </div>
      <div className="group">
        <Radio
          name="manual-splitting"
          onChange={() => addField('manualSplitting', true)}
          checked={fields.manualSplitting === true}
        >
          <FormattedMessage id="XEditor.Wizard.ScrambledSentences.Manual" />
        </Radio>
      </div>
    </FormGroup>
  </form>
);
