import React, {type FC, useRef, useState} from 'react';
import classNames from 'classnames';
import {type DropTargetMonitor, useDrop} from 'react-dnd';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {NativeTypes} from 'react-dnd-html5-backend';
import {unstable_batchedUpdates} from 'react-dom';

import Icon from 'components/Icon';
import {type AudioFile} from 'components/media/interface';
import AudioWidgetPlayer from 'components/media/AudioPlayer/containers/Exercise/AudioWidgetPlayer';
import Spinner from 'components/Spinner';
import {md5Chunk} from 'services/common-methods';
import {type AxiosResponseAction} from 'services/axios/interface';
import {maxSoundSize} from 'config/static';

import {validate} from './validate';
import {type AudioResponse, UploadingStatus, UploadingValidationError} from './interface';
import ErrorComponent from './Error';
import Uploader from './Uploader/Uploader';

const {MULTIPLE_FILES} = UploadingValidationError;
const {PREPARING, UPLOADING, UPLOADING_ERROR} = UploadingStatus;

interface Props {
  attachAudio: (audioFile: AudioFile) => void;
  clearAudio: () => void;
  audioFile?: AudioFile;
  audioId?: number;
  widgetId?: string;
  maxFileSize?: number;
}

const AudioInput: FC<Props> = ({
  audioFile,
  audioId,
  attachAudio,
  widgetId,
  clearAudio,
  maxFileSize = maxSoundSize
}) => {
  const [file, setFile] = useState<File | undefined>();
  const [md5, setMd5] = useState<string | undefined>();
  const [readingFile, setReadingFile] = useState(false);
  const [status, setStatus] = useState<UploadingStatus | UploadingValidationError | undefined>();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [{isOver}, collectDropTarget] = useDrop({
    accept: NativeTypes.FILE,
    drop: ({files}: {files: File[]}) => {
      handleFile(files);
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver()
    })
  });

  const showPlayer = !!audioId && !status && !isOver && !readingFile;
  const style = showPlayer ? undefined : {display: 'none'};

  const hasError = Boolean(
    status && (status in UploadingValidationError || status === UPLOADING_ERROR)
  );

  const className = classNames('audio-input', {
    'is-over': isOver,
    'has-error': hasError,
    'with-audio': showPlayer
  });

  const reset = () => {
    if (inputRef.current?.files && inputRef.current.value) {
      inputRef.current.value = '';
    }
    unstable_batchedUpdates(() => {
      setFile(undefined);
      setMd5(undefined);
      setStatus(undefined);
    });
  };

  const handleFile = async (files: File[] | FileList) => {
    const file = files[0];
    reset();
    try {
      if (files.length > 1) {
        throw new Error(MULTIPLE_FILES);
      }
      const md5 = await md5ChunkFunc(file);
      const error = validate(file, maxFileSize);
      if (!error) {
        unstable_batchedUpdates(() => {
          setFile(file);
          setMd5(md5);
          setStatus(PREPARING);
        });
      }
    } catch (e) {
      setStatus(e.message);
    } finally {
      setReadingFile(false);
    }
  };

  const handleInput = () => inputRef.current?.files && handleFile(inputRef.current.files);

  const handleResponse = (response: AxiosResponseAction<AudioResponse>) => {
    const {
      data: {id, length, url, md5: newMd5}
    } = response.payload;
    if (!file || newMd5 !== md5) {
      // handle another file being uploaded before uploading of this file was finished]
      if (!file) {
        setStatus(undefined);
      } else {
        setStatus(UploadingStatus.UPLOADING);
      }
      return;
    }
    attachAudio({id, title: file!.name, url, length});
    reset();
  };

  const md5ChunkFunc = async (file: File) => {
    setReadingFile(true);

    return await md5Chunk(file);
  };

  const selectFile = () => inputRef.current?.click();

  return collectDropTarget(
    <div className={className}>
      {!status ? (
        audioId ? (
          <AudioWidgetPlayer
            audioFile={audioFile}
            parentContainerClass={'xeditor'}
            style={style}
            audioId={audioId}
            widgetId={widgetId}
            setLoadedFile={attachAudio}
            dark={true}
            withTogetherButton={false}
          >
            <div className="button-group" style={style}>
              <Button onClick={selectFile}>
                <Icon name="virc-replace" />
              </Button>
              <Button onClick={clearAudio}>
                <Icon name="trash" />
              </Button>
            </div>
            {isOver && (
              <div className="no-file-dropped-component">
                <Icon name="virc-upload" />
                <FormattedMessage id="Common.Upload" />
              </div>
            )}
          </AudioWidgetPlayer>
        ) : (
          <div className="no-file-dropped-component" onClick={selectFile}>
            {readingFile ? <Spinner size={20} /> : <Icon name="virc-upload" />}
            {isOver ? (
              <FormattedMessage id="Common.Upload" />
            ) : (
              <FormattedMessage id="XEditorWidget.Audio.InputPlaceholder" />
            )}
          </div>
        )
      ) : status === PREPARING || status === UPLOADING ? (
        <Uploader
          file={file!}
          handleResponse={handleResponse}
          md5={md5!}
          setStatus={(s: UploadingStatus) => setStatus(s)}
          status={status}
        />
      ) : (
        <ErrorComponent error={status} onClick={reset} maxFileSize={maxFileSize} />
      )}

      <input type="file" ref={inputRef} onChange={handleInput} accept={'.mp3'} multiple={false} />
    </div>
  );
};

export default AudioInput;
