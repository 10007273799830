import React from 'react';
import {FormattedMessage} from 'react-intl';

import Counter from 'components/Counter';
import {type XQuizWizardOptions} from 'store/exercise/player/widgets/Quiz/interface';
import XQuizRecord from 'store/exercise/editor/widgets/XQuiz/XQuizRecord';

import {type XWizardStepProps} from '../interface';

import './QuizWizzard.scss';

type Props = XWizardStepProps<XQuizWizardOptions>;

class QuizWizard extends React.Component<Props> {
  componentDidMount() {
    this.props.addField('answersNumber', XQuizRecord.Answers.Default);
    this.props.addField('questionsNumber', XQuizRecord.Questions.Default);
  }

  public render() {
    const {addField} = this.props;
    const {answersNumber, questionsNumber} = this.props.fields;

    return (
      <form className="quiz-wizard">
        <div className="counters">
          <div>
            <p>
              <b>
                <FormattedMessage id="XEditor.Wizard.MultipleChoice.QuestionsNumberHeader" />
              </b>
            </p>
            <Counter
              value={questionsNumber || XQuizRecord.Questions.Default}
              onChange={newValue => addField('questionsNumber', newValue)}
              minValue={XQuizRecord.Questions.Min}
              maxValue={XQuizRecord.Questions.Max}
            />
          </div>

          <div>
            <p>
              <b>
                <FormattedMessage id="XEditor.Wizard.MultipleChoice.AnswersNumberHeader" />
              </b>
            </p>
            <Counter
              value={answersNumber || XQuizRecord.Answers.Default}
              onChange={newValue => addField('answersNumber', newValue)}
              minValue={XQuizRecord.Answers.Min}
              maxValue={XQuizRecord.Answers.Max}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default QuizWizard;
