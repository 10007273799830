import {type Editor} from '@englex/slate';
import {type List} from 'immutable';

import {type WordPictureSetBaseCardId} from 'store/exercise/player/widgets/WordPictureSet/baseInterface';

import {
  WORD_PICTURE_SET_ADD_CARD,
  WORD_PICTURE_SET_CHANGE_CARD_SIZE_TYPE,
  WORD_PICTURE_SET_CHANGE_IMAGE,
  WORD_PICTURE_SET_CHANGE_TEXT,
  WORD_PICTURE_SET_CHANGE_VISIBILITY,
  WORD_PICTURE_SET_MOVE_CARD,
  WORD_PICTURE_SET_CHANGE_WIDGET_THEME,
  WORD_PICTURE_SET_REMOVE_CARD,
  WORD_PICTURE_SET_ROLL_BACK_CARS,
  WORD_PICTURE_SET_SWITCH_INTERACTIVE
} from './actionType';
import {type CardSizeType} from './XPictureSet/interface';
import {type WPSTheme} from './baseInterface';
import {type XWidgetAction} from '../../actions/interface';

export interface WordPictureSetCardAction extends XWidgetAction {
  cardId: string;
}

export interface WordPictureSetChangeCardTextAction extends WordPictureSetCardAction {
  editor: Editor;
}

export interface WordPictureSetChangeCardImageAction extends WordPictureSetCardAction {
  image: number;
}

export interface WordPictureSetChangeCardSizeTypeAction extends WordPictureSetCardAction {
  cardSizeType: CardSizeType;
}

export interface WordPictureSetMoveCard extends XWidgetAction {
  moveCardIndex: number;
  targetIndex: number;
}

export interface WordPictureSetRollBackCards extends XWidgetAction {
  cardIds: List<WordPictureSetBaseCardId>;
}

export interface WordPictureSetChangeWidgetThemeAction extends XWidgetAction {
  widgetTheme: WPSTheme;
}

export const addCard = (xwidgetId: string): XWidgetAction => ({
  type: WORD_PICTURE_SET_ADD_CARD,
  xwidgetId
});

export const removeCard = (xwidgetId: string, cardId: string): WordPictureSetCardAction => ({
  type: WORD_PICTURE_SET_REMOVE_CARD,
  xwidgetId,
  cardId
});

export const changeDefaultVisibility = (xwidgetId: string): XWidgetAction => ({
  type: WORD_PICTURE_SET_CHANGE_VISIBILITY,
  xwidgetId
});

export const switchIsNotInteractive = (xwidgetId: string): XWidgetAction => ({
  type: WORD_PICTURE_SET_SWITCH_INTERACTIVE,
  xwidgetId
});

export const changeText = (
  xwidgetId: string,
  cardId: string,
  editor: Editor
): WordPictureSetChangeCardTextAction => ({
  type: WORD_PICTURE_SET_CHANGE_TEXT,
  xwidgetId,
  cardId,
  editor
});

export const changeImage = (
  xwidgetId: string,
  cardId: string,
  image: number
): WordPictureSetChangeCardImageAction => ({
  type: WORD_PICTURE_SET_CHANGE_IMAGE,
  xwidgetId,
  cardId,
  image
});

export const changeCardSizeType = (
  xwidgetId: string,
  cardId: string,
  cardSizeType: CardSizeType
): WordPictureSetChangeCardSizeTypeAction => ({
  type: WORD_PICTURE_SET_CHANGE_CARD_SIZE_TYPE,
  xwidgetId,
  cardId,
  cardSizeType
});

export const moveCard = (
  xwidgetId: string,
  moveCardIndex: number,
  targetIndex: number
): WordPictureSetMoveCard => ({
  type: WORD_PICTURE_SET_MOVE_CARD,
  xwidgetId,
  moveCardIndex,
  targetIndex
});

export const rollBackCards = (
  xwidgetId: string,
  cardIds: List<WordPictureSetBaseCardId>
): WordPictureSetRollBackCards => ({
  type: WORD_PICTURE_SET_ROLL_BACK_CARS,
  xwidgetId,
  cardIds
});

export const changeWidgetTheme = (
  xwidgetId: string,
  widgetTheme: WPSTheme
): WordPictureSetChangeWidgetThemeAction => ({
  type: WORD_PICTURE_SET_CHANGE_WIDGET_THEME,
  xwidgetId,
  widgetTheme
});
