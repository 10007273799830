import React, {Component} from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {injectIntl} from 'react-intl';
import {type CompleteCrop} from 'react-image-crop';

import {md5Chunk} from 'services/common-methods';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';
import {validateImageFile} from 'common/ImageUpload/functions';
import {
  UploadingPictureStatus,
  UploadingPictureValidationError
} from 'common/ImageUpload/interface';
import ImageCropUploader from 'common/ImageUpload/ImageCropUploader';
import PictureUploadingStatus from 'common/ImageUpload/PictureUploadingStatus';
import {type EnglexImage} from 'store/interface';
import Icon from 'components/Icon';

import {initialState, Phase, type Props, type State} from './interface';
import ImageModalBody from './ImageModalBody';
import ImageModalFooterBody from './ImageModalFooterBody';

class ImageModal extends Component<Props, State> {
  private static readonly minHeight = 360;
  private static readonly minWidth = 640;

  public state: State = initialState;

  private get isErrorStatus() {
    const {status} = this.state;
    return (
      !!status &&
      (status! in UploadingPictureValidationError ||
        status === UploadingPictureStatus.UPLOADING_ERROR)
    );
  }

  render() {
    const {intl, show, title} = this.props;
    const {crop, freeze, imageDataUrl, phase, shouldRetry, status, validatedFile} = this.state;
    return (
      <Modal
        backdrop="static"
        className="video-poster-input dnd-image-modal"
        show={show}
        onHide={this.hide}
      >
        <Modal.Header className="dnd-image-modal-header">
          <span>{title}</span>
          <Button className="btn-xs" onClick={this.hide}>
            <Icon name="pc-close" />
          </Button>
        </Modal.Header>
        <Modal.Body className="dnd-image-modal-body">
          <ImageModalBody
            handleFileSelected={this.handleFileSelected}
            imageDataUrl={imageDataUrl}
            intl={intl}
            minHeight={ImageModal.minHeight}
            minWidth={ImageModal.minWidth}
            phase={phase}
            storeCrop={this.storeCrop}
            storeFile={this.storeFile}
          />
        </Modal.Body>
        <Modal.Footer className="dnd-image-modal-footer">
          <div className="image-upload-controls">
            <Button bsSize="small" onClick={this.hide}>
              {intl.formatMessage({id: 'Slate.Modal.Image.CancelButton'})}
            </Button>
            <ImageModalFooterBody
              phase={phase}
              renderErrorStatus={() =>
                this.isErrorStatus ? (
                  <PictureUploadingStatus
                    isError={true}
                    status={status!}
                    minResolution={[ImageModal.minWidth, ImageModal.minHeight]}
                  />
                ) : null
              }
              renderUploader={() => (
                <ImageCropUploader
                  errorMessage={this.state.errorMessage}
                  file={validatedFile!}
                  handleError={this.handleRequestError}
                  handleResponse={this.handleResponse}
                  isErrorStatus={this.isErrorStatus}
                  crop={crop!}
                  setStatus={this.setStatus}
                  status={status}
                  shouldRetry={shouldRetry}
                  turnOffRetry={this.setRestartOff}
                />
              )}
            />
            <Button
              bsSize="small"
              bsStyle="primary"
              onClick={this.apply}
              disabled={freeze || phase === Phase.DEFAULT}
            >
              {intl.formatMessage({id: 'Common.Upload'})}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  private apply = () => {
    this.setState({
      freeze: true,
      phase: Phase.UPLOADING,
      shouldRetry: this.isErrorStatus
    });
  };

  private handleFileSelected = (imageDataUrl: string) => {
    this.validateFile()
      .then(() => {
        this.setState({
          imageDataUrl,
          status: undefined,
          phase: Phase.CROP
        });
      })
      .catch(e => {
        this.setState({status: e.message});
      });
  };

  private handleResponse = async (response: AxiosResponseAction<EnglexImage>) => {
    const success = await this.props.attachPoster(
      response.payload.data.id,
      this.handleRequestError
    );
    if (success) {
      this.setState({freeze: false}, this.hide);
    }
  };

  private handleRequestError = ({error}: AxiosRequestError) => {
    let errorMessage;
    if (error.response && [422, 400].includes(error.response.status)) {
      if (Array.isArray(error.response.data)) {
        errorMessage = error.response.data[0].message;
      } else if (error.response.data.message) {
        errorMessage = error.response.data.message;
      }
    }
    this.setState({errorMessage, freeze: false, status: UploadingPictureStatus.UPLOADING_ERROR});
  };

  private hide = () => {
    if (!this.state.freeze) {
      this.setState(initialState);
      this.props.hide();
    }
  };

  private setRestartOff = () => this.setState({shouldRetry: false});

  private setStatus = (status: UploadingPictureStatus) => this.setState({status});

  private storeCrop = (crop: CompleteCrop) => this.setState({crop});

  private storeFile = (file: File) => this.setState({file});

  private validateFile = async () => {
    const {file} = this.state;
    if (file) {
      const md5 = await md5Chunk(file);
      const status = await validateImageFile(file, md5, undefined, undefined, [
        ImageModal.minWidth,
        ImageModal.minHeight
      ]);
      if (status in UploadingPictureValidationError) {
        throw new Error(status);
      }
      this.setState({status, validatedFile: {md5, file}});
    }
  };
}

export default injectIntl(ImageModal);
