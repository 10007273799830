import React, {type FC} from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {
  type QuestsJSON,
  type XQuestsWizardOptions
} from 'store/exercise/player/widgets/Writing/interface';
import {questsInitialValue, valueJSONFromText} from 'components/Slate/utils/value';
import genKey from 'components/Slate/utils/genKey';
import XQuestsRecord from 'store/exercise/editor/widgets/XQuests/XQuestsRecord';

import {type XWizardWidgetData} from '../interface';
import QuestsOptions from './QuestsOptions';
import Tile from '../Tile';
import {QuestionsSVG} from '../assets';

const tile: FC = () => (
  <Tile title={WidgetTitle.Quests}>
    <QuestionsSVG />
  </Tile>
);

export const questsWizardData: XWizardWidgetData<QuestsJSON, XQuestsRecord> = {
  type: WidgetType.QUESTS,
  steps: [
    {
      canGoToNextStep: () => true,
      component: QuestsOptions
    }
  ],
  tile,
  createWidget: (widget: XQuestsWizardOptions) => {
    return new XQuestsRecord({
      id: genKey(),
      type: widget.type,
      task: valueJSONFromText(''),
      options: widget.questsCountWords
        ? {
            countWords: widget.questsCountWords
          }
        : undefined,
      content: questsInitialValue(),
      questsExampleContent: valueJSONFromText('')
    });
  }
};
