import React, {memo} from 'react';
import {type Editor, type Value} from '@englex/slate';
import classNames from 'classnames';

import LoadableImage from 'components/LoadableImage/LoadableImage';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Icon from 'components/Icon';

import {AvatarImage, AvatarTypes} from '../../assets/AvatarImage';

import './MessageCard.scss';

const slatePlugins = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting()
];

interface Props {
  speakerId: string;
  avatar: number;
  name: Value;
  message: Value;
  placeholder: string;
  deleteTitle: string;
  editTitle: string;
  moveUpTitle: string;
  moveDownTitle: string;
  withError: boolean;
  isFirst: boolean;
  isLast: boolean;
  onChangeMessage: (change: Editor) => void;
  onDeleteMessage: () => void;
  onChangeSpeaker: () => void;
  onMoveMessageDown: () => void;
  onMoveMessageUp: () => void;
}

export const MessageCardView: React.FC<Props> = memo(
  ({
    speakerId,
    avatar,
    name,
    message,
    placeholder,
    deleteTitle,
    editTitle,
    moveUpTitle,
    moveDownTitle,
    withError,
    isFirst,
    isLast,
    onDeleteMessage,
    onChangeSpeaker,
    onMoveMessageDown,
    onMoveMessageUp,
    onChangeMessage
  }) => (
    <div className="message-card">
      <div className="avatar">
        {avatar ? (
          <LoadableImage imageId={avatar} width="100%" height="100%" draggable={false} />
        ) : (
          <AvatarImage avatarType={AvatarTypes.DEFAULT} />
        )}
      </div>

      <div className={classNames('message-container', {'with-error': withError})}>
        <div className="control-panel">
          <span className="user-name">{name.document.text}</span>
          <div className="controls">
            <button className="btn pencil-btn" title={editTitle} onClick={onChangeSpeaker}>
              <Icon name="pencil" />
            </button>
            <button className="btn delete-btn" title={deleteTitle} onClick={onDeleteMessage}>
              <Icon name="trash" />
            </button>
            <button
              className="btn up-btn"
              title={moveUpTitle}
              disabled={isFirst}
              onClick={onMoveMessageUp}
            >
              <Icon name="pc-left-arrow" className="i-rotate-90" />
            </button>
            <button
              className="btn down-btn"
              title={moveDownTitle}
              disabled={isLast}
              onClick={onMoveMessageDown}
            >
              <Icon name="pc-right-arrow" className="i-rotate-90" />
            </button>
          </div>
        </div>
        <SlateEditor
          value={message}
          plugins={slatePlugins}
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          className={classNames('message', {isError: withError})}
          placeholder={placeholder}
          onChange={onChangeMessage}
        />
      </div>
    </div>
  )
);
