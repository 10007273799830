import React, {type FC} from 'react';
import {FormattedMessage} from 'react-intl';

import {useXEditorPageMeta} from '../../useXEditorPageMeta';

const MobileSection: FC = () => {
  const {
    pageNumber,
    exerciseId,
    exerciseOrdinal,
    parentExerciseId,
    coursebookTitle,
    unitTitle,
    supplementaryOrdinal
  } = useXEditorPageMeta();

  return (
    <>
      <section className="mobile-section">
        <span className="section-title">
          <FormattedMessage id="Coursebook.Coursebook" />:{' '}
        </span>
        {coursebookTitle}
      </section>
      <section className="mobile-section">
        <span className="section-title">
          <FormattedMessage id="Coursebook.Unit" />:{' '}
        </span>
        {unitTitle}
      </section>
      {!!pageNumber && (
        <section className="mobile-section">
          <span className="section-title">
            <FormattedMessage id="Feedback.Page" />:{' '}
          </span>
          {pageNumber}
        </section>
      )}
      <section className="mobile-section">
        {exerciseOrdinal !== -1 ? (
          <>
            <span className="section-title">
              <FormattedMessage id="Coursebook.Exercise" />:{' '}
            </span>
            {exerciseOrdinal + 1}
          </>
        ) : (
          <span className="section-title">
            <FormattedMessage id="Library.Breadcrumbs.NewExercise" />
          </span>
        )}
      </section>
      {parentExerciseId && exerciseOrdinal !== -1 && (
        <section className="mobile-section">
          <span className="section-title">
            {!exerciseId ? (
              <FormattedMessage id="Library.Breadcrumbs.AdditionalExercise.New" />
            ) : (
              <FormattedMessage
                id="Library.Breadcrumbs.AdditionalExercise"
                values={{ordinal: supplementaryOrdinal + 1}}
              />
            )}
          </span>
        </section>
      )}
    </>
  );
};

export default MobileSection;
