import React, {Component} from 'react';
import classNames from 'classnames';
import {type CompleteCrop} from 'react-image-crop';
import {FormattedMessage, type WrappedComponentProps, injectIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux';

import md5Chunk from 'services/common-methods/md5chunk';
import {
  renderStatusText,
  type ValidatedImageFile,
  validateImageFile
} from 'common/ImageUpload/functions';
import {
  UploadingPictureStatus,
  UploadingPictureValidationError
} from 'common/ImageUpload/interface';
import ImageCropUploader from 'common/ImageUpload/ImageCropUploader';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';
import {type EnglexImage} from 'store/interface';
import {AspectRatio} from 'components/Crop/static';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';
import {type XWidgetAction} from 'store/exercise/editor/actions/interface';

import {ImageInput} from '../../components/ImageInput';
import CropModal from '../../components/CropModal';
import CropControls from '../CropControls/CropControls';

import './ImageCard.scss';

const IMAGE_MIN_DIMENSION = 235;

const IMAGE_MIN_RECTANGLE_WIDTH = 485;

const RECTANGLE_MIN_SIZE = [IMAGE_MIN_RECTANGLE_WIDTH, IMAGE_MIN_DIMENSION];

const LOCKED_ASPECT_RATIOS: AspectRatio[] = [];

interface OwnProps {
  id: string;
  imageId?: number;
  xwidgetId: string;
  handle?: JSX.Element;
  isDragLayer?: boolean;
  isCenterDeleteButton?: boolean;
  reportError: (e: string | JSX.Element) => string | JSX.Element;
  resetErrors: () => void;
  deletePair: () => void;
  setImage: (imageId: number, url: string) => void;
  changeCardSizeTypeAction: (
    widgetId: string,
    cardId: string,
    cardSizeType: CardSizeType
  ) => XWidgetAction;
}

interface DispatchProps {
  changeCardSizeType: (cardSizeType: CardSizeType) => void;
}

type Props = OwnProps & WrappedComponentProps & DispatchProps;

interface State {
  errorMessage?: string;
  file: File | null;
  freezeModal?: boolean;
  imageDataUrl?: string;
  cropResult?: CompleteCrop;
  retry?: boolean;
  status?: UploadingPictureStatus | UploadingPictureValidationError;
  validatedFile?: ValidatedImageFile;
  uploadingStarted?: boolean;
  aspectRatio: AspectRatio;
  imageSize?: [number, number];
}

class ImageCard extends Component<Props, State> {
  public state: State = {
    file: null,
    imageDataUrl: undefined,
    cropResult: undefined,
    status: undefined,
    validatedFile: undefined,
    aspectRatio: AspectRatio.SQUARE,
    imageSize: undefined
  };

  private get imageBlockClasses() {
    return classNames('img-block', {'with-image': !!this.props.imageId});
  }

  private get isErrorStatus(): boolean {
    const {status} = this.state;
    return (
      !!status &&
      (status! in UploadingPictureValidationError ||
        status === UploadingPictureStatus.UPLOADING_ERROR)
    );
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.status !== this.state.status) {
      if (this.isErrorStatus) {
        this.props.reportError(renderStatusText(this.state.status!, IMAGE_MIN_DIMENSION));
      }
    }
  }

  public render() {
    const {imageBlockClasses} = this;
    const {id, xwidgetId, handle, isDragLayer, imageId, isCenterDeleteButton} = this.props;
    const {
      errorMessage,
      freezeModal,
      imageDataUrl,
      cropResult,
      retry,
      status,
      validatedFile,
      uploadingStarted,
      aspectRatio,
      imageSize
    } = this.state;

    const rectangleDisabled =
      !!imageSize && (imageSize[0] < RECTANGLE_MIN_SIZE[0] || imageSize[1] < RECTANGLE_MIN_SIZE[1]);

    return (
      <div className={imageBlockClasses}>
        {imageDataUrl ? (
          <CropModal
            aspect={aspectRatio}
            minWidth={IMAGE_MIN_DIMENSION}
            imageDataUrl={imageDataUrl}
            hide={this.hideModal}
            storeCrop={this.storeCrop}
            setImageSize={this.setImageSize}
            renderFooter={() => (
              <div className="image-upload-controls">
                <Button bsSize="small" onClick={this.hideModal}>
                  <FormattedMessage id="Slate.Modal.Image.CancelButton" />
                </Button>
                {uploadingStarted ? (
                  <ImageCropUploader
                    errorMessage={errorMessage}
                    file={validatedFile!}
                    handleError={this.handleError}
                    handleResponse={this.handleResponse}
                    isErrorStatus={this.isErrorStatus}
                    crop={cropResult!}
                    setStatus={this.setStatus}
                    shouldRetry={retry}
                    turnOffRetry={this.turnOffRetry}
                    status={status}
                    thumbnail={
                      aspectRatio === AspectRatio.RECTANGLE
                        ? [IMAGE_MIN_RECTANGLE_WIDTH, IMAGE_MIN_DIMENSION]
                        : [IMAGE_MIN_DIMENSION]
                    }
                  />
                ) : (
                  <CropControls
                    aspectRatio={this.state.aspectRatio}
                    lockedAspectRatios={LOCKED_ASPECT_RATIOS}
                    rectangleDisabled={rectangleDisabled}
                    setAspectRatio={this.setAspectRatio}
                  />
                )}
                <Button
                  bsSize="small"
                  bsStyle="primary"
                  onClick={this.startUploading}
                  disabled={freezeModal}
                >
                  <FormattedMessage id="Common.Upload" />
                </Button>
              </div>
            )}
          />
        ) : (
          <ImageInput
            id={id}
            imageId={imageId}
            widgetId={xwidgetId}
            handle={handle}
            isDragLayer={isDragLayer}
            isCenterDeleteButton={isCenterDeleteButton}
            storeFile={this.storeFile}
            deletePair={this.deletePair}
            handleFileSelected={this.handleFileSelected}
          />
        )}
      </div>
    );
  }

  private startUploading = () => {
    const {changeCardSizeType} = this.props;
    const {aspectRatio} = this.state;

    const cardSizeType =
      aspectRatio === AspectRatio.RECTANGLE ? CardSizeType.RECTANGLE : CardSizeType.SQUARE;

    changeCardSizeType(cardSizeType);

    if (this.state.status === UploadingPictureStatus.UPLOADING_ERROR) {
      this.setState({
        freezeModal: true,
        retry: this.isErrorStatus,
        uploadingStarted: true,
        errorMessage: undefined
      });
    }
    this.setState({freezeModal: true, uploadingStarted: true});
  };

  private turnOffRetry = () => this.setState({retry: false});

  private hideModal = () => {
    if (!this.state.freezeModal) {
      this.setState({
        errorMessage: undefined,
        imageDataUrl: undefined,
        status: undefined,
        uploadingStarted: false,
        aspectRatio: AspectRatio.SQUARE
      });
    }
  };

  private handleFileSelected = (imageDataUrl: string) => {
    this.validateFile()
      .then(() => {
        this.setState({imageDataUrl, status: undefined});
      })
      .catch(e => {
        this.setState({status: e.message});
      });
  };

  private handleResponse = ({
    payload: {
      data: {id, urls}
    }
  }: AxiosResponseAction<EnglexImage>) => {
    this.props.setImage(id, urls[0]);
    this.props.resetErrors();
    this.setState({
      freezeModal: false,
      imageDataUrl: undefined,
      status: undefined,
      uploadingStarted: false,
      aspectRatio: AspectRatio.SQUARE
    });
  };

  private handleError = (action: AxiosRequestError) => {
    const {error} = action;
    let errorMessage;
    if (error.response && [422, 400].includes(error.response.status)) {
      if (Array.isArray(error.response.data)) {
        errorMessage = error.response.data[0].message;
      } else if (error.response.data.message) {
        errorMessage = error.response.data.message;
      }
    }
    this.setState({
      errorMessage,
      freezeModal: false,
      status: UploadingPictureStatus.UPLOADING_ERROR
    });
  };

  private deletePair = () => {
    this.props.resetErrors();
    this.props.deletePair();
  };

  private setStatus = (status?: UploadingPictureStatus) => this.setState({status});

  private storeCrop = (cropResult: CompleteCrop) => this.setState({cropResult});

  private storeFile = (file: File) => this.setState({file});

  private setImageSize = (imageSize: [number, number]) => this.setState({imageSize});

  private validateFile = async () => {
    const {file} = this.state;
    if (file) {
      const md5 = await md5Chunk(file);
      const status = await validateImageFile(file, md5, undefined, undefined, IMAGE_MIN_DIMENSION);
      if (status in UploadingPictureValidationError) {
        throw new Error(status);
      }
      this.setState({validatedFile: {md5, file}});
    }
  };

  private setAspectRatio = (aspectRatio: AspectRatio) => this.setState({aspectRatio});
}

const mapDispatchToProps = (
  dispatch: Dispatch<XWidgetAction>,
  {xwidgetId, id, changeCardSizeTypeAction}: Props
) => ({
  changeCardSizeType: (cardSizeType: CardSizeType) => {
    dispatch(changeCardSizeTypeAction(xwidgetId, id, cardSizeType));
  }
});

export default connect<{}, DispatchProps>(undefined, mapDispatchToProps)(injectIntl(ImageCard));
