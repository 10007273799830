import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {type Value, type Editor} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import {InlineInputPlugin} from 'components/Slate/SlateEditor/plugins/InlineInputPlugin';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import {xexerciseChangeGrammarHeader} from 'store/exercise/editor/actions/xexercise';
import {type AppState} from 'store/interface';

import ValidationError from './ValidationError';
import './GrammarOptionalTitle.scss';

const GrammarOptionalTitle: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const grammarTitle = useSelector<AppState, Value | null>(
    state => state.xeditor?.xexercise.grammarTitle || null
  );
  const validationError = useSelector<AppState, string | undefined>(state =>
    state.xeditor?.errors.get('grammarTitle')?.first()
  );

  const isGrammarExercise = useSelector<AppState, boolean | undefined>(
    state => state.xeditor?.xexercise.isGrammarExercise
  );

  const onChange = useCallback(
    (change: Editor) => {
      dispatch(xexerciseChangeGrammarHeader(change.value));
    },
    [dispatch]
  );

  const textNormalizerOptions = useMemo(
    () => ({
      stripChars: '\n'
    }),
    []
  );

  const plugins = useMemo<Array<Plugin>>(() => {
    return [
      InlineInputPlugin(),
      new Undo(),
      new Redo(),
      new Italic(),
      new Underline(),
      new StrikeThrough(),
      new FormatPainter(),
      new ClearFormatting()
    ];
  }, []);

  if (!isGrammarExercise) {
    return null;
  }

  return grammarTitle ? (
    <div className="xeditor-widget optional-title grammar-rule-header">
      <div
        className={classNames('widget-body', {
          'widget-has-error': validationError
        })}
      >
        <SlateEditor
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          value={grammarTitle}
          onChange={onChange}
          plugins={plugins}
          placeholder={intl.formatMessage({id: 'XEditor.GrammarOptionalTitle'})}
          className="grammar-title"
          textNormalizerOptions={textNormalizerOptions}
        />
      </div>
      <ValidationError message={validationError} />
    </div>
  ) : null;
};

export default GrammarOptionalTitle;
