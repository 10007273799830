import React, {type FC, type ReactElement} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {connect, type MapStateToProps} from 'react-redux';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {components, type MultiValueProps, type ValueContainerProps} from 'react-select';

import ReactSelect from 'components/ReactSelect/ReactSelect';
import Icon from 'components/Icon';
import {type MediaMeta, type WidgetMediaType, WidgetTitle} from 'store/exercise/player/interface';
import {type UserV2} from 'components/CoursebookLibrary/interface';
import {coursebookLibraryMessages} from 'components/CoursebookLibrary/messages';
import {type OptionType, type OptionTypeBase} from 'components/ReactSelect/interface';
import {
  type AppState,
  type Coursebook,
  type ExerciseCategory,
  type LanguageLevel
} from 'store/interface';
import {type XEditorUnitExercise} from 'store/exercise/editor/interface';
import {selectCategory, selectLevel} from 'store/exercise/editor/actions/xmeta';
import {
  type ExerciseMetaProperties,
  type XExerciseOwnership,
  type XWidgetProperties
} from 'store/exercise/editor/widgets/interface';

import {messages, sectionHeadings} from './i18n';
import TextbooksList from './TextbooksList';
import CategoriesModal from './CategoriesModal';
import GrammarSection from './GrammarSection';
import './styles.scss';

interface StateProps {
  categories: ExerciseCategory[];
  levels: LanguageLevel[];
  media?: MediaMeta;
  meta: ExerciseMetaProperties;
  coursebooks: Array<Pick<Coursebook, 'id' | 'title'>>;
  ownership: XExerciseOwnership;
  includeWidgetTypes: string;
  unitExercise?: XEditorUnitExercise;
}

interface DispatchProps {
  selectCategory: (categories: ExerciseCategory[]) => void;
  selectLevel: (levels: number[]) => void;
}

interface OwnProps {
  exerciseId?: string;
  children: ReactElement;
}

type Props = StateProps & DispatchProps & WrappedComponentProps & OwnProps;

interface State {
  showModal: boolean;
}

class Sidebar extends React.PureComponent<Props, State> {
  public state: State = {
    showModal: false
  };

  public render() {
    const {
      intl,
      intl: {formatMessage},
      categories,
      levels,
      meta,
      coursebooks,
      ownership: {author, original},
      includeWidgetTypes,
      unitExercise
    } = this.props;
    if (!unitExercise) {
      return null;
    }
    const {MediaSection, MultiValue, ValueContainer} = this;

    return (
      <div className="sidebar">
        <Scrollbars>
          <div className="wrapper">
            <div className="meta">
              <section>
                <span className="section-title">{formatMessage(sectionHeadings.author)}:</span>{' '}
                {this.renderAuthor(author)}
              </section>
              <section>
                {original && (
                  <>
                    <span className="section-title">
                      {formatMessage(sectionHeadings.originalAuthor)}:{' '}
                    </span>
                    {this.renderAuthor(original.author)}
                  </>
                )}
              </section>
              <TextbooksList intl={intl} coursebooks={coursebooks} />
              <section>
                <span className="section-title">{formatMessage(sectionHeadings.type)}:</span>{' '}
                {includeWidgetTypes}
              </section>
              <MediaSection />
              {this.props.children}
            </div>
            <div className="separator" />
            <div className="categories">
              <section>
                <span className="section-title">{formatMessage(sectionHeadings.categories)}:</span>
                <ReactSelect
                  options={categories.map(c => ({label: c.title, value: c.id}))}
                  onChange={this.onCategorySelect}
                  components={{MultiValue, ValueContainer}}
                  placeholderMessageDescriptor={messages.categoriesPlaceholder}
                  isValueSelected={this.isValueSelectedForCategories}
                />
                <Button className="all-categories" onClick={this.showModal}>
                  <Icon name="sitemap" />
                  {formatMessage(messages.allCategories)}
                </Button>
              </section>
            </div>
            <div className="separator" />
            <div className="levels">
              <section>
                <span className="section-title">{formatMessage(sectionHeadings.levels)}:</span>
                <ReactSelect
                  options={levels.map(l => ({key: l.id, label: l.title, value: l.id}))}
                  onChange={this.onLevelSelect}
                  placeholderMessageDescriptor={messages.levelsPlaceholder}
                  isValueSelected={this.isValueSelectedForLevels}
                />
              </section>
            </div>
            <GrammarSection />
            {this.state.showModal && (
              <CategoriesModal
                categories={categories}
                confirm={this.confirmModal}
                onHide={this.hideModal}
                selectedCategories={meta.categories.map((c: ExerciseCategory) => c.id).toArray()}
              />
            )}
          </div>
        </Scrollbars>
      </div>
    );
  }

  private isValueSelectedForCategories = (option: OptionType<number>): boolean =>
    !!this.props.meta.categories.find((c: ExerciseCategory) => c.id === option.value);

  private isValueSelectedForLevels = (option: OptionType<number>): boolean =>
    !!this.props.meta.levels.find(l => l === option.value);

  private hideModal = () => this.setState({showModal: false});

  private MediaSection = () => {
    const {
      intl: {formatMessage},
      media
    } = this.props;
    if (!media) {
      return null;
    }
    return (
      <section>
        <span className="section-title">{formatMessage(sectionHeadings.contains)}:</span>{' '}
        {media
          .keySeq()
          .filter(k => k !== 'pronunciations')
          .map((k: WidgetMediaType) => formatMessage(messages[k]))
          .join(', ')}
      </section>
    );
  };

  private MultiValue: FC<MultiValueProps<OptionTypeBase>> = ({children, ...props}) => (
    <components.MultiValue {...props}>
      <span title={props.data.label}>{children}</span>
    </components.MultiValue>
  );

  private ValueContainer: FC<ValueContainerProps<OptionTypeBase>> = ({children, ...props}) => {
    return (
      <components.ValueContainer {...props} className="categories-value-container">
        <Scrollbars autoHeight={true} autoHeightMin={17} autoHeightMax={155} autoHide={true}>
          {children}
        </Scrollbars>
      </components.ValueContainer>
    );
  };

  private onCategorySelect = (selectedCategories: Array<{value: number; label: string}> | null) => {
    const {categories} = this.props;
    const selectedIds = selectedCategories ? selectedCategories.map(sc => sc.value) : [];
    this.props.selectCategory(categories.filter(c => selectedIds.includes(c.id)));
  };

  private onLevelSelect = (selectedLevels: Array<{value: number; label: string}> | null) => {
    const selectedIds = selectedLevels?.map(sl => sl.value) || [];
    this.props.selectLevel(selectedIds);
  };

  private confirmModal = (categories: number[]) => {
    this.props.selectCategory(this.props.categories.filter(c => categories.includes(c.id)));
  };

  private showModal = () => this.setState({showModal: true});

  private renderAuthor = (author: UserV2) => {
    if (author.role === 'methodist') {
      return this.props.intl.formatMessage(coursebookLibraryMessages.EnglexDepartmentOfMethodology);
    }
    return `${author.profile.firstName} ${author.profile.lastName}`;
  };
}

const mapStateToProps: MapStateToProps<StateProps, {}, AppState> = state => {
  const {
    categories,
    levels,
    xexercise: {meta, media, ownership, coursebooks, widgets},
    unitExercise
  } = state.xeditor!;
  return {
    categories,
    levels,
    media,
    meta,
    ownership,
    coursebooks,
    unitExercise,
    includeWidgetTypes: widgets
      .map((w: XWidgetProperties) => WidgetTitle[w.type])
      .toOrderedSet()
      .join(', ')
  };
};

export default connect(mapStateToProps, {selectCategory, selectLevel})(injectIntl(Sidebar));
