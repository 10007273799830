import React from 'react';
import {type Editor, type Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';
import {
  defineMessages,
  type WrappedComponentProps,
  injectIntl,
  type MessageDescriptor
} from 'react-intl';

import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import {WidgetType} from 'store/exercise/player/interface';

import './XWidgetTask.scss';

const messages = defineMessages({
  placeholder: {
    id: 'XEditor.XWidget.Task.Placeholder'
  }
});

interface OwnProps {
  widgetType: WidgetType;
  value: Value;
  onChange: (change: Editor) => void;
  placeholder?: MessageDescriptor;
}

type Props = OwnProps & WrappedComponentProps;

interface State {}

class XWidgetTask extends React.Component<Props, State> {
  private readonly plugins: Plugin[];

  constructor(props: Props) {
    super(props);
    const isNoteOrComment =
      props.widgetType === WidgetType.COMMENT || props.widgetType === WidgetType.NOTE;

    this.plugins = [
      new Undo(),
      new Redo(),
      new Italic(),
      new Underline(),
      new StrikeThrough(),
      ...(!isNoteOrComment ? [new HighlightToolbar()] : []),
      new CharSelector(),
      new FormatPainter(),
      new ClearFormatting()
    ];
  }

  public render() {
    const {intl, onChange, placeholder, value} = this.props;
    return (
      <div className="task">
        <SlateEditor
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          value={value}
          onChange={onChange}
          plugins={this.plugins}
          placeholder={intl.formatMessage(placeholder ? placeholder : messages.placeholder)}
        />
      </div>
    );
  }
}

export default injectIntl(XWidgetTask);
