import React, {type PropsWithChildren} from 'react';
import {type WrappedComponentProps, FormattedMessage} from 'react-intl';
import {type Action, type Dispatch} from 'redux';
import {connect} from 'react-redux';

import TextDown from 'components/TextDown';
import {XVocabularyDefaultCategories} from 'components/Dictionary/shared/interface';
import {xVocabularySetCategory} from 'store/exercise/editor/widgets/XVocabulary/actions';
import {type VocabularyCategoryProperties} from 'store/exercise/editor/widgets/XVocabulary/interface';

import {vocabularyMessages} from './vocabularyMessages';

interface OwnProps extends WrappedComponentProps {
  onFocus: () => void;
  onBlur: () => void;
  category: VocabularyCategoryProperties;
  widgetId: string;
  positionInVocabulary: number;
}

interface DispatchProps {
  changeCategory: (value: string) => void;
}

type Props = PropsWithChildren<OwnProps> & DispatchProps;

class XVocabularyCategoryInput extends React.Component<Props> {
  public render() {
    const {
      intl,
      onFocus,
      onBlur,
      category,
      widgetId,
      positionInVocabulary,
      changeCategory,
      children
    } = this.props;
    return (
      <div className="vocabulary-category-input">
        <div className="title">
          <FormattedMessage id="XEditorXWidget.Vocabulary.Category" />
        </div>
        <TextDown
          dropdownValues={Object.values(XVocabularyDefaultCategories)}
          value={category.name}
          placeholder={intl.formatMessage(vocabularyMessages.CategoryPlaceholder)}
          onFocus={onFocus}
          onBlur={onBlur}
          changeValue={changeCategory}
          dropdownId={`xeditor-widget${widgetId}-category${positionInVocabulary}-dropdown`}
          dividerHeight={20}
          menuWidth={200}
          buttonWidth={34}
        />
        <div className="controls">{children}</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): DispatchProps => ({
  changeCategory: (value: string) =>
    dispatch(xVocabularySetCategory(ownProps.widgetId, ownProps.positionInVocabulary, value))
});

export default connect(null, mapDispatchToProps)(XVocabularyCategoryInput);
