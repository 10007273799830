import React from 'react';

import './Tile.scss';

interface Props {
  title: string;
  additionalText?: string;
}

class Tile extends React.Component<Props> {
  public render() {
    return (
      <div className="tile">
        {this.props.children}
        <span>
          <div className="title">{this.props.title}</div>
          <div className="additional-text">{this.props.additionalText}</div>
        </span>
      </div>
    );
  }
}

export default Tile;
