import React from 'react';
import {type Action, type Dispatch} from 'redux';
import {type Editor, type Value} from '@englex/slate';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';

import {type AudioFile} from 'components/media/interface';
import {type AppState} from 'store/interface';
import {
  formattedTextContentChange,
  type XWidgetChangeAction
} from 'store/exercise/editor/xwidgetActions';
import {allowAudio, attachAudio, clearAudio} from 'store/media/audio/actions';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type XAudioProperties} from 'store/exercise/editor/widgets/XAudio/interface';
import {maxSoundSizeForMethodist} from 'config/static';

import {messages} from './messages';
import AudioInput from './AudioInput/AudioInput';
import Transcript from '../components/Transcript';
import {XDisplayAsButton} from '../components/XDisplayAsButton/XDisplayAsButton';

import './styles.scss';

interface OwnProps {
  id: string;
}

interface StateProps {
  audioAllowed: boolean;
  audioFile?: AudioFile;
  audioId?: number;
  content: Value;
}

interface DispatchProps {
  attachAudioObject: (audioFile: AudioFile) => void;
  clearAudioObject: () => void;
  allowAudio: () => void;
  onContentChange: (change: Editor) => XWidgetChangeAction;
}

interface Props extends OwnProps, StateProps, DispatchProps {}

class XEditorAudio extends React.Component<Props> {
  public componentDidMount() {
    if (!this.props.audioAllowed) {
      this.props.allowAudio();
    }
  }

  public render() {
    const {attachAudioObject, audioFile, clearAudioObject, content, onContentChange, audioId} =
      this.props;
    return (
      <>
        <AudioInput
          widgetId={this.props.id}
          attachAudio={attachAudioObject}
          audioFile={audioFile}
          clearAudio={clearAudioObject}
          audioId={audioId}
          maxFileSize={maxSoundSizeForMethodist}
        />
        <Transcript
          content={content}
          onContentChange={onContentChange}
          placeholder={messages.placeholder}
        />
        <XDisplayAsButton xwidgetId={this.props.id} />
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  {id}: OwnProps
) => {
  const {audio, content, audioId} = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === id
  ) as XAudioProperties;
  const audioAllowed = !!state.media.audio;
  return {audioAllowed, audioFile: audio, content, audioId};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {id: string; task: Value}> = (
  dispatch: Dispatch<Action>,
  {id: xwidgetId}: OwnProps
) => ({
  allowAudio: () => dispatch(allowAudio()),
  attachAudioObject: (audioFile: AudioFile) => dispatch(attachAudio(xwidgetId, audioFile)),
  clearAudioObject: () => dispatch(clearAudio(xwidgetId)),
  onContentChange: (change: Editor) => dispatch(formattedTextContentChange(xwidgetId, change))
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(XEditorAudio);
