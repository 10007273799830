import React from 'react';

import DragHandle from 'components/DragHandle/DragHandle';
import {
  type VocabularyCategoryJSON,
  type VocabularyWordJSON
} from 'store/exercise/player/widgets/Vocabulary/interface';
import {isVocabularyWordJSON} from 'store/exercise/player/widgets/Vocabulary/utils';

import './XEditorVocabularyEntry.scss';

interface Props {
  vocabularyEntry?: VocabularyWordJSON | VocabularyCategoryJSON;
  elementWidth: number;
  style: React.CSSProperties;
}

export default class XEditorVocabularyDragItem extends React.Component<Props> {
  public render() {
    const {vocabularyEntry, elementWidth, style} = this.props;
    if (!vocabularyEntry) {
      return null;
    }
    return (
      <div
        className="xvocabulary-entry dragging focused"
        style={{...style, width: elementWidth, right: elementWidth - 15}}
      >
        {isVocabularyWordJSON(vocabularyEntry)
          ? this.renderWordInput(vocabularyEntry)
          : this.renderCategoryInput(vocabularyEntry)}
      </div>
    );
  }

  private renderWordInput = (word: VocabularyWordJSON) => {
    return (
      <React.Fragment>
        <div className="vocabulary-word-input">
          <div className="title" />
          <input className="original-input" value={word.original} onChange={this.emptyOnChange} />
        </div>
        <div className="vocabulary-word-input">
          <input
            className="translation-input"
            value={word.translation}
            onChange={this.emptyOnChange}
          />
          {this.renderControls()}
        </div>
      </React.Fragment>
    );
  };

  private renderCategoryInput = (category: VocabularyCategoryJSON) => {
    return (
      <div className={`vocabulary-category-input`}>
        <div className="title" />
        <input value={category.name} onChange={this.emptyOnChange} />
        {this.renderControls()}
      </div>
    );
  };

  private renderControls = () => (
    <div className="controls">
      <div className="drag-handle">
        <DragHandle />
      </div>
    </div>
  );

  private emptyOnChange = () => null;
}
