import React, {Component} from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';
import {FormattedMessage} from 'react-intl';

import {GapFillType} from 'components/Slate/interface';
import {type GapFillJSON} from 'store/exercise/player/widgets/GapFill/interface';
import GapChecked from 'components/XPlayer/widgets/GapFill/component/GapChecked';
import Icon from 'components/Icon';
import DragHandle from 'components/DragHandle/DragHandle';

import {type XWizardStepProps} from '../interface';
import './GapTypeSelector.scss';

type Props = XWizardStepProps<GapFillJSON>;

class GapTypeSelector extends Component<Props> {
  public render() {
    const radioGroupName = 'gap';
    return (
      <form className="gap-type-selector">
        <p>
          <b>
            <FormattedMessage id="XEditor.Wizard.Gap.Header" />
          </b>
        </p>
        <FormattedMessage id="XEditor.Wizard.Gap.Text" />
        <FormGroup>
          <div className="group">
            <Radio
              name={radioGroupName}
              inline={true}
              onChange={() => this.props.addField('gap', GapFillType.INPUT)}
              checked={this.props.fields.gap === GapFillType.INPUT}
            >
              <span>
                <FormattedMessage id="XEditor.Wizard.Gap.Option1" />
              </span>
            </Radio>
            <GapChecked gap={GapFillType.INPUT} value="Examp..." />
          </div>

          <div className="group">
            <Radio
              name={radioGroupName}
              inline={true}
              onChange={() => this.props.addField('gap', GapFillType.DROPDOWN)}
              checked={this.props.fields.gap === GapFillType.DROPDOWN}
            >
              <span>
                <FormattedMessage id="XEditor.Wizard.Gap.Option2" />
              </span>
            </Radio>
            <GapChecked gap={GapFillType.DROPDOWN} value="Example" childPullRight={true}>
              <Icon name="angle-down" className="arrow" />
            </GapChecked>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="group">
            <Radio
              name={radioGroupName}
              inline={true}
              onChange={() => this.props.addField('gap', GapFillType.DND)}
              checked={this.props.fields.gap === GapFillType.DND}
            >
              <span>
                <FormattedMessage id="XEditor.Wizard.Gap.Option3" />
              </span>
            </Radio>
            <GapChecked gap={GapFillType.DND} value="Example">
              <DragHandle />
            </GapChecked>
          </div>

          <div className="group">
            <Radio
              name={radioGroupName}
              inline={true}
              onChange={() => this.props.addField('gap', GapFillType.DND_INPUT)}
              checked={this.props.fields.gap === GapFillType.DND_INPUT}
            >
              <span>
                <FormattedMessage id="XEditor.Wizard.Gap.Option4" />
              </span>
            </Radio>
            <GapChecked gap={GapFillType.DND_INPUT} value="Example" className="dnd">
              <DragHandle />
            </GapChecked>
          </div>
        </FormGroup>
      </form>
    );
  }
}

export default GapTypeSelector;
