import React, {Component, type SyntheticEvent} from 'react';
import classNames from 'classnames';
import {type Dispatch} from 'redux';
import {connect} from 'react-redux';
import {defineMessages, type WrappedComponentProps, injectIntl} from 'react-intl';
import {default as FormControl, type FormControlProps} from 'react-bootstrap/lib/FormControl';

import {
  deletePair,
  imageMatchingChangeCardSizeType,
  setChoiceValue,
  setImageData
} from 'store/exercise/editor/widgets/XImage/actions';
import {type DndSortingWrapperProps} from 'components/DndSortingWrapper/DndSortingWrapper';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';
import {type XWidgetAction} from 'store/exercise/editor/actions/interface';

import ImageCard from '../../components/ImageCard/ImageCard';

import './ImageMatchingCard.scss';

const i18n = defineMessages({
  placeholder: {
    id: 'XEditorWidget.ImageMatching.Input.Placeholder'
  }
});

export interface ImageMatchingCardProps {
  id: string;
  imageId: number;
  choiceId: string;
  index: number;
  xwidgetId: string;
  cardSizeType: CardSizeType;
  value?: string;
  reportError: (e: string | JSX.Element) => string | JSX.Element;
  resetErrors: () => void;
  updateRecoveryPoint: () => void;
}

interface DispatchProps {
  deletePair: () => void;
  setImage: (imageId: number, url: string) => void;
  setValue: (value: string) => void;
}

type Props = ImageMatchingCardProps &
  DispatchProps &
  WrappedComponentProps &
  DndSortingWrapperProps;

class ImageMatchingCard extends Component<Props> {
  private get inputClasses() {
    return classNames('value', {'not-empty': !!this.props.value});
  }

  public render() {
    const {inputClasses} = this;
    const {
      id,
      xwidgetId,
      imageId,
      style,
      value,
      handle,
      isDragging,
      isDragLayer,
      cardSizeType,
      setImage,
      deletePair,
      reportError,
      resetErrors,
      intl: {formatMessage}
    } = this.props;

    return (
      <div
        className={classNames('image-matching__card', {
          'is-rectangle': cardSizeType === CardSizeType.RECTANGLE
        })}
        style={style}
      >
        <div
          className={classNames(
            'block-wrapper',
            {'is-dragging': isDragging && !isDragLayer},
            {'is-drag-layer': isDragLayer}
          )}
        >
          <ImageCard
            id={id}
            xwidgetId={xwidgetId}
            imageId={imageId}
            handle={handle}
            isDragLayer={isDragLayer}
            reportError={reportError}
            resetErrors={resetErrors}
            deletePair={deletePair}
            setImage={setImage}
            changeCardSizeTypeAction={imageMatchingChangeCardSizeType}
          />
        </div>

        <div className={classNames('block-wrapper', {'is-dragging': isDragging && !isDragLayer})}>
          <FormControl
            bsSize="small"
            className={inputClasses}
            onSubmit={() => ({})}
            placeholder={!isDragLayer ? formatMessage(i18n.placeholder) : undefined}
            title={value}
            type="text"
            value={value || ''}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  }

  private onChange = ({currentTarget: {value}}: SyntheticEvent<FormControlProps>) => {
    this.props.resetErrors();
    this.props.setValue(value as string);
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch<XWidgetAction>,
  {xwidgetId, choiceId, index, updateRecoveryPoint}: ImageMatchingCardProps
) => ({
  deletePair: () => {
    dispatch(deletePair(xwidgetId, index));
    requestAnimationFrame(updateRecoveryPoint);
  },
  setImage: (imageId: number, url: string) => {
    dispatch(setImageData(xwidgetId, index, imageId, url));
    requestAnimationFrame(updateRecoveryPoint);
  },
  setValue: (value: string) => {
    dispatch(setChoiceValue(xwidgetId, choiceId, value));
    requestAnimationFrame(updateRecoveryPoint);
  }
});

export default connect<{}, DispatchProps>(
  undefined,
  mapDispatchToProps
)(injectIntl(ImageMatchingCard));
