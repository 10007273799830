import React from 'react';
import {type WrappedComponentProps, injectIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

import Icon from 'components/Icon';
import {megaByteMultiplier} from 'config/static';

import {UploadingStatus, UploadingValidationError} from './interface';
import {errorMessages} from './errorMessages';

const {EMPTY_FILE, FILE_CORRUPTED, FILE_TOO_BIG, INVALID_EXTENSION, MULTIPLE_FILES} =
  UploadingValidationError;
const {UPLOADING_ERROR} = UploadingStatus;

interface Props {
  onClick: () => void;
  error: UploadingValidationError | UploadingStatus;
  maxFileSize: number;
}

class Error extends React.Component<Props & WrappedComponentProps> {
  public render() {
    const {error} = this.props;
    return (
      <div className="error-component">
        <Icon name="exclamation-triangle" />
        <span className="message">{this.getStatusText(error)}</span>
        <div>
          <Button bsSize="sm" onClick={this.onClick}>
            OK
          </Button>
        </div>
      </div>
    );
  }

  private onClick = () => {
    this.props.onClick();
  };

  private getStatusText = (error: UploadingValidationError | UploadingStatus) => {
    const {
      intl: {formatMessage},
      maxFileSize
    } = this.props;

    switch (error) {
      case MULTIPLE_FILES:
        return formatMessage(errorMessages.MultipleFilesError);
      case INVALID_EXTENSION:
        return formatMessage(errorMessages.InvalidSoundExtension);
      case FILE_CORRUPTED:
        return formatMessage(errorMessages.FileCorruptedError);
      case FILE_TOO_BIG:
        return formatMessage(errorMessages.InvalidFileSize, {
          size: maxFileSize / megaByteMultiplier
        });
      case EMPTY_FILE:
        return formatMessage(errorMessages.EmptyFile);
      case UPLOADING_ERROR:
      default:
        return formatMessage(errorMessages.UnknownUploadingError);
    }
  };
}

export default injectIntl(Error);
