import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {type CardsJSON} from 'store/exercise/player/widgets/Cards/interface';
import XCardsRecord from 'store/exercise/editor/widgets/XCards/XCardsRecord';

import {type XWizardWidgetData} from '../interface';
import Tile from '../Tile';
import {CardsSVG} from '../assets';
import CardsTypeSelector from './CardsTypeSelector';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.Cards}>
    <CardsSVG />
  </Tile>
);

export const cardsWizardData: XWizardWidgetData<CardsJSON, XCardsRecord> = {
  type: WidgetType.CARDS,
  steps: [
    {
      canGoToNextStep: (widget: Partial<CardsJSON>) => !!widget.cardsType,
      component: CardsTypeSelector
    }
  ],
  tile,
  createWidget: (widget: CardsJSON) => {
    return XCardsRecord.fromCards(widget.cardsType);
  }
};
