import React, {useCallback} from 'react';
import {type Editor, type Value} from '@englex/slate';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';

import {type AppState} from 'store/interface';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import {grammarTitleChange} from 'store/exercise/editor/xwidgetActions';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type XGrammarRulesProperties} from 'store/exercise/editor/widgets/XGrammarRules/interface';

import {formattedTextMessages} from './messages';
import XEditorFormattedText from './XEditorFormattedText';
import './XEditorGrammarRules.scss';

interface Props {
  id: string;
}

const grammarTitlePlugins = [
  new Undo(),
  new Redo(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting()
];

const XEditorGrammarRules: React.FC<Props> = ({id}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const grammarTitle = useSelector<AppState, Value>(state => {
    const xwidget = state.xeditor!.xexercise.widgets.find(
      (x: XWidgetProperties) => x.id === id
    ) as XGrammarRulesProperties;
    const {grammarTitle} = xwidget;
    return grammarTitle;
  });
  const onGrammarTitleChange = useCallback(
    (change: Editor) => {
      dispatch(grammarTitleChange(id, change.value));
    },
    [dispatch, id]
  );

  return (
    <React.Fragment>
      <SlateEditor
        toolbar={{portalId: 'xeditor-toolbar-portal'}}
        value={grammarTitle}
        onChange={onGrammarTitleChange}
        plugins={grammarTitlePlugins}
        placeholder={intl.formatMessage(formattedTextMessages.titlePlaceholder)}
        className="grammar-title"
      />
      <XEditorFormattedText id={id} />
    </React.Fragment>
  );
};

export default XEditorGrammarRules;
