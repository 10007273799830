import React, {type FC, useCallback} from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon';

interface Props {
  onClick: (icon: string) => void;
  icon: string;
  activeIcon?: string;
}

export const DisplayButtonIcon: FC<Props> = ({onClick, icon, activeIcon}) => {
  const onClickHandler = useCallback(() => {
    onClick(icon);
  }, [icon, onClick]);

  const classname = classNames('overlay-display-buttons-icons', {active: activeIcon === icon});

  return (
    <span onClick={onClickHandler} className={classname}>
      <Icon size="lg" name={icon} />
    </span>
  );
};
