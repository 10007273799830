import {SPELLING_SET_ORDINAL} from './actionsType';
import {type XWidgetAction} from '../../actions/interface';

export interface SpellingSetOrdinalAction extends XWidgetAction {
  ordinal?: string;
}

export const setOrdinal = (xwidgetId: string, ordinal?: string): SpellingSetOrdinalAction => ({
  type: SPELLING_SET_ORDINAL,
  xwidgetId,
  ordinal
});
