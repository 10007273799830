import {type Plugin, type Editor, type SchemaProperties, type SlateError} from '@englex/slate';

import {SlateBlock, SlateObject} from '../../../../../interface';
import {logNormalizeError} from '../../../utils';

// Use this schema to forbid any document children except the only one and terribly lonely List.
class TheOnlyListNormalizer implements Plugin {
  public schema: SchemaProperties = {
    rules: [
      {
        match: {object: SlateObject.DOCUMENT},
        nodes: [
          {
            match: [{type: SlateBlock.LIST}],
            min: 1,
            max: 1
          }
        ],
        normalize: (change: Editor, error: SlateError) => {
          if (error.code === 'child_unknown') {
            if (error.child) {
              change.mergeNodeByKey(error.child.key);
              logNormalizeError(error, 'TheOnlyListNormalizer.schema.rules', true, '0');
              return;
            }
          }
          if (error.code === 'child_type_invalid') {
            change.unwrapNodeByKey(error.child!.key);
            logNormalizeError(error, 'TheOnlyListNormalizer.schema.rules', true, '0');
            return;
          }
          logNormalizeError(error, 'TheOnlyListNormalizer.schema.rules', false, '0');
        }
      }
    ]
  };
}

export default TheOnlyListNormalizer;
