import React from 'react';
import {List} from 'immutable';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {
  type ImageMatchingJSON,
  type XImageMatchingOptions
} from 'store/exercise/player/widgets/ImageMatching/interface';
import XImageMatchingRecord from 'store/exercise/editor/widgets/XImage/XImageMatching/XImageMatchingRecord';
import XImageMatchingCardRecord from 'store/exercise/editor/widgets/XImage/XImageMatching/XImageMatchingCardRecord';
import {GapFillType} from 'components/Slate/interface';
import XImageLabelingRecord from 'store/exercise/editor/widgets/XImage/XImageLabeling/XImageLabelingRecord';
import XImageLabelingCardRecord from 'store/exercise/editor/widgets/XImage/XImageLabeling/XImageLabelingCardRecord';

import ImageMatchingWizard from './ImageMatchingWizard';
import {ImageMatchingSVG} from '../assets';
import Tile from '../Tile';
import {type XWizardWidgetData} from '../interface';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.ImageMatching}>
    <ImageMatchingSVG />
  </Tile>
);

export const imageMatchingWizardData: XWizardWidgetData<
  ImageMatchingJSON,
  XImageMatchingRecord | XImageLabelingRecord
> = {
  type: WidgetType.IMAGE_MATCHING,
  steps: [
    {
      canGoToNextStep: (options: XImageMatchingOptions) => !!options.gap,
      component: ImageMatchingWizard
    }
  ],
  tile,
  createWidget: (options: XImageMatchingOptions) => {
    return options.gap === GapFillType.DND
      ? XImageMatchingRecord.fromCards(
          List([
            new XImageMatchingCardRecord(),
            new XImageMatchingCardRecord(),
            new XImageMatchingCardRecord()
          ])
        )
      : XImageLabelingRecord.fromCards(
          List([
            new XImageLabelingCardRecord(),
            new XImageLabelingCardRecord(),
            new XImageLabelingCardRecord()
          ])
        );
  }
};
