import React, {type FC, useEffect} from 'react';
import {FormattedMessage, injectIntl, type IntlShape, type WrappedComponentProps} from 'react-intl';
import Radio from 'react-bootstrap/lib/Radio';

import {type CardsJSON, CardsType} from 'store/exercise/player/widgets/Cards/interface';

import {type XWizardStepProps} from '../interface';

import './CardsTypeSelector.scss';

const cardVariants = [
  {
    type: CardsType.TEXT
  },
  {
    type: CardsType.IMAGES
  }
];

type Props = XWizardStepProps<CardsJSON> & {intl: IntlShape};

const CardsTypeSelector: FC<Props> = ({fields, addField}: Props & WrappedComponentProps) => {
  useEffect(() => {
    addField('cardsType', CardsType.TEXT);
  }, [addField]);

  return (
    <form className="cards-type-selector">
      <p>
        <b>
          <FormattedMessage id="XEditor.Wizard.Cards.Header" />
        </b>
      </p>
      <div>
        {cardVariants.map(variant => {
          return (
            <Radio
              name="cards-type"
              onChange={() => addField('cardsType', variant.type)}
              checked={fields.cardsType === variant.type}
              key={variant.type}
            >
              <span>
                {variant.type === CardsType.TEXT ? (
                  <FormattedMessage id="XEditorXWidget.Cards.Types.Text" />
                ) : (
                  <FormattedMessage id="XEditorXWidget.Cards.Types.Images" />
                )}
              </span>
            </Radio>
          );
        })}
      </div>
    </form>
  );
};

export default injectIntl(CardsTypeSelector);
