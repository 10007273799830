import React, {type FC} from 'react';
import {FormattedMessage, injectIntl, type IntlShape, type WrappedComponentProps} from 'react-intl';
import Radio from 'react-bootstrap/lib/Radio';

import Icon from 'components/Icon';
import {type NoteJSON} from 'store/exercise/player/widgets/Note/interface';
import {noteDataByType} from 'components/XPlayer/widgets/Note/noteDataByType';

import {type XWizardStepProps} from '../interface';
import './NoteTypeSelector.scss';

type Props = XWizardStepProps<NoteJSON> & {intl: IntlShape};

const NoteTypeSelector: FC<Props> = (props: Props & WrappedComponentProps) => {
  const radioGroupName = 'note-type';
  return (
    <form className="note-type-selector">
      <p>
        <b>
          <FormattedMessage id="XEditor.Wizard.Note.Header" />
        </b>
      </p>
      {noteDataByType.map(noteType => {
        return (
          <Radio
            name={radioGroupName}
            onChange={() => props.addField('noteType', noteType.type)}
            checked={props.fields.noteType === noteType.type}
            key={noteType.type}
          >
            <span>
              <Icon name={noteType.iconName} />
              <span>{noteType.title}</span>
            </span>
          </Radio>
        );
      })}
    </form>
  );
};

export default injectIntl(NoteTypeSelector);
