import React from 'react';
import {FormattedMessage} from 'react-intl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';
import classNames from 'classnames';

import {type GapFillJSON} from 'store/exercise/player/widgets/GapFill/interface';
import {GapFillSize, GapFillType} from 'components/Slate/interface';
import GapChecked from 'components/XPlayer/widgets/GapFill/component/GapChecked';
import Icon from 'components/Icon';

import {type XWizardStepProps} from '../interface';
import './GapSizeSelector.scss';

export default class GapSizeSelector extends React.Component<XWizardStepProps<GapFillJSON>> {
  componentDidMount() {
    this.props.addField('gapSize', GapFillSize.LARGE);
  }

  public render() {
    const {addField, fields} = this.props;
    const radioGroupName = 'gap-size';
    const largeChecked = fields.gapSize === GapFillSize.LARGE;
    const smallChecked = fields.gapSize === GapFillSize.SMALL;
    return (
      <form className="gap-size-selector">
        <p>
          <b>
            <FormattedMessage id="XEditor.Wizard.Gap.HeaderStep3" />
          </b>
        </p>
        <FormGroup>
          <div className="group">
            <Radio
              name={radioGroupName}
              onChange={() => addField('gapSize', GapFillSize.LARGE)}
              checked={largeChecked}
              className={classNames({checked: largeChecked})}
            >
              <span>
                <FormattedMessage id="Slate.Toolbar.Button.FontSizeLarge" />
              </span>
            </Radio>
            {fields.gap === GapFillType.DROPDOWN ? (
              <div>
                <GapChecked
                  isLarge={true}
                  gap={GapFillType.DROPDOWN}
                  value={''}
                  empty={true}
                  childPullRight={true}
                >
                  <Icon name="angle-down" className="arrow" />
                </GapChecked>
              </div>
            ) : (
              <input className="x-input x-gap-core empty large" disabled={true} />
            )}
            <FormattedMessage id="XEditor.Wizard.Gap.ClarificationLarge" />
          </div>
          <div className="group">
            <Radio
              name={radioGroupName}
              onChange={() => addField('gapSize', GapFillSize.SMALL)}
              checked={smallChecked}
              className={classNames({checked: smallChecked})}
            >
              <span>
                <FormattedMessage id="XEditor.Wizard.Gap.SizeSmall" />
              </span>
            </Radio>
            {fields.gap === GapFillType.DROPDOWN ? (
              <div>
                <GapChecked
                  gap={GapFillType.DROPDOWN}
                  value={''}
                  empty={true}
                  childPullRight={true}
                >
                  <Icon name="angle-down" className="arrow" />
                </GapChecked>
              </div>
            ) : (
              <input className="x-input x-gap-core empty small" disabled={true} />
            )}
            <FormattedMessage id="XEditor.Wizard.Gap.ClarificationSmall" />
          </div>
        </FormGroup>
      </form>
    );
  }
}
