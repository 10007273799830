import {GapFillType} from '../../../../interface';
import GapFillInput from './GapFillInput';
import GapFillDropDown from './GapFillDropDown';
import GapFillDND from './GapFillDND';
import GapFillDNDInput from './GapFillDNDInput';

function gapPluginFactory(gap: GapFillType) {
  switch (gap) {
    case GapFillType.INPUT:
      return new GapFillInput();
    case GapFillType.DROPDOWN:
      return new GapFillDropDown();
    case GapFillType.DND:
      return new GapFillDND();
    case GapFillType.DND_INPUT:
      return new GapFillDNDInput();
    default:
      throw new Error(`Slate Plugin for GapType=${gap} is not implemented yet`);
  }
}

export default gapPluginFactory;
