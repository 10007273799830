import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import genKey from 'components/Slate/utils/genKey';
import {valueJSONFromText} from 'components/Slate/utils';
import {
  MultipleChoiceElement,
  type MultipleChoiceJSON
} from 'store/exercise/player/widgets/List/interface';
import XOddWordRecord from 'store/exercise/editor/widgets/XOddWord/XOddWordRecord';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';

import {type XWizardWidgetData} from '../interface';
import {OddWordSVG} from '../assets';
import Tile from '../Tile';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.OddWord}>
    <OddWordSVG />
  </Tile>
);

export const oddWordWizardData: XWizardWidgetData<MultipleChoiceJSON, XWidgetProperties> = {
  type: WidgetType.ODD_WORD,
  steps: [],
  tile,
  createWidget: (widget: MultipleChoiceJSON) =>
    new XOddWordRecord({
      id: genKey(),
      type: widget.type,
      task: valueJSONFromText(''),
      content: valueJSONFromText(''),
      element: MultipleChoiceElement.RADIO,
      options: undefined
    })
};
