import React from 'react';
import Spinner from 'react-spinkit';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage, type IntlShape} from 'react-intl';

import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import {requestLibraryTree} from 'store/exercise/editor/widgets/XVideo/actions';
import {type AxiosResponseAction} from 'services/axios/interface';
import {type AppState} from 'store/interface';

import {type VideoItem, type VideoLibraryNode} from './interface';
import FileTree from './FileTree';
import i18n from '../../../i18n';
import './styles.scss';

interface OwnProps {
  close: () => void;
  handleSubmit: (sourceId: string) => void;
  intl: IntlShape;
  show: boolean;
  submitting: boolean;
}

interface DispatchProps {
  requestLibraryTree: () => Promise<AxiosResponseAction<VideoLibraryNode>>;
}

interface Props extends OwnProps, DispatchProps {}

interface State {
  library?: VideoLibraryNode;
  loading: boolean;
  selectedId?: string;
}

class LibraryInputModal extends React.Component<Props, State> {
  public state: State = {loading: true, library: undefined};

  public componentDidUpdate(prevProps: Props) {
    if (this.props.show && !prevProps.show) {
      this.requestLibraryTree();
    }
  }

  public render() {
    const {close, show, submitting} = this.props;
    const {library, loading, selectedId} = this.state;
    return (
      <Modal backdrop="static" className="video-input-modal library" onHide={close} show={show}>
        <Modal.Header>
          <Modal.Title>
            <Icon name="video-camera" />
            <FormattedMessage id="XEditorWidget.Video.Modal.Library.Title" />
          </Modal.Title>
          <Button className="header-close-button" onClick={close}>
            <Icon name="pc-multiply" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {loading && (
            <Spinner name="three-bounce" className="center-block brand-primary" fadeIn="none" />
          )}
          {library && (
            <FileTree library={library} selectFile={this.selectFile} selectedId={selectedId} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize="small" onClick={close}>
            <FormattedMessage id="Common.Cancel" />
          </Button>
          <Button
            bsSize="small"
            bsStyle="primary"
            disabled={!selectedId || submitting || loading}
            onClick={this.acceptSelection}
          >
            <FormattedMessage id="Common.Add" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  private acceptSelection = () => {
    const {selectedId} = this.state;
    selectedId && this.props.handleSubmit(selectedId);
  };

  private requestLibraryTree = () => {
    const {
      close,
      intl: {formatMessage}
    } = this.props;
    this.props
      .requestLibraryTree()
      .then(({payload: {data: library}}) => {
        this.setState({library});
      })
      .catch(() => {
        toastr.error('', formatMessage(i18n.getVideoLibraryTreeRequestError));
        close();
      })
      .finally(() => this.setState({loading: false}));
  };

  private selectFile = (node: VideoItem) => this.setState({selectedId: node.id});
}

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>) => ({
  requestLibraryTree: () => dispatch(requestLibraryTree())
});

export default connect(null, mapDispatchToProps)(LibraryInputModal);
