import React, {type FC} from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {
  type QuestionsJSON,
  type XQuestsWizardOptions
} from 'store/exercise/player/widgets/Writing/interface';
import {questsInitialValue, valueJSONFromText} from 'components/Slate/utils/value';
import genKey from 'components/Slate/utils/genKey';
import XQuestionsRecord from 'store/exercise/editor/widgets/XQuestions/XQuestionsRecord';

import {type XWizardWidgetData} from '../interface';
import Tile from '../Tile';
import {InterrogationSVG} from '../assets';
import QuestsOptions from '../Quests/QuestsOptions';

const tile: FC = () => (
  <Tile title={WidgetTitle.Questions}>
    <InterrogationSVG />
  </Tile>
);

export const questionsWizardData: XWizardWidgetData<QuestionsJSON, XQuestionsRecord> = {
  type: WidgetType.QUESTIONS,
  steps: [
    {
      canGoToNextStep: () => true,
      component: QuestsOptions
    }
  ],
  tile,
  createWidget: (widget: XQuestsWizardOptions) => {
    return new XQuestionsRecord({
      id: genKey(),
      type: widget.type,
      task: valueJSONFromText(''),
      options: widget.questsCountWords
        ? {
            countWords: widget.questsCountWords
          }
        : undefined,
      content: questsInitialValue(),
      questsExampleContent: valueJSONFromText('')
    });
  }
};
