import {type FC, memo} from 'react';
import {type ConnectDragSource} from 'react-dnd';

import {ReactComponent as Handle} from './DragHandle.svg';

import './DragHandle.scss';

interface Props {
  drag?: ConnectDragSource;
}

export const DragHandle: FC<Props> = memo(({drag}) => (
  <div className="dnd-handle" ref={drag}>
    <Handle />
  </div>
));
