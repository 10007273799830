import React from 'react';
import {useIntl} from 'react-intl';

import Icon from 'components/Icon';

import {messages} from '../messages';

interface Props {
  onDelete: () => void;
  canMoveUp: boolean;
  onMoveUp: () => void;
  canMoveDown: boolean;
  onMoveDown: () => void;
  canDelete: boolean;
}

export function XEditorCardFooter({
  onDelete,
  canMoveUp,
  onMoveUp,
  canMoveDown,
  onMoveDown,
  canDelete
}: Props) {
  const {formatMessage} = useIntl();

  return (
    <div className="card-footer">
      <button
        className="btn btn-ico"
        disabled={!canDelete}
        onClick={onDelete}
        title={formatMessage(messages.DeleteCard)}
      >
        <Icon name="trash" />
      </button>

      <button
        className="btn btn-ico"
        onClick={onMoveUp}
        disabled={!canMoveUp}
        title={formatMessage(messages.MoveCardUp)}
      >
        <Icon name="pc-left-arrow" className="i-rotate-90" />
      </button>

      <button
        className="btn btn-ico"
        onClick={onMoveDown}
        disabled={!canMoveDown}
        title={formatMessage(messages.MoveCardDown)}
      >
        <Icon name="pc-right-arrow" className="i-rotate-90" />
      </button>
    </div>
  );
}
