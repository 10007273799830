import {type Editor} from '@englex/slate';

import {
  STYLED_LIST_CHANGE_CONTENT,
  STYLED_LIST_CHANGE_STYLE,
  STYLED_LIST_SWITCH_WITH_NUMBERING,
  STYLED_LIST_CHANGE_TWO_COLUMNS
} from './actionTypes';
import {type XWidgetAction} from '../../actions/interface';

export interface XStyledListChangeContent extends XWidgetAction {
  content: Editor;
}

export interface XStyledListChangeStyle extends XWidgetAction {
  styleName: string;
}

export interface XStyledListChangeTwoColumnsAction extends XWidgetAction {
  twoColumnsCheckbox: boolean;
}

export const changeContent = (xwidgetId: string, content: Editor): XStyledListChangeContent => ({
  type: STYLED_LIST_CHANGE_CONTENT,
  xwidgetId,
  content
});

export const changeStyle = (xwidgetId: string, styleName: string): XStyledListChangeStyle => ({
  type: STYLED_LIST_CHANGE_STYLE,
  xwidgetId,
  styleName
});

export const switchWithNumbering = (xwidgetId: string): XWidgetAction => {
  return {
    type: STYLED_LIST_SWITCH_WITH_NUMBERING,
    xwidgetId
  };
};

export const changeTwoColumns = (
  xwidgetId: string,
  twoColumnsCheckbox: boolean
): XStyledListChangeTwoColumnsAction => {
  return {
    type: STYLED_LIST_CHANGE_TWO_COLUMNS,
    xwidgetId,
    twoColumnsCheckbox
  };
};
