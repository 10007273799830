import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import {default as Checkbox, type CheckboxProps} from 'react-bootstrap/lib/Checkbox';
import {type FormikProps, validateYupSchema, withFormik, yupToFormErrors} from 'formik';
import {type FormikBag} from 'formik/dist/withFormik';
import {FormattedMessage, type WrappedComponentProps, injectIntl} from 'react-intl';
import FormControl from 'react-bootstrap/lib/FormControl';
import classNames from 'classnames';
import * as Yup from 'yup';
import {pipe, trim, removeHidden, collapseSpaces, toLowerCase, pipeline} from '@englex/utils';

import Icon from 'components/Icon';

import {type ToolBoxChildrenProps} from '../../../interface';
import {type GapFillEditFormProps} from '../';
import FormControlTooltip from './FormControlTooltip';
import {FormFooter} from './FormFooter';

import './EditGapForm.scss';

interface Values {
  answer: string;
  choices: string[];
  example: boolean;
}

type Props = GapFillEditFormProps & ToolBoxChildrenProps & WrappedComponentProps;

type FormProps = Props & FormikProps<Values>;

class EditGapDropDown extends React.Component<FormProps> {
  public render() {
    const {handleSubmit, errors, values, handleChange, del} = this.props;
    const answerClasses = classNames('answer-row', {'has-error': !!errors.answer});
    return (
      <form className="edit-gap-form" onSubmit={handleSubmit}>
        <Modal.Body className="no-header">
          <div className="label-answer">
            <FormattedMessage id="XEditor.Form.EditGap.CorrectAnswer" />
          </div>
          <div className={answerClasses}>
            <FormControlTooltip
              state={errors.answer ? 'error' : null}
              content={errors.answer ? errors.answer : null}
            >
              <FormControl
                className="correct"
                autoFocus={this.correctAnswerAutoFocus}
                autoComplete="off"
                bsSize="sm"
                value={values.answer}
                name="answer"
                onChange={handleChange}
              />
            </FormControlTooltip>
            {this.renderEmptyDelete()}
          </div>

          <div className="label-answer">
            <FormattedMessage id="XEditor.Form.EditGap.IncorrectChoices" />
          </div>

          {values.choices.map(this.renderInput)}

          <Button bsSize="sm" bsStyle="success" className="add-gap" onClick={this.addAnswer}>
            <FormattedMessage
              id="XEditor.Form.EditGap.AddAnswer"
              values={{
                icon: <Icon name="plus-circle" />
              }}
            />
          </Button>

          <Button bsStyle={null} className="btn-ico toolbox-close" onClick={this.close}>
            <Icon name="pc-close" />
          </Button>
          <Checkbox name="example" onChange={this.handleCheckbox} defaultChecked={values.example}>
            <FormattedMessage id="XEditor.Example" />
          </Checkbox>
        </Modal.Body>
        <FormFooter
          showDeleteButton={!!del}
          submitDisabled={this.isDisabled}
          close={this.close}
          deleteGap={this.deleteGap}
        />
      </form>
    );
  }

  private handleCheckbox = (e: React.SyntheticEvent<CheckboxProps>) =>
    this.props.setFieldValue('example', e.currentTarget.checked);

  private renderEmptyDelete = () => {
    return <div className="answer-delete empty" />;
  };

  private renderInput = (answer: string, key: number) => {
    const {values, handleChange, intl, errors} = this.props;
    const choiceError = errors.choices && errors.choices[key] ? errors.choices[key] : null;

    const answerClasses = classNames('answer-row', {'has-error': choiceError});

    const lastKey = values.choices.length - 1;
    const isOnlyOneChoice = values.choices.length === 1;

    return (
      <div className={answerClasses} key={key}>
        <FormControlTooltip
          state={choiceError ? 'error' : null}
          content={choiceError ? choiceError : null}
        >
          <FormControl
            className="incorrect"
            autoFocus={!this.correctAnswerAutoFocus && key === lastKey}
            autoComplete="off"
            bsSize="sm"
            value={answer}
            name={`choices[${key}]`}
            onChange={handleChange}
          />
        </FormControlTooltip>

        {isOnlyOneChoice ? (
          this.renderEmptyDelete()
        ) : (
          <Button
            bsStyle={null}
            className="btn-ico answer-delete"
            onClick={() => this.deleteAnswer(key)}
            title={intl.formatMessage({id: 'Common.Delete'})}
          >
            <Icon name="trash" />
          </Button>
        )}
      </div>
    );
  };

  private close = () => {
    this.props.close();
  };

  private addAnswer = () => {
    const {values} = this.props;
    values.choices.push('');
    this.props.setFieldValue('choices', values.choices, false);
  };

  private get correctAnswerAutoFocus() {
    return !this.props.del && this.props.values.choices.length === 1;
  }

  private deleteAnswer = (key: number) => {
    const {choices} = this.props.values;
    this.props.setFieldValue(
      'choices',
      choices.filter((choice, index) => index !== key)
    );
  };

  private deleteGap = () => {
    const {del, close} = this.props;
    if (del) {
      del();
      close(false);
    }
  };

  private get isDisabled() {
    return this.props.isSubmitting;
  }
}

interface FormContext {
  answer: string;
}

const validationSchema = (props: Props) => {
  return Yup.object().shape({
    answer: Yup.string().trim(),
    choices: Yup.array()
      .min(1)
      .of(
        Yup.string()
          .default('')
          .trim()
          .test(
            'uniqueAnswer',
            props.intl.formatMessage({id: 'XEditor.Form.EditGap.Validation.Answer.Unique'}),
            function (value: string) {
              const mutator = pipe(trim, removeHidden, collapseSpaces, toLowerCase);
              const strippedSpacesValue = mutator(value);
              const context = this.options.context as FormContext;
              const choices = this.parent as string[];
              const answer: string = mutator(context.answer);
              const sameChoices = choices.filter(ans => mutator(ans) === strippedSpacesValue);

              return answer !== strippedSpacesValue && sameChoices.length <= 1;
            }
          )
      )
  });
};

/**
 * Validate form function
 * TODO: Used here just to make possible add custom uniqueAnswer validation, until validationSchemaContext will be
 *       available in formik out of box @see https://github.com/jaredpalmer/formik/pull/522
 *
 * @param {Values} values
 * @param {FormProps} props
 * @returns {any}
 */
const validateForm = (values: Values, props: FormProps) => {
  try {
    validateYupSchema<Values>(values, validationSchema(props), true, {
      answer: pipeline(values.answer, trim, removeHidden, collapseSpaces)
    });
  } catch (err) {
    return yupToFormErrors(err);
  }

  return {};
};

const mapPropsToValues = (props: Props): Values => {
  const {data, defaultAnswer} = props;
  const answers = data ? [...data.get('answer')] : [defaultAnswer ? defaultAnswer : ''];
  const answer = answers[0];
  const example = !!props.data && !!props.data.get('example');
  let choices = data && data.has('choices') ? data.get('choices')! : [];
  // remove answer from choices
  choices = choices.filter(c => c !== answer);
  if (!choices.length) {
    // ensure that at least one empty choice exist
    choices.push('');
  }
  return {
    answer,
    choices,
    example
  };
};

const handleFormSubmit: (values: Values, formik: FormikBag<Props, Values>) => void = (
  values,
  formik
) => {
  const {answer, choices, example} = values;
  const mutator = pipe(trim, removeHidden, collapseSpaces);
  const ans = mutator(answer);
  const ch = choices.map(mutator);

  formik.props.save([ans], {
    example: example || undefined,
    choices: [ans, ...ch].sort()
  });

  formik.props.close(false);
};

const FormikEditGapDropDown = withFormik<Props, Values>({
  mapPropsToValues,
  handleSubmit: handleFormSubmit,
  validate: validateForm
})(EditGapDropDown);

export default injectIntl(FormikEditGapDropDown);
