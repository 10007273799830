import React, {type FC, memo} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {useDispatch} from 'react-redux';
import Checkbox from 'react-bootstrap/lib/Checkbox';

import Icon from 'components/Icon';
import {changeStyle, changeTwoColumns} from 'store/exercise/editor/widgets/XStyledList/actions';
import {StyledListStyles} from 'store/exercise/player/widgets/List/StyledList/interface';
import {VerticalNumberedItem} from 'components/Slate/SlateEditor/plugins/button/List/StylesList/components/VerticalNumberedItem/VerticalNumberedItem';
import {VerticalBulletedItem} from 'components/Slate/SlateEditor/plugins/button/List/StylesList/components/VerticalBulletedItem/VerticalBulletedItem';

import './VerticalStylesModal.scss';

interface Props {
  opened: boolean;
  widgetId: string;
  styleName: string;
  twoColumns: boolean;
  onClose: () => void;
  onSelect: () => void;
}

const numbered = [
  StyledListStyles.NUMBERED_BLUE,
  StyledListStyles.NUMBERED_GREEN,
  StyledListStyles.NUMBERED_ORANGE,
  StyledListStyles.NUMBERED_WHITE,
  StyledListStyles.NUMBERED_WHITE_OUTLINE
];
const bulleted = [
  StyledListStyles.BULLETED_BLUE,
  StyledListStyles.BULLETED_GREEN,
  StyledListStyles.BULLETED_ORANGE,
  StyledListStyles.BULLETED_WHITE
];

export const VerticalStylesModal: FC<Props> = memo(
  ({opened, onClose, onSelect, widgetId, styleName, twoColumns}) => {
    const dispatch = useDispatch();

    const getOnChange = (key: string) => {
      return () => dispatch(changeStyle(widgetId, key));
    };

    const onChangeColumns = () => {
      dispatch(changeTwoColumns(widgetId, !twoColumns));
    };

    return (
      <Modal backdrop="static" className="vertical-style-modal" onHide={onClose} show={opened}>
        <Modal.Header className="header">
          <FormattedMessage id="XEditor.XWidget.StyledList.ModalTitle" />
          <Button className="btn-xs" onClick={onClose}>
            <Icon name="pc-close i-lg" />
          </Button>
        </Modal.Header>
        <Modal.Body className="body">
          <div className="style-items">
            <div className="numbered-items">
              <h4 className="subtitle">
                <FormattedMessage id="XEditor.XWidget.StyledList.Numbered" />
              </h4>
              {numbered.map((style: StyledListStyles) => (
                <div key={style} className={`style-card`}>
                  <label className="style-card__label">
                    <input
                      className="style-card__radio"
                      type="radio"
                      name="styleName"
                      value={style}
                      defaultChecked={styleName === style}
                      onChange={getOnChange(style)}
                    />
                    <span className="style-card__radio-border">
                      <span className="styled-card__radio-circle" />
                    </span>
                    <VerticalNumberedItem
                      index={0}
                      attributes={{}}
                      id={style}
                      styleName={style}
                      isActive={false}
                      children={'List Item 1'}
                      onClick={() => {}}
                    />
                  </label>
                </div>
              ))}
            </div>

            <div className="bulleted-items">
              <h4 className="subtitle">
                <FormattedMessage id="XEditor.XWidget.StyledList.Bulleted" />
              </h4>
              {bulleted.map((style: StyledListStyles) => (
                <div key={style} className={`style-card`}>
                  <label className="style-card__label">
                    <input
                      className="style-card__radio"
                      type="radio"
                      name="styleName"
                      value={style}
                      defaultChecked={styleName === style}
                      onChange={getOnChange(style)}
                    />
                    <span className="style-card__radio-border">
                      <span className="styled-card__radio-circle" />
                    </span>
                    <VerticalBulletedItem
                      attributes={{}}
                      id={style}
                      styleName={style}
                      isActive={false}
                      children={'List Item 1'}
                      onClick={() => {}}
                    />
                  </label>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer">
          <div className="modal-controls">
            <Checkbox onChange={onChangeColumns} checked={twoColumns}>
              <span />
              <FormattedMessage id="XEditor.XWidget.StyledList.TwoColumns" />
            </Checkbox>

            <Button bsSize="small" onClick={onClose}>
              <FormattedMessage id="MediaDevicesWizard.ButtonClose" />
            </Button>

            <Button bsSize="small" bsStyle="primary" onClick={onSelect}>
              <FormattedMessage id="Common.Save" />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
);
