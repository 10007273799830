import {type FC} from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';

interface RadioButton {
  [key: string]: JSX.Element;
}

interface Data {
  name: string;
  title: JSX.Element;
  radioButtons: RadioButton;
}

interface Props {
  value: string;
  data: Data;
  disabled?: boolean;
  callBack: (key: string, value: string) => () => void;
}

export const RadioGroup: FC<Props> = ({value, data, disabled, callBack}) => {
  return (
    <>
      <p>
        <b>{data.title}</b>
      </p>

      <FormGroup>
        {Object.keys(data.radioButtons).map((item: string) => (
          <Radio
            name={data.name}
            inline={true}
            onChange={callBack(data.name, item)}
            checked={value === item}
            disabled={disabled}
            key={item}
          >
            <span>{data.radioButtons[item]}</span>
          </Radio>
        ))}
      </FormGroup>
    </>
  );
};
