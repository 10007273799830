import React, {Component, type SyntheticEvent} from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import {default as Checkbox, type CheckboxProps} from 'react-bootstrap/lib/Checkbox';
import {type FormikProps, withFormik} from 'formik';
import {type FormikBag} from 'formik/dist/withFormik';
import {FormattedMessage, type WrappedComponentProps, injectIntl} from 'react-intl';
import FormControl from 'react-bootstrap/lib/FormControl';
import * as Yup from 'yup';
import classNames from 'classnames';
import {pipe, trim, collapseSpaces, removeHidden, pipeline} from '@englex/utils';

import Icon from 'components/Icon';

import {type ToolBoxChildrenProps} from '../../../interface';
import {type GapFillEditFormProps} from '../';
import FormControlTooltip from './FormControlTooltip';
import {FormFooter} from './FormFooter';

import './EditGapForm.scss';

type Props = GapFillEditFormProps & ToolBoxChildrenProps & WrappedComponentProps;

type FormProps = Props & FormikProps<Values>;

interface Values {
  answer: string;
  caseSensitive: boolean;
  example: boolean;
  startOfSentence: boolean;
}

interface State {}

class EditGapDND extends Component<FormProps, State> {
  public render() {
    const {handleSubmit, errors, values, handleChange, del} = this.props;
    const answerClasses = classNames('answer-row', {'has-error': !!errors.answer});
    return (
      <form className="edit-gap-form" onSubmit={handleSubmit}>
        <Modal.Body className="no-header">
          <div className="label-answer">
            <FormattedMessage id="XEditor.Form.EditGap.CorrectAnswer" />
          </div>
          <div className={answerClasses}>
            <FormControlTooltip
              state={errors.answer ? 'error' : null}
              content={errors.answer ? errors.answer : null}
            >
              <FormControl
                className="correct"
                autoFocus={true}
                autoComplete="off"
                bsSize="sm"
                value={values.answer}
                name="answer"
                onChange={handleChange}
              />
            </FormControlTooltip>
            <div className="answer-delete empty" />
          </div>

          <Button bsStyle={null} className="btn-ico toolbox-close" onClick={this.close}>
            <Icon name="pc-close" />
          </Button>
          <Checkbox
            name="startOfSentence"
            onChange={this.toggleStartOfSentence}
            checked={values.startOfSentence}
          >
            <FormattedMessage id="XEditor.Form.EditGap.StartOfSentence" />
          </Checkbox>
          <Checkbox
            name="caseSensitive"
            onChange={this.toggleCaseSensitive}
            checked={values.caseSensitive}
          >
            <FormattedMessage id="XEditor.Form.EditGap.PreserveCapitalization" />
          </Checkbox>
          <Checkbox name="example" onChange={this.handleExampleCheckbox} checked={values.example}>
            <FormattedMessage id="XEditor.Example" />
          </Checkbox>
        </Modal.Body>
        <FormFooter
          showDeleteButton={!!del}
          submitDisabled={this.isDisabled}
          close={this.close}
          deleteGap={this.deleteGap}
        />
      </form>
    );
  }

  private handleExampleCheckbox = (e: SyntheticEvent<CheckboxProps>) =>
    this.props.setFieldValue('example', e.currentTarget.checked);
  private toggleCaseSensitive = (e: SyntheticEvent<CheckboxProps>) =>
    this.props.setFieldValue('caseSensitive', e.currentTarget.checked);
  private toggleStartOfSentence = (e: SyntheticEvent<CheckboxProps>) =>
    this.props.setFieldValue('startOfSentence', e.currentTarget.checked);

  private close = () => {
    this.props.close();
  };

  private deleteGap = () => {
    const {del, close} = this.props;
    if (del) {
      del();
      close(false);
    }
  };

  private get isDisabled() {
    return this.props.isSubmitting;
  }
}

const mapPropsToValues = (props: Props): Values => {
  const answers = props.data
    ? [...props.data.get('answer')]
    : [props.defaultAnswer ? props.defaultAnswer : ''];
  const answer = answers.shift()!;
  const caseSensitive = !!props.data && !!props.data.get('caseSensitive');
  const example = !!props.data && !!props.data.get('example');
  const startOfSentence = !!props.data && !!props.data.get('startOfSentence');
  return {
    answer,
    caseSensitive,
    example,
    startOfSentence
  };
};

const validationSchema = (props: Props) => {
  return Yup.object().shape({
    answer: Yup.string()
      .transform(pipe(trim, removeHidden, collapseSpaces))
      .required(props.intl.formatMessage({id: 'XEditor.Form.EditGap.Validation.Answer.Required'}))
  });
};

const handleFormSubmit: (values: Values, formik: FormikBag<Props, Values>) => void = (
  values,
  formik
) => {
  const {example, caseSensitive, answer, startOfSentence} = values;
  const answers = [pipeline(answer, trim, removeHidden, collapseSpaces)];

  formik.props.save(answers, {
    example: example || undefined,
    caseSensitive: caseSensitive || undefined,
    startOfSentence: startOfSentence || undefined
  });

  formik.props.close(false);
};

const FormikEditGapDND = withFormik<Props, Values>({
  mapPropsToValues,
  handleSubmit: handleFormSubmit,
  validationSchema
})(EditGapDND);

export default injectIntl(FormikEditGapDND);
