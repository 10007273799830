import {type Editor} from '@englex/slate';
import {type List} from 'immutable';
import React, {useCallback, memo} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {
  changeMessage,
  moveMessageDown,
  moveMessageUp,
  removeMessage
} from 'store/exercise/editor/widgets/XDialogue/actions';
import {type XDialogueSpeakerProperties} from 'store/exercise/editor/widgets/XDialogue/interface';
import type XDialogueMessageRecord from 'store/exercise/editor/widgets/XDialogue/XDialogueMessageRecord';

import {localMessages} from '../../messages';
import {MessageCardView} from './MessageCardView';

interface Props {
  widgetId: string;
  speakers: List<XDialogueSpeakerProperties>;
  message: XDialogueMessageRecord;
  withError: boolean;
  isFirst: boolean;
  isLast: boolean;
  resetErrors: () => void;
  onChangeSpeaker: (messageId: string, speakerId: string) => void;
}

export const MessageCard: React.FC<Props> = memo(
  ({widgetId, speakers, message, withError, isFirst, isLast, resetErrors, onChangeSpeaker}) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();

    const {id: messageId, speakerId} = message;

    const {name, avatar} = speakers.find(speaker => speaker?.id === speakerId);

    const placeholder = formatMessage(localMessages.EnterMessage);
    const deleteTitle = formatMessage(localMessages.DeleteMessage);
    const editTitle = formatMessage(localMessages.EditMessage);
    const moveUpTitle = formatMessage(localMessages.MoveUp);
    const moveDownTitle = formatMessage(localMessages.MoveDown);

    const onChangeMessage = useCallback(
      (editor: Editor) => {
        resetErrors();
        dispatch(changeMessage(widgetId, messageId, editor.value));
      },
      [dispatch, messageId, resetErrors, widgetId]
    );

    const onDeleteMessage = useCallback(() => {
      dispatch(removeMessage(widgetId, messageId));
      resetErrors();
    }, [dispatch, widgetId, messageId, resetErrors]);

    const onMoveMessageDown = useCallback(() => {
      dispatch(moveMessageDown(widgetId, messageId));
    }, [widgetId, messageId, dispatch]);

    const onMoveMessageUp = useCallback(() => {
      dispatch(moveMessageUp(widgetId, messageId));
    }, [widgetId, messageId, dispatch]);

    const changeSpeakerHandle = useCallback(() => {
      onChangeSpeaker(messageId, speakerId);
    }, [messageId, onChangeSpeaker, speakerId]);

    return (
      <MessageCardView
        speakerId={speakerId}
        avatar={avatar}
        name={name}
        message={message.message}
        placeholder={placeholder}
        deleteTitle={deleteTitle}
        editTitle={editTitle}
        moveUpTitle={moveUpTitle}
        moveDownTitle={moveDownTitle}
        withError={withError}
        isFirst={isFirst}
        isLast={isLast}
        onDeleteMessage={onDeleteMessage}
        onChangeMessage={onChangeMessage}
        onMoveMessageDown={onMoveMessageDown}
        onMoveMessageUp={onMoveMessageUp}
        onChangeSpeaker={changeSpeakerHandle}
      />
    );
  }
);
