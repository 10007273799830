import React, {type FC, useCallback} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {useNavigate, useParams} from 'react-router-dom';

import {type XEditorUrlParams} from 'store/exercise/editor/interface';
import Icon from 'components/Icon';

interface Props {
  onClick: () => void;
  editingDisabled: boolean;
}

export const CancelExercise: FC<Props> = ({editingDisabled, onClick}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {coursebookId, unitId} = useParams<XEditorUrlParams>();

  const cancel = useCallback(() => {
    navigate(`/library/coursebook/${coursebookId}/unit/${unitId}`);
    onClick();
  }, [coursebookId, navigate, onClick, unitId]);

  return (
    <Button
      bsSize="sm"
      className="btn-transparent"
      onClick={cancel}
      disabled={editingDisabled}
      title={intl.formatMessage({id: 'XEditor.Cancel'})}
    >
      <FormattedMessage id="XEditor.Cancel" />
      <Icon name="pc-close" />
    </Button>
  );
};
