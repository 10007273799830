import React, {Component} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {type Editor, type Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';

import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import TheOnlyOrderedList from 'components/Slate/SlateEditor/plugins/button/List/SpecificCases';
import {DraggableBlock} from 'components/Slate/SlateEditor/plugins/button/DraggableBlock';
import {type AppState} from 'store/interface';
import {type XScrambledProperties} from 'store/exercise/editor/widgets/XScrambled/interface';
import {
  scrambledSentencesChange,
  toggleContainsExample
} from 'store/exercise/editor/xwidgetActions';
import {WordDecorator} from 'components/Slate/SlateEditor/plugins/TextDecorator';

import ToggleExample from './ToggleExample';

import './styles.scss';

interface OwnProps {
  id: string;
}

interface StateProps {
  content: Value;
  containsExample: boolean;
  manualSplitting: boolean;
}

interface DispatchProps {
  onChange(id: string, change: Editor): void;
  toggleExample(id: string): void;
}

type Props = OwnProps & StateProps & DispatchProps;

class XEditorScrambledSentences extends Component<Props> {
  private readonly plugins: Plugin[] = [
    this.props.manualSplitting && new DraggableBlock(),
    new Undo(),
    new Redo(),
    this.props.manualSplitting || new WordDecorator(),
    new TheOnlyOrderedList()
  ].filter(Boolean) as Plugin[];

  public render() {
    const {content, containsExample} = this.props;
    return (
      <>
        <div
          className={classNames('scrambled-sentences', {
            'example-sentence': containsExample
          })}
        >
          <SlateEditor
            value={content}
            onChange={this.onChange}
            plugins={this.plugins}
            toolbar={{portalId: 'xeditor-toolbar-portal'}}
            spellcheck={false}
          />
        </div>
        <ToggleExample checked={containsExample} onChange={this.toggleExample} />
      </>
    );
  }

  private onChange = (change: Editor) => this.props.onChange(this.props.id, change);
  private toggleExample = () => this.props.toggleExample(this.props.id);
}

const mapStateToProps = (state: AppState, {id}: OwnProps): StateProps => {
  const xwidget = state.xeditor!.xexercise.widgets.find(
    (x: XScrambledProperties) => x.id === id
  ) as XScrambledProperties;

  const {sentencesValue, options} = xwidget;
  return {
    content: sentencesValue,
    containsExample: options?.get('containsExample') || false,
    manualSplitting: options?.get('manualSplitting') || false
  };
};

export default connect(mapStateToProps, {
  onChange: scrambledSentencesChange,
  toggleExample: toggleContainsExample
})(XEditorScrambledSentences);
