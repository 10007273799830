import React, {type FC} from 'react';
import {connect, type MapDispatchToProps} from 'react-redux';
import {type ThunkDispatch} from 'redux-thunk';
import {type Action} from 'redux';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import {useParams} from 'react-router-dom';

import {type AppState} from 'store/interface';
import {
  type XEditorDispatchProps,
  type XEditorStateProps,
  type XEditorUrlParams
} from 'store/exercise/editor/interface';
import {
  type CoursebookUnitExerciseResponse,
  loadExercise,
  requestExerciseSuccess,
  resetLoading,
  setUnitExercise
} from 'store/exercise/editor/actions/xeditor';
import {type ExerciseJSON} from 'store/exercise/player/interface';
import XExerciseRecord from 'store/exercise/editor/widgets/XExerciseRecord';
import Icon from 'components/Icon';
import {toggleWizard} from 'store/exercise/editor/actions/xwizard';
import {newXExercise, resetXEditor} from 'store/exercise/editor/actions/xexercise';
import {deleteDraftExercise} from 'store/exercise/editor/actions/xdraftexercises';
import Confirm from 'components/modals/Confirm';
import WampErrorMask from 'components/WampErrorMask';
import Loader from 'components/Loader';

import {PageNotFound} from '../../PageNotFound/components/PageNotFound';
import XEditorPage from './XEditorPage';

type OwnProps = XEditorUrlParams;
type Props = OwnProps & XEditorStateProps & XEditorDispatchProps & WrappedComponentProps;

interface State {
  wasCompared: boolean;
  isOpenModal: boolean;
}

export class XEditorRouteComponent extends React.Component<Props, State> {
  state = {wasCompared: false, isOpenModal: false};

  private exercise?: ExerciseJSON;

  private static compareExercises(exerciseA?: ExerciseJSON, exerciseB?: ExerciseJSON) {
    if (!exerciseA || !exerciseB) {
      return true;
    }

    const preparedA = {
      id: exerciseB.id,
      title: exerciseA.title,
      widgets: exerciseA.widgets,
      mediaSources: exerciseA.mediaSources
    };

    const preparedB = {
      id: exerciseB.id,
      title: exerciseB.title,
      widgets: exerciseB.widgets,
      mediaSources: exerciseB.mediaSources
    };

    const xexerciseA = new XExerciseRecord(preparedA);
    const xexerciseB = new XExerciseRecord(preparedB);

    return JSON.stringify(xexerciseA) === JSON.stringify(xexerciseB);
  }

  public componentDidMount() {
    this.loadExercise();
  }

  public componentWillUnmount(): void {
    this.props.resetXEditor();
  }

  public render() {
    const {exerciseId} = this.props;
    const isLoading =
      this.props.loading ||
      !this.state.wasCompared ||
      !this.props.xexercise ||
      !this.props.unitExercise;

    if (this.props.loadingError) {
      return this.renderError();
    }

    const {title, text, acceptButton, declineButton} = this.getTranslations();

    return (
      <>
        {isLoading ? <Loader /> : <XEditorPage exerciseId={exerciseId} />}
        <Confirm
          className="large"
          show={this.state.isOpenModal}
          onAccept={this.onAccept}
          onDecline={this.onDecline}
          headerIcon={<Icon name="warning" tag="i" className="header-icon" />}
          headerText={title}
          bodyText={text}
          acceptButtonText={acceptButton}
          declineButtonText={declineButton}
          hideCloseButton={true}
          disableButtons={false}
          inverted={true}
          keyboard={false}
        />
      </>
    );
  }

  private getTranslations() {
    const {
      intl: {formatMessage},
      isNew
    } = this.props;

    return {
      title: isNew
        ? formatMessage({id: 'XEditor.UnsavedExerciseDetected.Title'})
        : formatMessage({id: 'XEditor.UnsavedChangesDetected.Title'}),
      text: isNew
        ? formatMessage({id: 'XEditor.UnsavedExerciseDetected.Text'})
        : formatMessage({id: 'XEditor.UnsavedChangesDetected.Text'}),
      acceptButton: isNew
        ? formatMessage({id: 'XEditor.UnsavedExerciseDetected.OpenUnsaved'})
        : formatMessage({id: 'XEditor.UnsavedChangesDetected.RestoreChanges'}),
      declineButton: isNew
        ? formatMessage({id: 'XEditor.UnsavedExerciseDetected.CreateNew'})
        : formatMessage({id: 'XEditor.UnsavedChangesDetected.DiscardChanges'})
    };
  }

  private openModal() {
    this.setState(prevState => ({...prevState, isOpenModal: true, wasCompared: false}));
  }

  private closeModal() {
    this.setState(prevState => ({...prevState, isOpenModal: false, wasCompared: true}));
  }

  private setLocalXExercise() {
    if (this.props.draft) {
      const {id, role, profile} = this.props.user;
      const {first_name: firstName, last_name: lastName, email} = profile!;

      const ownership = {
        author: {
          role,
          id: id!,
          profile: {
            firstName,
            lastName,
            email
          }
        }
      };

      const exercise = {...this.props.draft.toJSON(), ownership};
      this.props.setXExercise(exercise);
    }
  }

  private setServerXExercise() {
    if (this.exercise) {
      this.props.setXExercise(this.exercise);
      this.exercise = undefined;
    }
  }

  private onAccept = () => {
    this.setLocalXExercise();

    this.closeModal();
  };

  private onDecline = () => {
    this.setServerXExercise();

    this.closeModal();

    if (this.props.isNew) {
      this.props.openWizard();
    }

    this.props.deleteDraft(`${this.props.draft.id!}`);
  };

  private renderError() {
    if (this.props.loadingError === 'not-found') {
      return <PageNotFound />;
    } else {
      return <WampErrorMask reload={this.reset} />;
    }
  }

  private loadExercise = () => {
    this.setState({isOpenModal: false, wasCompared: false});

    if (this.props.isNew) {
      this.loadNewExercise();
    } else {
      this.loadExistingExercise();
    }
  };

  private loadExistingExercise = () => {
    const {coursebookId, unitId, exerciseId} = this.props;

    this.props
      .loadExercise(coursebookId, Number(unitId), exerciseId, true)
      .then((response: CoursebookUnitExerciseResponse) => {
        const {exercise, unit, pageNumber, ordinal, parentExerciseId, grammar} = response;
        const unitExercise = {unit, pageNumber, ordinal, parentExerciseId};

        this.props.setUnitExercise(unitExercise);

        this.compareExercises({...exercise!, grammar, isNew: false});
      });
  };

  private loadNewExercise = () => {
    const {coursebookId, unitId, exerciseId} = this.props;

    this.props
      .loadExercise(coursebookId, Number(unitId), exerciseId)
      .then((exercise: ExerciseJSON) => {
        if (this.props.isCopy) {
          this.props.setXExercise(exercise);
          return this.setState(prevState => ({...prevState, wasCompared: true}));
        }

        return this.compareExercises({...exercise, isNew: false});
      });
  };

  private getUnitLevels() {
    const {unitExercise} = this.props;

    return unitExercise?.unit.coursebook.languageLevelIds?.map(l => ({id: l, title: ''})) || [];
  }

  private getInitExerciseMeta() {
    return {
      categories: [],
      levels: this.getUnitLevels()
    };
  }

  private compareExercises(xexercise: ExerciseJSON) {
    const isEqual = XEditorRouteComponent.compareExercises(xexercise, this.props.draft?.toJSON());

    const exercise = this.props.isNew
      ? {
          ...xexercise,
          meta: this.getInitExerciseMeta()
        }
      : xexercise;

    if (isEqual) {
      this.props.setXExercise(exercise);

      this.setState(prevState => ({...prevState, wasCompared: true}));

      if (this.props.isNew) this.props.openWizard();
    } else {
      this.exercise = exercise;
      this.openModal();
    }
  }

  private reset = () => {
    this.props.resetXEditor();
    this.loadExercise();
  };
}

const mapStateToProps = (state: AppState, {exerciseId}: OwnProps): XEditorStateProps => {
  const {user, xeditor} = state;
  const {unitExercise, loading, loadingError, xexercise, draftExercises} = xeditor!;

  return {
    user,
    unitExercise,
    loading,
    loadingError,
    xexercise,
    isNew: false,
    isCopy: false,
    draft: draftExercises.get(exerciseId!)
  };
};

const mapDispatchToProps: MapDispatchToProps<XEditorDispatchProps, OwnProps> = (
  dispatch: ThunkDispatch<AppState, void, Action>
) => ({
  deleteDraft: (id: string) => dispatch(deleteDraftExercise(id)),
  setXExercise: (exercise: ExerciseJSON) => dispatch(newXExercise(exercise)),
  setUnitExercise: unitExercise => dispatch(setUnitExercise(unitExercise)),
  openWizard: () => dispatch(toggleWizard()),
  resetLoading: () => dispatch(resetLoading()),
  resetXEditor: () => dispatch(resetXEditor()),
  requestExerciseSuccess: (data: CoursebookUnitExerciseResponse) =>
    dispatch(requestExerciseSuccess(data)),
  loadExercise: (
    coursebookId: string,
    unitId: number,
    exerciseId: string,
    preventDispatch: boolean
  ) => dispatch(loadExercise(coursebookId, unitId, exerciseId, preventDispatch))
});

const ConnectedRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(XEditorRouteComponent));

const XEditorRoute: FC = () => {
  const {exerciseId, unitId, coursebookId} = useParams<XEditorUrlParams>();
  return <ConnectedRoute unitId={unitId!} exerciseId={exerciseId} coursebookId={coursebookId!} />;
};

export default XEditorRoute;
