import {type Editor, type Value} from '@englex/slate';
import React, {memo, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {changeText, removeCard} from 'store/exercise/editor/widgets/XWordPictureSet/actions';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Icon from 'components/Icon';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';

import {messages} from '../../messages';

import './WordSetCard.scss';

interface Props {
  widgetId: string;
  cardId: string;
  text: Value;
  resetErrors: () => void;
}

const slatePlugins = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting()
];

const toolbarProp = {portalId: 'xeditor-toolbar-portal'};

export const XWordSetCard: React.FC<Props> = memo(({widgetId, text, cardId, resetErrors}) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();

  const onChange = useCallback(
    (change: Editor) => {
      dispatch(changeText(widgetId, cardId, change));
      resetErrors();
    },
    [dispatch, widgetId, cardId, resetErrors]
  );

  const onDelete = () => {
    dispatch(removeCard(widgetId, cardId));
    resetErrors();
  };

  return (
    <div className="word-set__card">
      <SlateEditor
        value={text}
        placeholder={formatMessage(messages.Placeholder)}
        className="text-field"
        toolbar={toolbarProp}
        onChange={onChange}
        plugins={slatePlugins}
      />

      <button className="delete-btn" title={formatMessage(messages.DeleteWord)} onClick={onDelete}>
        <Icon name="trash" />
      </button>
    </div>
  );
});
