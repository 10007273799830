import React from 'react';
import {type RenderAnnotationProps} from '@englex/slate-react';
import {
  type Annotation,
  type Block,
  type Document,
  type Editor,
  type Next,
  type Node,
  Point
} from '@englex/slate';
import classNames from 'classnames';
import {type List, Map} from 'immutable';

import {getEditorId, SlateAnnotation} from '../../../interface';
import {genKey} from '../../../utils';
import {ButtonType} from '../interface';
import ToolbarButton from '../ToolbarButton';
import {Tooltip} from '../TextDecorator/Tooltip';
import {buttonTitle} from './i18n';
import {resolveLiIds} from '../TextDecorator/commands';
import {schema} from '../TextDecorator/schema';

const liAnnotations = (
  document: Document,
  li: Block,
  annotations: Map<string, Annotation>
): Map<string, Annotation> => {
  const liPath = document.getPath(li.key);
  return annotations.filter((a: Annotation) =>
    (a.anchor.path as List<number>).slice(0, 2).equals(liPath as List<number>)
  ) as Map<string, Annotation>;
};

export class DraggableBlock extends ToolbarButton {
  public icon = 'cubes';
  public shortcut = 'mod+option+b';
  public title = buttonTitle.DraggableBlock;
  public type = ButtonType.DRAGGABLE_BLOCK;

  public schema = schema;

  protected toggleChange = (editor: Editor) => {
    if (!editor.value.selection) return;

    const {start, end} = editor.value.selection;
    if (DraggableBlock.multipleSentencesSelected(editor)) return;
    const path = [...start.path.toArray().slice(0, 2), 0, 0];
    const selectedAnnotations = DraggableBlock.getSelectedAnnotations(editor);
    switch (selectedAnnotations.size) {
      case 0:
        DraggableBlock.addAnnotation(editor, path, start.offset, end.offset - start.offset);
        break;
      case 1:
        DraggableBlock.processOneAnnotation(editor, path, selectedAnnotations.first());
        break;
      default:
        DraggableBlock.processMultipleAnnotations(editor, path, selectedAnnotations);
    }
    selectedAnnotations.forEach((a: Annotation) => editor.removeAnnotation(a));
  };

  public onChange = (editor: Editor) => {
    resolveLiIds(editor, genKey);
    editor.value.annotations.forEach((a: Annotation) => {
      if (!a.anchor.key || a.anchor.offset === a.focus.offset) editor.removeAnnotation(a);
    });
    const {annotations, document} = editor.value;
    (document.nodes.get(0) as Block).nodes.forEach((li: Block) => {
      const blockAnnotations = liAnnotations(document, li, annotations);
      let prevAnnotation: Annotation | undefined;
      blockAnnotations
        .sort((a1, a2) => a1.start.offset - a2.start.offset)
        .forEach((a: Annotation) => {
          if (prevAnnotation) {
            if (prevAnnotation.end.offset >= a.start.offset) {
              editor.removeAnnotation(prevAnnotation).removeAnnotation(a);
              DraggableBlock.addAnnotation(
                editor,
                document.getPath(li.key)!.toArray(),
                prevAnnotation.start.offset,
                a.end.offset - prevAnnotation.start.offset
              );
            }
          }
          prevAnnotation = a;
        });
    });
  };

  public isActive = (editor: Editor): boolean => {
    if (DraggableBlock.multipleSentencesSelected(editor)) return false;

    const selectedAnnotations = DraggableBlock.getSelectedAnnotations(editor);
    if (selectedAnnotations.size !== 1) return false;
    if (!editor.value.selection) return false;

    const {start: sS, end: sE} = editor.value.selection;
    const {start: aS, end: aE} = selectedAnnotations.first();
    return sS.offset >= aS.offset && sE.offset <= aE.offset;
  };

  public isDisabled(editor: Editor): boolean {
    const {selection} = editor.value;
    if (super.isDisabled(editor)) return true;
    if (selection?.isCollapsed) return !this.isActive(editor);
    return DraggableBlock.multipleSentencesSelected(editor);
  }

  public renderAnnotation = (
    {attributes, children, annotation, node, offset, text}: RenderAnnotationProps,
    editor: Editor,
    next: Next
  ) => {
    if (annotation.type !== SlateAnnotation.PARSED_WORD) return next();
    if (text === '') return null;

    if (text === text.toLowerCase()) {
      return (
        <span className="word">
          <span {...attributes}>{children}</span>
        </span>
      );
    }

    return (
      <span className="word" onClick={e => e.stopPropagation()}>
        <Tooltip
          capitalize={annotation.data.get('capitalize')}
          getTooltipContainer={this.getTooltipContainer(editor)}
          toggleCapitalize={() => this.toggleCapitalization(editor, node, annotation)}
          contentEditable={false}
        >
          <span
            {...attributes}
            className={classNames('capitalizable', {
              checked: annotation.data.get('capitalize')
            })}
          >
            {children}
            <span className="dot" contentEditable={false} />
          </span>
        </Tooltip>
      </span>
    );
  };

  protected getTooltipContainer = (editor: Editor): (() => HTMLElement) => {
    if (!this.editorId) {
      this.editorId = editor.query<string>(getEditorId);
    }
    return () => document.getElementById(this.editorId)!;
  };

  private toggleCapitalization = (editor: Editor, node: Node, annotation: Annotation) => {
    editor.removeAnnotation(annotation);
    DraggableBlock.addAnnotation(
      editor,
      [...editor.value.document.getPath(node.key)!.toArray().slice(0, 2), 0, 0],
      annotation.anchor.offset,
      annotation.focus.offset - annotation.anchor.offset,
      annotation.data.get('capitalize')
        ? undefined
        : {
            capitalize: true
          }
    );
  };

  private static addAnnotation(
    editor: Editor,
    path: number[],
    offset: number,
    length: number,
    data?: {capitalize: true}
  ) {
    return editor.addAnnotation(
      editor.value.document.createAnnotation({
        type: SlateAnnotation.PARSED_WORD,
        key: genKey(),
        anchor: Point.create({
          offset,
          path
        }),
        focus: Point.create({
          offset: offset + length,
          path
        }),
        data
      })
    );
  }

  private static processOneAnnotation(
    editor: Editor,
    path: number[],
    {start: aS, end: aE}: Annotation
  ) {
    if (!editor.value.selection) return;

    const {start: sS, end: sE, isCollapsed} = editor.value.selection;
    if (isCollapsed) return;
    if (sS.offset === aS.offset && sE.offset === aE.offset) return;
    if (sS.offset >= aS.offset && sE.offset <= aE.offset) {
      if (sS.offset - aS.offset > 0)
        DraggableBlock.addAnnotation(editor, path, aS.offset, sS.offset - aS.offset);
      if (aE.offset - sE.offset > 0)
        DraggableBlock.addAnnotation(editor, path, sE.offset, aE.offset - sE.offset);
      return;
    }
    const anchor = sS.offset > aS.offset ? aS : sS;
    const focus = sE.offset > aE.offset ? sE : aE;
    DraggableBlock.addAnnotation(editor, path, anchor.offset, focus.offset - anchor.offset);
  }

  private static processMultipleAnnotations(
    editor: Editor,
    path: number[],
    annotations: Map<string, Annotation>
  ) {
    if (!editor.value.selection) return;

    const {start, end} = editor.value.selection;
    let [smallestStart, largestEnd] = [start, end];
    annotations.forEach((a: Annotation) => {
      if (a.start.offset < smallestStart.offset) smallestStart = a.start;
      if (a.end.offset > largestEnd.offset) largestEnd = a.end;
    });
    DraggableBlock.addAnnotation(
      editor,
      path,
      smallestStart.offset,
      largestEnd.offset - smallestStart.offset
    );
  }

  private static getSelectedAnnotations(editor: Editor): Map<string, Annotation> {
    if (!editor.value.selection) return Map();

    const {
      annotations,
      selection: {start, end}
    } = editor.value;
    return annotations.filter(
      ({start: {offset: s, path: p}, end: {offset: e}}: Annotation) =>
        start.path?.get(1) === p?.get(1) &&
        ((s <= start.offset && e >= start.offset) ||
          (s <= end.offset && e >= end.offset) ||
          (s >= start.offset && e <= end.offset))
    ) as Map<string, Annotation>;
  }

  private static multipleSentencesSelected(editor: Editor): boolean {
    if (!editor.value.selection) return false;

    const {start, end} = editor.value.selection;
    if (!start.path || !end.path) return false;
    return start.path.get(1) !== end.path.get(1);
  }
}
