import React from 'react';
import {type Editor, type Value} from '@englex/slate';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  type MessageDescriptor,
  type WrappedComponentProps
} from 'react-intl';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {type Action, type Dispatch} from 'redux';
import {type Plugin} from '@englex/slate-react';
import Checkbox from 'react-bootstrap/lib/Checkbox';

import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import TextAlignment from 'components/Slate/SlateEditor/plugins/button/TextAlignment';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Link from 'components/Slate/SlateEditor/plugins/button/Link';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Image from 'components/Slate/SlateEditor/plugins/button/Image';
import {NestedLists} from 'components/Slate/SlateEditor/plugins/button/List/NestedLists';
import ImageButton from 'components/Slate/SlateEditor/plugins/button/Image/ImageButton';
import {WidgetType} from 'store/exercise/player/interface';
import AddDialogBtn from 'components/Slate/SlateEditor/plugins/button/Table/Dialog/AddDialogBtn';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import FontSizeToolbar from 'components/Slate/SlateEditor/plugins/button/FontSize';
import {type AppState} from 'store/interface';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type XNoteProperties} from 'store/exercise/editor/widgets/XNote/interface';
import {formattedTextContentChange} from 'store/exercise/editor/xwidgetActions';
import {deleteWidget} from 'store/exercise/editor/actions/xwidgets';
import {type DisplayButtonProperties} from 'store/exercise/player/DisplayButtonRecord';
import {toggleIsNoteCollapsed} from 'store/exercise/editor/widgets/XNote/actions';

import {XDisplayAsButton} from './components/XDisplayAsButton/XDisplayAsButton';

import './XEditorNote.scss';

const messages = defineMessages({
  notePlaceholder: {
    id: 'XEditor.XWidget.XNote.Placeholder'
  },
  commentPlaceholder: {
    id: 'XEditor.XWidget.XComment.Placeholder'
  }
});

interface StateProps {
  isCollapsible: boolean;
  content: Value;
  type: WidgetType;
  isCollapsed?: boolean;
  displayButton?: DisplayButtonProperties;
}

interface DispatchProps {
  onContentChange: (widgetId: string, change: Editor) => void;
  toggleCollapsed: (widgetId: string) => void;
}

interface OwnProps {
  id: string;
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps;

class XEditorNote extends React.Component<Props> {
  public static plugins: Plugin[] = [
    new Undo(),
    new Redo(),
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new TextAlignment(),
    new FontSizeToolbar(),
    new FontColorToolbar(),
    new HighlightToolbar(),
    new Link(),
    new Image(),
    new ImageButton(),
    new NestedLists(),
    new AddDialogBtn(),
    new CharSelector(),
    new FormatPainter(),
    new ClearFormatting()
  ];

  private get placeholder(): MessageDescriptor | undefined {
    switch (this.props.type) {
      case WidgetType.COMMENT:
        return messages.commentPlaceholder;
      case WidgetType.NOTE:
        return messages.notePlaceholder;
      default:
        return undefined;
    }
  }

  private toggleCollapsed = () => {
    const {id, toggleCollapsed} = this.props;
    toggleCollapsed(id);
  };

  public render() {
    const {
      isCollapsible,
      id,
      content,
      isCollapsed,
      intl: {formatMessage},
      displayButton
    } = this.props;
    return (
      <React.Fragment>
        <SlateEditor
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          value={content}
          onChange={this.onContentChange}
          placeholder={this.placeholder && formatMessage(this.placeholder)}
          plugins={XEditorNote.plugins}
        />

        {isCollapsible ? (
          <XDisplayAsButton xwidgetId={id}>
            <Checkbox
              className="collapsed-checkbox"
              onChange={this.toggleCollapsed}
              checked={isCollapsed}
              disabled={!!displayButton}
            >
              <FormattedMessage id="XEditor.XWidget.DisplayCollapsed" />
            </Checkbox>
          </XDisplayAsButton>
        ) : (
          <XDisplayAsButton xwidgetId={id} />
        )}
      </React.Fragment>
    );
  }

  private onContentChange = (change: Editor) => {
    this.props.onContentChange(this.props.id, change);
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps
) => {
  const xwidget = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === ownProps.id
  ) as XNoteProperties;
  const {content, type, isCollapsed, isCollapsible, displayButton} = xwidget;
  return {
    displayButton,
    isCollapsible,
    content,
    type,
    isCollapsed
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {task: Value}> = (
  dispatch: Dispatch<Action>
) => {
  return {
    onContentChange: (widgetId: string, change: Editor) =>
      dispatch(formattedTextContentChange(widgetId, change)),
    deleteWidget: (widgetId: string) => dispatch(deleteWidget(widgetId)),
    toggleCollapsed: (widgetId: string) => dispatch(toggleIsNoteCollapsed(widgetId))
  };
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(XEditorNote));
