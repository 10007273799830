import React, {type FC, useCallback, useEffect, useState} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {defineMessages, FormattedMessage, type IntlShape, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {type Action} from 'redux';
import {useParams} from 'react-router-dom';
import {type ThunkDispatch} from 'redux-thunk';

import * as toastr from 'components/toastr';
import isShortcut from 'helpers/shortcut';
import {type AppState} from 'store/interface';
import {
  addUnitExercise,
  changeEditingState,
  saveExercise,
  validate
} from 'store/exercise/editor/actions/xexercise';
import {XEditorStage, type XEditorUrlParams} from 'store/exercise/editor/interface';
import {type XExerciseProperties} from 'store/exercise/editor/widgets/interface';
import Icon from 'components/Icon';
import Confirm from 'components/modals/Confirm';

const messages = defineMessages({
  saveSuccess: {
    id: 'XEditor.Save.Success'
  },
  saveError: {
    id: 'XEditor.Save.Error'
  },
  confirmSaveWithoutMeta: {
    id: 'XEditor.Save.ConfirmSaveWithoutMeta'
  }
});

const SAVE_SHORTCUT = 'mod+s';

interface Props {
  editingDisabled: boolean;
}

export const SaveExercise: FC<Props> = ({editingDisabled}) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const dispatch = useDispatch<ThunkDispatch<AppState, never, Action>>();
  const params = useParams<XEditorUrlParams>();

  const clientVersion = useSelector((state: AppState) => state.common.version);
  const xexercise = useSelector((state: AppState) => state.xeditor!.xexercise);
  const noLevelsSelected = useSelector(
    (state: AppState) => !state.xeditor!.xexercise.meta.levels.size
  );
  const noCategoriesSelected = useSelector(
    (state: AppState) => !state.xeditor!.xexercise.meta.categories.size
  );

  const [confirmSave, setConfirmSave] = useState<boolean | undefined>();
  const [isSaving, setIsSaving] = useState(false);
  const [shouldRedirectAfterSaved, setShouldRedirectAfterSaved] = useState(true);

  const dispatchChangeEditingState = useCallback(
    (stage: XEditorStage) => dispatch(changeEditingState(stage)),
    [dispatch]
  );
  const dispatchSaveExercise = useCallback(
    (shouldRedirect: boolean) =>
      dispatch(
        params.exerciseId
          ? saveExercise(
              {
                exerciseId: params.exerciseId,
                unitId: params.unitId!,
                coursebookId: params.coursebookId!
              },
              shouldRedirect
            )
          : addUnitExercise(
              {
                mainExerciseId: params.mainExerciseId,
                unitId: params.unitId!,
                coursebookId: params.coursebookId!
              },
              shouldRedirect
            )
      ),
    [dispatch, params]
  );
  const dispatchValidateExercise = useCallback(
    (intl: IntlShape) => dispatch(validate(intl, true, false)),
    [dispatch]
  );

  const save = useCallback(async () => {
    dispatchChangeEditingState(XEditorStage.BLOCK_EDITING);
    if (confirmSave) {
      setConfirmSave(false);
    }
    setIsSaving(true);
    const isSaved = await dispatchSaveExercise(shouldRedirectAfterSaved);
    if (isSaved) {
      toastr.success('', intl.formatMessage(messages.saveSuccess));
    } else {
      toastr.error('', intl.formatMessage(messages.saveError));
    }
    if (!shouldRedirectAfterSaved) {
      setIsSaving(false);
    }
  }, [
    confirmSave,
    dispatchChangeEditingState,
    dispatchSaveExercise,
    intl,
    shouldRedirectAfterSaved
  ]);

  const validateAndSave = useCallback(async () => {
    if (!(await dispatchValidateExercise(intl))) {
      dispatchChangeEditingState(XEditorStage.EDITING);
      return;
    }

    if (noLevelsSelected || noCategoriesSelected) {
      setConfirmSave(true);
      dispatchChangeEditingState(XEditorStage.EDITING);
    } else {
      save();
    }
  }, [
    dispatchChangeEditingState,
    dispatchValidateExercise,
    intl,
    noCategoriesSelected,
    noLevelsSelected,
    save
  ]);

  const handleClick = (e: React.MouseEvent<Button>) => {
    if (e.metaKey && e.altKey) {
      saveToFile(xexercise);
    } else {
      setShouldRedirectAfterSaved(true);
      validateAndSave();
    }
  };

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!isShortcut(e, SAVE_SHORTCUT)) {
        return;
      }
      e.preventDefault();
      setShouldRedirectAfterSaved(false);
      validateAndSave();
    },
    [validateAndSave]
  );

  const getMissingFields = useCallback(() => {
    if (noCategoriesSelected && noLevelsSelected) {
      return 'categoriesAndLevels';
    }
    if (noCategoriesSelected) {
      return 'categories';
    }
    if (noLevelsSelected) {
      return 'levels';
    }
    return 'none';
  }, [noCategoriesSelected, noLevelsSelected]);

  const declineSaving = useCallback(() => {
    setConfirmSave(false);
  }, []);

  const saveToFile = (xexercise: XExerciseProperties) => {
    const dataStr =
      'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(xexercise.toJSON()));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute(
      'download',
      `exercise-proto-${clientVersion}-${new Date().toISOString()}.xex`
    );
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  return (
    <>
      <Button
        bsSize="sm"
        bsStyle="success"
        onClick={handleClick}
        disabled={editingDisabled}
        className="save-exercise-button"
        title={formatMessage({id: 'XEditor.Save'})}
      >
        <FormattedMessage id="XEditor.Save" />
        <Icon name="save" />
      </Button>
      <Confirm
        headerText={formatMessage(
          {id: 'XEditor.Save.ConfirmSaveWithoutMeta'},
          {missingFields: getMissingFields()}
        )}
        onAccept={save}
        onDecline={declineSaving}
        show={confirmSave}
        disableButtons={isSaving}
      />
    </>
  );
};
