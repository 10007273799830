import {type Editor} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';
import React, {memo, useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import Icon from 'components/Icon';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {
  changeContent,
  changeStyle,
  changeTwoColumns
} from 'store/exercise/editor/widgets/XStyledList/actions';
import type XVerticalStyledListRecord from 'store/exercise/editor/widgets/XStyledList/XVerticalStyledListRecord';
import {type AppState} from 'store/interface';
import Image from 'components/Slate/SlateEditor/plugins/button/Image';
import ImageButton from 'components/Slate/SlateEditor/plugins/button/Image/ImageButton';
import StyledListItem from 'components/Slate/SlateEditor/plugins/button/List/StylesList/StyledListItem';
import {StyledListPlugin} from 'components/Slate/SlateEditor/plugins/button/List/StylesList/StyledList';
import {BlockFloat} from 'components/Slate/interface';
import {WidgetType} from 'store/exercise/player/interface';
import {StyledListStyles} from 'store/exercise/player/widgets/List/StyledList/interface';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';

import {localMessages} from '../messages';
import {VerticalStylesModal} from './VerticalStylesModal/VerticalStylesModal';

import './XStyledListVertical.scss';

interface Props {
  id: string;
  setError: (e: string | JSX.Element) => string | JSX.Element;
}

const plugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontColorToolbar(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting(),
  new Image({maxWidth: 480, withImageTextWrapCenter: false, defaultFloat: BlockFloat.LEFT}),
  new ImageButton(),
  new StyledListPlugin({pluginType: WidgetType.VERTICAL_STYLED_LIST}),
  new StyledListItem()
];

export const XStyledListVertical: React.FC<Props> = memo(({id}) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(StyledListStyles.NUMBERED_BLUE);
  const [selectedColumns, setSelectedColumns] = useState(false);

  const widget = useSelector(
    (state: AppState) =>
      state.xeditor!.xexercise.widgets.find(
        (x: XWidgetProperties) => x.id === id
      ) as XVerticalStyledListRecord
  );

  const {styleName, content, twoColumns} = widget;

  const onChangeList = useCallback(
    (editor: Editor) => {
      dispatch(changeContent(id, editor));
    },
    [dispatch, id]
  );

  const onCloseModal = useCallback(() => {
    dispatch(changeStyle(id, selectedStyle));
    dispatch(changeTwoColumns(id, selectedColumns));
    setIsModalOpened(false);
  }, [dispatch, id, selectedColumns, selectedStyle]);

  const onOpenModal = useCallback(() => {
    setSelectedStyle(styleName);
    setSelectedColumns(twoColumns);
    setIsModalOpened(true);
  }, [styleName, twoColumns]);

  const onselect = useCallback(() => {
    setIsModalOpened(false);
  }, []);

  return (
    <div className={`styled-list ${styleName}`}>
      <SlateEditor
        value={content}
        plugins={plugins}
        toolbar={{portalId: 'xeditor-toolbar-portal'}}
        onChange={onChangeList}
      />
      <div className="styled-list__controls">
        <button className="styled-list__control" onClick={onOpenModal}>
          <Icon name="cog" />
          {formatMessage(localMessages.ChangeTheme)}
        </button>
      </div>

      <VerticalStylesModal
        opened={isModalOpened}
        widgetId={id}
        styleName={styleName}
        onClose={onCloseModal}
        onSelect={onselect}
        twoColumns={twoColumns}
      />
    </div>
  );
});
