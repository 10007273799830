import {type EventHook, type Plugin, getEventTransfer} from '@englex/slate-react';
import type React from 'react';
import {type Editor, type SlateError, type Block} from '@englex/slate';

import isShortcut from 'helpers/shortcut';

import {SlateBlock, SlateObject} from '../../interface';

export const InlineInputPlugin = (): Plugin => {
  const onPaste: EventHook<React.ClipboardEvent> = (event, editor, next) => {
    const transfer = getEventTransfer(event);
    let text: string | null = null;
    if (transfer?.type === 'html' && transfer?.html) {
      const parser = new DOMParser();
      const textDoc = parser.parseFromString(transfer.html, 'text/html');
      text = textDoc.body.innerText;
    } else if (transfer.type === 'text') {
      text = transfer.text;
    } else if (transfer.type === 'fragment' && transfer.fragment) {
      text = transfer.fragment.text;
    }

    if (text) {
      text = text.replace(/[\n\r]/g, '');
      editor.insertText(text);
      return;
    } else {
      // eslint-disable-next-line no-console
      console.warn('onPaste: insertText transfer unsupported:', transfer);
      return;
    }
  };
  const onKeyDown: EventHook<React.KeyboardEvent> = (event, editor, next) => {
    if (isShortcut(event, 'mod?+shift?+ctrl?+alt?+enter')) {
      event.preventDefault();
      event.stopPropagation();
      // restrict enter
      return;
    }
    return next();
  };

  return {
    schema: {
      rules: [
        {
          match: {object: SlateObject.DOCUMENT},
          nodes: [{match: {type: SlateBlock.DEFAULT}, min: 1, max: 1}],
          normalize: (editor: Editor, error: SlateError): boolean | void => {
            if (error.code === 'child_max_invalid') {
              editor.withoutSaving(() => {
                editor.value.document.nodes
                  .shift()
                  .reverse()
                  .forEach((node: Block) => {
                    editor.removeNodeByKey(node.key);
                  });
              });
            }
          }
        }
      ]
    },
    onPaste,
    onKeyDown
  };
};
