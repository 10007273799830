import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {
  FormattedMessage,
  injectIntl,
  type MessageDescriptor,
  type WrappedComponentProps
} from 'react-intl';
import Scrollbars from 'react-custom-scrollbars';

import {type ExerciseCategory} from 'store/interface';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';
import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import {
  createCategoryAndLoad,
  deleteCategoryAndLoad,
  renameCategoryAndLoad
} from 'store/exercise/editor/actions/xeditor';

import Categories from './Categories';
import SelectedCategories from './SelectedCategories';
import {messages, xeditorSidebarErrors} from '../i18n';
import './styles.scss';
import {Search} from './Search';

interface OwnProps {
  categories: ExerciseCategory[];
  confirm: (pendingCategories: number[]) => void;
  onHide: () => void;
  selectedCategories: number[];
}

interface DispatchProps {
  createCategory: (
    catcher: (reject?: AxiosRequestError) => void | null,
    callback: (response: AxiosResponseAction<ExerciseCategory>) => void,
    title: string,
    parentId?: number
  ) => void;
  deleteCategory: (
    catcher: (reject?: AxiosRequestError) => void | null,
    responseSideEffect: () => void,
    id: number
  ) => void;
  renameCategory: (
    catcher: (reject?: AxiosRequestError) => void | null,
    responseSideEffect: () => void,
    title: string,
    id: number
  ) => void;
}

interface Props extends OwnProps, DispatchProps, WrappedComponentProps {}

interface State {
  activeIds: number[];
  foundId?: number;
  pendingCategories: number[];
  processingRequestId?: number | null;
  show: boolean;
}

class CategoriesModal extends PureComponent<Props, State> {
  public state: State = {
    activeIds: [],
    pendingCategories: this.props.selectedCategories,
    processingRequestId: undefined,
    show: true
  };

  private scrollbars: Scrollbars | null;

  private get selectedEqualPending() {
    const [s, p] = [this.props.selectedCategories.sort(), this.state.pendingCategories.sort()];
    return s.length === p.length && s.sort().every((value, index) => value === p[index]);
  }

  public render() {
    const {categories, onHide} = this.props;
    const {show} = this.state;
    return (
      <Modal
        backdrop="static"
        className="categories-selector-modal"
        onHide={this.onHide}
        onExited={onHide}
        onShow={this.onShow}
        show={show}
      >
        <Modal.Header>
          <Icon name="pc-book" />
          <span className="title">
            <FormattedMessage id="Exercise.CategoriesModal.Header" />
          </span>
          <Search categories={categories} onOptionSelected={this.onOptionSelected} />
          <Button className="btn-transparent btn-close" onClick={this.onHide}>
            <Icon name="pc-close" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Scrollbars ref={this.scrollbarRef}>
            {this.renderCategories(null)}
            {this.state.activeIds.map(this.renderCategories)}
          </Scrollbars>
        </Modal.Body>
        <Modal.Footer>
          <SelectedCategories
            deselectCategory={this.toggleCategory}
            categories={categories.filter(c => this.state.pendingCategories.includes(c.id))}
          />
          <div className="categories-footer-controlls">
            <Button bsSize="small" onClick={this.onHide}>
              <FormattedMessage id="Common.Cancel" />
            </Button>
            <Button
              bsSize="small"
              bsStyle="primary"
              disabled={this.selectedEqualPending}
              onClick={this.confirm}
            >
              <FormattedMessage id="Common.Add" />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  private onOptionSelected = (o: {label: string; value: number}) => {
    const activeIds = this.getTree(o.value);
    this.setState(
      {activeIds, foundId: activeIds[activeIds.length - 1]},
      this.scrollbars?.scrollToRight
    );
  };

  private clearFoundId = () => this.setState({foundId: undefined});

  private getTree = (id: number, descendants: number[] = []): number[] => {
    const ids = [id, ...descendants];
    const parentId = this.props.categories.find(c => c.id === id)?.parentId;
    return parentId ? this.getTree(parentId, ids) : ids;
  };

  private confirm = () => {
    this.props.confirm(this.state.pendingCategories);
    this.onHide();
  };

  private createCategory = (callback: (id: number) => void, title: string, parentId?: number) => {
    this.setState({processingRequestId: null});
    this.props.createCategory(
      () => this.fireToastr(xeditorSidebarErrors.toastrErrorMessage, true),
      response => {
        const {id} = response.payload.data;
        this.setState({processingRequestId: undefined});
        this.openCategory(id, parentId || null);
        this.fireToastr(messages.createCategorySuccess);
        callback(id);
      },
      title,
      parentId
    );
  };

  private deleteCategory = (siblings: ExerciseCategory[]) => (id: number) => {
    const {categories, deleteCategory} = this.props;
    const remainingSiblings = siblings.filter(c => c.id !== id);
    if (remainingSiblings.length) {
      this.openCategory(remainingSiblings[0].id, remainingSiblings[0].parentId);
    } else {
      const category = categories.find(c => c.id === id);
      const parentId = category!.parentId;
      if (parentId) {
        const grandPaId = categories.find(c => c.id === parentId)!.parentId;
        this.openCategory(parentId, grandPaId);
      } else {
        this.setState({activeIds: []});
      }
    }
    this.setState({processingRequestId: id});
    deleteCategory(
      () => {
        this.fireToastr(xeditorSidebarErrors.toastrErrorMessage, true);
        this.setState({processingRequestId: undefined});
      },
      () => {
        this.fireToastr(messages.deleteCategorySuccess);
        this.setState({processingRequestId: undefined});
      },
      id
    );
  };

  private fireToastr = (message: MessageDescriptor, error?: true) => {
    const {formatMessage} = this.props.intl;
    error ? toastr.error('', formatMessage(message)) : toastr.success('', formatMessage(message));
  };

  private onHide = () => this.setState({show: false});

  private onShow = () => {
    this.setState({pendingCategories: this.props.selectedCategories});
  };

  private openCategory = (id: number, parentId: number | null) => {
    const {activeIds} = this.state;
    this.setState(
      {
        activeIds: parentId ? [...activeIds.slice(0, activeIds.indexOf(parentId) + 1), id] : [id]
      },
      () => this.scrollbars?.scrollToRight()
    );
  };

  private renameCategory = (title: string, id: number) => {
    this.setState({processingRequestId: id});
    this.props.renameCategory(
      () => {
        this.fireToastr(xeditorSidebarErrors.toastrErrorMessage, true);
        this.setState({processingRequestId: undefined});
      },
      () => {
        this.fireToastr(messages.renameCategorySuccess);
        this.setState({processingRequestId: undefined});
      },
      title,
      id
    );
  };

  private renderCategories = (parentId: number | null) => {
    const {categories, intl} = this.props;
    const {activeIds, foundId, pendingCategories, processingRequestId} = this.state;
    const childCategories = categories
      .filter(c => c.parentId === parentId)
      .sort((a, b) => a.title.localeCompare(b.title));
    const parentIds = categories.reduce((r: number[], c) => {
      const pId = c.parentId;
      if (pId && !r.includes(pId)) {
        return [...r, pId];
      }
      return r;
    }, []);
    return (
      <Categories
        key={parentId || -1}
        activeIds={activeIds}
        allCategories={categories}
        categories={childCategories}
        clearFoundId={this.clearFoundId}
        createCategory={this.createCategory}
        deleteCategory={this.deleteCategory(childCategories)}
        foundId={foundId}
        intl={intl}
        openCategory={this.openCategory}
        parentId={parentId}
        parentIds={parentIds}
        renameCategory={this.renameCategory}
        pendingCategories={pendingCategories}
        processingRequestId={processingRequestId}
        toggleCategory={this.toggleCategory}
      />
    );
  };

  private scrollbarRef = (el: Scrollbars | null) => (this.scrollbars = el);

  private toggleCategory = (id: number) => {
    const {pendingCategories} = this.state;
    const cId = pendingCategories.find(c => c === id);
    if (cId) {
      this.setState({pendingCategories: [...this.state.pendingCategories.filter(c => c !== id)]});
    } else {
      this.setState({pendingCategories: [...this.state.pendingCategories, id]});
    }
  };
}

export default connect(null, {
  createCategory: createCategoryAndLoad,
  deleteCategory: deleteCategoryAndLoad,
  renameCategory: renameCategoryAndLoad
})(injectIntl(CategoriesModal));
