import React from 'react';

import {WidgetType} from 'store/exercise/player/interface';
import {valueJSONFromText} from 'components/Slate/utils/value';
import genKey from 'components/Slate/utils/genKey';
import XHorizontalStyledList from 'store/exercise/editor/widgets/XStyledList/XHorizontalStyledListRecord';
import {
  type StyledListJSON,
  type StyledListOptions
} from 'store/exercise/player/widgets/List/StyledList/interface';
import XVerticalStyledList from 'store/exercise/editor/widgets/XStyledList/XVerticalStyledListRecord';
import {styledListInitialValue} from 'store/exercise/editor/widgets/XStyledList/styledListInitialValue';

import {type XWizardWidgetData} from '../interface';
import Tile from '../Tile';
import {StyledListSVG} from '../assets';
import StyleListWizard from './StyleListWizard';

const tile: React.FC = () => (
  <Tile title="List">
    <StyledListSVG />
  </Tile>
);

export const styleListWizardData: XWizardWidgetData<
  StyledListJSON,
  XHorizontalStyledList | XVerticalStyledList
> = {
  type: WidgetType.HORIZONTAL_STYLED_LIST,
  steps: [
    {
      canGoToNextStep: (options: StyledListOptions) => !!options.sort,
      component: StyleListWizard
    }
  ],
  tile,
  createWidget: (options: StyledListOptions) => {
    return options.sort === WidgetType.HORIZONTAL_STYLED_LIST
      ? new XHorizontalStyledList({
          id: genKey(),
          type: options.sort,
          task: valueJSONFromText(''),
          content: styledListInitialValue()
        })
      : new XVerticalStyledList({
          id: genKey(),
          type: options.sort,
          task: valueJSONFromText(''),
          content: styledListInitialValue()
        });
  }
};
