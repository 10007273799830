import React, {type FC, useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';

import {type ExerciseCategory} from 'store/interface';
import ReactSuggest from 'components/ReactSelect/ReactSuggest';
import Icon from 'components/Icon';

import {messages} from '../i18n';

interface Props {
  categories: ExerciseCategory[];
  onOptionSelected(o: {label: string; value: number}): void;
}

export const Search: FC<Props> = ({categories, onOptionSelected: oos}) => {
  const [value, setValue] = useState('');

  const options = useMemo(() => categories.map(c => ({label: c.title, value: c.id})), [categories]);

  const onOptionSelected = useCallback(
    (o: {label: string; value: number}) => {
      oos(o);
      setValue(o.label);
    },
    [oos]
  );

  const onSelectChange = useCallback((v?: string) => typeof v === 'string' && setValue(v), []);

  const isOptionSelected = useCallback(
    (option: {label: string; value: number}) => options.map(o => o.value).includes(option.value),
    [options]
  );

  return (
    <div className={classNames('select-wrapper', {empty: !value})}>
      <ReactSuggest
        inputValue={value}
        options={options}
        onChange={onSelectChange}
        onOptionSelected={onOptionSelected}
        isOptionSelected={isOptionSelected}
        placeholder={messages.categoriesPlaceholder}
      />
      <Icon name={!value ? 'virc-search' : 'pc-close'} onClick={() => setValue('')} />
    </div>
  );
};
