import React from 'react';
import classNames from 'classnames';
import TreeView from 'react-treeview';

import {type VideoItem, type VideoLibraryNode} from './interface';
import File from './File';

interface FolderProps {
  collapse: (nodeKey: string) => void;
  defaultCollapsed: boolean;
  isFileSelected: (node: VideoItem) => boolean;
  isFolderCollapsed: (nodeKey: string) => boolean;
  node: VideoLibraryNode | VideoItem[];
  nodeKey: string;
  prefix?: string;
  selectFile: (node: VideoItem) => void;
}

const Folder: React.FC<FolderProps> = ({
  collapse,
  defaultCollapsed = true,
  isFileSelected,
  isFolderCollapsed,
  node,
  nodeKey,
  prefix = '',
  selectFile
}) => {
  const childNodeKey = `${prefix}-d-${nodeKey.toLowerCase()}`;
  const nodeLabel = (
    <span className="folder-label" onClick={() => collapse(childNodeKey)}>
      {nodeKey}
    </span>
  );
  const folderClass = classNames('folder', {
    empty: (node as VideoItem[]).length === 0
  });
  return (
    <TreeView
      itemClassName={folderClass}
      childrenClassName="folder-content"
      key={childNodeKey}
      nodeLabel={nodeLabel}
      collapsed={prefix !== '0' ? isFolderCollapsed(childNodeKey) : false}
      defaultCollapsed={defaultCollapsed}
      onClick={() => collapse(childNodeKey)}
    >
      {!node.hasOwnProperty('length')
        ? Object.keys(node).map((currentNodeKey: string, index) => (
            <TreeNode
              collapse={collapse}
              isFileSelected={isFileSelected}
              isFolderCollapsed={isFolderCollapsed}
              key={currentNodeKey}
              libraryNode={node[currentNodeKey]}
              nodeKey={currentNodeKey}
              prefix={prefix !== '' ? `${prefix}-${index}` : nodeKey}
              selectFile={selectFile}
            />
          ))
        : (node as VideoItem[]).map((libraryItem: VideoItem, index) => (
            <TreeNode
              collapse={collapse}
              isFileSelected={isFileSelected}
              isFolderCollapsed={isFolderCollapsed}
              key={libraryItem.id}
              libraryNode={libraryItem}
              nodeKey={libraryItem.id}
              prefix={prefix !== '' ? `${prefix}-${index}` : nodeKey}
              selectFile={selectFile}
            />
          ))}
    </TreeView>
  );
};

interface TreeNodeProps {
  collapse: (nodeKey: string) => void;
  libraryNode: VideoLibraryNode;
  nodeKey: string;
  prefix?: string;
  defaultCollapsed?: boolean;
  isFileSelected: (node: VideoItem) => boolean;
  isFolderCollapsed: (nodeKey: string) => boolean;
  selectFile: (node: VideoItem) => void;
}

export const TreeNode: React.FC<TreeNodeProps> = ({
  collapse,
  defaultCollapsed = true,
  isFileSelected,
  isFolderCollapsed,
  libraryNode,
  nodeKey,
  prefix = '',
  selectFile
}) => {
  if (libraryNode.hasOwnProperty('id')) {
    return <File node={libraryNode as VideoItem} select={selectFile} selected={isFileSelected} />;
  }
  return (
    <Folder
      collapse={collapse}
      defaultCollapsed={defaultCollapsed}
      isFileSelected={isFileSelected}
      isFolderCollapsed={isFolderCollapsed}
      node={libraryNode}
      nodeKey={nodeKey}
      prefix={prefix}
      selectFile={selectFile}
    />
  );
};
