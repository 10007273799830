import {type Editor} from '@englex/slate';
import {type List} from 'immutable';

import {type PictureChoiceQuestionProperties} from 'store/exercise/player/widgets/PictureChoice/interface';

import {
  type ChangeQuestionContent,
  type MoveQuestion,
  PICTURE_CHOICE_ADD_CARD,
  PICTURE_CHOICE_ADD_QUESTION,
  PICTURE_CHOICE_CHANGE_QUESTION_CONTENT,
  PICTURE_CHOICE_CHOOSE_ANSWER,
  PICTURE_CHOICE_DELETE_CARD,
  PICTURE_CHOICE_DELETE_QUESTION,
  PICTURE_CHOICE_MOVE_QUESTION,
  PICTURE_CHOICE_SET_CARD_IMAGE_DATA,
  type QuestionCardIds,
  type QuestionId,
  type QuestionIndex,
  type SetImageData,
  PICTURE_CHOICE_CHANGE_CARD_SIZE_TYPE,
  type PictureChoiceMoveCard,
  PICTURE_CHOICE_MOVE_CARD,
  type PictureChoiceRollBackCards,
  PICTURE_CHOICE_ROLL_BACK_CARDS
} from './actionTypes';
import {type CardSizeType} from '../XWordPictureSet/XPictureSet/interface';
import {type XWidgetAction} from '../../actions/interface';

export const deleteQuestion = (xwidgetId: string, questionId: string): QuestionId => ({
  type: PICTURE_CHOICE_DELETE_QUESTION,
  xwidgetId,
  questionId
});

export const moveQuestion = (xwidgetId: string, from: number, to: number): MoveQuestion => ({
  type: PICTURE_CHOICE_MOVE_QUESTION,
  xwidgetId,
  from,
  to
});

export const addQuestion = (xwidgetId: string): XWidgetAction => ({
  type: PICTURE_CHOICE_ADD_QUESTION,
  xwidgetId
});

export const changeQuestionContent = (
  xwidgetId: string,
  questionIndex: number,
  change: Editor
): ChangeQuestionContent => ({
  type: PICTURE_CHOICE_CHANGE_QUESTION_CONTENT,
  xwidgetId,
  questionIndex,
  change
});

export const addCard = (xwidgetId: string, questionIndex: number): QuestionIndex => ({
  type: PICTURE_CHOICE_ADD_CARD,
  xwidgetId,
  questionIndex
});

export const setImageData = (
  xwidgetId: string,
  questionIndex: number,
  cardIndex: number,
  imageId: number,
  url: string
): SetImageData => ({
  type: PICTURE_CHOICE_SET_CARD_IMAGE_DATA,
  xwidgetId,
  questionIndex,
  cardIndex,
  imageId,
  url
});

export const deleteCard = (
  xwidgetId: string,
  questionId: string,
  cardId: string
): QuestionCardIds => ({
  type: PICTURE_CHOICE_DELETE_CARD,
  xwidgetId,
  questionId,
  cardId
});

export const chooseAnswer = (
  xwidgetId: string,
  questionId: string,
  cardId: string
): QuestionCardIds => ({
  type: PICTURE_CHOICE_CHOOSE_ANSWER,
  xwidgetId,
  questionId,
  cardId
});

export const pictureChoiceChangeCardSizeType = (
  xwidgetId: string,
  questionIndex: number,
  cardIndex: number,
  cardSizeType: CardSizeType
) => ({
  type: PICTURE_CHOICE_CHANGE_CARD_SIZE_TYPE,
  xwidgetId,
  questionIndex,
  cardIndex,
  cardSizeType
});

export const pictureChoiceMoveCard = (
  xwidgetId: string,
  questionId: string,
  moveCardIndex: number,
  targetCardIndex: number
): PictureChoiceMoveCard => ({
  type: PICTURE_CHOICE_MOVE_CARD,
  xwidgetId,
  questionId,
  moveCardIndex,
  targetCardIndex
});

export const pictureChoiceRollBackCards = (
  xwidgetId: string,
  questions: List<PictureChoiceQuestionProperties>
): PictureChoiceRollBackCards => ({
  type: PICTURE_CHOICE_ROLL_BACK_CARDS,
  xwidgetId,
  questions
});
