// todo: like with ./errorMessages.tsx - maybe this has to contain
//       all uploading(error) statuses for any file type and live
//       higher in project tree?

export enum UploadingStatus {
  UPLOADING_ERROR = 'UPLOADING_ERROR',
  UPLOADING = 'UPLOADING',
  PREPARING = 'PREPARING',
  FINISHED = 'FINISHED'
}

export enum UploadingValidationError {
  FILE_TOO_BIG = 'FILE_TOO_BIG',
  INVALID_EXTENSION = 'INVALID_EXTENSION',
  EMPTY_FILE = 'EMPTY_FILE',
  FILE_CORRUPTED = 'FILE_CORRUPTED',
  MULTIPLE_FILES = 'MULTIPLE_FILES'
}

export interface AudioResponse {
  id: number;
  length?: number;
  md5: string;
  title: string;
  url: string;
}
