import React from 'react';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {type List} from 'immutable';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import Scrollbars from 'react-custom-scrollbars';
import ReactResizeDetector from 'react-resize-detector';

import {DragLayer} from 'components/dnd/DragLayer';
import {type WidgetJSON} from 'store/exercise/player/interface';
import {type AppState} from 'store/interface';
import {toggleWizard} from 'store/exercise/editor/actions/xwizard';
import {clearWidgetClipboard, resetWidgetErrors} from 'store/exercise/editor/actions/xeditor';
import {addWidget} from 'store/exercise/editor/actions/xwidgets';
import xwidgetFactory from 'store/exercise/editor/widgets/xwidgetFactory';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type ChangeTitleAction} from 'store/exercise/editor/actions/interface';
import {xexerciseChangeTitle} from 'store/exercise/editor/actions/xexercise';
import {cloneWidgetJSON} from 'store/exercise/editor/widgets/cloneWidgetJSON';

import WidgetList from './WidgetList';
import XEditorActions from './XEditorActions';
import {type ToggleElementAction} from '../../../../../common/interface';
import OptionalTitle from '../OptionalTitle';
import XWizardModal from './xwizard/XWizardModal';
import ValidationError from '../ValidationError';
import defaultTitles from '../defaultTitles';
import GrammarOptionalTitle from '../GrammarOptionalTitle';
import {MediaSources} from '../MediaSources/MediaSources';
import {renderEditorDragItem} from './RenderDragItem';

import './XEditor.scss';

interface StateProps {
  title: string;
  xwidgets: List<XWidgetProperties>;
  titleError?: string;
  widgetsError?: string;
  wizardIsActive: boolean;
  clipboardWidget?: WidgetJSON;
}

interface DispatchProps {
  openWizard: () => ToggleElementAction;
  changeTitle: (title: string) => ChangeTitleAction;
  clearWidgetClipboard: () => void;
  addWidget: (widget: XWidgetProperties) => void;
  resetWidgetErrors: (id: string) => void;
}

type ConnectedProps = StateProps & DispatchProps;

type Props = ConnectedProps;

interface State {
  scrollToNewWidget: boolean;
  clientHeight: number;
  scrollHeight: number;
  scrollTop: number;
}

class XEditor extends React.PureComponent<Props, State> {
  public state: State = {
    scrollToNewWidget: false,
    clientHeight: 0,
    scrollHeight: 0,
    scrollTop: 0
  };

  private scrollbars: Scrollbars | null;

  public componentDidMount() {
    this.updatePositionValues();
  }

  public componentWillUnmount(): void {}

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.xwidgets.size && !this.props.xwidgets.size) {
      this.props.openWizard();
    }
    if (this.state.scrollToNewWidget) {
      const widgetNodes = document.querySelectorAll('.xeditor-widget');
      if (widgetNodes.length) {
        widgetNodes[widgetNodes.length - 1].scrollIntoView();
        this.setState({scrollToNewWidget: false});
      }
    }
    if (prevProps.xwidgets.size < this.props.xwidgets.size) {
      this.setState({scrollToNewWidget: true});
    }
    if (this.props.widgetsError && this.props.xwidgets.size !== prevProps.xwidgets.size) {
      this.props.resetWidgetErrors('');
    }
  }

  public render() {
    const {
      changeTitle,
      title,
      titleError,
      widgetsError,
      openWizard,
      wizardIsActive,
      clipboardWidget
    } = this.props;

    return (
      <div className="xeditor">
        <ReactResizeDetector
          onResize={this.updatePositionValues}
          refreshRate={16}
          refreshMode="throttle"
        >
          <div className="xeditor-canvas-wrapper">
            <Scrollbars ref={this.scrollbarsRef} onScrollStop={this.updatePositionValues}>
              <div className="xeditor-canvas">
                <ValidationError message={widgetsError} className="widgets-error" />
                <OptionalTitle
                  validationError={titleError}
                  changeTitle={changeTitle}
                  title={title}
                  defaultTitles={defaultTitles}
                />
                <GrammarOptionalTitle />
                <WidgetList xwidgets={this.props.xwidgets} />
                <MediaSources />
                <XWizardModal />
                <XEditorActions
                  openWizard={openWizard}
                  wizardIsActive={wizardIsActive}
                  clientHeight={this.state.clientHeight}
                  scrollHeight={this.state.scrollHeight}
                  scrollTop={this.state.scrollTop}
                  clipboardHasWidget={!!clipboardWidget}
                  clearWidgetClipboard={this.props.clearWidgetClipboard}
                  pasteWidgetFromClipboard={this.pasteWidgetFromClipboard}
                />
              </div>
            </Scrollbars>
          </div>
        </ReactResizeDetector>
        <div id="xeditor-toolbar-portal" className="xeditor-toolbar-portal" />
        <DragLayer renderItem={renderEditorDragItem} />
      </div>
    );
  }

  private pasteWidgetFromClipboard = () => {
    if (this.props.clipboardWidget) {
      this.props.addWidget(xwidgetFactory(cloneWidgetJSON(this.props.clipboardWidget)));
      this.props.clearWidgetClipboard();
    }
  };

  private updatePositionValues = () => {
    if (this.scrollbars) {
      const {clientHeight, scrollHeight, scrollTop} = this.scrollbars.getValues();
      if (
        clientHeight !== this.state.clientHeight ||
        scrollHeight !== this.state.scrollHeight ||
        scrollTop !== this.state.scrollTop
      ) {
        this.setState({clientHeight, scrollHeight, scrollTop});
      }
    }
  };

  private scrollbarsRef = (el: Scrollbars | null) => (this.scrollbars = el);
}

const mapStateToProps: MapStateToProps<StateProps, WrappedComponentProps, AppState> = (
  state: AppState
) => {
  let titleError: string | undefined;
  let widgetsError: string | undefined;
  const editorState = state.xeditor!;
  if (editorState.errors.has('title')) {
    titleError = editorState.errors.get('title').first();
  }

  if (editorState.errors.has('widgets')) {
    widgetsError = editorState.errors.getIn(['widgets', ''])?.message;
  }

  return {
    title: state.xeditor!.xexercise.title,
    xwidgets: state.xeditor!.xexercise.widgets,
    titleError,
    widgetsError,
    wizardIsActive: editorState.xwizard !== undefined,
    clipboardWidget: state.clipboard.widget
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = dispatch => {
  return {
    openWizard: () => dispatch(toggleWizard()),
    changeTitle: (title: string) => dispatch(xexerciseChangeTitle(title)),
    clearWidgetClipboard: () => dispatch(clearWidgetClipboard()),
    addWidget: (widget: XWidgetProperties) => dispatch(addWidget(widget)),
    resetWidgetErrors: (id: string) => dispatch(resetWidgetErrors(id))
  };
};

export default injectIntl(
  connect<StateProps, DispatchProps, WrappedComponentProps>(
    mapStateToProps,
    mapDispatchToProps
  )(XEditor)
);
