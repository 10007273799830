import {useSelector} from 'react-redux';
import {useMemo} from 'react';

import {type AppState} from 'store/interface';
import {type XEditorUnitExercise} from 'store/exercise/editor/interface';

export const useXEditorPageMeta = () => {
  const unitExercise = useSelector<AppState, XEditorUnitExercise | undefined>(
    state => state.xeditor?.unitExercise
  );

  const exerciseId = useSelector<AppState, string | null | undefined>(
    state => state.xeditor?.xexercise?.id
  );

  return useMemo(
    () => ({
      pageNumber: unitExercise?.parentUnitExercise?.pageNumber || unitExercise?.pageNumber || 0,
      exerciseOrdinal:
        unitExercise?.parentUnitExercise?.ordinal !== undefined
          ? unitExercise?.parentUnitExercise.ordinal
          : unitExercise?.ordinal !== undefined && !unitExercise?.parentExerciseId
            ? unitExercise?.ordinal
            : -1,
      supplementaryOrdinal: unitExercise?.ordinal !== undefined ? unitExercise?.ordinal : -1,
      parentUnitExercise: unitExercise?.parentUnitExercise,
      parentExerciseId: unitExercise?.parentExerciseId,
      coursebookTitle: unitExercise?.unit.coursebook.title,
      unitTitle: unitExercise?.unit.title,
      exerciseId: exerciseId
    }),
    [unitExercise, exerciseId]
  );
};
