import React from 'react';
import {type Editor as ReactEditor, type RenderBlockProps} from '@englex/slate-react';
import {Block, type Editor, type Next, type Operation, type SlateError} from '@englex/slate';

import {SlateBlock, SlateObject} from '../../../../../interface';
import {isBlockOfType, isListBlock, isListItemBlock} from '../../../../../utils';
import ListItem from '../ListItem';
import './SimpleListItem.scss';
import {type SchemaBlockRules} from '../../../interface';

class SimpleListItem extends ListItem {
  public blockRules = (): SchemaBlockRules => {
    return {
      type: this.block,
      rules: {
        data: {style: () => true},
        nodes: [
          {
            match: [{type: SlateBlock.DEFAULT}],
            min: 1,
            max: 1
          }
        ],
        parent: {type: SlateBlock.LIST}
      },
      normalizer: {
        predicate: ({node}: SlateError) => !!node && isListItemBlock(node),
        reasons: {
          parent_type_invalid: (change: Editor, {node}: SlateError) => {
            change.unwrapBlockByKey(node.key, {
              type: this.block
            });
            return true;
          },
          child_min_invalid: (change: Editor, error: SlateError) => {
            change.removeNodeByKey(error.node.key);
            return true;
          },
          child_type_invalid: (change: Editor, {child, node}: SlateError) => {
            if (!child) {
              return;
            }
            if (child.object === SlateObject.TEXT) {
              change.removeNodeByKey(node.key);
              return true;
            }
            if (child.object !== SlateObject.TEXT) {
              change.unwrapNodeByKey(child.key);
              return true;
            }
            return;
          },
          child_unknown: (change: Editor, {child}: SlateError) => {
            if (isListBlock(child)) {
              if (isListBlock(change.value.document.getPreviousSibling(child.key))) {
                change.mergeNodeByKey(child.key);
                return true;
              }
            }
            return;
          },
          child_max_invalid: (change: Editor, {child, node}: SlateError) => {
            if (Block.isBlock(child) && isBlockOfType(child, SlateBlock.DEFAULT)) {
              change.splitNodeByKey(node.key, 1);
              return true;
            }
            return;
          }
        }
      }
    };
  };

  public onChange = (change: ReactEditor & Editor, next: Next) => {
    if (!change.operations.filter((o: Operation) => o.type.includes('_node')).size) {
      return next();
    }
    const lis = change.value.document.filterDescendants(
      (d: Block) => d.type === SlateBlock.LIST_ITEM
    );
    change.withoutNormalizing(() => {
      lis.slice(1).forEach((li: Block) => {
        if (li.data.get('example')) {
          change.setNodeByKey(li.key, {data: li.data.delete('example')});
        }
      });
    });
    return next();
  };

  public renderBlock = (
    {parent, node, attributes, children}: RenderBlockProps,
    editor: ReactEditor & Editor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.LIST_ITEM)) {
      return next();
    }
    const isFirstListItem = (parent as Block).nodes.first().key === node.key;
    if (node.data.get('example') && isFirstListItem) {
      // userSelect: 'none' is added to prevent runtime crash when trying to make listItem into an example in Questions widget
      return (
        <li className="simple-list-item example" {...attributes}>
          <span className="example-label" contentEditable={false} style={{userSelect: 'none'}}>
            Example:
          </span>
          <span className="slate-content example">{children}</span>
        </li>
      );
    }
    return <li {...attributes}>{children}</li>;
  };
}

export default SimpleListItem;
