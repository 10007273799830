import React from 'react';
import {FormattedMessage} from 'react-intl';

import Counter from 'components/Counter';
import {
  pictureChoiceAnswersNumberDefault,
  pictureChoiceAnswersNumberMax,
  pictureChoiceAnswersNumberMin,
  pictureChoiceQuestionsNumberDefault,
  pictureChoiceQuestionsNumberMax,
  pictureChoiceQuestionsNumberMin
} from 'store/exercise/editor/widgets/constants';
import {
  PictureChoiceAnswerType,
  PictureChoiceMode,
  PictureChoiceQuestionsMode,
  type XPictureChoiceWizardProps
} from 'store/exercise/editor/widgets/XPictureChoice/interface';

import {type XWizardStepProps} from '../interface';
import {RadioGroup} from './RadioGroup';

import './PictureChoiceWizard.scss';

const modeData = {
  name: 'mode',
  title: <FormattedMessage id="XEditor.Wizard.PictureChoice.CorrectAnswersAre" />,
  radioButtons: {
    [PictureChoiceMode.MULTIPLE_CHOICE]: <FormattedMessage id="XEditor.Wizard.PictureChoice.Yes" />,
    [PictureChoiceMode.FREE_CHOICE]: <FormattedMessage id="XEditor.Wizard.PictureChoice.No" />
  }
};

const answersTypeData = {
  name: 'answerType',
  title: <FormattedMessage id="XEditor.Wizard.MultipleChoice.ElementTypeHeader" />,
  radioButtons: {
    [PictureChoiceAnswerType.One]: (
      <FormattedMessage id="XEditor.Wizard.MultipleChoice.ElementType1" />
    ),
    [PictureChoiceAnswerType.Multiple]: (
      <FormattedMessage id="XEditor.Wizard.MultipleChoice.ElementType2" />
    )
  }
};

const questionsModeData = {
  name: 'questionsMode',
  title: <FormattedMessage id="XEditor.Wizard.PictureChoice.AvailabilityQuestions" />,
  radioButtons: {
    [PictureChoiceQuestionsMode.Default]: (
      <FormattedMessage id="XEditor.Wizard.PictureChoice.Enabled" />
    ),
    [PictureChoiceQuestionsMode.WithoutQuestionsTitle]: (
      <FormattedMessage id="XEditor.Wizard.PictureChoice.Disabled" />
    )
  }
};

type Props = XWizardStepProps<XPictureChoiceWizardProps>;

class PictureChoiceWizard extends React.Component<Props> {
  componentDidMount() {
    this.props.addField('mode', PictureChoiceMode.MULTIPLE_CHOICE);
    this.props.addField('answerType', PictureChoiceAnswerType.One);
    this.props.addField('initialQuestionsNumber', pictureChoiceQuestionsNumberDefault);
    this.props.addField('defaultAnswersNumber', pictureChoiceAnswersNumberDefault);
    this.props.addField('questionsMode', PictureChoiceQuestionsMode.Default);
  }

  public changeField = (
    key: keyof XPictureChoiceWizardProps,
    value: PictureChoiceMode | PictureChoiceAnswerType
  ) => {
    return () => {
      this.props.addField(key, value);
    };
  };

  public changeAnswersNumber = (newValue: number) => {
    return this.props.addField('defaultAnswersNumber', newValue);
  };

  public changeQuestionsNumber = (newValue: number) => {
    return this.props.addField('initialQuestionsNumber', newValue);
  };

  public render() {
    const {mode, answerType, initialQuestionsNumber, defaultAnswersNumber, questionsMode} =
      this.props.fields;

    const answerTypeDisabled =
      mode === PictureChoiceMode.FREE_CHOICE ||
      questionsMode === PictureChoiceQuestionsMode.WithoutQuestionsTitle;

    return (
      <form className="picture-choice-wizard">
        <RadioGroup value={mode as string} callBack={this.changeField} data={modeData} />

        <RadioGroup
          value={answerType as string}
          callBack={this.changeField}
          data={answersTypeData}
          disabled={answerTypeDisabled}
        />

        <RadioGroup
          value={questionsMode as string}
          callBack={this.changeField}
          data={questionsModeData}
        />

        <div className="counters">
          <div>
            <p>
              <b>
                <FormattedMessage id="XEditor.Wizard.MultipleChoice.QuestionsNumberHeader" />
              </b>
            </p>
            <Counter
              value={initialQuestionsNumber || pictureChoiceQuestionsNumberDefault}
              onChange={this.changeQuestionsNumber}
              minValue={pictureChoiceQuestionsNumberMin}
              maxValue={pictureChoiceQuestionsNumberMax}
            />
          </div>

          <div>
            <p>
              <b>
                <FormattedMessage id="XEditor.Wizard.MultipleChoice.AnswersNumberHeader" />
              </b>
            </p>
            <Counter
              value={defaultAnswersNumber || pictureChoiceAnswersNumberDefault}
              onChange={this.changeAnswersNumber}
              minValue={pictureChoiceAnswersNumberMin}
              maxValue={pictureChoiceAnswersNumberMax}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default PictureChoiceWizard;
