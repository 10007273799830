import React, {type FC} from 'react';
import classNames from 'classnames';
import {defineMessages, type WrappedComponentProps, injectIntl} from 'react-intl';
import {type Editor, type Value} from '@englex/slate';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {type Action, type Dispatch} from 'redux';
import {type Plugin} from '@englex/slate-react';

import {type AppState} from 'store/interface';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import TheOnlyOrderedList from 'components/Slate/SlateEditor/plugins/button/List/SpecificCases';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import {
  questionsChange,
  questionsExampleAnswerChange,
  toggleContainsExample
} from 'store/exercise/editor/xwidgetActions';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type XQuestsProperties} from 'store/exercise/editor/widgets/XQuests/interface';
import {type XEditorWidgetsAction} from 'store/exercise/editor/actions/interface';

import ToggleExample from './XScrambledSentences/ToggleExample';

import './XEditorQuests.scss';

const i18n = defineMessages({
  placeholder: {
    id: 'XEditor.XWidget.XQuests.ExampleAnswerPlaceholder'
  }
});

interface OwnProps {
  id: string;
}

interface StateProps {
  containsExample: boolean;
  content: Value;
  questsExampleContent: Value;
}

interface DispatchProps {
  onChange: (change: Editor) => void;
  onExampleAnswerChange: (change: Editor) => void;
  toggleExample: () => XEditorWidgetsAction;
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps;

const plugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting(),
  new TheOnlyOrderedList()
];

const exampleAnswerPlugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FormatPainter(),
  new ClearFormatting()
];

const XEditorQuests: FC<Props> = ({
  containsExample,
  content,
  intl,
  onChange,
  onExampleAnswerChange,
  questsExampleContent,
  toggleExample
}) => (
  <div className={classNames('quests', {'with-example': containsExample})}>
    <SlateEditor
      toolbar={{portalId: 'xeditor-toolbar-portal'}}
      value={content}
      onChange={onChange}
      plugins={plugins}
    />
    <ToggleExample checked={containsExample} onChange={toggleExample} />
    {containsExample && (
      <SlateEditor
        toolbar={{portalId: 'xeditor-toolbar-portal'}}
        value={questsExampleContent}
        onChange={onExampleAnswerChange}
        plugins={exampleAnswerPlugins}
        placeholder={intl.formatMessage(i18n.placeholder)}
      />
    )}
  </div>
);

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps
): StateProps => {
  const xwidget = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === ownProps.id
  ) as XQuestsProperties;

  const {containsExample, content, questsExampleContent} = xwidget;
  return {
    containsExample,
    content,
    questsExampleContent
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch<Action>,
  {id}: OwnProps
): DispatchProps => ({
  onChange: (change: Editor) => dispatch(questionsChange(id, change)),
  onExampleAnswerChange: (change: Editor) => dispatch(questionsExampleAnswerChange(id, change)),
  toggleExample: () => dispatch(toggleContainsExample(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(XEditorQuests));
