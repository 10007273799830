import {buttonLabel, buttonTitle} from '../../button/i18n';
import {ButtonType} from '../../interface';
import EditGapDropDown from './forms/EditGapDropDown';
import {GapFillType} from '../../../../interface';

import GapFill from './index';

class GapFillDropDown extends GapFill {
  public gap = GapFillType.DROPDOWN;
  public title = buttonTitle.GapFillDropDown;
  public type = ButtonType.GAP_FILL_DROP_DOWN;
  public label = buttonLabel.GapFillDropDown;

  public editForm = EditGapDropDown;
}

export default GapFillDropDown;
