import React, {memo} from 'react';
import classNames from 'classnames';
import {type Value} from '@englex/slate';

import LoadableImage from 'components/LoadableImage/LoadableImage';

import {AvatarImage, AvatarTypes} from '../../assets/AvatarImage';

import './SpeakerView.scss';

interface Props {
  avatar: number;
  name: Value;
  selected: boolean;
  onClick: () => void;
}

export const SpeakerView: React.FC<Props> = memo(({avatar, name, selected, onClick}) => (
  <div className={classNames('speaker-view', {active: selected})} onClick={onClick}>
    <div className="avatar">
      {avatar ? (
        <LoadableImage imageId={avatar} width="100%" height="100%" draggable={false} />
      ) : (
        <AvatarImage avatarType={AvatarTypes.DEFAULT} />
      )}
    </div>

    <span className="name">{name.document.text}</span>
  </div>
));
