import React from 'react';
import classNames from 'classnames';

import {SaveExercise} from './components/SaveExercise';
import {CancelExercise} from './components/CancelExercise';

import './XEditorActions.scss';

interface OwnProps {
  onCancel: () => void;
  editingDisabled: boolean;
}

type Props = OwnProps;

export class XEditorActions extends React.PureComponent<Props> {
  public render() {
    const {editingDisabled} = this.props;
    return (
      <div className={classNames('xeditor-actions')}>
        <CancelExercise editingDisabled={editingDisabled} onClick={this.props.onCancel} />
        <SaveExercise editingDisabled={editingDisabled} />
      </div>
    );
  }
}
