import React from 'react';
import {FormattedMessage} from 'react-intl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';

import {type StyledListOptions} from 'store/exercise/player/widgets/List/StyledList/interface';
import {WidgetType} from 'store/exercise/player/interface';

import {type XWizardStepProps} from '../interface';
import './StyledListWizard.scss';

type Props = XWizardStepProps<StyledListOptions>;

class StyleListWizard extends React.Component<Props> {
  public componentDidMount() {
    this.props.addField('sort', WidgetType.HORIZONTAL_STYLED_LIST);
  }

  public render() {
    const radioGroupName = 'list';
    const {addField} = this.props;

    const changeToHorizontalList = () => {
      addField('sort', WidgetType.HORIZONTAL_STYLED_LIST);
    };

    const changeToVerticalList = () => {
      addField('sort', WidgetType.VERTICAL_STYLED_LIST);
    };

    return (
      <form className="styled-list-wizard-options-form">
        <p className="styled-list-wizard-options-title">
          <b>
            <FormattedMessage id="XEditor.Wizard.StyleList.ListType" />
          </b>
        </p>
        <FormGroup>
          <div className="group">
            <Radio
              name={radioGroupName}
              inline={true}
              defaultChecked={true}
              onChange={changeToHorizontalList}
            >
              <FormattedMessage id="XEditor.Wizard.StyleList.Horizontal" />
            </Radio>
          </div>
          <div className="group">
            <Radio name={radioGroupName} inline={true} onChange={changeToVerticalList}>
              <FormattedMessage id="XEditor.Wizard.StyleList.Vertical" />
            </Radio>
          </div>
        </FormGroup>
      </form>
    );
  }
}

export default StyleListWizard;
