import React from 'react';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';

import Icon from 'components/Icon';
import {performExistenceCheck, uploadAudio} from 'store/media/audio/actions';
import {type AxiosResponseAction, type UploadProgressHandler} from 'services/axios/interface';
import {type WampCallResponseAction} from 'services/wamp/actions/interface';

import {type AudioResponse, UploadingStatus, type UploadingValidationError} from '../interface';
import Progress from './Progress';
import Percentage from './Percentage';

import './Uploader.scss';

const {FINISHED, UPLOADING, UPLOADING_ERROR} = UploadingStatus;

interface Props {
  file: File;
  handleResponse: (response: AxiosResponseAction<AudioResponse>) => void;
  md5: string;
  setStatus: (status?: UploadingStatus) => void;
  status: UploadingStatus | UploadingValidationError;

  performExistenceCheck: (md5: string) => Promise<AxiosResponseAction<AudioResponse>>;
  upload: (
    formData: FormData,
    progressHandler: UploadProgressHandler
  ) => Promise<AxiosResponseAction<AudioResponse>>;
}

interface State {
  percentsUploaded: number;
}

class Uploader extends React.Component<Props, State> {
  public state: State = {percentsUploaded: 0};

  public componentDidMount() {
    this.performExistenceCheck();
  }

  public render() {
    const {
      file: {name},
      status
    } = this.props;
    const {percentsUploaded} = this.state;
    return (
      <div className="uploader-wrapper">
        <Icon name="virc-audio" />
        <div className="uploader">
          <div>
            <span className="title">{name}</span>
            <Percentage status={status} percentsUploaded={percentsUploaded} />
          </div>
          <Progress status={status} percentsUploaded={percentsUploaded} />
        </div>
      </div>
    );
  }

  private performExistenceCheck = async () => {
    const {md5} = this.props;
    let response;
    try {
      response = await this.props.performExistenceCheck(md5);
    } catch (rejection) {
      if (rejection.error.response && rejection.error.response.status === 404) {
        this.upload();
      } else {
        this.props.setStatus(UPLOADING_ERROR);
      }
    }
    response && this.handleSuccess(response);
  };

  private upload = async () => {
    const {file, setStatus, upload} = this.props;
    const formData = new FormData();
    let response;
    formData.append('file', file);
    setStatus(UPLOADING);
    try {
      response = await upload(formData, this.progressHandler);
    } catch (e) {
      setStatus(UPLOADING_ERROR);
    }
    response && this.handleSuccess(response);
  };

  private handleSuccess = (response: AxiosResponseAction<AudioResponse>) => {
    this.props.setStatus(FINISHED);
    this.props.handleResponse(response);
  };

  private progressHandler = ({loaded, total}: ProgressEvent) => {
    this.setState({percentsUploaded: Math.round((loaded * 100) / total)});
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch<AxiosResponseAction<AudioResponse>, WampCallResponseAction<{}, {}, {}>>
) => ({
  performExistenceCheck: (md5: string) =>
    dispatch<AxiosResponseAction<AudioResponse>>(performExistenceCheck(md5)),
  upload: (file: FormData, progressHandler: UploadProgressHandler) =>
    dispatch<AxiosResponseAction<AudioResponse>>(uploadAudio(file, progressHandler))
});

export default connect(null, mapDispatchToProps)(Uploader);
