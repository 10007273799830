import React, {type FC, useCallback, useRef, useState} from 'react';
import classNames from 'classnames';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {type DragSourceMonitor, type DropTargetMonitor, useDrag, useDrop} from 'react-dnd';

import Icon from 'components/Icon';
import DragHandle from 'components/DragHandle/DragHandle';
import {isVocabularyWordRecord} from 'store/exercise/player/widgets/Vocabulary/utils';
import {
  xVocabularyMoveEntry,
  xVocabularyRemoveEntry
} from 'store/exercise/editor/widgets/XVocabulary/actions';
import {isCursorInTopHalfOfElement} from 'common/Dnd/helpers';
import {
  type VocabularyCategoryProperties,
  type VocabularyWordProperties
} from 'store/exercise/editor/widgets/XVocabulary/interface';
import {DndTypes, type DragObject} from 'components/dnd/interface';
import {useDndEmptyImage} from 'components/dnd/useDndEmptyImage';

import XVocabularyWordInput from './XVocabularyWordInput';
import XVocabularyCategoryInput from './XVocabularyCategoryInput';

import './XEditorVocabularyEntry.scss';

const getIsEntryDragged = (
  dndItem: VocabularyDragObject | null,
  dndItemType: ReturnType<DragSourceMonitor['getItemType']>,
  widgetId: string,
  positionInVocabulary: number
) => {
  if (!dndItem || dndItemType !== DndTypes.XVOCABULARY_ENTRY || dndItem?.widgetId !== widgetId) {
    return false;
  }

  return dndItem.positionInVocabulary === positionInVocabulary;
};

export interface VocabularyDragObject extends DragObject {
  widgetId: string;
  positionInVocabulary: number;
  vocabularyEntry: VocabularyWordProperties | VocabularyCategoryProperties;
  elementWidth: number;
}

interface Props {
  wordNumber: number;
  entry: VocabularyWordProperties | VocabularyCategoryProperties;
  widgetId: string;
  positionInVocabulary: number;
  withError: boolean;
}

export const XEditorVocabularyEntry: FC<Props> = ({
  entry,
  widgetId,
  positionInVocabulary,
  wordNumber,
  withError
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const elRef = useRef<HTMLDivElement | null>(null);
  const intl = useIntl();
  const dispatch = useDispatch();

  const [{dndItem, dndItemType}, connectDragSource, connectDragPreview] = useDrag({
    type: DndTypes.XVOCABULARY_ENTRY,
    item: (): VocabularyDragObject => {
      return {
        widgetId,
        elementWidth: elRef.current ? elRef.current.clientWidth : 754,
        positionInVocabulary,
        vocabularyEntry: entry
      };
    },
    collect: monitor => ({
      dndItem: monitor.getItem(),
      dndItemType: monitor.getItemType()
    })
  });

  const [, connectDropTarget] = useDrop({
    accept: DndTypes.XVOCABULARY_ENTRY,
    hover: (item: VocabularyDragObject, monitor: DropTargetMonitor) => {
      if (widgetId !== item.widgetId) {
        return null;
      }
      const dragIndex = item.positionInVocabulary;
      const hoverIndex = positionInVocabulary;

      if (dragIndex === hoverIndex) {
        return;
      }

      const cursorInTopHalfOfEl = isCursorInTopHalfOfElement(elRef.current, monitor);
      const cursorInBottomHalfOfEl = !cursorInTopHalfOfEl;
      const draggingDownwards = dragIndex < hoverIndex;
      const draggingUpwards = !draggingDownwards;

      if (
        (draggingDownwards && cursorInTopHalfOfEl) ||
        (draggingUpwards && cursorInBottomHalfOfEl)
      ) {
        return;
      }

      item.positionInVocabulary = hoverIndex;
      return dispatch(xVocabularyMoveEntry(widgetId, dragIndex, hoverIndex));
    }
  });

  const getEntryElRef = useCallback((el: HTMLDivElement | null) => (elRef.current = el), []);

  const onFocus = useCallback(() => setIsFocused(true), []);

  const onBlur = useCallback(() => setIsFocused(false), []);

  const removeEntry = useCallback(() => {
    dispatch(xVocabularyRemoveEntry(widgetId, positionInVocabulary));
  }, [dispatch, widgetId, positionInVocabulary]);

  const renderDragHandle = useCallback(
    () =>
      connectDragSource(
        <div className="drag-handle">
          <DragHandle />
        </div>
      ),
    [connectDragSource]
  );

  const renderCommonControls = useCallback(
    () => (
      <>
        <Icon name="trash" onClick={removeEntry} />
        {renderDragHandle()}
      </>
    ),
    [removeEntry, renderDragHandle]
  );

  const renderInputs = useCallback(() => {
    if (isVocabularyWordRecord(entry)) {
      return (
        <XVocabularyWordInput
          onBlur={onBlur}
          onFocus={onFocus}
          word={entry}
          intl={intl}
          widgetId={widgetId}
          title={String(wordNumber + 1)}
        >
          {renderCommonControls()}
        </XVocabularyWordInput>
      );
    }
    return (
      <XVocabularyCategoryInput
        onBlur={onBlur}
        onFocus={onFocus}
        category={entry}
        intl={intl}
        widgetId={widgetId}
        positionInVocabulary={positionInVocabulary}
      >
        {renderCommonControls()}
      </XVocabularyCategoryInput>
    );
  }, [
    entry,
    onBlur,
    onFocus,
    intl,
    widgetId,
    renderCommonControls,
    positionInVocabulary,
    wordNumber
  ]);

  useDndEmptyImage(connectDragPreview);

  const isEntryDragged = getIsEntryDragged(dndItem, dndItemType, widgetId, positionInVocabulary);

  const className = classNames('xvocabulary-entry', {
    'with-error': withError,
    'is-dragged': isEntryDragged,
    focused: isFocused
  });

  return connectDropTarget(
    <div ref={getEntryElRef} className={className}>
      {renderInputs()}
    </div>
  );
};
