import React from 'react';
import {type Action} from 'redux';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {FormattedMessage, type IntlShape, type WrappedComponentProps, injectIntl} from 'react-intl';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';
import Tab from 'react-bootstrap/lib/Tab';

import {type AppState} from 'store/interface';
import {stopMedia} from 'store/media/actions';
import Loader from 'components/Loader';
import {CollapsedSidebarButton} from 'components/FloatSidebar/CollapsedSidebarButton';
import {FloatSidebar} from 'components/FloatSidebar/FloatSidebar';
import {XEditorStage, XEditorTab} from 'store/exercise/editor/interface';
import {validate} from 'store/exercise/editor/actions/xexercise';
import {changeTab} from 'store/exercise/editor/actions/xeditor';
import {deleteDraftExercise} from 'store/exercise/editor/actions/xdraftexercises';
import {BootstrapBreakpoint} from 'config/static';

import {Breadcrumbs} from './components/Breadcrumbs';
import {XPreviewWithCarousel} from './XPreviewWithCarousel';
import XEditor from './components/XEditor/XEditor';
import {XEditorActions} from './XEditorActions';
import MobileSection from './components/Sidebar/MobileSection';
import Sidebar from './components/Sidebar';

import './XEditorPage.scss';

interface OwnProps {
  exerciseId?: string;
}

type ConnectedProps = StateProps & DispatchProps & WrappedComponentProps;

type Props = OwnProps & ConnectedProps;

class XEditorPage extends React.PureComponent<Props> {
  public render() {
    const {
      props: {activeTab, editingDisabled}
    } = this;

    return (
      <React.Fragment>
        <Tab.Container
          id="xeditor-nav"
          defaultActiveKey={activeTab}
          activeKey={activeTab}
          onSelect={this.changeTab}
        >
          <div className="xeditor-page">
            <div className="xeditor-header">
              <div className="nav-breadcrumbs">
                <Breadcrumbs />
              </div>
              <Nav bsStyle="tabs">
                <NavItem eventKey="editor" disabled={editingDisabled}>
                  <FormattedMessage id="XEditor.TabItem.Editor" />
                </NavItem>
                <NavItem eventKey="preview" disabled={editingDisabled}>
                  <FormattedMessage id="XEditor.TabItem.Preview" />
                </NavItem>
              </Nav>
              <XEditorActions editingDisabled={editingDisabled} onCancel={this.props.onCancel} />
            </div>
            <div className="xeditor-body">
              <div className="xeditor-content">
                <Tab.Content animation={true}>
                  <Tab.Pane eventKey={XEditorTab.EDITOR} className="editor-tab">
                    <XEditor />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey={XEditorTab.PREVIEW}
                    className="player-tab"
                    unmountOnExit={true}
                  >
                    <XPreviewWithCarousel />
                  </Tab.Pane>
                </Tab.Content>
              </div>
              <FloatSidebar
                breakpoint={BootstrapBreakpoint.LG}
                collapsedButton={(clickHandler, sidebarCollapsed) => (
                  <CollapsedSidebarButton
                    onClick={clickHandler}
                    shouldBeRendered={sidebarCollapsed || false}
                  />
                )}
              >
                <Sidebar>
                  <MobileSection />
                </Sidebar>
              </FloatSidebar>
              {editingDisabled ? (
                <div className="loading-mask">
                  <Loader />
                </div>
              ) : null}
            </div>
          </div>
        </Tab.Container>
      </React.Fragment>
    );
  }

  private changeTab = async (eventKey: {}) => {
    const {intl, validateExercise, activeTab} = this.props;
    if (activeTab === (eventKey as XEditorTab)) {
      return;
    }
    const isValid = await validateExercise(intl);
    if (isValid) {
      this.props.stopMedia();
      this.props.changeTab(eventKey as XEditorTab);
    }
  };
}

interface StateProps {
  activeTab: XEditorTab;
  editingDisabled: boolean;
}

interface DispatchProps {
  changeTab: (tab: XEditorTab) => void;
  stopMedia: () => void;
  validateExercise: (intl: IntlShape) => Promise<boolean>;
  onCancel: () => void;
}

const mapStateToProps: MapStateToProps<StateProps, {}, AppState> = (
  state: AppState
): StateProps => {
  const {activeTab, stage} = state.xeditor!;
  return {
    activeTab,
    editingDisabled: stage !== XEditorStage.EDITING
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: Dispatch<Action, AppState>,
  owmProps: OwnProps
) => {
  return {
    onCancel: () => dispatch(deleteDraftExercise(`${owmProps.exerciseId || null}`)),
    changeTab: tab => dispatch(changeTab(tab)),
    stopMedia: () => dispatch(stopMedia()),
    validateExercise: (intl: IntlShape) => dispatch(validate(intl))
  };
};

const ConnectedXEditorPage = connect(mapStateToProps, mapDispatchToProps)(XEditorPage);

export default injectIntl(ConnectedXEditorPage);
