import React from 'react';
import * as Yup from 'yup';
import Button from 'react-bootstrap/lib/Button';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage, type IntlShape} from 'react-intl';
import {Form, type FormikBag, type InjectedFormikProps, withFormik} from 'formik';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

import Icon from 'components/Icon';
import Loader from 'components/Loader';

import messages from '../../i18n';

import './YoutubeInputModal.scss';

interface Values {
  link: string;
  start: string;
  end: string;
  withStart: boolean;
  withEnd: boolean;
}

interface Props {
  clearReadyStateError: () => void;
  close: () => void;
  timeoutError?: true;
  handleSubmit: (values: {link: string; start?: number; end?: number}) => void;
  intl: IntlShape;
  readyStateError: boolean;
  show: boolean;
  submitting: boolean;
  start?: number;
  end?: number;
  youtubeSourceId?: string;
  onChangeTime: (event: React.FormEvent<Checkbox> | React.ChangeEvent<HTMLInputElement>) => void;
}

type AllProps = InjectedFormikProps<Props, Values>;

class YoutubeInputModal extends React.Component<AllProps> {
  private get submitButtonClasses() {
    const {show, submitting} = this.props;
    return classNames({submitting, 'hidden-text': !show});
  }

  public componentDidUpdate(prevProps: AllProps) {
    if (!prevProps.show && this.props.show) {
      this.props.resetForm();
    }

    if (prevProps.values.withStart && !this.props.values.withStart) {
      this.props.setFieldValue('start', '');
    }

    if (prevProps.values.withEnd && !this.props.values.withEnd) {
      this.props.setFieldValue('end', '');
    }
  }

  private onChangeLink = (event: React.FormEvent<FormControl>) => {
    this.props.readyStateError && this.props.clearReadyStateError();
    this.props.handleChange(event);
  };

  private onChangeTime = (
    event: React.FormEvent<Checkbox> | React.ChangeEvent<HTMLInputElement>
  ) => {
    this.props.readyStateError && this.props.clearReadyStateError();
    this.props.handleChange(event);
  };

  public render() {
    const {
      close,
      dirty,
      errors,
      timeoutError,
      intl,
      readyStateError,
      show,
      submitting,
      touched,
      values
    } = this.props;

    const disabled = !!(
      ((touched.link || dirty) && errors.link) ||
      ((touched.start || dirty) && errors.start) ||
      submitting
    );

    return (
      <Modal backdrop="static" className="video-input-modal youtube" onHide={close} show={show}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="XEditorWidget.Video.Modal.Youtube.Title" />
          </Modal.Title>
          <Button className="header-close-button" onClick={close}>
            <Icon name="pc-multiply" />
          </Button>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <FormGroup controlId="link">
              <FormControl
                className={classNames({
                  'with-error': (errors.link && (touched.link || dirty)) || readyStateError
                })}
                autoComplete="off"
                autoFocus={true}
                name="link"
                onChange={this.onChangeLink}
                placeholder={intl.formatMessage(messages.youtubeInputPlaceholder)}
                value={values.link}
              />
              <div className="start-time">
                <Checkbox name="withStart" onChange={this.onChangeTime} checked={values.withStart}>
                  <FormattedMessage id="XEditorWidget.Video.Modal.Youtube.StartTime" />
                </Checkbox>

                <InputMask
                  name="start"
                  mask="99:99:99"
                  maskPlaceholder="--:--:--"
                  value={values.start}
                  onChange={this.onChangeTime}
                  disabled={!values.withStart}
                >
                  <FormControl
                    className={classNames({
                      'with-error': values.withStart && errors.start && (touched.start || dirty)
                    })}
                    autoComplete="off"
                  />
                </InputMask>
              </div>
              <div className="end-time">
                <Checkbox name="withEnd" onChange={this.onChangeTime} checked={values.withEnd}>
                  <FormattedMessage id="XEditorWidget.Video.Modal.Youtube.EndTime" />
                </Checkbox>

                <InputMask
                  name="end"
                  mask="99:99:99"
                  maskPlaceholder="--:--:--"
                  value={values.end}
                  onChange={this.onChangeTime}
                  disabled={!values.withEnd}
                >
                  <FormControl
                    className={classNames({
                      'with-error': values.withEnd && errors.end && (touched.end || dirty)
                    })}
                    autoComplete="off"
                  />
                </InputMask>
              </div>

              <div
                className={classNames('help-block', {
                  error: errors.link || errors.start || errors.end
                })}
              >
                {readyStateError
                  ? intl.formatMessage(
                      timeoutError ? messages.errorVerifyingLink : messages.videoIsUnavailableError
                    )
                  : errors.link || errors.start || errors.end}
              </div>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="small" onClick={close}>
              <FormattedMessage id="Common.Cancel" />
            </Button>
            <Button
              bsSize="small"
              bsStyle="primary"
              disabled={disabled}
              type="submit"
              className={this.submitButtonClasses}
            >
              {submitting && (
                <div className="loader-button-positioning-helper">
                  <Loader shouldRender={true} />
                </div>
              )}
              <FormattedMessage id="Common.Add" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

function getTime(seconds: number | undefined): string {
  return Number.isInteger(seconds) ? new Date(seconds! * 1000).toISOString().substr(11, 8) : '';
}

function isTime(time: string): boolean {
  const regex = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]:[0-5][\d]$/;

  return regex.test(time);
}

function getSeconds(time: string): number | undefined {
  try {
    const [hours, minutes, seconds] = time.split(':').map(t => Number(t));
    return Date.UTC(1970, 0, 1, hours, minutes, seconds) / 1000;
  } catch {
    return undefined;
  }
}

function getLink(youtubeSourceId?: string) {
  return youtubeSourceId ? `https://www.youtube.com/watch?v=${youtubeSourceId}` : '';
}

const handleSubmit = (values: Values, {props, setErrors}: FormikBag<Props, Values>) => {
  if (values.withStart || values.withEnd) {
    if (values.withStart && !values.start) {
      return setErrors({start: props.intl.formatMessage(messages.startMustNotBeEmpty)});
    }

    if (values.withStart && !isTime(values.start)) {
      return setErrors({start: props.intl.formatMessage(messages.startMustBeValid)});
    }

    if (values.withEnd && !values.end) {
      return setErrors({end: props.intl.formatMessage(messages.endMustNotBeEmpty)});
    }

    if (values.withEnd && !isTime(values.end)) {
      return setErrors({end: props.intl.formatMessage(messages.endMustBeValid)});
    }

    const startTime = values.withStart ? getSeconds(values.start) : undefined;
    const endTime = values.withEnd ? getSeconds(values.end) : undefined;

    if (Number.isInteger(startTime) && Number.isInteger(endTime) && startTime! >= endTime!) {
      return setErrors({start: props.intl.formatMessage(messages.startMustBeLessThanEnd)});
    }

    if (!startTime && endTime === 0) {
      return setErrors({end: props.intl.formatMessage(messages.endMustNotBeStart)});
    }

    return props.handleSubmit({link: values.link, start: startTime, end: endTime});
  }

  props.handleSubmit({link: values.link});
};

const validationSchema = ({intl: {formatMessage}}: AllProps) =>
  Yup.object().shape({
    link: Yup.string()
      .trim()
      .required(formatMessage(messages.invalidInput))
      .matches(
        /(http(s)?:\/\/)?((w){3}\.)?youtu(\.be|(be\.com))\/.+/,
        formatMessage(messages.invalidInput)
      )
  });

const mapPropsToValues = ({youtubeSourceId, start, end}: Props): Values => ({
  link: getLink(youtubeSourceId),
  start: getTime(start),
  end: getTime(end),
  withStart: Number.isInteger(start),
  withEnd: Number.isInteger(end)
});

export default withFormik({
  handleSubmit,
  mapPropsToValues,
  validationSchema,
  enableReinitialize: true
})(YoutubeInputModal);
