import {type Block, type Node, type Plugin, type SchemaProperties, Text} from '@englex/slate';

import {SlateBlock, SlateObject} from '../../../../interface';
import {isBlockOfType} from '../../../../utils';
import {logNormalizeError} from '../../../../SlateEditor/plugins/utils';

export class OddWordNormalizer implements Plugin {
  public schema: SchemaProperties = {
    rules: [
      {
        match: {type: SlateBlock.QUESTION_ITEM},
        first: (child: Node) => {
          if (!isBlockOfType(child, SlateBlock.DEFAULT) || child.nodes.size === 0) {
            // first node is not a paragraph, or it doesn't contain children
            // in general it's possible, but the one is not a case for this normalizer,
            // and it should be handled by other normalizers
            return true;
          }
          return (
            child.nodes.size === 1 &&
            child.nodes.first().object === SlateObject.TEXT &&
            child.text === ''
          );
        },
        normalize: (e, error) => {
          const {child: paragraph} = error;
          const nodes = paragraph.nodes;
          e.insertNodeByKey(paragraph.key, 0, Text.create(''));
          nodes.forEach((n: Block) => e.removeNodeByKey(n.key));

          logNormalizeError(error, 'OddWordNormalizer.schema.rules', true, '0');
        }
      }
    ]
  };
}
