import {type FC, type FormEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {type Plugin} from '@englex/slate-react';
import {type Editor, type Node, PathUtils} from '@englex/slate';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import FormControl from 'react-bootstrap/lib/FormControl';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import {FormattedMessage, useIntl} from 'react-intl';

import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import TheOnlyOrderedList from 'components/Slate/SlateEditor/plugins/button/List/SpecificCases';
import {type AppState} from 'store/interface';
import type XSpellingRecord from 'store/exercise/editor/widgets/XSpelling/XSpellingRecord';
import {ORDINAL_MAX_SIZE} from 'store/exercise/editor/widgets/XSpelling/XSpellingRecord';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import {SlateObject} from 'components/Slate/interface';
import {
  scrambledSentencesChange,
  toggleContainsExample
} from 'store/exercise/editor/xwidgetActions';
import {setOrdinal} from 'store/exercise/editor/widgets/XSpelling/actions';
import {LetterDecorator} from 'components/Slate/SlateEditor/plugins/TextDecorator';

import './XSpelling.scss';

interface Props {
  id: string;
}

function whenPlaceholder({value: {document}}: Editor, node: Node) {
  return (
    node.object === SlateObject.DOCUMENT &&
    document.text === '' &&
    document.getFurthestBlock(PathUtils.create([0, 0]))?.nodes.size === 1
  );
}

export const XSpelling: FC<Props> = ({id}) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const [isOneSentence, setIsOneSentence] = useState(true);

  const {
    sentencesValue,
    options,
    isOneSentence: getIsOneSentence,
    ordinal,
    letterChecker: checker
  } = useSelector(
    (state: AppState) =>
      state.xeditor!.xexercise.widgets.find(
        (x: XWidgetProperties) => x.id === id
      ) as XSpellingRecord
  );

  const plugins: Plugin[] = useMemo(
    () => [new Undo(), new Redo(), new TheOnlyOrderedList(), new LetterDecorator({checker})],
    [checker]
  );

  useEffect(() => {
    return setIsOneSentence(getIsOneSentence);
  }, [getIsOneSentence]);

  const containsExample = options?.get('containsExample') || false;

  const onChange = useCallback(
    (change: Editor) => dispatch(scrambledSentencesChange(id, change)),
    [dispatch, id]
  );
  const toggleExample = useCallback(() => dispatch(toggleContainsExample(id)), [dispatch, id]);

  const onChangeNumber = useCallback(
    (event: FormEvent<FormControl>) => {
      const text = (event.target as HTMLInputElement).value;
      if (/^\d+$/.test(text) || text === '') dispatch(setOrdinal(id, text ? text : undefined));
    },
    [dispatch, id]
  );

  return (
    <>
      <div
        className={classNames('x-spelling', {
          'example-sentence': containsExample,
          'is-one-sentence': isOneSentence
        })}
      >
        {isOneSentence && (
          <FormControl
            bsSize="small"
            className={'x-spelling-number'}
            placeholder={'#'}
            type="text"
            value={ordinal || ''}
            onChange={onChangeNumber}
            maxLength={ORDINAL_MAX_SIZE}
          />
        )}
        <SlateEditor
          value={sentencesValue}
          onChange={onChange}
          plugins={plugins}
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          spellcheck={false}
          placeholder={formatMessage({id: 'XEditorXWidget.Spelling.EnterWordOrWords'})}
          whenPlaceholder={whenPlaceholder}
        />
      </div>
      <Checkbox onChange={toggleExample} checked={containsExample}>
        {isOneSentence ? (
          <FormattedMessage id="XEditorXWidget.Spelling.ToggleExampleWithOneSentence" />
        ) : (
          <FormattedMessage id="XEditorXWidget.Spelling.ToggleExampleWithFewSentences" />
        )}
      </Checkbox>
    </>
  );
};
