import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {type Action, type Dispatch} from 'redux';

import {type AppState, type Role} from 'store/interface';
import {stopMedia} from 'store/media/actions';
import {changePreviewRole} from 'store/exercise/player/preview/actions';
import {
  type XPreviewStateProps,
  type XPreviewDispatchProps
} from 'store/exercise/player/preview/interface';

import XPreview from './XPreview';

const mapStateToProps: MapStateToProps<XPreviewStateProps, {}, AppState> = (state: AppState) => {
  return {
    xpreview: state.xeditor && state.xeditor.xpreview ? state.xeditor.xpreview : undefined
  };
};

const mapDispatchToProps: MapDispatchToProps<XPreviewDispatchProps, {}> = (
  dispatch: Dispatch<Action>
) => {
  return {
    changeRole: (role: Role) => {
      dispatch(changePreviewRole(role));
      dispatch(stopMedia());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(XPreview);
