import React, {memo, useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector, useDispatch} from 'react-redux';

import {resetWidgetErrors} from 'store/exercise/editor/actions/xeditor';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {
  addMessage,
  addSpeaker,
  changeColorSchema,
  changeSpeaker
} from 'store/exercise/editor/widgets/XDialogue/actions';
import {type XDialogueProperties} from 'store/exercise/editor/widgets/XDialogue/interface';
import type XDialogueSpeakerRecord from 'store/exercise/editor/widgets/XDialogue/XDialogueSpeakerRecord';
import type XDialogueMessageRecord from 'store/exercise/editor/widgets/XDialogue/XDialogueMessageRecord';
import {type AppState} from 'store/interface';
import Icon from 'components/Icon';

import {SpeakerCard} from './components/SpeakerCard/SpeakerCard';
import {localMessages} from './messages';
import {SpeakersModal} from './components/SpeakersModal/SpeakersModal';
import {MessageCard} from './components/MessageCard/MessageCard';
import {StyleModal} from './components/StyleModal/StyleModal';
import {XDisplayAsButton} from '../components/XDisplayAsButton/XDisplayAsButton';

import './XDialogue.scss';

interface Props {
  id: string;
  setError: (e: string | React.ReactElement) => string | React.ReactElement;
}

export const XDialogue: React.FC<Props> = memo(({id, setError}) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();

  const [speakersModalShowed, setSpeakersModalShowed] = useState(false);
  const [selectedSpeaker, setSelectedSpeaker] = useState<string | null>(null);
  const [messageWithChangingSpeaker, setMessageWithChangingSpeaker] = useState<string | null>(null);

  const [styleModalShowed, setStyleModalSowed] = useState(false);
  const [currentStyleName, setCurrentStyleName] = useState('');

  const widget = useSelector((state: AppState) =>
    state.xeditor!.xexercise.widgets.find((x: XWidgetProperties) => x.id === id)
  ) as XDialogueProperties;

  const errors = useSelector((state: AppState) => {
    return state.xeditor!.errors.getIn(['widgets', id], {});
  });

  const {speakers, messages, styleName} = widget;

  const filteredSpeakers = speakers.filter(speaker => !!speaker?.name);
  const isEmptySpeakers = filteredSpeakers.size === 0;

  const resetErrors = useCallback(() => dispatch(resetWidgetErrors(id)), [dispatch, id]);

  const createSpeaker = () => {
    dispatch(addSpeaker(id));
  };

  const openSpeakersModal = () => {
    setSpeakersModalShowed(true);
  };

  const openStyleModal = () => {
    setStyleModalSowed(true);
    setCurrentStyleName(styleName);
  };

  const onSelect = () => {
    setStyleModalSowed(false);
  };

  const closeStyleModal = () => {
    dispatch(changeColorSchema(id, currentStyleName));
    setStyleModalSowed(false);
  };

  const closeSpeakersModal = useCallback(() => {
    setSpeakersModalShowed(false);
    setMessageWithChangingSpeaker(null);
    setSelectedSpeaker(null);
  }, []);

  const selectSpeaker = useCallback(() => {
    if (messageWithChangingSpeaker && selectedSpeaker) {
      dispatch(changeSpeaker(id, messageWithChangingSpeaker, selectedSpeaker));
    }

    if (!messageWithChangingSpeaker && selectedSpeaker) {
      dispatch(addMessage(id, selectedSpeaker));
    }

    closeSpeakersModal();
    resetErrors();
  }, [closeSpeakersModal, dispatch, id, messageWithChangingSpeaker, resetErrors, selectedSpeaker]);

  const onChangeSpeaker = useCallback((messageId: string, currentSpeakerId: string) => {
    setSelectedSpeaker(currentSpeakerId);
    setMessageWithChangingSpeaker(messageId);
    openSpeakersModal();
  }, []);

  return (
    <>
      <SpeakersModal
        speakers={filteredSpeakers}
        opened={speakersModalShowed}
        selectedSpeaker={selectedSpeaker}
        onClose={closeSpeakersModal}
        setSelectedSpeaker={setSelectedSpeaker}
        onSelected={selectSpeaker}
      />
      <StyleModal
        opened={styleModalShowed}
        widgetId={id}
        styleName={styleName}
        onClose={closeStyleModal}
        onSelect={onSelect}
      />
      <div className="speakers">
        <div className="cards">
          {speakers.map((speaker: XDialogueSpeakerRecord) => (
            <SpeakerCard
              key={speaker.id}
              widgetId={id}
              speakerId={speaker.id}
              avatar={speaker.avatar}
              name={speaker.name}
              withError={!!errors.meta?.[speaker.id]}
              haveMessages={!!messages.find(message => speaker.id === message?.speakerId)}
              setError={setError}
              resetErrors={resetErrors}
            />
          ))}
        </div>

        <div className="footer">
          <button className="control" onClick={createSpeaker}>
            <Icon name="plus-circle" />
            {formatMessage(localMessages.AddSpeaker)}
          </button>

          <span className="text">{formatMessage(localMessages.ToUpload)}</span>
        </div>
      </div>

      <div className="messages">
        {!!messages.size &&
          messages.map((message: XDialogueMessageRecord, index) => (
            <MessageCard
              key={message.id}
              message={message}
              speakers={speakers}
              widgetId={id}
              withError={!!errors.meta?.[message.id]}
              isFirst={index === 0}
              isLast={index === messages.size - 1}
              resetErrors={resetErrors}
              onChangeSpeaker={onChangeSpeaker}
            />
          ))}

        <div className="controls">
          <button
            className="control"
            disabled={isEmptySpeakers}
            title={isEmptySpeakers ? formatMessage(localMessages.EmptySpeakers) : undefined}
            onClick={openSpeakersModal}
          >
            <Icon name="plus-circle" />
            {formatMessage(localMessages.AddMessage)}
          </button>

          <button
            className="control"
            title={isEmptySpeakers ? formatMessage(localMessages.EmptySpeakers) : undefined}
            onClick={openStyleModal}
          >
            <Icon name="cog" />
            {formatMessage(localMessages.ChangeTheme)}
          </button>
        </div>
      </div>
      <XDisplayAsButton xwidgetId={id} />
    </>
  );
});
