import React from 'react';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';

import Icon from 'components/Icon';
import './Dropdown.scss';

interface Props extends WrappedComponentProps {
  delete: (id: number) => void;
  dropdownDropupCheck: (e: React.MouseEvent<{}>) => void;
  edit: () => void;
  id: number;
  shouldDropup: boolean;
}

interface State {
  isOpen: boolean;
}

class Dropdown extends React.Component<Props, State> {
  public state: State = {isOpen: false};

  public render() {
    const {isOpen} = this.state;
    const {shouldDropup} = this.props;
    return (
      <DropdownButton
        className="edit-category-dropdown"
        id="edit-category-dropdown"
        title={<Icon name="virc-actions" />}
        open={isOpen}
        onClick={this.onClick}
        onToggle={this.toggle}
        dropup={shouldDropup}
      >
        <MenuItem onClick={this.edit}>
          <FormattedMessage id="Common.Rename" />
        </MenuItem>
        <MenuItem onClick={this.delete}>
          <FormattedMessage id="File.Delete" />
        </MenuItem>
      </DropdownButton>
    );
  }

  private edit = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
    this.props.edit();
  };

  private delete = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
    this.props.delete(this.props.id);
  };

  private onClick = (e: React.MouseEvent<DropdownButton>) => {
    e.stopPropagation();
    this.props.dropdownDropupCheck(e);
  };

  private toggle = () => {
    this.setState({isOpen: !this.state.isOpen});
  };
}

export default injectIntl(Dropdown);
