import React, {type FC, type PropsWithChildren, useCallback, useRef} from 'react';
import {useDispatch} from 'react-redux';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';

import {displayButtonIconChange} from 'store/exercise/editor/xwidgetActions';
import Icon from 'components/Icon';
import {widgetDisplayAsButtonIconDefault} from 'config/static';
import {type DisplayButtonProperties} from 'store/exercise/player/DisplayButtonRecord';

import {DisplayButtonIcon} from './XDisplayButtonIcon';

interface Props {
  displayButton: DisplayButtonProperties;
  xwidgetId: string;
}

const displayButtonIcons = [
  'book',
  'virc-vocabulary',
  'play-example',
  'music',
  'wechat',
  'info-circle'
];

export const XDisplayAsButtonIconSelector: FC<PropsWithChildren<Props>> = ({
  displayButton,
  xwidgetId,
  children
}) => {
  const dispatch = useDispatch();
  const [isVisible, setVisible] = React.useState(false);
  const onVisibleChange = useCallback(visible => {
    setVisible(visible);
  }, []);
  const containerRef = useRef<HTMLDivElement>(null);
  const getTooltipContainer: () => HTMLElement = useCallback(
    () => containerRef.current || document.body,
    []
  );

  const activeIcon = displayButton?.icon || widgetDisplayAsButtonIconDefault;

  const isFocused = displayButton?.title.selection?.isFocused;

  const onButtonIconChange = useCallback(
    (icon: string) => {
      dispatch(displayButtonIconChange(xwidgetId, icon));
      onVisibleChange(false);
    },
    [dispatch, onVisibleChange, xwidgetId]
  );

  const overlayTooltipIcons = displayButtonIcons.map(icon => (
    <DisplayButtonIcon
      key={icon}
      onClick={onButtonIconChange}
      icon={icon}
      activeIcon={activeIcon}
    />
  ));

  if (!displayButton) {
    return null;
  }

  return (
    <div ref={containerRef}>
      <InputGroup>
        <Tooltip
          trigger="click"
          defaultVisible={false}
          visible={isVisible}
          onVisibleChange={onVisibleChange}
          overlay={overlayTooltipIcons}
          showArrow={false}
          getTooltipContainer={getTooltipContainer}
          placement="bottomLeft"
        >
          <InputGroup.Addon className={classNames({focus: isFocused})}>
            <span className="input-group-icon">
              <Icon size="lg" name={displayButton.icon || widgetDisplayAsButtonIconDefault} />
            </span>
            <span className="input-group-icon">
              <Icon name="angle-down" />
            </span>
          </InputGroup.Addon>
        </Tooltip>
        {children}
      </InputGroup>
    </div>
  );
};
