import React from 'react';

import genKey from 'components/Slate/utils/genKey';
import {valueJSONFromText} from 'components/Slate/utils/value';
import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import type XMatchingRecord from 'store/exercise/editor/widgets/XMatching/XMatchingRecord';
import {type MatchingJSON} from 'store/exercise/player/widgets/Matching/interface';
import {xmatchingFactory} from 'store/exercise/editor/widgets/XMatching/xmatchingFactory';

import {type XWizardWidgetData} from '../interface';
import Tile from '../Tile';
import {MatchingSVG} from '../assets';
import {MatchingWizard} from './MatchingWizard';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.Matching}>
    <MatchingSVG />
  </Tile>
);

export const matchingWizardData: XWizardWidgetData<MatchingJSON, XMatchingRecord> = {
  type: WidgetType.MATCHING,
  steps: [
    {
      canGoToNextStep: (widget: Partial<MatchingJSON>) => !!widget.matchingType,
      component: MatchingWizard
    }
  ],
  tile,
  createWidget: (widget: Pick<MatchingJSON, 'type' | 'matchingType'>) => {
    const raw = {
      id: genKey(),
      type: widget.type,
      task: valueJSONFromText(''),
      content: valueJSONFromText(''),
      matchingType: widget.matchingType
    };

    return xmatchingFactory(raw);
  }
};
