import React from 'react';
import {FormattedMessage} from 'react-intl';

import {VideoSourceType} from 'components/media/interface';
import Icon from 'components/Icon';

interface Props {
  onClick: () => void;
  source: VideoSourceType;
  show: boolean;
}

class NewVideoTrigger extends React.Component<Props> {
  public render() {
    const {onClick, show, source} = this.props;
    return show ? (
      <div className="new-video-trigger">
        <div className="open-modal-mask" onClick={onClick} />
        <Icon name={source === VideoSourceType.YOUTUBE ? 'youtube-play' : 'enlx-logo'} />
        <FormattedMessage id="XEditorWidget.Video.InputPlaceholder" />
      </div>
    ) : null;
  }
}

export default NewVideoTrigger;
