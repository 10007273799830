import React from 'react';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';

import Icon from './Icon';

import './TextDown.scss';

interface Props {
  changeValue: (value: string) => void;
  value: string;
  dropdownId: string;
  placeholder?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  dropdownValues: string[];
  additionalClass?: string;
  menuElementsDisplayedAtOnce: number;
  dividerHeight: number;
  buttonWidth: number;
  menuWidth?: number;
}

interface State {
  dropdownIsOpen: boolean;
}

export default class TextDown extends React.Component<Props, State> {
  public static defaultProps = {
    menuElementsDisplayedAtOnce: 5
  };

  private static menuItemHeight = 42;

  public state: State = {
    dropdownIsOpen: false
  };

  private input: HTMLInputElement | null;

  public render() {
    const {
      placeholder,
      value,
      dropdownId,
      dropdownValues,
      additionalClass,
      dividerHeight,
      menuWidth,
      buttonWidth
    } = this.props;
    const className = classNames('text-down', additionalClass);
    return (
      <div className={className}>
        <input
          placeholder={placeholder}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          value={value}
          onChange={this.onChange}
          ref={this.getRef}
        />
        <DropdownButton
          className={'btn btn-circle'}
          id={dropdownId}
          noCaret={true}
          pullRight={true}
          style={{width: buttonWidth}}
          open={this.state.dropdownIsOpen}
          onToggle={this.toggleDropdown}
          title={
            <React.Fragment>
              <div className="divider" style={{height: dividerHeight}} />
              <Icon name="angle-down" size="lg" />
            </React.Fragment>
          }
        >
          <Scrollbars style={{height: this.getScrollbarHeight(), width: menuWidth}}>
            {dropdownValues.map(t => (
              <MenuItem key={t} eventKey={t} onClick={() => this.onDropdownSelect(t)}>
                {t}
              </MenuItem>
            ))}
          </Scrollbars>
        </DropdownButton>
      </div>
    );
  }

  private getScrollbarHeight = () => {
    const {menuElementsDisplayedAtOnce, dropdownValues} = this.props;
    if (dropdownValues.length > menuElementsDisplayedAtOnce) {
      return menuElementsDisplayedAtOnce * TextDown.menuItemHeight;
    }
    return dropdownValues.length * TextDown.menuItemHeight;
  };

  private getRef = (el: HTMLInputElement | null) => (this.input = el);

  private onDropdownSelect = (eventKey: string) => {
    this.props.changeValue(eventKey);
    this.setState({dropdownIsOpen: false});
    if (this.input) {
      this.input.focus();
    }
  };

  private onChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.props.changeValue(e.currentTarget.value);

  private onFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  private onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  private toggleDropdown = () => this.setState({dropdownIsOpen: !this.state.dropdownIsOpen});
}
