import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {
  type ScrambledJSON,
  type XScrambledSentencesWizardOptions
} from 'store/exercise/player/widgets/ScrambledSentences/interface';
import XScrambledRecord from 'store/exercise/editor/widgets/XScrambled/XScrambledRecord';

import {ScrambledSentencesSVG} from '../assets';
import {type XWizardWidgetData} from '../interface';
import Tile from '../Tile';
import {ManualSplitting} from './ManualSplitting';

export const scrambledSentencesWizardData: XWizardWidgetData<ScrambledJSON, XScrambledRecord> = {
  type: WidgetType.SCRAMBLED_SENTENCES,
  steps: [
    {
      canGoToNextStep: () => true,
      component: ManualSplitting
    }
  ],
  tile: () => (
    <Tile title={WidgetTitle.ScrambledSentences}>
      <ScrambledSentencesSVG />
    </Tile>
  ),
  createWidget: ({manualSplitting}: XScrambledSentencesWizardOptions) =>
    XScrambledRecord.create(
      manualSplitting
        ? ['', '', '']
        : ['Sample sentence one.', 'Sample sentence two?', 'Sample sentence three!'],
      manualSplitting
    )
};
