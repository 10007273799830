import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {type QuizJSON, type XQuizWizardOptions} from 'store/exercise/player/widgets/Quiz/interface';
import genKey from 'components/Slate/utils/genKey';
import {valueJSONFromText} from 'components/Slate/utils';
import {MultipleChoiceElement} from 'store/exercise/player/widgets/List/interface';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import XQuizRecord from 'store/exercise/editor/widgets/XQuiz/XQuizRecord';

import {type XWizardWidgetData} from '../interface';
import QuizWizard from './QuizWizard';
import Tile from '../Tile';
import {QuizSVG} from '../assets';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.Quiz}>
    <QuizSVG />
  </Tile>
);

export const quizWizardData: XWizardWidgetData<QuizJSON, XWidgetProperties> = {
  type: WidgetType.Quiz,
  steps: [
    {
      canGoToNextStep: () => true,
      component: QuizWizard
    }
  ],
  tile,
  createWidget: (widget: XQuizWizardOptions) =>
    new XQuizRecord({
      id: genKey(),
      type: widget.type,
      task: valueJSONFromText(''),
      content: valueJSONFromText(''),
      resultBlocks: XQuizRecord.createBlocks(widget.answersNumber),
      options: {
        element: MultipleChoiceElement.RADIO,
        questionsNumber: widget.questionsNumber,
        answersNumber: widget.answersNumber
      }
    })
};
