import type React from 'react';
import {useDrag, useDrop} from 'react-dnd';

import {type DndComponentProps} from 'routes/Library/XEditorPage/components/XEditor/RenderDragItem';
import {type DndTypes} from 'components/dnd/interface';
import {useDndContext} from 'components/XPlayer/contexts/dndContext';

interface DragItem {
  dragId: string;
  type: string;
}

interface Props {
  itemId: string;
  currentDndElementRect: React.MutableRefObject<DOMRect | undefined>;
  childOptions: DndComponentProps;
  dndType: DndTypes;
  handle: React.ReactElement;
  isNotUseDragLayer: boolean;
  updateRecoveryPoint: () => void;
  moveItem: (moveItemIndex: number, targetIndex: number) => void;
  rollBackChanges: () => void;
  getItemIndex: (cardId: string) => number;
}

export function useDndSorting({
  itemId,
  currentDndElementRect,
  childOptions,
  dndType,
  handle,
  isNotUseDragLayer,
  updateRecoveryPoint,
  moveItem,
  rollBackChanges,
  getItemIndex
}: Props) {
  const {currentHoverElementId, setCurrentHoverElementId} = useDndContext();

  const [{isDragging}, drag, dragPreview] = useDrag(
    () => ({
      type: dndType,
      item: () => ({
        dragId: itemId,
        itemRect: currentDndElementRect.current,
        handle,
        isNotUseDragLayer,
        ...childOptions
      }),
      collect: monitor => ({
        isDragging: monitor.isDragging()
      }),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<{dropId: string} | null>();
        setCurrentHoverElementId?.('');
        if (!dropResult) {
          rollBackChanges();
        } else updateRecoveryPoint();
      }
    }),
    [rollBackChanges, updateRecoveryPoint, childOptions, setCurrentHoverElementId]
  );

  const [{isOver}, drop] = useDrop(
    {
      accept: dndType,
      drop: () => ({dropId: itemId}),
      collect(monitor) {
        return {
          isOver: monitor.isOver()
        };
      },
      hover(item: DragItem) {
        const {dragId} = item;
        const dropId = itemId;

        if (dragId === dropId) {
          setCurrentHoverElementId?.('');
          return;
        }

        if (currentHoverElementId === dropId) {
          return;
        }

        setCurrentHoverElementId?.(dropId);

        const dragIndex = getItemIndex(dragId);
        const dropIndex = getItemIndex(dropId);
        moveItem(dragIndex, dropIndex);
      }
    },
    [moveItem, getItemIndex, setCurrentHoverElementId, currentHoverElementId]
  );

  return {drag, dragPreview, isDragging, drop, isOver};
}
