import React, {Component, type SyntheticEvent} from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import {type CheckboxProps, default as Checkbox} from 'react-bootstrap/lib/Checkbox';
import {type FormikProps, withFormik} from 'formik';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import FormControl from 'react-bootstrap/lib/FormControl';
import classNames from 'classnames';
import * as Yup from 'yup';
import {collapseSpaces, convertTicks, pipe, removeHidden, trim} from '@englex/utils';

import Icon from 'components/Icon';

import {type ToolBoxChildrenProps} from '../../../interface';
import {type GapFillEditFormProps} from '../';
import FormControlTooltip from './FormControlTooltip';
import {FormFooter} from './FormFooter';
import './EditGapForm.scss';

interface Values {
  answer: string[];
  caseSensitive: boolean;
  example: boolean;
  indefiniteForm: string;
  startOfSentence: boolean;
}

type Props = GapFillEditFormProps & ToolBoxChildrenProps & WrappedComponentProps;

type FormProps = Props & FormikProps<Values>;

class EditGapDNDInput extends Component<FormProps> {
  public render() {
    const {errors, values, handleChange, handleSubmit, del} = this.props;
    return (
      <form className="edit-gap-form" onSubmit={handleSubmit}>
        <Modal.Body className="no-header">
          <div className="label-answer">
            <FormattedMessage id="XEditor.Form.EditGap.CorrectAnswers" />
          </div>
          {values.answer.map(this.renderInput)}
          <Button bsSize="sm" bsStyle="success" className="add-gap" onClick={this.addAnswer}>
            <FormattedMessage
              id="XEditor.Form.EditGap.AddAnswer"
              values={{icon: <Icon name="plus-circle" />}}
            />
          </Button>

          <Checkbox
            name="example"
            onChange={this.handleExampleCheckbox}
            defaultChecked={values.example}
          >
            <FormattedMessage id="XEditor.Example" />
          </Checkbox>

          <div className="label-indefinite-form">
            <FormattedMessage id="XEditor.Form.EditGap.IndefiniteForm" />
          </div>
          <div className={classNames('answer-row', {'has-error': errors.indefiniteForm})}>
            <FormControlTooltip
              state={errors.indefiniteForm ? 'error' : null}
              content={errors.indefiniteForm}
            >
              <FormControl
                className="indefinite-form"
                autoComplete="off"
                bsSize="sm"
                name="indefiniteForm"
                onChange={handleChange}
                defaultValue={values.indefiniteForm}
              />
            </FormControlTooltip>
          </div>
          <Checkbox
            name="startOfSentence"
            onChange={this.toggleStartOfSentence}
            checked={values.startOfSentence}
          >
            <FormattedMessage id="XEditor.Form.EditGap.StartOfSentence" />
          </Checkbox>
          <Checkbox
            name="caseSensitive"
            onChange={this.toggleCaseSensitive}
            checked={values.caseSensitive}
          >
            <FormattedMessage id="XEditor.Form.EditGap.PreserveAndCheckCapitalization" />
          </Checkbox>

          <Button bsStyle={null} className="btn-ico toolbox-close" onClick={this.close}>
            <Icon name="pc-close" />
          </Button>
        </Modal.Body>
        <FormFooter
          showDeleteButton={!!del}
          submitDisabled={this.isDisabled}
          close={this.close}
          deleteGap={this.deleteGap}
        />
      </form>
    );
  }

  private handleExampleCheckbox = (e: SyntheticEvent<CheckboxProps>) =>
    this.props.setFieldValue('example', e.currentTarget.checked);
  private toggleCaseSensitive = (e: SyntheticEvent<CheckboxProps>) =>
    this.props.setFieldValue('caseSensitive', e.currentTarget.checked);
  private toggleStartOfSentence = (e: SyntheticEvent<CheckboxProps>) =>
    this.props.setFieldValue('startOfSentence', e.currentTarget.checked);

  private renderInput = (answer: string, key: number) => {
    const {values, handleChange, intl, errors} = this.props;

    const error = errors.answer?.[key] || null;
    const isLastKey = key === values.answer.length - 1;
    const isOnlyOneChoice = values.answer.length === 1;

    return (
      <div className={classNames('answer-row', {'has-error': error})} key={key}>
        <FormControlTooltip state={error ? 'error' : null} content={error}>
          <FormControl
            className="correct"
            autoFocus={isLastKey}
            autoComplete="off"
            bsSize="sm"
            value={answer}
            name={`answer[${key}]`}
            onChange={handleChange}
          />
        </FormControlTooltip>

        {isOnlyOneChoice ? (
          <div className="answer-delete empty" />
        ) : (
          <Button
            bsStyle={null}
            className="btn-ico answer-delete"
            onClick={() => this.deleteAnswer(key)}
            title={intl.formatMessage({id: 'Common.Delete'})}
          >
            <Icon name="trash" />
          </Button>
        )}
      </div>
    );
  };

  private close = () => {
    this.props.close();
  };

  private addAnswer = () => {
    const {values} = this.props;
    values.answer.push('');
    this.props.setValues(values);
  };

  private deleteAnswer = (key: number) => {
    const {answer} = this.props.values;
    this.props.setFieldValue(
      'answer',
      answer.filter((ans, index) => index !== key)
    );
  };

  private deleteGap = () => {
    const {del, close} = this.props;
    if (del) {
      del();
      close(false);
    }
  };

  private get isDisabled() {
    return this.props.isSubmitting;
  }
}

const FormikEditGapDNDInput = withFormik<Props, Values>({
  mapPropsToValues: ({data, defaultAnswer}: Props) => ({
    answer: [...(data?.get('answer').slice(1) || [defaultAnswer || ''])],
    caseSensitive: !!data?.get('caseSensitive'),
    indefiniteForm: data?.get('answer')[0] || '',
    example: !!data?.get('example'),
    startOfSentence: !!data?.get('startOfSentence')
  }),
  validationSchema: (props: Props) => {
    const clean = pipe(trim, removeHidden, collapseSpaces, convertTicks);
    return Yup.object().shape({
      answer: Yup.array()
        .min(1)
        .of(
          Yup.string()
            .default('')
            .transform(clean)
            .required(
              props.intl.formatMessage({id: 'XEditor.Form.EditGap.Validation.Answer.Required2'})
            )
            .test(
              'uniqueAnswer',
              props.intl.formatMessage({id: 'XEditor.Form.EditGap.Validation.Answer.Unique'}),
              function (value: string) {
                const answers = this.parent as string[];
                const equalAnswers = answers.filter(
                  ans => clean(ans).toLowerCase() === value.toLowerCase()
                );

                return equalAnswers.length <= 1;
              }
            )
        ),
      indefiniteForm: Yup.string()
        .default('')
        .transform(clean)
        .required(
          props.intl.formatMessage({
            id: 'XEditor.Form.EditGap.Validation.IndefiniteForm.Required'
          })
        )
    });
  },
  handleSubmit: (values, formik) => {
    const {answer, caseSensitive, example, indefiniteForm, startOfSentence} = values;
    const mutate = pipe(trim, removeHidden, collapseSpaces, convertTicks);
    const answers = [mutate(indefiniteForm), ...answer.map(mutate)];

    formik.props.save(answers, {
      caseSensitive: caseSensitive || undefined,
      example: example || undefined,
      startOfSentence: startOfSentence || undefined
    });

    formik.props.close(false);
  }
})(EditGapDNDInput);

export default injectIntl(FormikEditGapDNDInput);
