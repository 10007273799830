import {type Value} from '@englex/slate';

import {
  DIALOGUE_CHANGE_AVATAR,
  DIALOGUE_ADD_SPEAKER,
  DIALOGUE_CHANGE_NAME,
  DIALOGUE_REMOVE_SPEAKER,
  DIALOGUE_ADD_MESSAGE,
  DIALOGUE_REMOVE_MESSAGE,
  DIALOGUE_CHANGE_MESSAGE,
  DIALOGUE_MOVE_MESSAGE_DOWN,
  DIALOGUE_MOVE_MESSAGE_UP,
  DIALOGUE_CHANGE_SPEAKER,
  DIALOGUE_CHANGE_COLOR_SCHEMA
} from './actionTypes';
import {type XWidgetAction} from '../../actions/interface';

export interface SpeakerContentAction extends XWidgetAction {
  speakerId: string;
}

export interface MessageContentAction extends XWidgetAction {
  messageId: string;
}

export interface DialogueColorSchemaAction extends XWidgetAction {
  styleName: string;
}

export interface ChangeAvatarAction extends SpeakerContentAction {
  avatar: number;
}

export interface ChangeNameAction extends SpeakerContentAction {
  name: Value;
}

export interface ChangeMessageAction extends MessageContentAction {
  message: Value;
}

export interface ChangeSpeakerAction extends MessageContentAction {
  speakerId: string;
}

export const addSpeaker = (xwidgetId: string): XWidgetAction => ({
  type: DIALOGUE_ADD_SPEAKER,
  xwidgetId
});

export const removeSpeaker = (xwidgetId: string, speakerId: string): SpeakerContentAction => ({
  type: DIALOGUE_REMOVE_SPEAKER,
  xwidgetId,
  speakerId
});

export const changeAvatar = (
  xwidgetId: string,
  speakerId: string,
  avatar: number
): ChangeAvatarAction => ({
  type: DIALOGUE_CHANGE_AVATAR,
  xwidgetId,
  speakerId,
  avatar
});

export const changeName = (
  xwidgetId: string,
  speakerId: string,
  name: Value
): ChangeNameAction => ({
  type: DIALOGUE_CHANGE_NAME,
  xwidgetId,
  speakerId,
  name
});

export const addMessage = (xwidgetId: string, speakerId: string): SpeakerContentAction => ({
  type: DIALOGUE_ADD_MESSAGE,
  xwidgetId,
  speakerId
});

export const removeMessage = (xwidgetId: string, messageId: string): MessageContentAction => ({
  type: DIALOGUE_REMOVE_MESSAGE,
  xwidgetId,
  messageId
});

export const changeMessage = (
  xwidgetId: string,
  messageId: string,
  message: Value
): ChangeMessageAction => ({
  type: DIALOGUE_CHANGE_MESSAGE,
  xwidgetId,
  messageId,
  message
});

export const changeSpeaker = (
  xwidgetId: string,
  messageId: string,
  speakerId: string
): ChangeSpeakerAction => ({
  type: DIALOGUE_CHANGE_SPEAKER,
  xwidgetId,
  messageId,
  speakerId
});

export const moveMessageDown = (xwidgetId: string, messageId: string): MessageContentAction => ({
  type: DIALOGUE_MOVE_MESSAGE_DOWN,
  xwidgetId,
  messageId
});

export const moveMessageUp = (xwidgetId: string, messageId: string): MessageContentAction => ({
  type: DIALOGUE_MOVE_MESSAGE_UP,
  xwidgetId,
  messageId
});

export const changeColorSchema = (
  xwidgetId: string,
  styleName: string
): DialogueColorSchemaAction => ({
  type: DIALOGUE_CHANGE_COLOR_SCHEMA,
  xwidgetId,
  styleName
});
