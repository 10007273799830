import {type Plugin} from '@englex/slate-react';

import SimpleListItem from './SimpleListItem';
import TheOnlyListNormalizer from './TheOnlyListNormalizer';
import SimplyExistingOrderedList from './SimplyExistingOrderedList';

// This list does not have any button, cant't be added - document should be initialized with one.
// onBackspace and onDelete are canceled - list is one leveled, no embedded lists allowed.
// Convenient to create simple lists of questions.
// todo: maybe it has to be in renderers folder, but for now main normalizer looks at buttons for rules.
export default class TheOnlyOrderedList implements Plugin {
  public plugins: Plugin[] = [
    new SimplyExistingOrderedList(),
    new SimpleListItem(),
    new TheOnlyListNormalizer()
  ];
}
