import React from 'react';
import {
  type Editor as ReactEditor,
  type Plugin,
  type RenderDecorationProps
} from '@englex/slate-react';
import {type Annotation, type Editor, type Next, type Path} from '@englex/slate';
import classNames from 'classnames';

import {genKey} from '../../../utils';
import {getEditorId, type SlateDecoration} from '../../../interface';
import {excludeSurroundingQuotes, reannotate, resolveLiIds, toggleAnnotation} from './commands';
import {Tooltip} from './Tooltip';
import {schema} from './schema';
import {type CheckerFn} from '../../../plugins/DecorateText/interface';

export interface Options {
  keyGen?: () => string;
  checker?: CheckerFn;
}

interface Props extends Options {
  decoration: SlateDecoration;
  isCapitalizable?: boolean;
  wrapperClassName: string;
}

export class TextDecorator implements Plugin {
  public plugins: Plugin[];
  private static toggleAnnotation =
    (editor: Editor, path: Path, offset: number, text: string, keyGen: () => string) => () =>
      editor.command(toggleAnnotation, path, offset, text, keyGen);

  public schema = schema;

  private readonly decoration: SlateDecoration;
  private readonly wrapperClassName: string;
  private readonly keyGen: () => string;
  private readonly isCapitalizable: boolean;

  private editorId: string;

  public constructor({
    decoration,
    wrapperClassName,
    keyGen = genKey,
    isCapitalizable = true
  }: Props) {
    this.keyGen = keyGen;
    this.isCapitalizable = isCapitalizable;
    this.decoration = decoration;
    this.wrapperClassName = wrapperClassName;
  }

  public onChange = (editor: ReactEditor, next: Next) => {
    editor
      .command(resolveLiIds, this.keyGen)
      .command(reannotate, this.keyGen)
      .command(excludeSurroundingQuotes, this.keyGen);
    return next();
  };

  public renderDecoration = (
    props: RenderDecorationProps,
    editor: ReactEditor & Editor,
    next: Next
  ) => {
    const {
      annotations,
      attributes,
      children,
      decoration: {type},
      node: {key},
      offset,
      text
    } = props;
    if (type !== this.decoration) {
      return next();
    }

    if (text === '') {
      return null;
    }

    if (text === text.toLowerCase()) {
      return (
        <span className={this.wrapperClassName}>
          <span {...attributes}>{children}</span>
        </span>
      );
    }

    const capitalize =
      !!annotations.find(
        ({anchor}: Annotation) => anchor.offset === offset && anchor.key === key
      ) || undefined;

    if (!this.isCapitalizable)
      return (
        <span className={this.wrapperClassName} onClick={e => e.stopPropagation()}>
          <span {...attributes}>{children}</span>
        </span>
      );

    return (
      <span className={this.wrapperClassName} onClick={e => e.stopPropagation()}>
        <Tooltip
          disabled={!this.isCapitalizable}
          capitalize={capitalize}
          getTooltipContainer={this.getTooltipContainer(editor)}
          toggleCapitalize={TextDecorator.toggleAnnotation(
            editor,
            editor.value.document.getPath(key) as Path,
            offset,
            text,
            this.keyGen
          )}
          contentEditable={false}
        >
          <span
            {...attributes}
            className={classNames('capitalizable', {
              checked: this.isCapitalizable && capitalize
            })}
          >
            {children}
            {this.isCapitalizable && <span className="dot" contentEditable={false} />}
          </span>
        </Tooltip>
      </span>
    );
  };

  private getTooltipContainer = (editor: Editor): (() => HTMLElement) => {
    if (!this.editorId) {
      this.editorId = editor.query<string>(getEditorId);
    }
    return () => document.getElementById(this.editorId)!;
  };
}
