import React from 'react';
import {defineMessages, type WrappedComponentProps, injectIntl} from 'react-intl';
import classNames from 'classnames';

import ReactSuggest from 'components/ReactSelect/ReactSuggest';
import {type ChangeTitleAction} from 'store/exercise/editor/actions/interface';

import ValidationError from './ValidationError';

import './OptionalTitle.scss';

const placeholder = defineMessages({
  XEditorOptionalTitlePlaceholder: {
    id: 'XEditor.OptionalTitlePlaceholder'
  }
});

interface OwnProps {
  changeTitle: (title: string) => ChangeTitleAction;
  defaultTitles: string[];
  title: string;
  validationError?: string;
}

type Props = OwnProps & WrappedComponentProps;

class OptionalTitle extends React.PureComponent<Props> {
  state = {title: this.props.title || ''};

  public get options() {
    return this.props.defaultTitles.map(title => ({label: title, value: title}));
  }

  public noOptionsMessage() {
    return null;
  }

  public changeTitle = (title: string) => {
    this.setState({title});
    this.props.changeTitle(title);
  };

  public render() {
    const {validationError} = this.props;
    const widgetBodyClassName = classNames('widget-body', {
      'widget-has-error': validationError
    });

    return (
      <div className="xeditor-widget optional-title">
        <div className={widgetBodyClassName}>
          <div className="select">
            <ReactSuggest
              value={undefined}
              inputValue={this.state.title}
              onChange={this.changeTitle}
              options={this.options}
              placeholder={placeholder.XEditorOptionalTitlePlaceholder}
              noOptionsMessage={this.noOptionsMessage}
              controlShouldRenderValue={false}
            />
          </div>
        </div>
        <ValidationError message={validationError} />
      </div>
    );
  }
}

export default injectIntl(OptionalTitle);
