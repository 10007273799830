import React from 'react';

import {DndTypes, type DragObject} from 'components/dnd/interface';
import {type DndSortingWrapperProps} from 'components/DndSortingWrapper/DndSortingWrapper';

import XEditorVocabularyDragItem from './xwidgets/XVocabulary/XEditorVocabularyDragItem';
import {type VocabularyDragObject} from './xwidgets/XVocabulary/XEditorVocabularyEntry';
import ImageMatchingCard, {
  type ImageMatchingCardProps
} from './xwidgets/XImage/ImageMatching/ImageMatchingCard';
import ImageLabelingCard, {
  type ImageLabelingCardProps
} from './xwidgets/XImage/ImageLabeling/ImageLabelingCard';
import {
  XPictureSetCard,
  type XPictureSetCardProps
} from './xwidgets/XWordPictureSet/components/PictureSetCard/PictureSetCard';
import {
  XWordPictureSetCard,
  type XWordPictureSetCardProps
} from './xwidgets/XWordPictureSet/components/WordPictureSetCard/WordPictureSetCard';
import {Card, type XPictureChoiceCardProps} from './xwidgets/XPictureChoice/Card/Card';
import {XFlipCard, type XFlipCardProps} from './xwidgets/XFlipCards/XFlipCard';

export type DndComponentProps =
  | VocabularyDragObject
  | ImageMatchingCardProps
  | ImageLabelingCardProps
  | XPictureSetCardProps
  | XWordPictureSetCardProps
  | XPictureChoiceCardProps
  | XFlipCardProps
  | (DragObject & DndSortingWrapperProps);

export const renderEditorDragItem = (
  itemType: DndTypes,
  item: DndComponentProps,
  style: React.CSSProperties
) => {
  switch (itemType) {
    case DndTypes.XVOCABULARY_ENTRY:
      return <XEditorVocabularyDragItem {...(item as VocabularyDragObject)} style={style} />;

    case DndTypes.IMAGE_MATCHING:
      return (
        <ImageMatchingCard {...(item as ImageMatchingCardProps)} isDragLayer={true} style={style} />
      );

    case DndTypes.IMAGE_LABELING:
      return (
        <ImageLabelingCard {...(item as ImageLabelingCardProps)} isDragLayer={true} style={style} />
      );
    case DndTypes.PICTURE_SET:
      return (
        <XPictureSetCard {...(item as XPictureSetCardProps)} isDragLayer={true} style={style} />
      );

    case DndTypes.WORD_PICTURE_SET:
      return (
        <XWordPictureSetCard
          {...(item as XWordPictureSetCardProps)}
          isDragLayer={true}
          style={style}
        />
      );

    case DndTypes.PICTURE_CHOICE:
      return <Card {...(item as XPictureChoiceCardProps)} isDragLayer={true} style={style} />;

    case DndTypes.FLIP_CARDS:
      return <XFlipCard {...(item as XFlipCardProps)} isDragLayer={true} style={style} />;

    default:
      return null;
  }
};
