import React, {type FC, memo} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {useDispatch} from 'react-redux';
import Checkbox from 'react-bootstrap/lib/Checkbox';

import Icon from 'components/Icon';
import {changeStyle, switchWithNumbering} from 'store/exercise/editor/widgets/XStyledList/actions';
import {HorizontalItem} from 'components/Slate/SlateEditor/plugins/button/List/StylesList/components/HorizontalItem/HorizontalItem';
import {StyledListStyles} from 'store/exercise/player/widgets/List/StyledList/interface';

import './HorizontalStylesModal.scss';

interface Props {
  opened: boolean;
  widgetId: string;
  styleName: string;
  withNumbering: boolean;
  onClose: () => void;
  onSelect: () => void;
}

const firstLine = [StyledListStyles.BLUE, StyledListStyles.GREEN, StyledListStyles.ORANGE];

const secondLine = [StyledListStyles.WHITE, StyledListStyles.WHITE, StyledListStyles.WHITE];

const thirdLine = [
  StyledListStyles.WHITE_OUTLINE,
  StyledListStyles.WHITE_OUTLINE,
  StyledListStyles.WHITE_OUTLINE
];

const fourthLine = [
  StyledListStyles.BLUE_OUTLINE,
  StyledListStyles.BLUE_OUTLINE,
  StyledListStyles.BLUE_OUTLINE
];

const fifthLine = [
  StyledListStyles.GREEN_OUTLINE,
  StyledListStyles.GREEN_OUTLINE,
  StyledListStyles.GREEN_OUTLINE
];

const sixthLine = [
  StyledListStyles.ORANGE_OUTLINE,
  StyledListStyles.ORANGE_OUTLINE,
  StyledListStyles.ORANGE_OUTLINE
];

export const HorizontalStylesModal: FC<Props> = memo(
  ({opened, onClose, onSelect, widgetId, styleName, withNumbering}) => {
    const dispatch = useDispatch();

    const getOnChange = (key: string) => {
      return () => dispatch(changeStyle(widgetId, key));
    };

    const checkboxOnChange = () => {
      dispatch(switchWithNumbering(widgetId));
    };

    return (
      <Modal backdrop="static" className="horizontal-style-modal" onHide={onClose} show={opened}>
        <Modal.Header className="header">
          <FormattedMessage id="XEditor.XWidget.StyledList.ModalTitle" />
          <Button className="btn-xs" onClick={onClose}>
            <Icon name="pc-close i-lg" />
          </Button>
        </Modal.Header>
        <Modal.Body className="body">
          <div className="style-items">
            <div className="items-line">
              {firstLine.map((style: StyledListStyles) => (
                <div key={style} className={`style-card`}>
                  <label className="style-card__label">
                    <input
                      className="style-card__radio"
                      type="radio"
                      name="styleName"
                      value={style}
                      defaultChecked={styleName === style}
                      onChange={getOnChange(style)}
                    />
                    <span className="style-card__radio-border">
                      <span className="styled-card__radio-circle" />
                    </span>
                    <HorizontalItem id={style} styleName={style} isActive={false}>
                      List Item 1
                    </HorizontalItem>
                  </label>
                </div>
              ))}
            </div>

            <div className="items-line">
              <div key={StyledListStyles.WHITE} className={`style-card`}>
                <label className="style-card__label">
                  <input
                    className="style-card__radio"
                    type="radio"
                    name="styleName"
                    value={StyledListStyles.WHITE}
                    defaultChecked={styleName === StyledListStyles.WHITE}
                    onChange={getOnChange(StyledListStyles.WHITE)}
                  />
                  <span className="style-card__radio-border">
                    <span className="styled-card__radio-circle" />
                  </span>
                  <div className="styled-list-items horizontal white">
                    {secondLine.map((style: StyledListStyles, index: number) => (
                      <HorizontalItem
                        key={style + index}
                        id={style}
                        styleName={style}
                        isActive={false}
                      >
                        {`List Item ${index + 1}`}
                      </HorizontalItem>
                    ))}
                  </div>
                </label>
              </div>
            </div>

            <div className="items-line">
              <div key={StyledListStyles.WHITE_OUTLINE} className={`style-card white-outline`}>
                <label className="style-card__label">
                  <input
                    className="style-card__radio"
                    type="radio"
                    name="styleName"
                    value={StyledListStyles.WHITE_OUTLINE}
                    defaultChecked={styleName === StyledListStyles.WHITE_OUTLINE}
                    onChange={getOnChange(StyledListStyles.WHITE_OUTLINE)}
                  />
                  <span className="style-card__radio-border">
                    <span className="styled-card__radio-circle" />
                  </span>
                  <div className="styled-list-items horizontal white-outline">
                    {thirdLine.map((style: StyledListStyles, index: number) => (
                      <HorizontalItem
                        key={style + index}
                        id={style}
                        styleName={style}
                        isActive={false}
                      >
                        {`List Item ${index + 1}`}
                      </HorizontalItem>
                    ))}
                  </div>
                </label>
              </div>
            </div>

            <div className="items-line">
              <div key={StyledListStyles.BLUE_OUTLINE} className={`style-card blue-outline`}>
                <label className="style-card__label">
                  <input
                    className="style-card__radio"
                    type="radio"
                    name="styleName"
                    value={StyledListStyles.BLUE_OUTLINE}
                    defaultChecked={styleName === StyledListStyles.BLUE_OUTLINE}
                    onChange={getOnChange(StyledListStyles.BLUE_OUTLINE)}
                  />
                  <span className="style-card__radio-border">
                    <span className="styled-card__radio-circle" />
                  </span>
                  <div className="styled-list-items horizontal blue-outline">
                    {fourthLine.map((style: StyledListStyles, index: number) => (
                      <HorizontalItem
                        key={style + index}
                        id={style}
                        styleName={style}
                        isActive={false}
                      >
                        {`List Item ${index + 1}`}
                      </HorizontalItem>
                    ))}
                  </div>
                </label>
              </div>
            </div>

            <div className="items-line">
              <div key={StyledListStyles.GREEN_OUTLINE} className={`style-card green-outline`}>
                <label className="style-card__label">
                  <input
                    className="style-card__radio"
                    type="radio"
                    name="styleName"
                    value={StyledListStyles.GREEN_OUTLINE}
                    defaultChecked={styleName === StyledListStyles.GREEN_OUTLINE}
                    onChange={getOnChange(StyledListStyles.GREEN_OUTLINE)}
                  />
                  <span className="style-card__radio-border">
                    <span className="styled-card__radio-circle" />
                  </span>
                  <div className="styled-list-items horizontal green-outline">
                    {fifthLine.map((style: StyledListStyles, index: number) => (
                      <HorizontalItem
                        key={style + index}
                        id={style}
                        styleName={style}
                        isActive={false}
                      >
                        {`List Item ${index + 1}`}
                      </HorizontalItem>
                    ))}
                  </div>
                </label>
              </div>
            </div>

            <div className="items-line">
              <div key={StyledListStyles.ORANGE_OUTLINE} className={`style-card orange-outline`}>
                <label className="style-card__label">
                  <input
                    className="style-card__radio"
                    type="radio"
                    name="styleName"
                    value={StyledListStyles.ORANGE_OUTLINE}
                    defaultChecked={styleName === StyledListStyles.ORANGE_OUTLINE}
                    onChange={getOnChange(StyledListStyles.ORANGE_OUTLINE)}
                  />
                  <span className="style-card__radio-border">
                    <span className="styled-card__radio-circle" />
                  </span>
                  <div className="styled-list-items horizontal orange-outline">
                    {sixthLine.map((style: StyledListStyles, index: number) => (
                      <HorizontalItem
                        key={style + index}
                        id={style}
                        styleName={style}
                        isActive={false}
                      >
                        {`List Item ${index + 1}`}
                      </HorizontalItem>
                    ))}
                  </div>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer">
          <Checkbox onChange={checkboxOnChange} checked={withNumbering}>
            <FormattedMessage id="XEditor.XWidget.StyledList.NumberItems" />
          </Checkbox>
          <div className="modal-controls">
            <Button bsSize="small" onClick={onClose}>
              <FormattedMessage id="MediaDevicesWizard.ButtonClose" />
            </Button>

            <Button bsSize="small" bsStyle="primary" onClick={onSelect}>
              <FormattedMessage id="Common.Save" />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
);
