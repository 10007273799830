import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {type WidgetFormattedTextJSON} from 'store/exercise/player/widgets/FormattedText/interface';
import genKey from 'components/Slate/utils/genKey';
import {valueJSONFromText} from 'components/Slate/utils';
import XGrammarRulesRecord from 'store/exercise/editor/widgets/XGrammarRules/XGrammarRulesRecord';

import {type XWizardWidgetData} from '../interface';
import Tile from '../Tile';
import {GrammarRulesSVG} from '../assets';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.GrammarRules}>
    <GrammarRulesSVG />
  </Tile>
);

export const grammarRulesWizardData: XWizardWidgetData<
  WidgetFormattedTextJSON,
  XGrammarRulesRecord
> = {
  type: WidgetType.GRAMMAR_RULES,
  steps: [],
  tile,
  createWidget: (widget: Pick<WidgetFormattedTextJSON, 'type'>) =>
    new XGrammarRulesRecord({
      id: genKey(),
      type: widget.type,
      task: valueJSONFromText(''),
      content: valueJSONFromText('')
    })
};
