import {type List, type Map} from 'immutable';
import React, {memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import type XPictureSetCardRecord from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/XPictureSetCardRecord';
import type XWordPictureSetCardRecord from 'store/exercise/editor/widgets/XWordPictureSet/XWordPictureSet/XWordPictureSetCardRecord';
import type XWordSetCardRecord from 'store/exercise/editor/widgets/XWordPictureSet/XWordSet/XWordSetCardRecord';
import {WidgetType} from 'store/exercise/player/interface';
import {
  type WordPictureSetBaseCardId,
  type WordPictureSetBaseMetaDataProperties,
  type WordPictureSetBaseValues
} from 'store/exercise/player/widgets/WordPictureSet/baseInterface';
import {DndSortingWrapper} from 'components/DndSortingWrapper/DndSortingWrapper';
import {DndTypes} from 'components/dnd/interface';
import {moveCard, rollBackCards} from 'store/exercise/editor/widgets/XWordPictureSet/actions';
import {Provider} from 'components/XPlayer/contexts/dndContext';

import {XPictureSetCard} from '../PictureSetCard/PictureSetCard';
import {XWordPictureSetCard} from '../WordPictureSetCard/WordPictureSetCard';
import {XWordSetCard} from '../WordSetCard/WordSetCard';

import './Cards.scss';

interface Props {
  widgetId: string;
  type: WidgetType;
  cards:
    | Map<string, XWordSetCardRecord>
    | Map<string, XWordPictureSetCardRecord>
    | Map<string, XPictureSetCardRecord>;
  defaultValues: WordPictureSetBaseValues<
    WordPictureSetBaseCardId,
    WordPictureSetBaseMetaDataProperties<WordPictureSetBaseCardId>
  >;

  resetErrors: () => void;
  setError: (e: string | React.ReactElement) => string | React.ReactElement;
  recoveryPoint: React.MutableRefObject<List<WordPictureSetBaseCardId>>;
  updateRecoveryPoint: () => void;
}

export const Cards: React.FC<Props> = memo(
  ({
    type,
    cards,
    defaultValues,
    widgetId,
    recoveryPoint,
    updateRecoveryPoint,
    resetErrors,
    setError
  }) => {
    const dispatch = useDispatch();

    const cardIds = defaultValues.get('cardIds') as List<WordPictureSetBaseCardId>;

    const moveItem = useCallback(
      (moveItemIndex: number, targetIndex: number) => {
        dispatch(moveCard(widgetId, moveItemIndex, targetIndex));
      },
      [dispatch, widgetId]
    );

    const rollBackChanges = useCallback(() => {
      dispatch(rollBackCards(widgetId, recoveryPoint.current));
    }, [dispatch, recoveryPoint, widgetId]);

    const getItemIndex = useCallback(
      (cardId: string) => {
        return cardIds.findIndex(card => card?.cardId === cardId);
      },
      [cardIds]
    );

    if (type === WidgetType.WORD_SET) {
      return (
        <div className="cards type-word-set">
          {cardIds
            .map((item: WordPictureSetBaseCardId) => (
              <XWordSetCard
                key={item.cardId}
                text={cards.get(item.cardId).get('text')}
                cardId={item.cardId}
                widgetId={widgetId}
                resetErrors={resetErrors}
              />
            ))
            .toArray()}
        </div>
      );
    }

    if (type === WidgetType.PICTURE_SET) {
      return (
        <Provider>
          <div className="cards type-picture-set">
            {cardIds
              .map((item: WordPictureSetBaseCardId) => (
                <DndSortingWrapper
                  key={item.cardId}
                  itemId={item.cardId}
                  dndType={DndTypes.PICTURE_SET}
                  isNotUseDragLayer={false}
                  ChildComponent={XPictureSetCard}
                  childOptions={{
                    image: cards.get(item!.cardId).get('image'),
                    cardId: item.cardId,
                    widgetId: widgetId,
                    cardSizeType: cards.get(item!.cardId).get('cardSizeType'),
                    resetErrors: resetErrors,
                    setError: setError,
                    updateRecoveryPoint
                  }}
                  updateRecoveryPoint={updateRecoveryPoint}
                  moveItem={moveItem}
                  rollBackChanges={rollBackChanges}
                  getItemIndex={getItemIndex}
                />
              ))
              .toArray()}
          </div>
        </Provider>
      );
    } else {
      return (
        <Provider>
          <div className="cards type-word-picture-set">
            {cardIds
              .map((item: WordPictureSetBaseCardId) => (
                <DndSortingWrapper
                  key={item.cardId}
                  itemId={item.cardId}
                  dndType={DndTypes.WORD_PICTURE_SET}
                  isNotUseDragLayer={false}
                  ChildComponent={XWordPictureSetCard}
                  childOptions={{
                    image: cards.get(item!.cardId).get('image'),
                    text: cards.get(item!.cardId).get('text'),
                    cardId: item.cardId,
                    widgetId: widgetId,
                    cardSizeType: cards.get(item!.cardId).get('cardSizeType'),
                    resetErrors: resetErrors,
                    setError: setError,
                    updateRecoveryPoint
                  }}
                  updateRecoveryPoint={updateRecoveryPoint}
                  moveItem={moveItem}
                  rollBackChanges={rollBackChanges}
                  getItemIndex={getItemIndex}
                />
              ))
              .toArray()}
          </div>
        </Provider>
      );
    }
  }
);
