import React from 'react';
import {type Editor, type EditorProps} from '@englex/slate-react';
import {type Next} from '@englex/slate';
import {FormattedMessage} from 'react-intl';

import {type RendererPlugin} from 'components/Slate/plugins/interface';

import Count from '../Count';
import {charCount} from '../queries';

interface SymbolCountOptions {
  maxCount?: number;
}

export class CharCount implements RendererPlugin {
  private readonly maxCount: number | undefined;

  constructor(options?: SymbolCountOptions) {
    this.maxCount = options?.maxCount;
  }

  public renderEditor = (props: EditorProps, editor: Editor, next: Next) => {
    const count = charCount(editor.value.document);
    const countText = `${count} / ${this.maxCount}`;

    return (
      <>
        {next()}
        <Count
          count={count}
          message={
            <FormattedMessage id="Slate.Plugin.CharCount.Symbol" values={{count: countText}} />
          }
          maxCount={this.maxCount}
        />
      </>
    );
  };
}
