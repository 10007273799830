import React from 'react';
import {FormattedMessage} from 'react-intl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';

import {type SpellingOptions} from 'store/exercise/player/widgets/Selling/interface';
import {SpellingType} from 'store/exercise/editor/widgets/XSpelling/interface';

import {type XWizardStepProps} from '../interface';

import './SpellingWizard.scss';

type Props = XWizardStepProps<SpellingOptions>;

class SpellingWizard extends React.Component<Props> {
  public componentDidMount() {
    this.props.addField('spellingType', SpellingType.Words);
  }

  public render() {
    const radioGroupName = 'list';
    const {addField} = this.props;

    const changeToWordType = () => {
      addField('spellingType', SpellingType.Words);
    };

    const changeToPhraseType = () => {
      addField('spellingType', SpellingType.Phrases);
    };

    return (
      <form className="spelling-wizard-options-form">
        <p className="spelling-wizard-options-title">
          <b>
            <FormattedMessage id="XEditor.Wizard.Spelling.CardsCanBeMovedWithin" />
          </b>
        </p>
        <FormGroup>
          <div className="group">
            <Radio
              name={radioGroupName}
              inline={true}
              defaultChecked={true}
              onChange={changeToWordType}
            >
              <FormattedMessage id="XEditor.Wizard.Spelling.SingleWord" />
            </Radio>
          </div>
          <div className="group">
            <Radio name={radioGroupName} inline={true} onChange={changeToPhraseType}>
              <FormattedMessage id="XEditor.Wizard.Spelling.SingleTask" />
            </Radio>
          </div>
        </FormGroup>
      </form>
    );
  }
}

export default SpellingWizard;
