import React from 'react';
import {type IntlShape} from 'react-intl';
import Panel from 'react-bootstrap/lib/Panel';

import Icon from 'components/Icon';

import {messages, sectionHeadings} from './i18n';
import {type ServerCoursebook} from '../../../../../components/CoursebookLibrary/interface';

const TextbooksList: React.FC<{
  coursebooks?: Array<Pick<ServerCoursebook, 'id' | 'title'>>;
  intl: IntlShape;
}> = ({coursebooks, intl: {formatMessage}}) => {
  if (!coursebooks || !coursebooks.length) {
    return null;
  }
  return (
    <section>
      <Panel>
        <Panel.Heading>
          <Panel.Title toggle={true}>
            <span className="section-title">{formatMessage(sectionHeadings.textbooks)}: </span>
            {formatMessage(messages.textbooksCount, {count: coursebooks.length})}
            <Icon name="angle-down" />
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible={true}>
          <ul>
            {coursebooks.map(cb => (
              <li key={cb.id}>{cb.title}</li>
            ))}
          </ul>
        </Panel.Body>
      </Panel>
    </section>
  );
};

export default TextbooksList;
