import {type Editor as ReactEditor, type Plugin, type RenderBlockProps} from '@englex/slate-react';
import {Block, type Editor, type Next, type SlateError, Text} from '@englex/slate';
import React from 'react';

import isShortcut from 'helpers/shortcut';
import {MultipleChoiceDefaultAnswers} from 'store/exercise/player/widgets/List/interface';

import {type SchemaBlockPlugin, type SchemaBlockRules} from '../../interface';
import {type QuestionItemBlock, SlateBlock, SlateObject} from '../../../../interface';
import {isBlockOfType} from '../../../../utils';
import {blockIsQuestionLabel} from './utils';
import {addDataToBlock} from '../../utils';
import './Question.scss';

interface QuestionPluginOptions {
  defaultAnswersNumber: number;
  defaultAnswersType: MultipleChoiceDefaultAnswers;
  genKey: () => string;
  minAnswers?: number;
  maxAnswers?: number;
}

export default class Question implements SchemaBlockPlugin, Plugin {
  public block = SlateBlock.QUESTION_ITEM;

  private defaultAnswersNumber: number;
  private readonly minAnswers: number;
  private readonly maxAnswers?: number;
  private defaultAnswersType: MultipleChoiceDefaultAnswers;
  private genKey: () => string;

  constructor({
    defaultAnswersNumber,
    defaultAnswersType,
    genKey,
    maxAnswers,
    minAnswers
  }: QuestionPluginOptions) {
    this.defaultAnswersNumber = defaultAnswersNumber;
    this.minAnswers = minAnswers || 1;
    this.maxAnswers = maxAnswers;
    this.defaultAnswersType = defaultAnswersType;
    this.genKey = genKey;
  }

  public blockRules = (): SchemaBlockRules => ({
    type: this.block,
    rules: {
      nodes: [
        {
          match: [
            {
              type: SlateBlock.DEFAULT,
              object: SlateObject.BLOCK
            }
          ],
          min: 1,
          max: 1
        },
        {
          match: [{type: SlateBlock.QUESTION_ANSWER}],
          min: this.minAnswers,
          max: this.maxAnswers || undefined
        }
      ],
      data: {
        id: (v?: string) => typeof v === 'string' && v.length > 0,
        example: (v?: boolean) => v === true || v === undefined
      }
    },
    normalizer: {
      predicate: ({node}: SlateError) => isBlockOfType(node, SlateBlock.QUESTION_ITEM),
      reasons: {
        child_min_invalid: (change: Editor, error: SlateError) => {
          const node: QuestionItemBlock = error.node;

          if (!node.nodes.filter((n: Block) => isBlockOfType(n, SlateBlock.DEFAULT)).size) {
            const block = Block.create(SlateBlock.DEFAULT);
            change.insertNodeByKey(node.key, 0, block);
            return true;
          }

          const currentAnswersCount = node.nodes.filter((n: Block) =>
            isBlockOfType(n, SlateBlock.QUESTION_ANSWER)
          ).size;
          const answersToAdd =
            currentAnswersCount === 0
              ? this.defaultAnswersNumber
              : this.minAnswers - currentAnswersCount;
          for (let i = 0; i < answersToAdd; i++) {
            const block = Block.create(SlateBlock.QUESTION_ANSWER);
            change.insertNodeByKey(node.key, (node as Block).nodes.size, block);
            if (this.defaultAnswersType === MultipleChoiceDefaultAnswers.BOOLEAN) {
              const answerNumberIsEven = !!(i % 2);
              const text = Text.create(answerNumberIsEven ? 'True' : 'False');
              change.insertNodeByKey(block.key, 0, text);
            }
          }
          return true;
        },
        child_type_invalid: (change: Editor, {child}: SlateError) => {
          if (isBlockOfType(child!, SlateBlock.DEFAULT)) {
            change.mergeNodeByKey(child.key);
            return true;
          }
          return;
        },
        node_data_invalid: (change: Editor, {node, key}: SlateError) => {
          if (key === 'id') {
            addDataToBlock(change, node as Block, 'id', this.genKey());
            return true;
          }
          if (key === 'checked') {
            addDataToBlock(change, node as Block, 'checked', undefined);
            return true;
          }
          return;
        },
        child_max_invalid: (change: Editor, {child}: SlateError) => {
          const previousSibling = change.value.document.getPreviousSibling(child.key);
          change.removeNodeByKey(child.key);
          previousSibling && change.moveToEndOfNode(previousSibling);
          return true;
        }
      }
    }
  });

  public onKeyDown = (event: React.KeyboardEvent, change: ReactEditor & Editor, next: Next) => {
    const {value} = change;
    const {startBlock, endBlock} = value;
    if (!startBlock || !endBlock) {
      return next();
    }

    const selectionIsInOneBlock = startBlock.key === endBlock.key;
    const selectionIsInOneQuestionLabel =
      selectionIsInOneBlock && blockIsQuestionLabel(value, startBlock);

    if (!selectionIsInOneQuestionLabel) {
      return next();
    }

    if (isShortcut(event, 'enter')) {
      // ignore enter button press
      return;
    }

    // ignore backspace start of question paragraph and delete in the end of question paragraph if selection is not expanded
    if (value.selection?.isExpanded) {
      return next();
    }

    if (isShortcut(event, 'backspace') && value.selection?.start.isAtStartOfNode(startBlock)) {
      return;
    }

    if (isShortcut(event, 'delete') && value.selection?.end.isAtEndOfNode(startBlock)) {
      return;
    }

    return next();
  };

  public renderBlock = (
    {node, attributes, children}: RenderBlockProps,
    editor: ReactEditor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.QUESTION_ITEM)) {
      return next();
    }

    if (node.data.get('example')) {
      return (
        <li className="question-item example">
          <span className="example-label" contentEditable={false}>
            Example:
          </span>
          <span {...attributes} className="slate-content example">
            {children}
          </span>
        </li>
      );
    }

    return <li {...attributes}>{children}</li>;
  };
}
