import React from 'react';
import {type Action, type Dispatch} from 'redux';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import {type Editor, type Value} from '@englex/slate';

import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import TextAlignment from 'components/Slate/SlateEditor/plugins/button/TextAlignment';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import FontSizeToolbar from 'components/Slate/SlateEditor/plugins/button/FontSize';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import Link from 'components/Slate/SlateEditor/plugins/button/Link';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Image from 'components/Slate/SlateEditor/plugins/button/Image';
import {NestedLists} from 'components/Slate/SlateEditor/plugins/button/List/NestedLists';
import ImageButton from 'components/Slate/SlateEditor/plugins/button/Image/ImageButton';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import AddDialogBtn from 'components/Slate/SlateEditor/plugins/button/Table/Dialog/AddDialogBtn';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import {type AppState} from 'store/interface';
import {formattedTextContentChange} from 'store/exercise/editor/xwidgetActions';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type XFormattedTextProperties} from 'store/exercise/editor/widgets/XFormattedText/interface';
import {ToggleExample} from 'components/Slate/SlateEditor/plugins/button/Example/ToggleExample';

import {formattedTextMessages} from './messages';
import {XDisplayAsButton} from './components/XDisplayAsButton/XDisplayAsButton';

import './XEditorFormattedText.scss';

interface StateProps {
  content: Value;
}

interface DispatchProps {
  onContentChange: (widgetId: string, change: Editor) => void;
}

interface OwnProps {
  id: string;
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps;

class XEditorFormattedText extends React.Component<Props> {
  public static plugins = [
    new Undo(),
    new Redo(),
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new TextAlignment(),
    new FontSizeToolbar(),
    new FontColorToolbar(),
    new HighlightToolbar(),
    new NestedLists(),
    new AddDialogBtn(),
    new CharSelector(),
    new Image(),
    new ImageButton(),
    new Link(),
    new FormatPainter(),
    new ClearFormatting(),
    new ToggleExample()
  ];

  public render() {
    const {
      id,
      content,
      intl: {formatMessage}
    } = this.props;

    return (
      <React.Fragment>
        <SlateEditor
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          value={content}
          onChange={this.onContentChange}
          plugins={XEditorFormattedText.plugins}
          placeholder={formatMessage(formattedTextMessages.placeholderOptional)}
        />
        <XDisplayAsButton xwidgetId={id} />
      </React.Fragment>
    );
  }

  private onContentChange = (change: Editor) => {
    this.props.onContentChange(this.props.id, change);
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps
) => {
  const xwidget = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === ownProps.id
  ) as XFormattedTextProperties;

  const {content, displayButton, hasDisplayButton} = xwidget;

  return {
    content,
    displayButton,
    hasDisplayButton
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {task: Value}> = (
  dispatch: Dispatch<Action>
) => ({
  onContentChange: (widgetId: string, change: Editor) =>
    dispatch(formattedTextContentChange(widgetId, change))
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(XEditorFormattedText));
