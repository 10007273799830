import React from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import {FormattedMessage} from 'react-intl';

import {
  MultipleChoiceDefaultAnswers,
  MultipleChoiceElement,
  type XMultipleChoiceWizardOptions
} from 'store/exercise/player/widgets/List/interface';
import Counter from 'components/Counter';
import {
  freeChoiceAnswersNumberMin,
  multipleChoiceAnswersNumberDefault,
  multipleChoiceAnswersNumberMax,
  multipleChoiceAnswersNumberMin,
  multipleChoiceQuestionsNumberDefault,
  multipleChoiceQuestionsNumberMax,
  multipleChoiceQuestionsNumberMin
} from 'store/exercise/editor/widgets/constants';

import {type XWizardStepProps} from '../interface';

import './MultipleChoiceWizard.scss';

type OwnProps = {
  isFreeChoice?: boolean;
};

type Props = XWizardStepProps<XMultipleChoiceWizardOptions> & OwnProps;

class MultipleChoiceWizard extends React.Component<Props> {
  componentDidMount() {
    this.props.addField(
      'element',
      this.props.isFreeChoice ? MultipleChoiceElement.CHECKBOX : MultipleChoiceElement.RADIO
    );
    this.props.addField('defaultAnswersType', MultipleChoiceDefaultAnswers.EMPTY);
    this.props.addField('initialQuestionsNumber', multipleChoiceQuestionsNumberDefault);
    this.props.addField('defaultAnswersNumber', multipleChoiceAnswersNumberDefault);
    this.props.addField('withoutQuestions', false);
  }

  public render() {
    const {addField} = this.props;
    const {
      element,
      defaultAnswersType,
      defaultAnswersNumber,
      initialQuestionsNumber,
      horizontal = false,
      withoutQuestions
    } = this.props.fields;
    const elementTypeRadioGroup = 'elementType';
    const defaultAnswerTypeRadioGroup = 'defaultAnswer';
    const alignmentGroup = 'alignmentGroup';
    const withoutQuestionsRadioGroup = 'withoutQuestionsRadioGroup';
    return (
      <form className="multiple-choice-wizard">
        <p>
          <b>
            {this.props.isFreeChoice ? (
              <FormattedMessage id="XEditor.Wizard.FreeChoice.ElementTypeHeader" />
            ) : (
              <FormattedMessage id="XEditor.Wizard.MultipleChoice.ElementTypeHeader" />
            )}
          </b>
        </p>
        <FormGroup>
          <Radio
            name={elementTypeRadioGroup}
            inline={true}
            onChange={() => addField('element', MultipleChoiceElement.RADIO)}
            checked={element === MultipleChoiceElement.RADIO}
          >
            <span>
              <FormattedMessage id="XEditor.Wizard.MultipleChoice.ElementType1" />
            </span>
          </Radio>
          <Radio
            name={elementTypeRadioGroup}
            inline={true}
            onChange={() => {
              addField('element', MultipleChoiceElement.CHECKBOX);
              addField('defaultAnswersType', MultipleChoiceDefaultAnswers.EMPTY);
            }}
            checked={element === MultipleChoiceElement.CHECKBOX}
          >
            <span>
              <FormattedMessage id="XEditor.Wizard.MultipleChoice.ElementType2" />
            </span>
          </Radio>
        </FormGroup>

        {!this.props.isFreeChoice && (
          <>
            <p>
              <b>
                <FormattedMessage id="XEditor.Wizard.MultipleChoice.DefaultAnswersHeader" />
              </b>
            </p>
            <FormGroup>
              <Radio
                name={defaultAnswerTypeRadioGroup}
                inline={true}
                onChange={() => addField('defaultAnswersType', MultipleChoiceDefaultAnswers.EMPTY)}
                checked={defaultAnswersType === MultipleChoiceDefaultAnswers.EMPTY}
                disabled={element === MultipleChoiceElement.CHECKBOX}
              >
                <span>
                  <FormattedMessage id="XEditor.Wizard.MultipleChoice.DefaultAnswer2" />
                </span>
              </Radio>
              <Radio
                name={defaultAnswerTypeRadioGroup}
                inline={true}
                onChange={() => {
                  addField('defaultAnswersType', MultipleChoiceDefaultAnswers.BOOLEAN);
                  addField('defaultAnswersNumber', 2);
                }}
                checked={defaultAnswersType === MultipleChoiceDefaultAnswers.BOOLEAN}
                disabled={element === MultipleChoiceElement.CHECKBOX}
              >
                <span>
                  <FormattedMessage id="XEditor.Wizard.MultipleChoice.DefaultAnswer1" />
                </span>
              </Radio>
            </FormGroup>
          </>
        )}

        <p>
          <b>
            <FormattedMessage id="XEditor.Wizard.PictureChoice.AvailabilityQuestions" />
          </b>
        </p>

        <FormGroup>
          <Radio
            name={withoutQuestionsRadioGroup}
            inline={true}
            onChange={() => addField('withoutQuestions', false)}
            checked={!withoutQuestions}
          >
            <span>
              <FormattedMessage id="XEditor.Wizard.PictureChoice.Yes" />
            </span>
          </Radio>
          <Radio
            name={withoutQuestionsRadioGroup}
            inline={true}
            onChange={() => {
              addField('withoutQuestions', true);
            }}
            checked={withoutQuestions}
          >
            <span>
              <FormattedMessage id="XEditor.Wizard.PictureChoice.No" />
            </span>
          </Radio>
        </FormGroup>

        <div className="counters">
          <div>
            <p>
              <b>
                <FormattedMessage id="XEditor.Wizard.MultipleChoice.QuestionsNumberHeader" />
              </b>
            </p>
            <Counter
              value={initialQuestionsNumber || multipleChoiceQuestionsNumberDefault}
              onChange={newValue => addField('initialQuestionsNumber', newValue)}
              minValue={multipleChoiceQuestionsNumberMin}
              maxValue={multipleChoiceQuestionsNumberMax}
            />
          </div>

          <div>
            <p>
              <b>
                <FormattedMessage id="XEditor.Wizard.MultipleChoice.AnswersNumberHeader" />
              </b>
            </p>
            <Counter
              value={defaultAnswersNumber || multipleChoiceAnswersNumberDefault}
              onChange={newValue => addField('defaultAnswersNumber', newValue)}
              disabled={defaultAnswersType === MultipleChoiceDefaultAnswers.BOOLEAN}
              minValue={
                this.props.isFreeChoice
                  ? freeChoiceAnswersNumberMin
                  : multipleChoiceAnswersNumberMin
              }
              maxValue={multipleChoiceAnswersNumberMax}
            />
          </div>
        </div>
        <FormGroup>
          <Checkbox
            className="alignment"
            name={alignmentGroup}
            checked={horizontal}
            onChange={() => addField('horizontal', horizontal ? undefined : true)}
          >
            <FormattedMessage id="XEditor.Wizard.MultipleChoice.DefaultAlignmentHorizontal" />
          </Checkbox>
        </FormGroup>
      </form>
    );
  }
}

export default MultipleChoiceWizard;
