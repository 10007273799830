import React from 'react';
import {type Editor, type Value} from '@englex/slate';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {type Action, type Dispatch} from 'redux';
import {type Plugin} from '@englex/slate-react';

import {
  type MultipleChoiceDefaultAnswers,
  type MultipleChoiceElement
} from 'store/exercise/player/widgets/List/interface';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import AddQuestion from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/AddQuestion';
import RemoveQuestion from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/RemoveQuestion';
import MCAnswer from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/MultipleChoice/MCAnswer';
import QuestionsList from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/QuestionsList';
import Question from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/Question';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import {ButtonType} from 'components/Slate/SlateEditor/plugins/interface';
import {buttonTitle} from 'components/Slate/SlateEditor/plugins/button/i18n';
import genKey from 'components/Slate/utils/genKey';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type XMultipleChoiceProperties} from 'store/exercise/editor/widgets/XMultipleChoice/interface';
import {formattedTextContentChange} from 'store/exercise/editor/xwidgetActions';
import {type AppState} from 'store/interface';
import {deleteWidget} from 'store/exercise/editor/actions/xwidgets';

import {OddWordNormalizer} from '../../../../../../components/Slate/plugins/renderers/QuestionsList/OddWord/OddWordNormalizer';

interface StateProps {
  content: Value;
  defaultAnswersNumber: number;
  defaultAnswersType: MultipleChoiceDefaultAnswers;
  element: MultipleChoiceElement;
  initialQuestionsNumber?: number;
}

interface DispatchProps {
  onContentChange(widgetId: string, change: Editor): void;
}

interface OwnProps {
  id: string;
}

type Props = OwnProps & StateProps & DispatchProps;

class XEditorOddWord extends React.Component<Props> {
  private readonly plugins: Plugin[];
  private readonly addQuestionProp = {
    type: ButtonType.ADD_ODD_WORD_QUESTION,
    title: buttonTitle.AddOddWordQuestion,
    label: buttonTitle.AddOddWordQuestion
  };
  private readonly removeQuestionProp = {
    type: ButtonType.REMOVE_ODD_WORD_QUESTION,
    title: buttonTitle.RemoveOddWordQuestion,
    label: buttonTitle.RemoveOddWordQuestion
  };

  constructor(props: Props) {
    super(props);
    this.plugins = [
      new AddQuestion(this.addQuestionProp),
      new RemoveQuestion(this.removeQuestionProp),
      new Undo(),
      new Redo(),
      new Bold(),
      new Italic(),
      new Underline(),
      new StrikeThrough(),
      new HighlightToolbar(),
      new CharSelector(),
      new MCAnswer({element: props.element, genKey}),
      new QuestionsList({defaultQuestionsNumber: props.initialQuestionsNumber}),
      new Question({
        defaultAnswersNumber: props.defaultAnswersNumber,
        defaultAnswersType: props.defaultAnswersType,
        genKey
      }),
      new OddWordNormalizer(),
      new FormatPainter(),
      new ClearFormatting()
    ];
  }

  public render() {
    return (
      <SlateEditor
        toolbar={{portalId: 'xeditor-toolbar-portal'}}
        value={this.props.content}
        onChange={this.onContentChange}
        plugins={this.plugins}
      />
    );
  }

  private onContentChange = (change: Editor) => {
    this.props.onContentChange(this.props.id, change);
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps
): StateProps => {
  const xwidget = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === ownProps.id
  ) as XMultipleChoiceProperties;

  const {content, element, options} = xwidget;
  return {
    content,
    element,
    defaultAnswersNumber: options.get('defaultAnswersNumber'),
    defaultAnswersType: options.get('defaultAnswersType'),
    initialQuestionsNumber: options.get('initialQuestionsNumber')
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch<Action>
) => {
  return {
    onContentChange: (widgetId: string, change: Editor) =>
      dispatch(formattedTextContentChange(widgetId, change)),
    deleteWidget: (widgetId: string) => dispatch(deleteWidget(widgetId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(XEditorOddWord);
