import React from 'react';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {type XEssayWizardOptions} from 'store/exercise/player/widgets/Essay/interface';
import {valueJSONFromText} from 'components/Slate/utils/value';
import genKey from 'components/Slate/utils/genKey';
import XEssayRecord from 'store/exercise/editor/widgets/XEssay/XEssayRecord';

import {type XWizardWidgetData} from '../interface';
import EssayOptions from './EssayOptions';
import Tile from '../Tile';
import {EssaySVG} from '../assets';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.Essay}>
    <EssaySVG />
  </Tile>
);

export const essayWizardData: XWizardWidgetData<XEssayWizardOptions, XEssayRecord> = {
  type: WidgetType.ESSAY,
  steps: [
    {
      canGoToNextStep: () => true,
      component: EssayOptions
    }
  ],
  tile,
  createWidget: (widget: XEssayWizardOptions) =>
    new XEssayRecord({
      id: genKey(),
      task: valueJSONFromText(''),
      type: widget.type,
      options: widget.shouldCountWords
        ? {
            countWords: widget.shouldCountWords
          }
        : undefined
    })
};
