import React from 'react';
import {type List} from 'immutable';

import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';

import Widget from './xwidgets/XEditorWidget';
import ValidationError from '../ValidationError';

interface Props {
  xwidgets: List<XWidgetProperties>;
  error?: string;
}

class WidgetList extends React.PureComponent<Props> {
  public render() {
    const {xwidgets, error} = this.props;

    return (
      <React.Fragment>
        {xwidgets.map((xwidget: XWidgetProperties, index: number) => (
          <Widget
            id={xwidget.id}
            key={xwidget.id}
            isFirstWidget={index === 0}
            isLastWidget={index === xwidgets.size - 1}
          />
        ))}
        <ValidationError message={error} />
      </React.Fragment>
    );
  }
}

export default WidgetList;
