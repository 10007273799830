import React from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import Well from 'react-bootstrap/lib/Well';
import {type WrappedComponentProps, injectIntl, defineMessages} from 'react-intl';

import Icon from 'components/Icon';
import Confirm from 'components/modals/Confirm';

interface XEditorWidgetHeaderProps {
  hintExpanded: boolean;
  widgetExpanded: boolean;
  title: string;
  toggleHint: () => void;
  toggleWidget: () => void;
  titleIconName?: string;
  deleteWidget: () => void;
  moveUp: () => void;
  moveDown: () => void;
  copyWidget: () => void;
  disableUpButton?: boolean;
  disableDownButton?: boolean;
}

type Props = XEditorWidgetHeaderProps & WrappedComponentProps;

interface State {
  confirmDeletingWidget?: boolean;
}

const messages = defineMessages({
  DeleteWidgetConfirm: {
    id: 'XEditor.Widget.DeleteConfirm'
  },
  HintTitle: {
    id: 'XEditor.Widget.Hint.Toggle'
  },
  CollapseTitle: {
    id: 'XEditor.Widget.CollapseTitle'
  },
  ExpamdTitle: {
    id: 'XEditor.Widget.ExpandTitle'
  },
  CopyTitle: {
    id: 'XEditor.Widget.CopyTitle'
  },
  DeleteTitle: {
    id: 'XEditor.Widget.DeleteTitle'
  },
  MoveUpTitle: {
    id: 'XEditor.Widget.MoveUpTitle'
  },
  MoveDownTitle: {
    id: 'XEditor.Widget.MoveDownTitle'
  }
});

class XEditorWidgetHeader extends React.Component<Props, State> {
  public state: State = {};

  public render() {
    const {
      hintExpanded,
      widgetExpanded,
      toggleHint,
      toggleWidget,
      copyWidget,
      moveUp,
      moveDown,
      deleteWidget,
      title,
      titleIconName,
      intl: {formatMessage},
      disableDownButton,
      disableUpButton
    } = this.props;
    return (
      <>
        <div className="header">
          <div className="heading">
            <div className="title">
              {titleIconName && <Icon name={titleIconName} />}
              {title}
            </div>
          </div>

          <div className="actions">
            <button
              className={`btn btn-ico${widgetExpanded ? ' widget-expanded' : ''}`}
              onClick={toggleWidget}
              title={
                widgetExpanded
                  ? formatMessage(messages.CollapseTitle)
                  : formatMessage(messages.ExpamdTitle)
              }
            >
              <Icon name="angle-double-up" />
            </button>
            <button
              className={`btn btn-ico${hintExpanded ? ' hint-expanded' : ''}`}
              onClick={toggleHint}
              title={formatMessage(messages.HintTitle)}
            >
              <Icon name="info-circle" />
            </button>
            <button
              className="btn btn-ico"
              onClick={copyWidget}
              title={formatMessage(messages.CopyTitle)}
            >
              <Icon name="copy" />
            </button>
            <button
              className="btn btn-ico"
              onClick={this.confirmDeletingWidget}
              title={formatMessage(messages.DeleteTitle)}
            >
              <Icon name="trash" />
            </button>
            <button
              className="btn btn-ico"
              onClick={moveUp}
              disabled={disableUpButton}
              title={formatMessage(messages.MoveUpTitle)}
            >
              <Icon name="pc-left-arrow" className="i-rotate-90" />
            </button>
            <button
              className="btn btn-ico"
              onClick={moveDown}
              disabled={disableDownButton}
              title={formatMessage(messages.MoveDownTitle)}
            >
              <Icon name="pc-right-arrow" className="i-rotate-90" />
            </button>
          </div>
        </div>
        <Panel
          expanded={hintExpanded}
          onToggle={() => ({})}
          className="hint-content"
          defaultExpanded={false}
        >
          <Panel.Collapse>
            <Panel.Body>
              <Well bsSize="sm">
                Next. Elements are the basic that make up all matter. About 100 different elements
                have been discovered. gold, oxygen and iron. These building blocks are not made from
                simpler,. so they can't be broken down.
              </Well>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
        <Confirm
          show={this.state.confirmDeletingWidget}
          headerText={formatMessage(messages.DeleteWidgetConfirm)}
          onAccept={deleteWidget}
          onDecline={this.declineDeletingWidget}
          disableButtons={false}
        />
      </>
    );
  }

  private confirmDeletingWidget = () => this.setState({confirmDeletingWidget: true});

  private declineDeletingWidget = () => this.setState({confirmDeletingWidget: false});
}

export default injectIntl(XEditorWidgetHeader);
