import React, {type SyntheticEvent} from 'react';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import FormControl from 'react-bootstrap/lib/FormControl';
import {type FormControlFeedbackProps} from 'react-bootstrap/lib/FormControlFeedback';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import Tooltip from 'rc-tooltip';

import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import {type ExerciseCategory} from 'store/interface';

import {xeditorSidebarErrors} from '../i18n';

interface Props extends WrappedComponentProps {
  id?: number;
  categories: ExerciseCategory[];
  close: () => void;
  createCategory?: (title: string, parentId?: number | null) => void;
  initialValue?: string;
  processingRequest: boolean;
  renameCategory?: (title: string, id: number) => void;
  parentId: number | null;
}

interface State {
  errorStatus?: string;
}

class Editor extends React.Component<Props, State> {
  public state: State = {errorStatus: undefined};

  private input: HTMLInputElement;
  private errorStatus: HTMLDivElement | null;

  public componentDidMount() {
    const {initialValue} = this.props;
    if (this.input) {
      this.input.value = initialValue || '';
      this.input.addEventListener('keydown', this.onKeyDown);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const {close, processingRequest} = this.props;
    if (prevProps.processingRequest && !processingRequest) {
      close();
    }
  }

  public componentWillUnmount() {
    this.input.removeEventListener('keydown', this.onKeyDown);
  }

  public render() {
    const {processingRequest} = this.props;
    const {ErrorStatus} = this;
    return (
      <div className="editor" onClick={e => e.stopPropagation()}>
        <Form onSubmit={this.submit}>
          <FormControl
            className={this.state.errorStatus ? 'has-error' : ''}
            type="text"
            onChange={this.onInputChange}
            autoFocus={true}
            inputRef={this.inputRef}
            bsSize="sm"
          />
          <ErrorStatus />
        </Form>
        <Button className="btn-transparent" onClick={this.close} disabled={processingRequest}>
          <Icon name="times" />
        </Button>
        <Button className="btn-transparent" onClick={this.submit} disabled={processingRequest}>
          {processingRequest ? <Spinner size={15} /> : <Icon name="check" />}
        </Button>
      </div>
    );
  }

  private close = (e: KeyboardEvent | React.SyntheticEvent<Button, MouseEvent>) => {
    e.stopPropagation();
    this.props.close();
  };

  private createCategory = (title: string, parentId?: number | null) => {
    const {createCategory} = this.props;
    if (!createCategory) {
      return;
    }
    createCategory(title, parentId);
  };

  private inputRef = (el: HTMLInputElement) => el && (this.input = el);

  private onInputChange = (e: SyntheticEvent<FormControlFeedbackProps>) => {
    e.stopPropagation();
    this.input.value = e.currentTarget.value as string;
  };

  private onKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      this.close(e);
    }
  };

  private submit = (e: SyntheticEvent<FormControlFeedbackProps>) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      categories,
      id,
      initialValue,
      intl: {formatMessage},
      parentId,
      renameCategory
    } = this.props;
    const value = this.input.value.trim();
    if (!value) {
      this.setState({errorStatus: formatMessage(xeditorSidebarErrors.blankCategory)});
      return;
    }
    if (value.length > 256) {
      this.setState({errorStatus: formatMessage(xeditorSidebarErrors.longNameCategory)});
      return;
    }
    if (initialValue && value === initialValue) {
      this.props.close();
      return;
    }
    if (categories.map(c => c.title).includes(value)) {
      this.setState({errorStatus: formatMessage(xeditorSidebarErrors.categoryExists)});
      return;
    }
    this.setState({errorStatus: undefined});
    this.createCategory(value, parentId);
    if (id && renameCategory && value !== initialValue) {
      renameCategory(value, id);
    }
  };

  private ErrorStatus: React.FC = () => {
    const {errorStatus} = this.state;
    if (!errorStatus) {
      return null;
    }
    return (
      <Tooltip
        align={{
          offset: [26, 5],
          overflow: {adjustX: false, adjustY: true}
        }}
        overlayClassName="error-overlay"
        defaultVisible={false}
        destroyTooltipOnHide={false}
        getTooltipContainer={() => this.errorStatus!}
        overlay={<div className="error-tooltip">{errorStatus}</div>}
        placement="bottomRight"
        trigger={['hover']}
      >
        <div className="error-status" ref={this.errorStatusRef}>
          <Icon name="warning" />
        </div>
      </Tooltip>
    );
  };

  private errorStatusRef = (el: HTMLDivElement | null) => (this.errorStatus = el);
}

export default injectIntl(Editor);
