import {defineMessages} from 'react-intl';

export const localMessages = defineMessages({
  AddSpeaker: {
    id: 'XEditorXWidget.Dialogue.AddSpeaker'
  },
  ToUpload: {
    id: 'XEditorXWidget.Dialogue.ToUpload'
  },
  EnterName: {
    id: 'XEditorXWidget.Dialogue.EnterName'
  },
  EnterMessage: {
    id: 'XEditorXWidget.Dialogue.EnterMessage'
  },
  AddMessage: {
    id: 'XEditorXWidget.Dialogue.AddMessage'
  },
  DeleteMessage: {
    id: 'XEditorXWidget.Dialogue.DeleteMessage'
  },
  EditMessage: {
    id: 'XEditorXWidget.Dialogue.EditMessage'
  },
  MoveUp: {
    id: 'XEditorXWidget.Dialogue.MoveUp'
  },
  MoveDown: {
    id: 'XEditorXWidget.Dialogue.MoveDown'
  },
  DeleteSpeaker: {
    id: 'XEditorXWidget.Dialogue.DeleteSpeaker'
  },
  CannotDeleteSpeaker: {
    id: 'XEditorXWidget.Dialogue.CannotDeleteSpeaker'
  },
  EmptyMessages: {
    id: 'XEditorXWidget.Dialogue.EmptyMessages'
  },
  EmptySpeakers: {
    id: 'XEditorXWidget.Dialogue.EmptySpeakers'
  },
  ChangeTheme: {
    id: 'XEditorXWidget.Dialogue.ChangeTheme'
  }
});
