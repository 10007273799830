import React from 'react';
import {type Dispatch} from 'redux';
import {connect} from 'react-redux';
import {type Editor, type Value} from '@englex/slate';

import {type AppState} from 'store/interface';
import {type VideoData, type VideoOptions} from 'components/media/interface';
import {
  formattedTextContentChange,
  type XWidgetChangeAction
} from 'store/exercise/editor/xwidgetActions';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import {type XVideoProperties} from 'store/exercise/editor/widgets/XVideo/interface';

import {XDisplayAsButton} from '../components/XDisplayAsButton/XDisplayAsButton';
import Transcript from '../components/Transcript';
import VideoBlock from './VideoBlock';
import messages from './i18n';

import './styles.scss';

interface OwnProps {
  id: string;
}

interface StateProps {
  content: Value;
  posterId?: number;
  video: VideoData;
  videoOptions?: VideoOptions;
}

interface DispatchProps {
  onContentChange: (change: Editor) => XWidgetChangeAction;
}

interface Props extends OwnProps, StateProps, DispatchProps {}

class XEditorVideo extends React.Component<Props> {
  public render() {
    const {content, id, onContentChange, posterId, video, videoOptions} = this.props;
    return (
      <>
        <VideoBlock posterId={posterId} video={video} videoOptions={videoOptions} xwidgetId={id} />
        <Transcript
          content={content}
          onContentChange={onContentChange}
          placeholder={messages.placeholder}
        />
        <XDisplayAsButton xwidgetId={id} />
      </>
    );
  }
}

const mapStateToProps = (state: AppState, {id}: OwnProps): StateProps => {
  const {content, posterId, video, videoOptions} = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === id
  ) as XVideoProperties;
  return {content, posterId, video, videoOptions};
};

const mapDispatchToProps = (dispatch: Dispatch, {id: xwidgetId}: OwnProps): DispatchProps => ({
  onContentChange: (change: Editor) => dispatch(formattedTextContentChange(xwidgetId, change))
});

export default connect(mapStateToProps, mapDispatchToProps)(XEditorVideo);
