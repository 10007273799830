import React, {useCallback, useRef} from 'react';
import {type DragElementWrapper, type DragSourceOptions, useDrag, useDrop} from 'react-dnd';
import classNames from 'classnames';

import {DndTypes} from 'components/dnd/interface';

import {type KeyValueItem, type MediaSourceDragObject} from './types';

import './KeyValueRow.scss';

interface Props {
  readonly rowNumber: number;
  readonly row: KeyValueItem;
  readonly keyPlaceholder: string;
  readonly valuePlaceHolder: string;
  readonly showError: boolean;
  readonly onChangeKey: (id: string, value: string) => void;
  readonly onChangeValue: (id: string, value: string) => void;
  readonly onFocus: (id: string) => void;
  readonly onDrop: (dragIndex: number, hoverIndex: number) => void;
  readonly renderRowActions: (
    id: string,
    ref: DragElementWrapper<DragSourceOptions>
  ) => React.ReactElement;
}

export function KeyValueRow({
  rowNumber,
  row,
  onDrop,
  onChangeKey,
  onChangeValue,
  onFocus,
  keyPlaceholder,
  valuePlaceHolder,
  showError,
  renderRowActions
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const onChangeKeyHandle = useCallback(
    event => onChangeKey(row.id, event.target.value),
    [onChangeKey, row.id]
  );

  const onChangeValueHandle = useCallback(
    event => onChangeValue(row.id, event.target.value),
    [onChangeValue, row.id]
  );

  const onFocusHandler = useCallback(() => onFocus(row.id), [onFocus, row.id]);

  const [, drop] = useDrop({
    accept: DndTypes.EXERCISE_MEDIA_SOURCE,
    hover(item: MediaSourceDragObject, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.rowNumber;
      const hoverIndex = rowNumber;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onDrop(dragIndex, hoverIndex);

      item.rowNumber = hoverIndex;
    }
  });

  const [{isDragging, opacity}, drag, preview] = useDrag({
    type: DndTypes.EXERCISE_MEDIA_SOURCE,
    item: {type: DndTypes.EXERCISE_MEDIA_SOURCE, rowNumber: rowNumber},
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.85 : 1,
      isDragging: monitor.isDragging()
    })
  });

  drop(ref);

  return (
    <div
      ref={ref}
      className="key-value-row-container"
      style={{visibility: isDragging ? 'hidden' : 'visible'}}
    >
      <div
        ref={preview}
        className={classNames({
          'key-value-row': true,
          error: showError
        })}
        style={{opacity: opacity}}
      >
        <div className="key-value-row__number">{rowNumber + 1}</div>

        <div className="key-value-row__key">
          <input
            placeholder={keyPlaceholder}
            onChange={onChangeKeyHandle}
            onFocus={onFocusHandler}
            value={row.key}
          />
        </div>

        <div className="key-value-row__value">
          <input
            placeholder={valuePlaceHolder}
            onChange={onChangeValueHandle}
            onFocus={onFocusHandler}
            value={row.value}
          />
        </div>

        <div className="key-value-row__controls">
          {renderRowActions && renderRowActions(row.id, drag)}
        </div>
      </div>
    </div>
  );
}
