import {type Editor} from '@englex/slate';

import {
  CARDS_CHANGE_CARD_TITLE,
  CARDS_CHANGE_CARD_TEXT,
  CARDS_CHANGE_CARD_IMAGE,
  CARDS_CREATE_CARD,
  CARDS_DELETE_CARD,
  CARDS_MOVE_CARD
} from './actionTypes';
import {type XWidgetAction} from '../../actions/interface';

export interface CardContentAction extends XWidgetAction {
  cardId: string;
}

export interface ChangeCardContentAction extends CardContentAction {
  change: Editor;
}

export interface ChangeCardImageAction extends CardContentAction {
  image: number;
}

export interface MoveCardAction extends XWidgetAction {
  from: number;
  to: number;
}

export const changeCardTitle = (
  xwidgetId: string,
  cardId: string,
  change: Editor
): ChangeCardContentAction => ({
  type: CARDS_CHANGE_CARD_TITLE,
  xwidgetId,
  cardId,
  change
});

export const changeCardText = (
  xwidgetId: string,
  cardId: string,
  change: Editor
): ChangeCardContentAction => ({
  type: CARDS_CHANGE_CARD_TEXT,
  xwidgetId,
  cardId,
  change
});

export const changeCardImage = (
  xwidgetId: string,
  cardId: string,
  image: number
): ChangeCardImageAction => ({
  type: CARDS_CHANGE_CARD_IMAGE,
  xwidgetId,
  cardId,
  image
});

export const createCard = (xwidgetId: string): XWidgetAction => ({
  type: CARDS_CREATE_CARD,
  xwidgetId
});

export const deleteCard = (xwidgetId: string, cardId: string): CardContentAction => ({
  type: CARDS_DELETE_CARD,
  xwidgetId,
  cardId
});

export const moveCard = (xwidgetId: string, from: number, to: number): MoveCardAction => ({
  type: CARDS_MOVE_CARD,
  xwidgetId,
  from,
  to
});
