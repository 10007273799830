import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {type Editor} from '@englex/slate';

import {CardsType} from 'store/exercise/player/widgets/Cards/interface';
import {
  changeCardImage,
  changeCardText,
  changeCardTitle,
  deleteCard,
  moveCard
} from 'store/exercise/editor/widgets/XCards/actions';
import {type XCardProperties} from 'store/exercise/editor/widgets/XCards/interface';

import {XEditorTextCard} from './components/XEditorTextCard';
import {XEditorImageCard} from './components/XEditorImageCard';
import {XEditorCardFooter} from './components/XEditorCardFooter';

import './XEditorCard.scss';

interface Props {
  widgetId: string;
  index: number;
  type: CardsType;
  data: XCardProperties;
  canMoveUp: boolean;
  canMoveDown: boolean;
  canDelete: boolean;
  withError: boolean;
  resetErrors: () => void;
  setError: (message: string, meta: Record<string, unknown>) => void;
}

export function XEditorCard({
  widgetId,
  type,
  index,
  canMoveUp,
  canMoveDown,
  canDelete,
  withError,
  resetErrors,
  setError,
  data: {id, title, image, text}
}: Props) {
  const dispatch = useDispatch();

  const onChangeTitle = useCallback(
    (change: Editor) => {
      resetErrors();
      dispatch(changeCardTitle(widgetId, id, change));
    },
    [dispatch, id, widgetId, resetErrors]
  );

  const onChangeText = useCallback(
    (change: Editor) => {
      resetErrors();
      dispatch(changeCardText(widgetId, id, change));
    },
    [dispatch, widgetId, id, resetErrors]
  );

  const onChangeImage = useCallback(
    (imageId: number) => {
      resetErrors();
      dispatch(changeCardImage(widgetId, id, imageId));
    },
    [dispatch, widgetId, id, resetErrors]
  );

  const onDelete = useCallback(() => {
    resetErrors();
    dispatch(deleteCard(widgetId, id));
  }, [dispatch, widgetId, id, resetErrors]);

  const onMoveDown = useCallback(
    () => dispatch(moveCard(widgetId, index, index + 1)),
    [index, widgetId, dispatch]
  );

  const onMoveUp = useCallback(
    () => dispatch(moveCard(widgetId, index, index - 1)),
    [index, widgetId, dispatch]
  );

  switch (type) {
    case CardsType.TEXT:
      return (
        <XEditorTextCard
          index={index}
          title={title}
          text={text}
          withError={withError}
          onChangeTitle={onChangeTitle}
          onChangeText={onChangeText}
          renderFooter={() => (
            <XEditorCardFooter
              onDelete={onDelete}
              canMoveUp={canMoveUp}
              onMoveUp={onMoveUp}
              canMoveDown={canMoveDown}
              onMoveDown={onMoveDown}
              canDelete={canDelete}
            />
          )}
        />
      );

    case CardsType.IMAGES:
      return (
        <XEditorImageCard
          id={id}
          widgetId={widgetId}
          index={index}
          image={image}
          text={text}
          withError={withError}
          onChangeText={onChangeText}
          onChangeImage={onChangeImage}
          setError={setError}
          renderFooter={() => (
            <XEditorCardFooter
              onDelete={onDelete}
              canMoveUp={canMoveUp}
              onMoveUp={onMoveUp}
              canMoveDown={canMoveDown}
              onMoveDown={onMoveDown}
              canDelete={canDelete}
            />
          )}
        />
      );
  }

  return null;
}
