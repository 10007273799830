import React from 'react';
import classNames from 'classnames';

import {type GapFillType} from '../../../interface';
import './XWidgetGap.scss';

interface Props {
  id?: string;
  type: GapFillType;
  count: number;
  answer: string;
  example?: true;
  isSelected: boolean;
  select: () => void;
  editGap: () => void;
  className?: string;
}

class XWidgetGap extends React.Component<Props> {
  public render() {
    const answer = this.props.answer.length ? this.props.answer : '\u00A0';
    const classes = classNames('xwidget-gap', {
      example: this.props.example,
      selected: this.props.isSelected,
      [this.props.className!]: !!this.props.className
    });
    return (
      <span className={classes} onClick={this.editGap}>
        <span className={classNames('gap', {[`gap-${this.props.type}`]: true})} title={answer}>
          {answer}
        </span>
        {this.renderCount()}
      </span>
    );
  }

  private renderCount = () => {
    const {count} = this.props;
    if (count < 2) {
      return null;
    }
    return (
      <span className="count-dot">
        <span className="count">{count}</span>
      </span>
    );
  };

  private editGap = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.select();
    requestAnimationFrame(this.props.editGap);
  };
}

export default XWidgetGap;
