import {buttonLabel, buttonTitle} from '../../button/i18n';
import {GapFillType} from '../../../../interface';
import {ButtonType} from '../../interface';
import EditGapDNDInput from './forms/EditGapDNDInput';

import GapFill from '.';

class GapFillDNDInput extends GapFill {
  public gap = GapFillType.DND_INPUT;
  public type = ButtonType.GAP_FILL_DND_INPUT;

  public title = buttonTitle.GapFillDNDInput;
  public label = buttonLabel.GapFillDNDInput;

  public editForm = EditGapDNDInput;
}

export default GapFillDNDInput;
