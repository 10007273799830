import React, {useEffect, useRef} from 'react';
import {type ConnectDragSource} from 'react-dnd';

import {useDndEmptyImage} from 'components/dnd/useDndEmptyImage';
import {type DndTypes} from 'components/dnd/interface';
import {type DndComponentProps} from 'routes/Library/XEditorPage/components/XEditor/RenderDragItem';

import {useDndSorting} from './hooks/useDndSorting';
import {DragHandle} from './components/DragHandle/DragHandle';

export interface DndSortingWrapperProps {
  handle?: JSX.Element;
  isDragging?: boolean;
  isOver?: boolean;
  isDragLayer?: boolean;
  style?: React.CSSProperties;
}

interface Props {
  itemId: string;
  ChildComponent: React.FC<DndComponentProps>;
  childOptions: DndComponentProps;
  dndType: DndTypes;
  isNotUseDragLayer?: boolean;
  HandleComponent?: React.FC<{drag?: ConnectDragSource}>;
  setIsDndDragUsed?: (isDndDragUsed: boolean) => void;
  updateRecoveryPoint: () => void;
  moveItem: (moveItemIndex: number, targetIndex: number) => void;
  rollBackChanges: () => void;
  getItemIndex: (cardId: string) => number;
}

export function DndSortingWrapper({
  itemId,
  HandleComponent = DragHandle,
  ChildComponent,
  childOptions,
  dndType,
  isNotUseDragLayer = true,
  updateRecoveryPoint,
  moveItem,
  rollBackChanges,
  getItemIndex,
  setIsDndDragUsed
}: Props) {
  const currentDndElementRect = useRef<DOMRect | undefined>();
  const handleWithOutDrag = <HandleComponent />;

  const {drag, dragPreview, isDragging, drop, isOver} = useDndSorting({
    itemId,
    currentDndElementRect,
    childOptions,
    dndType,
    handle: handleWithOutDrag,
    isNotUseDragLayer,
    updateRecoveryPoint,
    moveItem,
    rollBackChanges,
    getItemIndex
  });

  const setCurrentDndElement = (element: HTMLDivElement) => {
    if (element) currentDndElementRect.current = element.getBoundingClientRect();
  };

  const handleWithDrag = <HandleComponent drag={drag} />;

  useEffect(() => {
    setIsDndDragUsed && setIsDndDragUsed(isDragging);
  }, [isDragging, setIsDndDragUsed]);

  useDndEmptyImage(!isNotUseDragLayer ? dragPreview : undefined, false);

  return drop(
    <div className="dnd-sorting-wrapper" ref={isNotUseDragLayer ? drag : setCurrentDndElement}>
      <ChildComponent
        {...childOptions}
        handle={handleWithDrag}
        isDragging={isDragging}
        isOver={isOver}
        isDragLayer={false}
      />
    </div>
  );
}
