import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  AddWord: {
    id: 'XEditor.XWidget.WordPictureSet.AddWord'
  },
  DeleteWord: {
    id: 'XEditor.XWidget.WordPictureSet.DeleteWord'
  },
  AddPicture: {
    id: 'XEditorWidget.ImageMatching.AddPairButton'
  },
  ChangeDefaultVisible: {
    id: 'XEditor.XWidget.WordPictureSet.ChangeDefaultVisible'
  },
  Placeholder: {
    id: 'XEditor.XWidget.WordPictureSet.Placeholder'
  },
  NotInteractive: {
    id: 'XEditor.XWidget.WordPictureSet.NotInteractive'
  },
  AvailableForNotInteractive: {
    id: 'XEditor.XWidget.WordPictureSet.AvailableForNotInteractive'
  }
});
