import React, {memo, type SyntheticEvent, useCallback, useLayoutEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import FormControl, {type FormControlProps} from 'react-bootstrap/lib/FormControl';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';

import {
  changeImage,
  changeText,
  deleteCard,
  flipCardsChangeCardSizeType
} from 'store/exercise/editor/widgets/XFlipCards/actions';
import XFlipCardsRecord from 'store/exercise/editor/widgets/XFlipCards/XFlipCardsRecord';
import validationMessages from 'store/exercise/editor/widgets/i18n';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';
import {type DndSortingWrapperProps} from 'components/DndSortingWrapper/DndSortingWrapper';

import ImageCard from '../components/ImageCard/ImageCard';

import './XFlipCard.scss';
import './CharactersCounterTooltip.scss';

export interface XFlipCardProps extends DndSortingWrapperProps {
  widgetId: string;
  cardId: string;
  imageId: number;
  text: string;
  cardSizeType: CardSizeType;
  reportError: (e: string | JSX.Element) => string | JSX.Element;
  resetErrors: () => void;
  updateRecoveryPoint: () => void;
}

export const XFlipCard: React.FC<XFlipCardProps> = memo(
  ({
    widgetId,
    cardId,
    imageId,
    text,
    cardSizeType,
    handle,
    style,
    isDragLayer,
    isDragging,
    reportError,
    resetErrors,
    updateRecoveryPoint
  }) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const [focused, setFocused] = useState(false);
    const [tooltipContainer, setTooltipContainer] = useState<HTMLElement | null>(null);

    const textLength = text.length;
    const {maxTextLength} = XFlipCardsRecord;
    const isManyCharacters = maxTextLength < textLength;

    useLayoutEffect(() => {
      setTooltipContainer(document.querySelector(`.x-flip-card.id-${cardId}`) as HTMLElement);
    }, [cardId]);

    const onDeleteCard = useCallback(() => {
      dispatch(deleteCard(widgetId, cardId));
      updateRecoveryPoint();
    }, [cardId, dispatch, updateRecoveryPoint, widgetId]);

    const onChangeImage = useCallback(
      (imageId: number) => {
        dispatch(changeImage(widgetId, cardId, imageId));
        updateRecoveryPoint();
      },
      [cardId, dispatch, updateRecoveryPoint, widgetId]
    );

    const onChangeText = useCallback(
      ({currentTarget: {value}}: SyntheticEvent<FormControlProps>) => {
        if (typeof value === 'string') {
          resetErrors();
          dispatch(changeText(widgetId, cardId, value));
          updateRecoveryPoint();
        }
      },
      [cardId, dispatch, resetErrors, updateRecoveryPoint, widgetId]
    );

    const onFocusText = useCallback(() => {
      setFocused(true);
    }, []);

    const onBlurText = useCallback(() => {
      setFocused(false);
    }, []);

    const renderTooltip = useCallback(() => {
      return `${formatMessage(
        validationMessages.CharactersEntered
      )} ${textLength}/${maxTextLength}`;
    }, [formatMessage, maxTextLength, textLength]);

    const getTooltipContainer = useCallback<() => HTMLElement>(
      () => tooltipContainer || document.body,
      [tooltipContainer]
    );

    return (
      <div
        key={cardId}
        className={classNames('x-flip-card', `id-${cardId}`, {
          'is-rectangle': cardSizeType === CardSizeType.RECTANGLE,
          'is-dragging': isDragging && !isDragLayer,
          'is-drag-layer': isDragLayer
        })}
        style={style}
      >
        <ImageCard
          id={cardId}
          xwidgetId={widgetId}
          imageId={imageId}
          handle={handle}
          isDragLayer={isDragLayer}
          resetErrors={resetErrors}
          deletePair={onDeleteCard}
          setImage={onChangeImage}
          reportError={reportError}
          changeCardSizeTypeAction={flipCardsChangeCardSizeType}
        />

        <div className={classNames('x-flip-card__text-group', {'has-error': isManyCharacters})}>
          <Tooltip
            placement="topRight"
            overlay={renderTooltip}
            overlayClassName={classNames('characters-counter-tooltip', {
              'is-many-characters': isManyCharacters
            })}
            getTooltipContainer={getTooltipContainer}
            visible={(focused || isManyCharacters) && !!tooltipContainer}
          >
            <FormControl
              bsSize="small"
              className="value"
              onChange={onChangeText}
              placeholder={
                !isDragLayer
                  ? formatMessage({id: 'XEditorWidget.ImageMatching.Input.Placeholder'})
                  : undefined
              }
              title={text}
              type="text"
              value={text}
              onFocus={onFocusText}
              onBlur={onBlurText}
            />
          </Tooltip>
        </div>
      </div>
    );
  }
);
