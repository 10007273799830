import React, {type FC, useEffect} from 'react';
import {useIntl} from 'react-intl';
import Radio from 'react-bootstrap/lib/Radio';

import {type MatchingJSON, MatchingType} from 'store/exercise/player/widgets/Matching/interface';

import {type XWizardStepProps} from '../interface';
import {enumValues} from '../../../../../../../helpers/enum';

import './MatchingWizard.scss';

export const MatchingWizard: FC<XWizardStepProps<MatchingJSON>> = ({fields, addField}) => {
  const intl = useIntl();

  const renderText = (type: MatchingType) => {
    switch (type) {
      case MatchingType.FREE_CHOICE:
        return intl.formatMessage({id: 'XEditor.Wizard.Matching.Type.Free'});
      case MatchingType.NO_CATEGORIES:
        return intl.formatMessage({id: 'XEditor.Wizard.Matching.Type.NoCategories'});
      default:
        return intl.formatMessage({id: 'XEditor.Wizard.Matching.Type.Default'});
    }
  };

  useEffect(() => {
    addField('matchingType', MatchingType.DEFAULT);
  }, [addField]);

  return (
    <form className="matching-wizard">
      <p>
        <b>{intl.formatMessage({id: 'XEditor.Wizard.Matching.Type'})}</b>
      </p>
      <div>
        {enumValues(MatchingType).map((type: MatchingType) => (
          <Radio
            name="matching-type"
            onChange={() => addField('matchingType', type)}
            checked={fields.matchingType === type}
            key={type}
          >
            <span>{renderText(type)}</span>
          </Radio>
        ))}
      </div>
    </form>
  );
};
