import React, {memo, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import {useIntl} from 'react-intl';
import {useDrop} from 'react-dnd';
import {NativeTypes} from 'react-dnd-html5-backend';
import classNames from 'classnames';
import {type Editor, type Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';

import Icon from 'components/Icon';
import {changeName, removeSpeaker} from 'store/exercise/editor/widgets/XDialogue/actions';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';

import {localMessages} from '../../messages';
import {Avatar} from './components/Avatar/Avatar';

import './SpeakerCard.scss';

interface Props {
  widgetId: string;
  speakerId: string;
  avatar: number;
  name: Value;
  withError: boolean;
  haveMessages: boolean;
  setError: (message: string, meta: Record<string, unknown>) => void;
  resetErrors: () => void;
}

const slatePlugins: Plugin[] = [];

export const SpeakerCard: React.FC<Props> = memo(
  ({widgetId, speakerId, avatar, name, withError, haveMessages, setError, resetErrors}) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();

    const [droppedFile, setDroppedFile] = useState<File | null>(null);

    const titleForDeleteButton = haveMessages
      ? formatMessage(localMessages.CannotDeleteSpeaker)
      : formatMessage(localMessages.DeleteSpeaker);

    const [{isOver}, drop] = useDrop({
      accept: NativeTypes.FILE,
      drop: async (item: {files: File[]}) => {
        const [file] = item.files;
        if (file) {
          setDroppedFile(file);
        }
      },
      collect: monitor => ({isOver: monitor.isOver({shallow: true})})
    });

    const deleteSpeaker = useCallback(() => {
      dispatch(removeSpeaker(widgetId, speakerId));
    }, [dispatch, speakerId, widgetId]);

    const changeNameHandle = (editor: Editor) => {
      resetErrors();
      dispatch(changeName(widgetId, speakerId, editor.value));
    };

    return (
      <div ref={drop} className={classNames('speaker-card', {isOver: isOver}, {error: withError})}>
        <Avatar
          widgetId={widgetId}
          speakerId={speakerId}
          avatar={avatar}
          droppedFile={droppedFile}
          isOver={isOver}
          setError={setError}
        />
        <SlateEditor
          value={name}
          plugins={slatePlugins}
          toolbar={{hide: true}}
          className={classNames('user-name', {isError: withError})}
          placeholder={formatMessage(localMessages.EnterName)}
          onChange={changeNameHandle}
        />
        <Button
          className="delete-btn"
          title={titleForDeleteButton}
          disabled={haveMessages}
          onClick={deleteSpeaker}
        >
          <Icon name="trash" />
        </Button>
      </div>
    );
  }
);
