import React, {Component} from 'react';
import {defineMessages} from 'react-intl';
import {type Action} from 'redux';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';

import {type VideoData} from 'components/media/interface';
import Confirm from 'components/modals/Confirm';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';
import {type AppState} from 'store/interface';
import {requestAttachPosterToVideo} from 'store/exercise/editor/widgets/XVideo/actions';

import {type DispatchProps, type OwnProps, type Props, type State} from './interface';
import ImageModal from './ImageModal';
import './styles.scss';

const i18n = defineMessages({
  confirmHeader: {
    id: 'XEditorWidget.Video.RemovePosterConfirmHeader'
  }
});

class PosterInput extends Component<Props, State> {
  public state: State = {showConfirmModal: false, showModal: false};

  render() {
    const {children, intl, modalTitle, videoId} = this.props;
    const {showConfirmModal, showModal} = this.state;
    return videoId ? (
      <div className="poster-input">
        <ImageModal
          attachPoster={this.attachPosterToVideo}
          hide={this.hideModal}
          title={modalTitle}
          show={showModal}
        />
        <Confirm
          headerText={intl.formatMessage(i18n.confirmHeader)}
          onAccept={this.removePoster}
          onDecline={this.hideConfirmModal}
          show={showConfirmModal}
          disableButtons={false}
        />
        {children(this.showModal, this.showConfirmModal)}
      </div>
    ) : null;
  }

  private removePoster = () => {
    this.props.attachPoster();
    this.hideConfirmModal();
  };

  private showConfirmModal = () => this.setState({showConfirmModal: true});

  private hideConfirmModal = () => this.setState({showConfirmModal: false});

  private attachPosterToVideo = async (
    id: number,
    errorCallback: (e: AxiosRequestError) => void
  ) => {
    let response: AxiosResponseAction<VideoData> | null = null;
    try {
      response = await this.props.attachPosterToVideo(id);
    } catch (e) {
      errorCallback(e);
    }
    if (response) {
      this.props.attachPoster(id);
      return true;
    }
    return false;
  };

  private hideModal = () => this.setState({showModal: false});
  private showModal = () => this.setState({showModal: true});
}

const mapDispatchToProps = (
  dispatch: Dispatch<Action, AppState>,
  {videoId}: OwnProps
): DispatchProps => ({
  attachPosterToVideo: (posterId: number) =>
    dispatch(requestAttachPosterToVideo(videoId!, posterId))
});

export default connect(null, mapDispatchToProps)(PosterInput);
