import React from 'react';
import {type Editor, type Value} from '@englex/slate';
import {injectIntl, type MessageDescriptor, type WrappedComponentProps} from 'react-intl';

import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import AddDialogBtn from 'components/Slate/SlateEditor/plugins/button/Table/Dialog/AddDialogBtn';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import TextAlignment from 'components/Slate/SlateEditor/plugins/button/TextAlignment';
import FontSizeToolbar from 'components/Slate/SlateEditor/plugins/button/FontSize';
import {NestedLists} from 'components/Slate/SlateEditor/plugins/button/List/NestedLists';
import Link from 'components/Slate/SlateEditor/plugins/button/Link';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import {type XWidgetChangeAction} from 'store/exercise/editor/xwidgetActions';
import './Transcript.scss';

interface OwnProps {
  content: Value;
  onContentChange: (change: Editor) => XWidgetChangeAction;
  placeholder: MessageDescriptor;
}

interface Props extends OwnProps, WrappedComponentProps {}

class Transcript extends React.Component<Props> {
  public static plugins = [
    new Undo(),
    new Redo(),
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new TextAlignment(),
    new FontSizeToolbar({shouldHide: () => true}),
    new FontColorToolbar(),
    new HighlightToolbar(),
    new NestedLists(),
    new AddDialogBtn(),
    new CharSelector(),
    new Link(),
    new FormatPainter(),
    new ClearFormatting()
  ];

  public render() {
    const {
      intl: {formatMessage},
      placeholder
    } = this.props;
    return (
      <div className="transcript">
        <SlateEditor
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          value={this.props.content}
          onChange={this.props.onContentChange}
          plugins={Transcript.plugins}
          placeholder={formatMessage(placeholder)}
        />
      </div>
    );
  }
}

export default injectIntl(Transcript);
