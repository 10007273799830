import {SELECT_CATEGORY, SELECT_LEVEL} from '../actionTypes/xmeta';
import {type ExerciseCategory} from '../../../interface';
import {type CategorySelectAction, type LevelSelectAction} from './interface';

export const selectCategory = (categories: ExerciseCategory[]): CategorySelectAction => ({
  type: SELECT_CATEGORY,
  categories
});

export const selectLevel = (levels: number[]): LevelSelectAction => ({
  type: SELECT_LEVEL,
  levels
});
