import React from 'react';

import {type WidgetFormattedTextJSON} from 'store/exercise/player/widgets/FormattedText/interface';
import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {valueJSONFromText} from 'components/Slate/utils';
import genKey from 'components/Slate/utils/genKey';
import XAudioRecord from 'store/exercise/editor/widgets/XAudio/XAudioRecord';

import {type XWizardWidgetData} from '../interface';
import Tile from '../Tile';
import {AudioSVG} from '../assets';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.Audio}>
    <AudioSVG />
  </Tile>
);

export const audioWizardData: XWizardWidgetData<WidgetFormattedTextJSON, XAudioRecord> = {
  type: WidgetType.AUDIO,
  steps: [],
  tile,
  createWidget: (widget: Pick<WidgetFormattedTextJSON, 'type'>) =>
    new XAudioRecord({
      id: genKey(),
      task: valueJSONFromText(''),
      type: widget.type,
      content: valueJSONFromText('')
    })
};
