import React, {type FC, memo} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';

import Icon from 'components/Icon';
import {WPSTheme} from 'store/exercise/editor/widgets/XWordPictureSet/baseInterface';
import {changeWidgetTheme} from 'store/exercise/editor/widgets/XWordPictureSet/actions';

import './ThemeModal.scss';

interface Props {
  opened: boolean;
  widgetId: string;
  styleName: string;
  onClose: () => void;
  onSelect: () => void;
}

export const ThemeModal: FC<Props> = memo(({opened, onClose, onSelect, widgetId, styleName}) => {
  const dispatch = useDispatch();

  return (
    <Modal backdrop="static" className="theme-modal" onHide={onClose} show={opened}>
      <Modal.Header className="header">
        <FormattedMessage id="XEditorXWidget.SelectWidgetStyle" />
        <Button className="btn-xs" onClick={onClose}>
          <Icon name="pc-close i-lg" />
        </Button>
      </Modal.Header>
      <Modal.Body className="body">
        <div className="theme-cards">
          {Object.keys(WPSTheme).map((key: WPSTheme) => {
            const currentColor = key;

            const onChange = () => {
              dispatch(changeWidgetTheme(widgetId, WPSTheme[key]));
            };
            return (
              <div key={currentColor} className={`theme-card ${WPSTheme[currentColor]}`}>
                <label className="theme-card__label">
                  <input
                    className="theme-card__radio"
                    type="radio"
                    name="styleName"
                    value={currentColor}
                    defaultChecked={styleName === WPSTheme[key]}
                    onChange={onChange}
                  />
                  <span className="theme-card__radio-border">
                    <span className="theme-card__radio-circle" />
                  </span>
                  <div
                    className={classNames('theme-card__content-block', WPSTheme[currentColor])}
                  />
                </label>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="footer">
        <div className="modal-controls">
          <Button bsSize="small" onClick={onClose}>
            <FormattedMessage id="MediaDevicesWizard.ButtonClose" />
          </Button>

          <Button bsSize="small" bsStyle="primary" onClick={onSelect}>
            <FormattedMessage id="Common.Save" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
});
