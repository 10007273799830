import React from 'react';
import {Map} from 'immutable';

import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import genKey from 'components/Slate/utils/genKey';
import XWordSetRecord from 'store/exercise/editor/widgets/XWordPictureSet/XWordSet/XWordSetRecord';
import XPictureSetRecord from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/XPictureSetRecord';
import XWordPictureSetRecord from 'store/exercise/editor/widgets/XWordPictureSet/XWordPictureSet/XWordPictureSetRecord';
import {type WordSetJSON} from 'store/exercise/player/widgets/WordPictureSet/WordSet/interface';
import {type PictureSetJSON} from 'store/exercise/player/widgets/WordPictureSet/PictureSet/interface';
import {type WordPictureSetJSON} from 'store/exercise/player/widgets/WordPictureSet/WordPictureSet/interface';
import XWordSetCardRecord from 'store/exercise/editor/widgets/XWordPictureSet/XWordSet/XWordSetCardRecord';
import XPictureSetCardRecord from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/XPictureSetCardRecord';
import XWordPictureSetCardRecord from 'store/exercise/editor/widgets/XWordPictureSet/XWordPictureSet/XWordPictureSetCardRecord';
import {type WordPictureSetOptions} from 'store/exercise/player/widgets/WordPictureSet/interface';

import {type XWizardWidgetData} from '../interface';
import Tile from '../Tile';
import ChooseTypeModal from './ChooseTypeModal';
import {WordPictureSetSVG} from '../assets';

const tile: React.FC = () => (
  <Tile title={WidgetTitle.WordPictureSet}>
    <WordPictureSetSVG />
  </Tile>
);

export const wordPictureSetWizardData: XWizardWidgetData<
  WordSetJSON | PictureSetJSON | WordPictureSetJSON,
  XWordSetRecord | XPictureSetRecord | XWordPictureSetRecord
> = {
  type: WidgetType.PICTURE_SET,
  steps: [
    {
      canGoToNextStep: (options: WordPictureSetOptions) => !!options.type,
      component: ChooseTypeModal
    }
  ],
  tile,
  createWidget: (options: WordPictureSetOptions) => {
    const keys = [genKey(), genKey(), genKey()];

    if (options.sort === WidgetType.WORD_SET) {
      const wordSetCardsRecords = keys.reduce((acc, key) => {
        acc[key] = new XWordSetCardRecord({id: key});
        return acc;
      }, {});
      return XWordSetRecord.fromCards(Map(wordSetCardsRecords));
    }

    if (options.sort === WidgetType.PICTURE_SET) {
      const pictureSetCardsRecords = keys.reduce((acc, key) => {
        acc[key] = new XPictureSetCardRecord({id: key});
        return acc;
      }, {});
      return XPictureSetRecord.fromCards(Map(pictureSetCardsRecords));
    }

    const wordPictureSetCardsRecords = keys.reduce((acc, key) => {
      acc[key] = new XWordPictureSetCardRecord({id: key});
      return acc;
    }, {});
    return XWordPictureSetRecord.fromCards(Map(wordPictureSetCardsRecords));
  }
};
