import React from 'react';

import {UploadingStatus, type UploadingValidationError} from '../interface';

interface Props {
  status: UploadingStatus | UploadingValidationError;
  percentsUploaded: number;
}

export default class Progress extends React.Component<Props> {
  public render() {
    const {percentsUploaded, status} = this.props;
    return status === UploadingStatus.UPLOADING ? (
      <div className="uploading-progress">
        <div style={{width: `${percentsUploaded}%`}} />
      </div>
    ) : null;
  }
}
