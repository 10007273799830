import React from 'react';

import Spinner from 'components/Spinner';

import {UploadingStatus, type UploadingValidationError} from '../interface';

const {FINISHED, PREPARING, UPLOADING} = UploadingStatus;

interface Props {
  status: UploadingStatus | UploadingValidationError;
  percentsUploaded: number;
}

export default class Percentage extends React.Component<Props> {
  public render() {
    const {percentsUploaded, status} = this.props;
    if (status === PREPARING || (percentsUploaded === 100 && status !== FINISHED)) {
      return <Spinner size={15} />;
    }
    return status === UPLOADING ? <span className="percentage">{percentsUploaded}%</span> : null;
  }
}
