import {defineMessages} from 'react-intl';

export const vocabularyMessages = defineMessages({
  OriginalPlaceholder: {
    id: 'XEditorXWidget.Vocabulary.OriginalPlaceholder'
  },
  TranslationPlaceholder: {
    id: 'XEditorXWidget.Vocabulary.TranslationPlaceholder'
  },
  CategoryPlaceholder: {
    id: 'XEditorXWidget.Vocabulary.CategoryPlaceholder'
  },
  QuizletURLPlaceholder: {
    id: 'XEditorXWidget.Vocabulary.QuizletURLPlaceholder'
  },
  ListTitlePlaceholder: {
    id: 'XEditorXWidget.Vocabulary.ListTitlePlaceholder'
  }
});
