import React from 'react';
import {type Editor, type Value} from '@englex/slate';
import {connect, type MapStateToProps} from 'react-redux';
import {type Action, type Dispatch} from 'redux';
import {type Plugin} from '@englex/slate-react';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import {FormattedMessage} from 'react-intl';

import {MultipleChoiceDefaultAnswers} from 'store/exercise/player/widgets/List/interface';
import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import AddQuestion from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/AddQuestion';
import RemoveQuestion from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/RemoveQuestion';
import QuestionsList from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/QuestionsList';
import Question from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/Question';
import MatchingAnswer from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/Matching/MatchingAnswer';
import genKey from 'components/Slate/utils/genKey';
import {
  formattedTextContentChange,
  toggleFirstQuestionItemExample
} from 'store/exercise/editor/xwidgetActions';
import {type AppState} from 'store/interface';
import {deleteWidget} from 'store/exercise/editor/actions/xwidgets';
import {
  type XMatchingFreeChoiceRecordProperties,
  type XMatchingProperties
} from 'store/exercise/editor/widgets/XMatching/interface';
import {
  matchingDefaultAnswersNumber,
  matchingInitialQuestionsNumber
} from 'store/exercise/editor/widgets/constants';
import {type XWidgetProperties} from 'store/exercise/editor/widgets/interface';
import TheOnlyOrderedList from 'components/Slate/SlateEditor/plugins/button/List/SpecificCases';
import {MatchingType} from 'store/exercise/player/widgets/Matching/interface';
import {changeExtraContent} from 'store/exercise/editor/widgets/XMatching/actions';
import {buttonTitle} from 'components/Slate/SlateEditor/plugins/button/i18n';
import {ButtonType} from 'components/Slate/SlateEditor/plugins/interface';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';

import './XEditorMatching.scss';

const isFreeChoice = (
  xwidget: XMatchingProperties | XMatchingFreeChoiceRecordProperties
): xwidget is XMatchingFreeChoiceRecordProperties => {
  return xwidget.matchingType === MatchingType.FREE_CHOICE;
};

interface StateProps {
  content: Value;
  extraContent?: Value;
  matchingType?: MatchingType;
  firstQuestionIsExample: boolean;
}

interface DispatchProps {
  onContentChange: (change: Editor) => void;
  onExtraContentChange: (change: Editor) => void;
  toggleFirstQuestionIsExample: (isExample: boolean) => void;
  deleteWidget: () => void;
}

interface OwnProps {
  id: string;
}

type Props = OwnProps & StateProps & DispatchProps;

class XEditorMatching extends React.Component<Props> {
  private readonly plugins: Plugin[];
  private readonly freeChoiceAnswersPlugins: Plugin[];
  private readonly freeChoiceCategoriesPlugins: Plugin[];
  private readonly noCategoriesPlugins: Plugin[];
  private readonly addQuestionProp = {
    type: ButtonType.ADD_MC_QUESTION,
    title: buttonTitle.AddMCCategory,
    label: buttonTitle.AddMCCategory
  };
  private readonly removeQuestionProp = {
    type: ButtonType.REMOVE_MC_QUESTION,
    title: buttonTitle.RemoveMCCategory,
    label: buttonTitle.RemoveMCCategory
  };

  constructor(props: Props) {
    super(props);
    this.plugins = [
      new AddQuestion(this.addQuestionProp),
      new RemoveQuestion(this.removeQuestionProp),
      new Undo(),
      new Redo(),
      new MatchingAnswer({genKey, allowedMarks: []}),
      new QuestionsList({defaultQuestionsNumber: matchingInitialQuestionsNumber}),
      new Question({
        defaultAnswersNumber: matchingDefaultAnswersNumber,
        defaultAnswersType: MultipleChoiceDefaultAnswers.EMPTY,
        genKey
      }),
      new Bold(),
      new Italic(),
      new Underline(),
      new StrikeThrough(),
      new HighlightToolbar(),
      new FormatPainter(),
      new ClearFormatting()
    ];
    this.freeChoiceCategoriesPlugins = [
      new Undo(),
      new Redo(),
      new TheOnlyOrderedList(),
      new Bold(),
      new Italic(),
      new Underline(),
      new StrikeThrough(),
      new HighlightToolbar(),
      new FormatPainter(),
      new ClearFormatting()
    ];
    this.freeChoiceAnswersPlugins = [new Undo(), new Redo(), new TheOnlyOrderedList()];
    this.noCategoriesPlugins = [new Undo(), new Redo(), new TheOnlyOrderedList()];
  }

  public render() {
    const {matchingType} = this.props;

    switch (matchingType) {
      case MatchingType.FREE_CHOICE: {
        return this.renderFreeChoice;
      }
      case MatchingType.NO_CATEGORIES: {
        return this.renderNoCategories;
      }
      default:
        return this.renderDefault;
    }
  }

  private get renderDefault() {
    const {content, firstQuestionIsExample} = this.props;

    return (
      <>
        <SlateEditor
          toolbar={{portalId: 'xeditor-toolbar-portal'}}
          value={content}
          onChange={this.onContentChange}
          plugins={this.plugins}
        />
        <Checkbox onChange={this.toggleFirstQuestionIsExample} checked={firstQuestionIsExample}>
          <FormattedMessage id="XEditorXWidget.Matching.FirstCategoryIsExample" />
        </Checkbox>
      </>
    );
  }

  private get renderFreeChoice() {
    const {content, extraContent} = this.props;

    return (
      extraContent && (
        <>
          <div className="matching-block-categories">
            <div className="matching-block-categories__header">
              <FormattedMessage id="XEditor.XWidget.XMatching.Categories" />
            </div>
            <SlateEditor
              toolbar={{portalId: 'xeditor-toolbar-portal'}}
              value={content}
              onChange={this.onContentChange}
              plugins={this.freeChoiceCategoriesPlugins}
            />
          </div>

          <div className="matching-block-answers">
            <div className="matching-block-answers__header">
              <FormattedMessage id="XEditor.XWidget.XMatching.Answers" />
            </div>
            <SlateEditor
              toolbar={{portalId: 'xeditor-toolbar-portal'}}
              value={extraContent}
              onChange={this.onExtraContentChange}
              plugins={this.freeChoiceAnswersPlugins}
            />
          </div>
        </>
      )
    );
  }

  private get renderNoCategories() {
    const {content} = this.props;

    return (
      <SlateEditor
        toolbar={{portalId: 'xeditor-toolbar-portal'}}
        value={content}
        onChange={this.onContentChange}
        plugins={this.noCategoriesPlugins}
      />
    );
  }

  private onContentChange = (change: Editor) => {
    this.props.onContentChange(change);
  };

  private onExtraContentChange = (change: Editor) => {
    this.props.onExtraContentChange(change);
  };

  private toggleFirstQuestionIsExample = () => {
    const {firstQuestionIsExample, toggleFirstQuestionIsExample} = this.props;
    toggleFirstQuestionIsExample(!firstQuestionIsExample);
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps
) => {
  const xwidget = state.xeditor!.xexercise.widgets.find(
    (x: XWidgetProperties) => x.id === ownProps.id
  ) as XMatchingProperties;

  const {content, matchingType, firstQuestionIsExample} = xwidget;

  return {
    content,
    matchingType,
    firstQuestionIsExample,
    extraContent: isFreeChoice(xwidget) ? xwidget.extraContent : undefined
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>, {id}: OwnProps): DispatchProps => {
  return {
    onContentChange: (change: Editor) => dispatch(formattedTextContentChange(id, change)),
    onExtraContentChange: (change: Editor) => dispatch(changeExtraContent(id, change)),
    deleteWidget: () => dispatch(deleteWidget(id)),
    toggleFirstQuestionIsExample: (isExample: boolean) =>
      dispatch(toggleFirstQuestionItemExample(id, isExample))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(XEditorMatching);
