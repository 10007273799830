import React from 'react';
import classNames from 'classnames';

import Icon from './Icon';

import './Counter.scss';

interface CounterProps {
  value: number;
  onChange: (newValue: number) => void;
  disabled?: boolean;
  minValue: number;
  maxValue: number;
}

export default class Counter extends React.Component<CounterProps, {}> {
  public render() {
    const {disabled, value} = this.props;
    const className = classNames('englex-counter', {disabled});
    return (
      <div className={className}>
        <button onClick={this.onDecrementClick}>
          <Icon name="minus" />
        </button>
        <input type="text" value={value} onChange={this.onChange} />
        <button onClick={this.onIncrementClick}>
          <Icon name="plus" />
        </button>
      </div>
    );
  }

  private onDecrementClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const {value, onChange, disabled, minValue} = this.props;
    if (value > minValue && !disabled) {
      onChange(value - 1);
    }
  };

  private onIncrementClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const {value, maxValue, onChange, disabled} = this.props;
    if (value && value < maxValue && !disabled) {
      onChange(value + 1);
    }
  };

  private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const {value} = e.target;
    const {onChange, maxValue, disabled, minValue} = this.props;
    if (
      value.match(/^\d{0,4}$/) &&
      Number(value) <= maxValue &&
      Number(value) >= minValue &&
      !disabled
    ) {
      onChange(Number(value));
    } else if (!value) {
      onChange(minValue);
    }
  };
}
