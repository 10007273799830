import React, {type FC} from 'react';

import {VideoSourceType} from 'components/media/interface';
import Youtube from 'components/media/VideoPlayer/Youtube';
import EnglexPlayer from 'components/media/VideoPlayer/EnglexPlayer';

const {SELECTEL, YOUTUBE} = VideoSourceType;

interface Props {
  id: number;
  posterId?: number;
  source: VideoSourceType;
  widgetId: string;
  start?: number;
  end?: number;
}

const Player: FC<Props> = ({id, posterId, source, widgetId, start, end}) => (
  <>
    {source === YOUTUBE && <Youtube key={id} id={id} start={start} end={end} />}
    {source === SELECTEL && (
      <EnglexPlayer key={id} id={id} posterId={posterId} widgetId={widgetId} />
    )}
  </>
);

export default Player;
