import React, {memo, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import type XFlipCardsCardRecord from 'store/exercise/editor/widgets/XFlipCards/XFlipCardsCardRecord';
import type XFlipCardsRecord from 'store/exercise/editor/widgets/XFlipCards/XFlipCardsRecord';
import {type AppState} from 'store/interface';
import {resetWidgetErrors} from 'store/exercise/editor/actions/xeditor';
import {
  addCard,
  changeDefaultFlipped,
  moveCard,
  rollBackCards
} from 'store/exercise/editor/widgets/XFlipCards/actions';
import Icon from 'components/Icon';
import {useRecoveryPoint} from 'components/DndSortingWrapper/hooks/useRecoveryPoint';
import {DndTypes} from 'components/dnd/interface';
import {DndSortingWrapper} from 'components/DndSortingWrapper/DndSortingWrapper';
import {Provider} from 'components/XPlayer/contexts/dndContext';

import {XFlipCard} from './XFlipCard';

import './XFlipCards.scss';

interface Props {
  id: string;
  setError: (e: string | React.ReactElement) => string | React.ReactElement;
}

export const XFlipCards: React.FC<Props> = memo(({id, setError}) => {
  const dispatch = useDispatch();
  const [isDndDragUsed, setIsDndDragUsed] = useState(false);

  const widget = useSelector((state: AppState) =>
    state.xeditor!.xexercise.widgets.find((x: XFlipCardsRecord) => x.id === id)
  ) as XFlipCardsRecord;

  const {cards, defaultFlipped} = widget;

  const {recoveryPoint, updateRecoveryPoint} = useRecoveryPoint({list: cards});

  const resetErrors = useCallback(() => {
    dispatch(resetWidgetErrors(id));
  }, [dispatch, id]);

  const onChangeIsTextSideDefault = useCallback(() => {
    dispatch(changeDefaultFlipped(id));
  }, [dispatch, id]);

  const onAddCard = () => {
    dispatch(addCard(id));
    updateRecoveryPoint();
  };

  const moveItem = useCallback(
    (moveItemIndex: number, targetIndex: number) => {
      dispatch(moveCard(id, moveItemIndex, targetIndex));
    },
    [dispatch, id]
  );

  const rollBackChanges = useCallback(() => {
    dispatch(rollBackCards(id, recoveryPoint.current));
  }, [dispatch, id, recoveryPoint]);

  const getItemIndex = useCallback(
    (cardId: string) => {
      return cards.findIndex(card => card?.id === cardId);
    },
    [cards]
  );

  return (
    <Provider>
      <div className={classNames('x-flip-cards', {'is-dnd-drag-used': isDndDragUsed})}>
        <div className="x-flip-cards__cards">
          {cards.map((card: XFlipCardsCardRecord) => (
            <DndSortingWrapper
              key={card.id}
              itemId={card.id}
              dndType={DndTypes.FLIP_CARDS}
              isNotUseDragLayer={false}
              ChildComponent={XFlipCard}
              childOptions={{
                widgetId: id,
                cardId: card.id,
                imageId: card.imageId,
                text: card.text,
                cardSizeType: card.cardSizeType,
                reportError: setError,
                resetErrors: resetErrors,
                updateRecoveryPoint
              }}
              updateRecoveryPoint={updateRecoveryPoint}
              moveItem={moveItem}
              rollBackChanges={rollBackChanges}
              getItemIndex={getItemIndex}
              setIsDndDragUsed={setIsDndDragUsed}
            />
          ))}
        </div>
        <div className="x-flip-cards__controls">
          <Checkbox onChange={onChangeIsTextSideDefault} checked={defaultFlipped}>
            <span />
            <FormattedMessage id="XEditor.XWidget.XFlipCards.FlipCardsTextUp" />
          </Checkbox>

          <button className="add-btn" onClick={onAddCard}>
            <Icon name="plus-circle" />
            <FormattedMessage id="XEditorXWidget.Cards.AddCard" />
          </button>
        </div>
      </div>
    </Provider>
  );
});
