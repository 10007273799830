import React, {Component} from 'react';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import {FormattedMessage} from 'react-intl';

class ToggleExample extends Component<{
  checked: boolean;
  onChange(): void;
}> {
  public render() {
    const {checked} = this.props;
    return (
      <Checkbox onChange={this.onChange} checked={checked}>
        <FormattedMessage id="XEditorXWidget.ScrambleSentences.ToggleExample" />
      </Checkbox>
    );
  }

  private onChange = () => {
    this.props.onChange();
  };
}

export default ToggleExample;
